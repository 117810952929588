import React, { useState } from 'react';
import APIService from "services/apiService";
import { alertService } from 'services/alertService';
import * as Constants from 'components/Constants';
import ClickOutsideListner from 'components/ClickOutsideListner';
import { BADGES } from './../Constants';
import './styles.scss';

const ManageMetrics = ({setShowManageMetrics, departmentsList, metrics, loadingMetrics, handleMetricsData}) => {

    const [selectedDepartment, setSelectedDepartment] = useState({ name: 'Generic', id: 'generic' });
    const [selectedBadge, setSelectedBadge] = useState(null);
    const [showAddOrEditMetric, setShowAddOrEditMetric] = useState(false);
    const [editMetricId, setEditMetricId] = useState(0);

    
    const [name, setName] = useState('');
    const [value, setValue] = useState(1);
    const [description, setDescription] = useState('');

    const handleSaveMetricBtn = () => {
        if (!name || !value || !selectedBadge || !description) {
            alertService.warning('Please fill/select all the required fields');
            return;
        }

        const payload = {
            name: name,
            value: value,
            badge: selectedBadge,
            description: description,
            department_id: selectedDepartment.id !== 'generic' ? selectedDepartment.id : null
        };

        APIService.apiRequest(Constants.API_BASE_URL + '/performance/metric', payload, false, 'POST')
            .then(response => {
                if (response.status === 1) {
                    // console.log('handleSaveMetricBtn - if response', response);
                    alertService.success(response.msg);
                    // setMetrics([...metrics, response.data[0]]);
                    handleMetricsData([...metrics, response.data]);
                    hideAddOrEditMetricForm();
                } else {
                    alertService.error(`Error occured while saving the metric`);
                    console.log('handleSaveMetricBtn - else response', response.msg);
                }
            }).catch(err => {
                alertService.error(`Error: ${err.msg}`);
                console.log('handleSaveMetricBtn - error', err);
            });
    }

    const hideAddOrEditMetricForm = () => {
        setShowAddOrEditMetric(false);
        setName('');
        setValue(1);
        setDescription('');
        setEditMetricId(0);
        setSelectedBadge(null);
    }

    const handleEditMetricBtn = (metricDetails) => {
        setShowAddOrEditMetric(true);
        setEditMetricId(metricDetails.id);
        setName(metricDetails.name);
        setValue(metricDetails.value);
        setDescription(metricDetails.description);
        setSelectedBadge(metricDetails.badge);
    }

    const modifyMetric = (metricId, key, value) => {
        let payload = {};
        payload[key] = value;

        if ((key === 'name' || key === 'description') && value === '') return;
        if (key === 'value' && value <= 0) return;

        APIService.apiRequest(Constants.API_BASE_URL + `/performance/metric/${metricId}`, payload, false, 'PUT')
            .then(response => {
                if (response.status === 1) {
                    // console.log('modifyMetric - if response', response);
                    let metricsCopy = JSON.parse(JSON.stringify(metrics));
                    metricsCopy.map(metric => {
                        metric[key] = metric.id === metricId ? value : metric[key];
                        return metric;
                    })
                    // setMetrics(metricsCopy);
                    handleMetricsData(metricsCopy);
                    alertService.success(response.msg);
                } else {
                    alertService.error(`Error occured while modifying the metric`);
                    console.log('modifyMetric - else response', response.msg);
                }
            }).catch(err => {
                alertService.error(`Error: ${err.msg}`);
                console.log('modifyMetric - error', err);
            });
    }

    const handleDeleteMetricBtn = (metricId) => {
        APIService.apiRequest(Constants.API_BASE_URL + `/performance/metric/${metricId}`, null, false, 'DELETE')
            .then(response => {
                if (response.status === 1) {
                    // console.log('handleDeleteMetricBtn - if response', response);
                    alertService.success(response.msg);
                    // setMetrics(metrics.filter(metric => metric.id !== metricId));
                    handleMetricsData(metrics.filter(metric => metric.id !== metricId));
                } else {
                    alertService.error(`Error occured while deleting the metric`);
                    console.log('handleDeleteMetricBtn - else response', response.msg);
                }
            }).catch(err => {
                alertService.error(`Error: ${err.msg}`);
                console.log('handleDeleteMetricBtn - error', err);
            });
    }

    const deptsListContent = [{ name: 'Generic', id: 'generic' }, ...departmentsList].map(dept => <p className={'dept' + (dept.name === selectedDepartment.name ? ' selected-item' : '')} onClick={() => setSelectedDepartment(dept)} key={dept.id}>{dept.name}</p>);
    const metricsContent = metrics && metrics.filter(metric => {
        return selectedDepartment.id === 'generic' ? metric.department_id === null : metric.department_id === selectedDepartment.id
    }).map(metric => <div className='metric' key={metric.id}>
        <span className={'metric-icon ' + (BADGES.includes(metric.badge) ? metric.badge : 'icon-badge-1')}></span>
        <div className='metric-details'>
            <p>{metric.name}</p>
            <p className='description'>{metric.description}</p>
        </div>
        <div className='action-btn-wrapper ml-auto'>
            <button
                className='icon-btn'
                onClick={() => handleEditMetricBtn(metric)}
            >
                <span className='icon btn-edit'></span>
            </button>
            <button
                className='icon-btn'
                onClick={() => handleDeleteMetricBtn(metric.id)}
            >
                <span className='icon btn-delete'></span>
            </button>
        </div>
    </div>)
    
    const badgesContent = BADGES.map((badge, i) => <div className={`badge-wrapper${selectedBadge === badge ? ' selected' : ''}`} key={i} onClick={() => {
        setSelectedBadge(badge);
        editMetricId && modifyMetric(editMetricId, 'badge', badge);
    }}>
        <span className={`badge ${badge}`}></span>
    </div>)

    return (
        <section id='manage-metrics-wrapper'>
            <ClickOutsideListner onOutsideClick={() => setShowManageMetrics(false)}>
                <div className='manage-metrics-dialog'>
                    <div className='tabs'>
                        <span className='label'>Department</span>
                        <div className='department-wrapper'>
                            {deptsListContent}
                        </div>
                    </div>
                    <div className='tab-content'>
                        {showAddOrEditMetric ?
                            <div className='add-metric-content-wrapper'>
                                <p className='label'>{editMetricId ? 'Edit Metric' : <span>Add new Metric</span>}</p>
                                <div className='name-value-container'>
                                    <div className='field-wrapper'>
                                        <label className='label' htmlFor='name'>Name*</label>
                                        <input
                                            type='text'
                                            value={name}
                                            className='metric-name'
                                            onChange={(e) => setName(e.target.value)}
                                            onBlur={(e) => editMetricId && modifyMetric(editMetricId, 'name', e.target.value)}
                                        />
                                    </div>
                                    <div className='field-wrapper'>
                                        <label className='label' htmlFor='value'>Value*</label>
                                        <input
                                            type='number'
                                            value={value}
                                            min={1}
                                            className='metric-value'
                                            onChange={(e) => setValue(e.target.value)}
                                            onBlur={(e) => editMetricId && modifyMetric(editMetricId, 'value', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <span className='label'>Select Badge*</span>
                                <div className='badge-container'>
                                    {/* <div className='badge-wrapper bg-gray'>
                                        <span className='badge add-badge'></span>
                                    </div> */}
                                    {badgesContent}

                                </div>
                                <div className="description">
                                    <label htmlFor="description" className='label'>Description*</label><br />
                                    <textarea
                                        className="textarea"
                                        id='description'
                                        name="description"
                                        value={description}
                                        rows="3"
                                        maxLength="200"
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder='Type here'
                                        onBlur={(e) => editMetricId && modifyMetric(editMetricId, 'description', e.target.value)}
                                    ></textarea>
                                    <span className='textLength'>{`${description.length}/200`}</span>
                                </div>
                                <div className='action-btn-wrapper'>

                                    {!editMetricId && <button
                                        className={"btn-save btn-with-icon btn-small ml-auto"}
                                        // disabled={!(props.funnelInfo.step === 2 && !isStep2Invalid)}
                                        // title={isStep2Invalid ? 'Please fill all the required information' : 'Save the funnel'}
                                        onClick={() => handleSaveMetricBtn()}
                                    >
                                        <i></i>
                                        <span>Save</span>
                                    </button>}
                                    <button
                                        className={"btn-cancel btn-with-icon btn-small" + (editMetricId ? ' ml-auto' : '')}
                                        onClick={hideAddOrEditMetricForm}
                                    >
                                        <i></i>
                                        <span>Cancel</span>
                                    </button>
                                </div>
                            </div>
                            :
                            <div className='metric-content-wrapper'>
                                <div className='task-content-header'>
                                    <span className='label'>Available Metrics</span>
                                    <span className='metric-count ml-auto'>{!metricsContent || !metricsContent.length ? 0 : metricsContent && metricsContent.length}</span>
                                    <button
                                        className='btn-add btn-with-icon btn-small'
                                        onClick={() => setShowAddOrEditMetric(true)}
                                    >
                                        <i></i>
                                        <span>Add</span>
                                    </button>
                                </div>
                                {loadingMetrics && !metrics ? <div className="loader-container"><span className='loader'></span></div> :
                                    <div className='task-content-body'>
                                        {metricsContent && metricsContent.length ? metricsContent : <p className='general-info'>No metrics to display</p>}
                                    </div>}
                            </div>}
                    </div>
                </div>
            </ClickOutsideListner>
        </section>
    )
}

export default ManageMetrics