import React, { useState } from 'react';
import ClickOutsideListener from './components/ClickOutsideListener';
import {
  DatePanel,
  MonthPanel,
  QuarterPanel,
  YearPanel,
} from './components/Panels';
import { isDateEqual, giveQuarterFromMonth } from './components/utils';
import { IconCalendar, IconClear } from './components/SvgIcons';
import './calendar-styles.scss';
import { isValid } from 'date-fns';

const DatePicker = ({
  picker,
  showInline,
  placeholder,
  align,
  allowClear,
  disable,
  date,
  onChange,
  disableFn,
  showTodayBtn = false,
}) => {
  picker = picker || 'date';
  showInline = showInline || false;
  align = align || 'left';
  date = date || null;
  allowClear = allowClear === false ? false : true;
  disable = disable || false;

  const [inputFocused, setInputFocused] = useState(false);

  const handlePickerClick = (e) => {
    setTimeout(() => {
      setInputFocused(true);
    });
  };

  const handleClearBtn = (e) => {
    e.stopPropagation();
    handleDateChange('', null);
  };

  const handleOutsideClick = (e) => {
    // console.log('outside clicked');
    setInputFocused(false);
  };

  const handleDateChange = (dateStr, date) => {
    onChange(dateStr, date);
    setTimeout(() => {
      setInputFocused(false);
    }, 200);
  };

  const giveFormattedDate = () => {
    if (date === null || date === '') {
      return '';
    }
    if (typeof date === 'string' && isValid(new Date(date)))
      date = new Date(date);
    if (picker === 'date') {
      return (
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        '/' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
        '/' +
        date.getFullYear()
      );
    }
    if (picker === 'month') {
      return (
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        '-' +
        date.getFullYear()
      );
    }
    if (picker === 'quarter') {
      return (
        'Q' + giveQuarterFromMonth(date.getMonth()) + '-' + date.getFullYear()
      );
    }
    // for year
    return date.getFullYear();
  };

  const displayDate = giveFormattedDate();
  placeholder =
    placeholder || 'Select ' + picker[0].toUpperCase() + picker.slice(1);

  return (
    <div
      className={
        'asc-date-picker' +
        (showInline ? ' inline' : '') +
        (disable ? ' disabled' : '')
      }
    >
      {!showInline && (
        <div
          className={
            'asc-picker-input' +
            (inputFocused ? ' asc-picker-input-focused' : '')
          }
          onClick={handlePickerClick}
        >
          <div className='asc-picker-input-inner'>
            <input
              placeholder={placeholder}
              autoComplete='off'
              size='12'
              value={displayDate}
              readOnly
              tabIndex='-1'
            />
            <span
              className='asc-picker-suffix'
              style={{
                visibility:
                  date === null || allowClear === false ? 'visible' : 'hidden',
              }}
            >
              <span role='img' className='asc-picker-icon asc-picker-cal-icon'>
                <IconCalendar />
              </span>
            </span>
            {allowClear === true && date !== null && (
              <span className='asc-picker-clear' onClick={handleClearBtn}>
                <span
                  role='img'
                  className='asc-picker-icon asc-picker-cal-clear'
                >
                  <IconClear />
                </span>
              </span>
            )}
          </div>
        </div>
      )}
      {!showInline && inputFocused && (
        <ClickOutsideListener onOutsideClick={handleOutsideClick}>
          <div
            className='asc-picker-container'
            style={{
              left: align === 'left' ? '0' : 'auto',
              right: align === 'right' ? '0' : 'auto',
            }}
          >
            <DatePickerPanel
              picker={picker}
              date={date}
              onChange={handleDateChange}
              disableFn={disableFn}
              showTodayBtn={showTodayBtn}
            />
          </div>
        </ClickOutsideListener>
      )}
      {showInline && (
        <DatePickerPanel
          picker={picker}
          date={date}
          onChange={handleDateChange}
          disableFn={disableFn}
          showTodayBtn={showTodayBtn}
        />
      )}
    </div>
  );
};

const DatePickerPanel = ({ picker, date, onChange, disableFn, showTodayBtn }) => {
  const [year, setYear] = useState(
    date ? date.getFullYear() : new Date().getFullYear()
  );
  const [month, setMonth] = useState(
    date ? date.getMonth() : new Date().getMonth()
  );
  const [panel, setPanel] = useState(picker);
  const [prevPanel, setPrevPanel] = useState('');

  const handleDateClick = ([year, month, day]) => {
    const dObj = new Date(year, month, day);
    const dStr =
      dObj.getFullYear() +
      '-' +
      (dObj.getMonth() + 1 < 10
        ? '0' + (dObj.getMonth() + 1)
        : dObj.getMonth() + 1) +
      '-' +
      (dObj.getDate() < 10 ? '0' + dObj.getDate() : dObj.getDate());
    onChange(dStr, dObj);
  };
  const handleMonthClick = ([year, month]) => {
    // console.log('month clicked', month);
    setMonth(month);
    if (picker === 'date') {
      changePanels('', 'date');
    } else if (picker === 'year') {
      changePanels('month', 'date');
    } else {
      // send the date object with selected month and selected year and 1st date
      const dObj = new Date(year, month, 1);
      const dStr =
        dObj.getFullYear() +
        '-' +
        (dObj.getMonth() + 1 < 10
          ? '0' + (dObj.getMonth() + 1)
          : dObj.getMonth() + 1);
      onChange(dStr, dObj);
    }
  };
  const handleYearClick = ([year]) => {
    // console.log('year clicked', year);
    setYear(year);
    if (picker === 'date' || picker === 'month' || picker === 'quarter') {
      changePanels('', prevPanel);
    } else if (picker === 'year') {
      changePanels('year', 'month');
    } else {
      // send the date object with selected year and Quarter & 1st date
      const dObj = new Date(year, 0, 1);
      const dStr = dObj.getFullYear() + '';
      onChange(dStr, dObj);
    }
  };
  const handleQuarterClick = ([year, quarter]) => {
    const dObj = new Date(year, Math.floor(quarter - 1) * 3, 1);
    const dStr = dObj.getFullYear() + '-Q' + quarter;
    onChange(dStr, dObj);
  };

  const handleYearBtnOnDatePanel = () => {
    // console.log('year clicked on date panel');
    changePanels('date', 'year');
  };
  const handleYearBtnOnMonthPanel = () => {
    // console.log('year clicked on month panel');
    changePanels('month', 'year');
  };
  const handleYearBtnOnQuarterPanel = () => {
    // console.log('year clicked on quarter panel');
    changePanels('quarter', 'year');
  };
  const handleMonthBtnOnDatePanel = () => {
    // console.log('month clicked on date panel');
    changePanels('date', 'month');
  };

  const changePanels = (prevP, currP) => {
    setTimeout(() => {
      setPrevPanel(prevP);
      setPanel(currP);
    });
  };

  const handlePrevMonthClick = (e) => {
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevYear = month === 0 ? year - 1 : year;
    setMonth(prevMonth);
    setYear(prevYear);
  };
  const handleNextMonthClick = (e) => {
    if (disableFn && disableFn(new Date(year, month + 1, 1))) return;
    const nextMonth = (month + 1) % 12;
    const nextYear = month === 11 ? year + 1 : year;
    setMonth(nextMonth);
    setYear(nextYear);
  };

  const handlePrevYearClick = (e) => setYear(year - 1);
  const handleNextYearClick = (e) => {
    if (disableFn && disableFn(new Date(year + 1, 0, 1))) return;
    return setYear(year + 1);
  }
  const handlePrevDecadeClick = () => setYear(year - 10);
  const handleNextDecadeClick = () => {
    if (disableFn && disableFn(new Date(year + 10, 0, 1))) return;
    setYear(year + 10)
  }

  return (
    <div className='asc-picker-panel'>
      {panel === 'date' && (
        <DatePanel
          year={year}
          month={month}
          onDateClick={handleDateClick}
          onMonthBtn={handleMonthBtnOnDatePanel}
          onYearBtn={handleYearBtnOnDatePanel}
          onPrevMonthBtn={handlePrevMonthClick}
          onNextMonthBtn={handleNextMonthClick}
          disable={disableFn}
          selectDate={(dObj) => (date ? isDateEqual(dObj, date) : false)}
          showTodayBtn={showTodayBtn}
        />
      )}
      {panel === 'month' && (
        <MonthPanel
          year={year}
          month={month}
          onMonthClick={handleMonthClick}
          onYearBtn={handleYearBtnOnMonthPanel}
          onPrevYearBtn={handlePrevYearClick}
          onNextYearBtn={handleNextYearClick}
          disable={disableFn}
          selectMonth={(month, y) => (date ? month === date.getMonth() : false)}
        />
      )}
      {panel === 'year' && (
        <YearPanel
          year={year}
          onYearClick={handleYearClick}
          onPrevDecadeBtn={handlePrevDecadeClick}
          onNextDecadeBtn={handleNextDecadeClick}
          disable={disableFn}
          selectYear={(y) => (date ? y === date.getFullYear() : false)}
        />
      )}
      {panel === 'quarter' && (
        <QuarterPanel
          year={year}
          onQuarterClick={handleQuarterClick}
          onYearBtn={handleYearBtnOnQuarterPanel}
          onPrevYearBtn={handlePrevYearClick}
          onNextYearBtn={handleNextYearClick}
          disable={disableFn}
        // selectQuarter={(quarter, y) => date ? y === date.getFullYear() && quarter === giveQuarterFromMonth(date.getMonth()) : false}
        />
      )}
    </div>
  );
};

export default DatePicker;
