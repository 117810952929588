export const BADGES = [
  "icon-badge-1",
  "icon-badge-2",
  "icon-badge-3",
  "icon-badge-4",
  "icon-badge-5",
  "Icon-help",
  "Icon-solution",
  "Icon-task-completed",
  "Icon-yellow-card",
];

export const STATUS = ['All', 'Accepted', 'Pending'];