import { API_BASE_URL } from "components/Constants";
import { useContext, createContext, useState } from "react"
import { useHistory } from "react-router-dom"
import APIService from "services/apiService";

export const JobInfoContext = createContext({
  jobInfo: {
    title: "",
    description: "",
    employment: "",
    location: "",
  },
  fetchJobInfo: async () => { },
});

export function JobInfoProvider({ children }) {
  const [jobInfo, setJobInfo] = useState({
    title: "",
    description: "",
    employment: "",
    location: "",
  })

  const fetchJobInfo = async (id, controller) => {
    try {
      const URL = API_BASE_URL + `/new_job_application/${id}`;
      const response = await APIService.apiRequest(
        URL,
        null,
        false,
        "GET",
        controller
      );
      if (response.status === 1) {
        if (response.output.is_active !== 1) {
          window.location.href = "/oops";
          return;
        }

        if (!response.output.job_title) {
          window.location.href = "/not-found";
          return;
        }

        setJobInfo({
          title: response.output.job_title,
          description: response.output.job_description,
          employment: response.output.employment,
          location: response.output.location,
        });
      } else {
        if (
          response.msg ===
          "We are no longer accepting applications for this job"
        ) {
          window.location.href = "/oops";
        } else {
          window.location.href = "/not-found";
        }
      }
    } catch (err) {
      console.error("CATCH: Error fetching job info", err);
    }
  };

  return (
    <JobInfoContext.Provider value={{ jobInfo, fetchJobInfo }}>
      {children}
    </JobInfoContext.Provider>
  )
}

export function useJobInfo() {
  return useContext(JobInfoContext);
}