import { useCallback, useState } from "react";
// components
import DatePicker from "components/ReactCalendar/DatePicker";
import ClickOutsideListner from "components/ClickOutsideListner";
import { MouseTooltip } from "components/ToolTip";
import { useAttendanceFilters } from "../context";
// hooks
import { format, addDays, subDays } from "utils/date";
import { convertTimeZone } from '../utils'
import useClickPreventionOnDoubleClick from "hooks/useClickPreventionOnDoubleClick";
import useWindowDimensions from "hooks/useWindowDimensions";
// styles
import "./AttendanceDatePicker.scss";

export default function AttendanceDatePicker() {
  const { date: selectedDate, handleDateChange } = useAttendanceFilters();
  const { width } = useWindowDimensions();
  const isDesktopVersion = width > 640;

  const [showDatePicker, setShowDatePicker] = useState(false);
  function toggleDatePicker() {
    setShowDatePicker(!showDatePicker);
  }

  function onDateChange(date) {
    toggleDatePicker();
    if (format(new Date(selectedDate)) === format(new Date(date))) {
      return;
    }
    handleDateChange(new Date(date));
  }

  function handleToday() {
    // toggleDatePicker();
    let date = convertTimeZone();
    if (format(new Date(selectedDate)) === format(new Date(date))) {
      return;
    }
    handleDateChange(date);
  }

  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(toggleDatePicker, handleToday);

  function handleRightArrowClick() {
    handleDateChange(addDays(selectedDate, 1));
  }

  function handleLeftArrowClick() {
    handleDateChange(subDays(selectedDate, 1));
  }

  const isToday = useCallback((date) => {
    return format(new Date(date), "YYYY-MM-DD") === format(convertTimeZone(), "YYYY-MM-DD");
  }, [])

  return (
    <div className="datepicker">
      {/* Left Arrow */}
      <button
        className="datepicker__leftArrow"
        onClick={handleLeftArrowClick}
      ></button>
      <div className="datepicker__controls">
        {/* Formatted date */}
        <MouseTooltip
          show={!showDatePicker && isDesktopVersion}
          content="Select date or Double click for today"
        >
          <button
            className={`datepicker__controls__selectedDate ${!isDesktopVersion ? 'date-icon' : ''}`}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
          >
            {!isDesktopVersion ? '' : isToday(selectedDate)
              ? "Today"
              : format(new Date(selectedDate), "ddd, DD MMM YYYY")}
          </button>
        </MouseTooltip>

        {/* Date picker modal */}
        <div className="datepicker__controls__modal">
          {showDatePicker && (
            <ClickOutsideListner onOutsideClick={toggleDatePicker}>
              <div className="datepicker__controls__modal__inner">
                <DatePicker
                  onChange={onDateChange}
                  date={selectedDate}
                  showTodayBtn
                  showInline
                />
              </div>
            </ClickOutsideListner>
          )}
        </div>
      </div>

      {/* Right Arrow */}
      <button
        className="datepicker__rightArrow"
        onClick={handleRightArrowClick}
      ></button>
    </div>
  );
}
