import { createContext, useContext, useEffect, useRef, useState } from "react";

import { alertService } from "services/alertService";
import APIService from "services/apiService";

import { deleteScrollPosition } from "hooks/useScrollRestoration";

import { emitter } from "components/SuperTabs/SuperTabs";

import { getUser } from "utils/Common";
import { API_BASE_URL } from "components/Constants";
import { PAGE_IDS } from "components/Navigation";

export const PerformanceContext = createContext({
    departmentsList: [],
    leaderBoard: null,
    updateLeaderBoard: (leaderBoardData) => { },
    isSearchResult: false,
    updateIsSearchResult: (isSearchResult) => { },
    metrics: null,
    updateMetrics: (metricsData) => { },

    individualTabMetrics: {},
    updateIndividualTabMetricsData: (resId, data) => { },
    selectedMetrics: {},
    updateSelectedMetrics: (resId, data) => { },
    savedTabStates: {},
    updateSavedTabStates: (resId, type, value) => { },

    loggedInUserResId: null,
    metaDataRef: { current: null }
});

const initialTabState = {
    type: 'request',
    activeTab: 'New Credit',
    receivedSearchString: '',
    givenSearchString: '',
    reason: '',
    showFilters: false,
    recipientsList: [],
    creditReceivedFromList: [],
    creditGivenToList: [],
    receivedMetricList: [],
    givenMetricList: [],
    selectedMetricFrom: '',
    selectedMetricTo: '',
    selectedReceivedMetricFilter: '',
    selectedGivenMetricFilter: '',
    receivedStatus: 'All',
    givenStatus: 'All',
};

export function PerformanceProvider({ children }) {

    const [loggedInUserResId] = useState(() => getUser()?.resource?.id || null);
    const [departmentsList, setDepartmentsList] = useState([]);
    const [leaderBoard, setLeaderBoard] = useState(null);
    const [isSearchResult, setIsSearchResult] = useState(false);
    const [metrics, setMetrics] = useState(null);

    const [savedTabStates, setSavedTabStates] = useState({});
    const [individualTabMetrics, setIndividualTabMetrics] = useState({});
    const [selectedMetrics, setSelectedMetrics] = useState({});

    useEffect(() => {
        const unsub = emitter.subscribe('superTabClose', ({ appId, isSubTab, tab }) => {
            if (appId === PAGE_IDS.PERFORMANCE && isSubTab) {
                const id = tab.id;
                const removeTabFromState = ({ [id]: _, ...newState }) => newState
                setSavedTabStates(removeTabFromState);
                setIndividualTabMetrics(removeTabFromState);
                setSelectedMetrics(removeTabFromState);

                deleteScrollPosition(`performance-credits-given-${id}`);
                deleteScrollPosition(`performance-credits-received-${id}`);
                deleteScrollPosition(`performance-metrics-${id}`);
            }
        })
        return unsub;
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const getDept = () => {
            APIService.apiRequest(API_BASE_URL + '/department', null, false, 'GET', controller)
                .then(response => {
                    if (response.status === 1) {
                        setDepartmentsList(response.output);
                    } else {
                        if (response instanceof DOMException && response.name === 'AbortError') return;
                        alertService.error(`Error occured while fetching department details`);
                        console.log('getDept - else response', response.msg);
                    }
                }).catch(err => {
                    alertService.error(`Error: ${err.msg}`);
                    console.log('getDept - error', err);
                });
        }
        getDept();
        return () => controller.abort();
    }, []);

    const metaDataRef = useRef(null);

    const updateLeaderBoard = (leaderBoardData) => setLeaderBoard(leaderBoardData);
    const updateIsSearchResult = (isSearchResult) => setIsSearchResult(isSearchResult);
    const updateMetrics = (metricsData) => setMetrics(metricsData);


    const updateIndividualTabMetricsData = (resId, data) => {
        setIndividualTabMetrics(prevMetrics => ({
            ...prevMetrics,
            [resId]: data
        }));
    }

    const updateSelectedMetrics = (resId, data) => {
        setSelectedMetrics(prevSelectedMetric => ({
            ...prevSelectedMetric,
            [resId]: data
        }));
    }

    const updateSavedTabStates = (resId, type, value) => {
        setSavedTabStates(prevState => {
            const newState = { ...prevState };

            if (type === 'newTab') {
                newState[resId] = initialTabState;
            } else if (type === 'reset') {
                newState[resId] = { ...newState[resId], type: 'request', reason: '', recipientsList: [] };
            } else if (type === 'filterReceivedMetrics') {
                newState[resId] = { ...newState[resId], creditReceivedFromList: value.resource, receivedMetricList: value.metric };
            } else if (type === 'filterGivenMetrics') {
                newState[resId] = { ...newState[resId], creditGivenToList: value.resource, givenMetricList: value.metric };
            } else if (type === 'clearReceivedFilterValue') {
                newState[resId] = { ...newState[resId], selectedMetricFrom: '', selectedReceivedMetricFilter: '', receivedSearchString: '', receivedStatus: 'All' };
            } else if (type === 'clearGivenFilterValue') {
                newState[resId] = { ...newState[resId], selectedMetricTo: '', selectedGivenMetricFilter: '', givenSearchString: '', givenStatus: 'All' };
            } else {
                newState[resId] = { ...newState[resId], [type]: value };
            }

            return newState;
        });
    };

    return (
        <PerformanceContext.Provider
            value={{
                departmentsList,
                leaderBoard,
                updateLeaderBoard,
                isSearchResult,
                updateIsSearchResult,
                metrics,
                updateMetrics,

                savedTabStates,
                updateSavedTabStates,
                individualTabMetrics,
                updateIndividualTabMetricsData,
                selectedMetrics,
                updateSelectedMetrics,

                loggedInUserResId,
                metaDataRef,
            }}
        >
            {children}
        </PerformanceContext.Provider>
    );

}

export function usePerformanceContext() {
    return useContext(PerformanceContext);
}