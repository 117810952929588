import { useEffect, useState, useRef } from 'react';
import './SpeedList.scss';

const SpeedList = (props) => {
  const inputRef = useRef(null);
  const [searchedText, setSearchedText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(props.options);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const isVisible = props.visible ?? true;

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) => (prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex));
    } else if (event.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) => (prevIndex >= 0 ? prevIndex - 1 : prevIndex));
    } else if (event.key === 'Enter') {
      if(selectedIndex > -1){
        handleOptionSelection(filteredOptions[selectedIndex]);
      }
    }
  };

  useEffect(() => {
    if (isVisible) window.addEventListener('keydown', handleKeyDown);
    return () => {
      if (isVisible) window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex, filteredOptions, isVisible]);
  
  //will be call on options update
  useEffect(()=>{
    setFilteredOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    if(props.inputFocus && selectedIndex===-1){
      inputRef.current.focus();
    } else {
      inputRef.current.blur();
    }
  }, [props.inputFocus, selectedIndex]);


  // * Show/Hide Header Navigation
  const handleOptionSelection = (option) => {
    props.onSelect(option);
  }

  const handleSearchTextChange = (e) => {
    setSearchedText(e.target.value);
  }

  useEffect(() => {
    if(typeof props.options==='object'){
      let updatedFilteredOptions = [];
      if(props.hasGroups){
        updatedFilteredOptions = {};
        if(Object.keys(props.options).length > 0){
          Object.keys(props.options).forEach((item) => {
            let keyWiseResults = props.options[item].filter((item) => (item.name.toLowerCase()).includes(searchedText.toLowerCase()));
            updatedFilteredOptions[item] = keyWiseResults;
          });
        }
      } else {
        updatedFilteredOptions = props.options.filter((item) => (item.name.toLowerCase()).includes(searchedText.toLowerCase()));
      }
  
      setFilteredOptions(updatedFilteredOptions);
    }
  }, [searchedText]);


  return (
    <div id="speedlist-wrapper" className={props.position!==undefined ? props.position : ''}>
      <div className="speedlist-search-wrapper">
        <div className="speedlist-search">
          <input type="text" ref={inputRef} name="txt-name" className="input-search" /*placeholder="Search"*/ value={searchedText} onChange={handleSearchTextChange} autoComplete='off' />
        </div>
      </div>

      <div className="speedlist-content-wrapper">
        {/* List with group */}
        {props.hasGroups && !Array.isArray(filteredOptions) && Object.keys(filteredOptions).length > 0 &&
          Object.keys(filteredOptions).map((group) => {
            return (<div className="speedlist-group">
              <h3>{group}</h3>

              <div className="speedlist-options">
                {filteredOptions[group].length > 0 &&
                  filteredOptions[group].map((option, i) => {
                    return <div key={i} className={"option "+(option.name.toLowerCase().replaceAll(' ', '-'))+' '+(option.name===props.selectedOption.name ? 'selected' : '')} onClick={()=>handleOptionSelection(option)}>{option.name}</div>
                  })
                }
              </div>

              {filteredOptions[group].length === 0 &&
                <div className="no-results">No records found.</div>
              }
            </div>)
          })
        }
        
        {/* List without group */}
        {!props.hasGroups &&
          <div className="speedlist-group">
            <div className="speedlist-options">
              {filteredOptions.length > 0 &&
                filteredOptions.map((option, i) => {
                  let option_class_name = option.name.toLowerCase().replaceAll(' ', '-');
                  let hover_class_name = (i === selectedIndex ? 'hovered' : '');
                  let selected_class_name = (option.name===props.selectedOption.name ? 'selected' : '');
                  return <div key={i} className={"option "+(option_class_name)+' '+(hover_class_name)+' '+(selected_class_name)} onClick={()=>handleOptionSelection(option)}>{option.name}</div>
                })
              }
            </div>

            {filteredOptions.length === 0 &&
              <div className="no-results">No records found.</div>
            }
          </div>
        }
        
      </div>
    </div>
  );
}
 
export default SpeedList;