export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

export const locationOptions = [
  {
    state: "Andaman and Nicobar Islands",
    cities: ["Car Nicobar", "Diglipur", "Port Blair", "Rangat"],
  },
  {
    state: "Andhra Pradesh",
    cities: [
      "Anantapur",
      "Guntur",
      "Kadapa",
      "Kakinada",
      "Kurnool",
      "Nellore",
      "Rajahmundry",
      "Tirupati",
      "Vijayawada",
      "Visakhapatnam",
    ],
  },
  {
    state: "Arunachal Pradesh",
    cities: ["Itanagar", "Naharlagun", "Pasighat", "Tawang", "Ziro"],
  },
  {
    state: "Assam",
    cities: [
      "Dibrugarh",
      "Guwahati",
      "Jorhat",
      "Nagaon",
      "Silchar",
      "Tezpur",
      "Tinsukia",
    ],
  },
  {
    state: "Bihar",
    cities: [
      "Arrah",
      "Bhagalpur",
      "Chhapra",
      "Darbhanga",
      "Gaya",
      "Muzaffarpur",
      "Patna",
      "Purnia",
    ],
  },
  { state: "Chandigarh", cities: ["Chandigarh"] },
  {
    state: "Chhattisgarh",
    cities: ["Bhilai", "Bilaspur", "Durg", "Korba", "Raipur", "Rajnandgaon"],
  },
  {
    state: "Dadra and Nagar Haveli and Daman & Diu",
    cities: ["Daman", "Diu", "Silvassa"],
  },
  {
    state: "Goa",
    cities: ["Mapusa", "Margao", "Panaji", "Ponda", "Vasco da Gama"],
  },
  {
    state: "Gujarat",
    cities: [
      "Ahmedabad",
      "Bhavnagar",
      "Gandhinagar",
      "Jamnagar",
      "Junagadh",
      "Rajkot",
      "Surat",
      "Vadodara",
    ],
  },
  {
    state: "Haryana",
    cities: [
      "Ambala",
      "Faridabad",
      "Gurugram",
      "Hisar",
      "Karnal",
      "Panchkula",
      "Panipat",
      "Rohtak",
    ],
  },
  {
    state: "Himachal Pradesh",
    cities: [
      "Dharamshala",
      "Hamirpur",
      "Kullu",
      "Manali",
      "Mandi",
      "Shimla",
      "Solan",
    ],
  },
  {
    state: "Jammu & Kashmir",
    cities: [
      "Anantnag",
      "Baramulla",
      "Jammu",
      "Kathua",
      "Srinagar",
      "Udhampur",
    ],
  },
  {
    state: "Jharkhand",
    cities: [
      "Bokaro",
      "Deoghar",
      "Dhanbad",
      "Giridih",
      "Hazaribagh",
      "Jamshedpur",
      "Ranchi",
    ],
  },
  {
    state: "Karnataka",
    cities: [
      "Belagavi",
      "Bengaluru",
      "Bijapur",
      "Davangere",
      "Gulbarga",
      "Hubli",
      "Mangaluru",
      "Mysuru",
      "Shimoga",
      "Tumkur",
    ],
  },
  {
    state: "Kerala",
    cities: [
      "Alappuzha",
      "Kannur",
      "Kochi",
      "Kollam",
      "Kozhikode",
      "Malappuram",
      "Palakkad",
      "Thiruvananthapuram",
      "Thrissur",
    ],
  },
  { state: "Ladakh", cities: ["Kargil", "Leh"] },
  {
    state: "Lakshadweep",
    cities: ["Agatti", "Amini", "Kavaratti", "Minicoy"],
  },
  {
    state: "Madhya Pradesh",
    cities: [
      "Bhopal",
      "Gwalior",
      "Indore",
      "Jabalpur",
      "Ratlam",
      "Rewa",
      "Sagar",
      "Satna",
      "Ujjain",
    ],
  },
  {
    state: "Maharashtra",
    cities: [
      "Amravati",
      "Aurangabad",
      "Kolhapur",
      "Mumbai",
      "Nagpur",
      "Nashik",
      "Navi Mumbai",
      "Pune",
      "Sangli",
      "Solapur",
      "Thane",
    ],
  },
  {
    state: "Manipur",
    cities: ["Bishnupur", "Churachandpur", "Imphal", "Thoubal"],
  },
  {
    state: "Meghalaya",
    cities: ["Jowai", "Nongstoin", "Shillong", "Tura"],
  },
  {
    state: "Mizoram",
    cities: ["Aizawl", "Champhai", "Lunglei", "Serchhip"],
  },
  {
    state: "Nagaland",
    cities: ["Dimapur", "Kohima", "Mokokchung", "Tuensang"],
  },
  {
    state: "Odisha",
    cities: [
      "Balasore",
      "Berhampur",
      "Bhubaneswar",
      "Cuttack",
      "Puri",
      "Rourkela",
      "Sambalpur",
    ],
  },
  {
    state: "Puducherry",
    cities: ["Karaikal", "Mahe", "Puducherry", "Yanam"],
  },
  {
    state: "Punjab",
    cities: [
      "Amritsar",
      "Bathinda",
      "Hoshiarpur",
      "Jalandhar",
      "Ludhiana",
      "Mohali",
      "Patiala",
    ],
  },
  {
    state: "Rajasthan",
    cities: [
      "Ajmer",
      "Alwar",
      "Bharatpur",
      "Bikaner",
      "Jaipur",
      "Jodhpur",
      "Kota",
      "Udaipur",
    ],
  },
  { state: "Sikkim", cities: ["Gangtok", "Gyalshing", "Mangan", "Namchi"] },
  {
    state: "Tamil Nadu",
    cities: [
      "Chennai",
      "Coimbatore",
      "Erode",
      "Madurai",
      "Salem",
      "Thoothukudi",
      "Tiruchirappalli",
      "Tirunelveli",
      "Tiruppur",
      "Vellore",
    ],
  },
  {
    state: "Telangana",
    cities: [
      "Hyderabad",
      "Karimnagar",
      "Khammam",
      "Nizamabad",
      "Ramagundam",
      "Warangal",
    ],
  },
  {
    state: "The Government of NCT of Delhi",
    cities: ["Delhi", "New Delhi"],
  },
  {
    state: "Tripura",
    cities: ["Agartala", "Dharmanagar", "Kailashahar", "Udaipur"],
  },
  {
    state: "Uttar Pradesh",
    cities: [
      "Agra",
      "Aligarh",
      "Allahabad",
      "Bareilly",
      "Ghaziabad",
      "Gorakhpur",
      "Kanpur",
      "Lucknow",
      "Meerut",
      "Moradabad",
      "Noida",
      "Saharanpur",
      "Varanasi",
    ],
  },
  {
    state: "Uttarakhand",
    cities: [
      "Dehradun",
      "Haldwani",
      "Haridwar",
      "Kashipur",
      "Nainital",
      "Rishikesh",
      "Roorkee",
    ],
  },
  {
    state: "West Bengal",
    cities: [
      "Asansol",
      "Bardhaman",
      "Darjeeling",
      "Durgapur",
      "Howrah",
      "Kharagpur",
      "Kolkata",
      "Siliguri",
    ],
  },
];

export const cityOptions = locationOptions
  .reduce((acc, cur) => {
    cur.cities.forEach((city) => {
      acc.push({ label: city, value: city });
    });
    return acc;
  }, [])
  .sort((a, b) => a.label.localeCompare(b.label));

export const stateOptions = locationOptions.reduce((acc, cur) => {
  acc.push({
    label: cur.state,
    value: cur.state,
    cities: cur.cities,
  });
  return acc;
}, []);

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const URL_REGEX = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w.-]*)*\/?$/;
export const LINKEDIN_PROFILE_REGEX = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[^\s/?#]+\/?(?:\?.*)?$/;
export const GITHUB_REGEX = /^(https?:\/\/)?(www\.)?github\.com\/[a-zA-Z0-9-]+\/?$/;

export const questionsData = [
  {
    id: 1,
    title: "Technical Assessment",
    questions: [
      {
        id: 1,
        isRequired: true,
        question:
          "Share with us a technical project from work or a technical hobby project. How did you come up with the idea? What was the biggest challenge you faced and how did you overcome it? What is the outcome of the project?",
      },
      {
        id: 2,
        isRequired: true,
        question:
          "Tell us about a recent skill you have developed either on or off work, and how you approached the process.",
      },
      {
        id: 3,
        isRequired: true,
        question:
          "Describe a solution you integrated into your work production stack. Tell us how you evaluated the solution and convinced your team to adopt this.",
      },
      {
        id: 4,
        isRequired: true,
        question:
          "One of our developers built a page to display market information for over 500 cryptocurrencies in a single page. One day, our users are complaining that they are not able to view the website or that it takes too long to load. Suggest 3 possible causes and how you would solve them.",
      },
    ],
  },
];
