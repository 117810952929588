import { motion, AnimatePresence } from "framer-motion";
import React, { useState, useCallback, useEffect } from "react";
import Cropper from 'react-easy-crop'
import "./style.scss";
import SpeedSelect from 'react-speedselect';
import default_image from 'images/icon-thumb-profile.svg';
import { alertService } from 'services/alertService';
import { SITE_PREFIX } from "components/Constants";

export default function Modal({ image, handleClose, show, handleOnChange, setImage, tabId }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [cropShape, setCropShape] = useState('Rectangle')
    const [showGrid, setShowGrid] = useState(true)
    const [cropAspect, setCropAspect] = useState(4 / 3)
    const [rotation, setRotation] = useState(0)
    const [imageSelected, setImageSelected] = useState(null)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [select, setSelect] = useState('Zoom')
    const [zoom, setZoom] = useState(1)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    //Function to get cropped Image
    const getCroppedImg = async (
        imageSrc,
        pixelCrop,
        rotation = 0,
        flip = { horizontal: false, vertical: false }
    ) => {
        const image = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        if (!ctx) {
            return null
        }
        const rotRad = getRadianAngle(rotation)
        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
            image.width,
            image.height,
            rotation
        )
        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight
        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)
        // draw rotated image
        ctx.drawImage(image, 0, 0)
        if (pixelCrop.width === 0) {
            pixelCrop.width = canvas.width;
            pixelCrop.height = canvas.height;
        }
        // croppedAreaPixels values are bounding box relative
        // extract the cropped image using these values
        const data = ctx.getImageData(
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height
        )
        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height
        // paste generated rotate image at the top left corner
        ctx.putImageData(data, 0, 0)
        // As Base64 string
        // return canvas.toDataURL(‘image/jpeg’);
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                blob.name = 'image/jpeg';
                blob.lastModifiedDate = new Date();
                resolve(blob)
            }, 'image/jpeg')
        })
    }

    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.setAttribute('crossOrigin', '')
            if (url.includes("base64") || url.includes("blob")) {
                image.src = url;
            } else {
                image.src = url + "?" + new Date().getTime();
            }
        }).catch(() => {
            alertService.error("Can't edit this image ,please try another image");
        })

    function rotateSize(width, height, rotation) {
        const rotRad = getRadianAngle(rotation)
        return {
            width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }

    function getRadianAngle(degreeValue) {
        return degreeValue * (Math.PI / 180)
    }

    //Image Selection
    const handleImageSelection = (e) => {
        if (e.target.files[0]) {
            setImageSelected(URL.createObjectURL(e.target.files[0]));
        }
    }

    //Image Upload
    const handleImageUpload = async () => {
        try {
            const croppedImage = await getCroppedImg(imageSelected, croppedAreaPixels, rotation)
            let e = {
                target: {
                    files: [croppedImage]
                }
            }

            handleOnChange(e, "image");
            handleClose();
        } catch (e) {
            console.error(e)
        }
    }

    //Function to return the proper value for image aspect
    const cropAspectValue = (cropAspect) => {
        if (cropAspect === 4 / 3) {
            return "Default"
        } else if (cropAspect === 2000 / 1000) {
            return "Landscape"
        } else if (cropAspect === 1000 / 2000) {
            return "Portrait"
        }
    }

    //Lifecycle to fetch the data on tab change
    useEffect(() => {
        let data = {
            crop: crop,
            cropShape: cropShape,
            showGrid: showGrid,
            cropAspect: cropAspect,
            rotation: rotation,
            imageSelected: imageSelected,
            croppedAreaPixels: croppedAreaPixels,
            zoom: zoom
        }
        setImage[1]({ ...setImage[0], [SITE_PREFIX + `${tabId}`]: data });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        crop,
        cropShape,
        showGrid,
        cropAspect,
        rotation,
        imageSelected,
        croppedAreaPixels,
        select,
        zoom,
    ])

    useEffect(() => {
        let data = setImage[0][SITE_PREFIX + `${tabId}`]
        setCrop(data?.crop ? data.crop : { x: 0, y: 0 })
        setCropShape(data?.cropShape ? data.cropShape : 'Rectangle')
        setShowGrid(data?.showGrid === false ? data.showGrid : true)
        setCropAspect(data?.cropAspect ? data.cropAspect : 4 / 3)
        setRotation(data?.rotation ? data.rotation : 0)
        setImageSelected(
            data?.imageSelected ? data.imageSelected : image && image !== '' ? image : null
        )
        setCroppedAreaPixels(data?.croppedAreaPixels ? data.croppedAreaPixels : null)
        setSelect('Zoom')
        setZoom(data?.zoom ? data.zoom : 1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabId])

    return (
        <AnimatePresence>
            {show && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="profile-modal"
                >
                    <div className="modal-main" onClick={(e) => e.stopPropagation()}>
                        <button
                            className="btn-close"
                            onClick={handleClose}
                        />
                        {imageSelected ?
                            <div className="sub-crop">
                                <Cropper
                                    image={imageSelected}
                                    crop={crop}
                                    rotation={rotation}
                                    zoom={zoom}
                                    aspect={cropAspect}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                    onRotationChange={setRotation}
                                    cropShape={cropShape === "Rectangle" ? "rect" : "round"}
                                    showGrid={showGrid}

                                />
                            </div>
                            :
                            <div className="sub-blank" >
                                <img src={default_image} alt="default_image" />
                            </div>

                        }
                        <div className="sub-modal">
                            <input
                                type='file'
                                accept=".png, .jpg, .jpeg"
                                onChange={(e) => handleImageSelection(e)}
                            />
                            <h2>Select Image</h2>
                        </div>
                        {imageSelected ? (
                            <>
                                <div className='profile-info-tab'>
                                    <ul className='tabbar'>
                                        <li>
                                            <button id="Zoom" onClick={() => setSelect("Zoom")}>Zoom</button>
                                            {select === "Zoom" ? <hr></hr> : ""}
                                        </li>
                                        <li>
                                            <button id="Rotate" onClick={() => setSelect("Rotate")}>Rotate</button>
                                            {select === "Rotate" ? <hr></hr> : ""}
                                        </li>
                                        <li>
                                            <button id="Aspect" onClick={() => setSelect("Aspect")}>Aspect Ratio</button>
                                            {select === "Aspect" ? <hr></hr> : ""}
                                        </li>
                                        <li>
                                            <button id="CropShape" onClick={() => setSelect("CropShape")}>Crop Shape</button>
                                            {select === "CropShape" ? <hr></hr> : ""}
                                        </li>
                                        <li>
                                            <button id="ShowGrid" onClick={() => setSelect("ShowGrid")}>Crop Grid</button>
                                            {select === "ShowGrid" ? <hr></hr> : ""}
                                        </li>
                                    </ul>
                                </div>
                                {select === "Zoom" &&
                                    <div className="sub-range">
                                        <input
                                            type="range"
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aria-labelledby="Zoom"
                                            onChange={(e) => {
                                                setZoom(e.target.value)
                                            }}
                                            className="zoom-range"
                                        />
                                    </div>
                                }
                                {select === "Rotate" &&
                                    <div className="sub-range">
                                        <input
                                            value={rotation}
                                            type="range"
                                            min={0}
                                            max={360}
                                            step={90}
                                            aria-labelledby="Rotation"
                                            onChange={(e) => setRotation(e.target.value)}
                                        />
                                    </div>
                                }
                                {select === "Aspect" &&
                                    <div className="sub-select">
                                        <SpeedSelect
                                            options={["Default", "Landscape", "Portrait"]} // required
                                            selectedOption={cropAspectValue(cropAspect)} // required
                                            onSelect={(e) => {
                                                if (e === "Default") {
                                                    setCropAspect(4 / 3)
                                                } else if (e === "Landscape") {
                                                    setCropAspect(2000 / 1000)
                                                } else if (e === "Portrait") {
                                                    setCropAspect(1000 / 2000)
                                                }
                                            }} // required
                                            maxHeight={100} // optional, in pixel, Default is 450px
                                            dropdownAlignment="right"
                                        />
                                    </div>
                                }
                                {select === "CropShape" &&
                                    <div className="sub-select">
                                        <SpeedSelect
                                            options={["Rectangle", "Round"]} // required
                                            selectedOption={cropShape} // required
                                            onSelect={(e) => setCropShape(e)} // required
                                            maxHeight={100} // optional, in pixel, Default is 450px
                                            dropdownAlignment="right"
                                        />
                                    </div>
                                }
                                {select === "ShowGrid" &&
                                    <div className="sub-select">
                                        <SpeedSelect
                                            options={["Grid", "No Grid"]} // required
                                            selectedOption={showGrid === true ? "Grid" : "No Grid"} // required
                                            onSelect={(e) => {
                                                if (e === "Grid") {
                                                    setShowGrid(true)
                                                } else if (e === "No Grid") {
                                                    setShowGrid(false)
                                                }
                                            }
                                            } // required
                                            maxHeight={100} // optional, in pixel, Default is 450px
                                            dropdownAlignment="right"
                                        />
                                    </div>
                                }
                                <div className="sub-upload">
                                    <button
                                        onClick={() => handleImageUpload()} >Upload Image</button>
                                </div>
                            </>
                        ) : null}

                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
