import { useTabContext } from "./TabContext";
import { getTitle } from "./Utils";
import './NotAvailable.scss';

const NotAvailable = ({ title, description, subTabName = 'Page' }) => {
    const { activeSubTab } = useTabContext();
    const heading = title ?? getTitle(activeSubTab);
    const subHeading = description ?? `${subTabName} is not available`

    return (
        <div className="not-availble-wrapper">
            <div className="not-available-content">
                <h3 className="title">{heading.replace('Loading...', 'Untitled')}</h3>
                <p className="description">{subHeading}</p>
            </div>
        </div>
    )
}

export default NotAvailable