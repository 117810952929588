/**
 * This is a custom store implementation that is inspired by zustand (https://zustand-demo.pmnd.rs/).
 * It is a simple store that can be used to manage global state in a React application.
 * At the time of writing this lib the zustand version is 4.5.2.
 */

import { useRef } from "react";
// shimmed version of use-sync-external-store required due to the project react version is using v17
import { useSyncExternalStoreWithSelector } from "use-sync-external-store/shim/with-selector";

// vanilla store implementation

const createStoreImpl = (createState) => {
  let state;
  const listeners = new Set();

  const setState = (partial, replace) => {
    const nextState = typeof partial === "function" ? partial(state) : partial;
    if (!Object.is(nextState, state)) {
      const previousState = state;
      state =
        replace ?? (typeof nextState !== "object" || nextState === null)
          ? nextState
          : Object.assign({}, state, nextState);
      listeners.forEach((listener) => listener(state, previousState));
    }
  };

  const getState = () => state;

  const getInitialState = () => initialState;

  const subscribe = (listener) => {
    listeners.add(listener);
    // Unsubscribe
    return () => listeners.delete(listener);
  };

  const api = { setState, getState, getInitialState, subscribe };
  const initialState = (state = createState(setState, getState, api));
  return api;
};

const createStore = (createState) =>
  createState ? createStoreImpl(createState) : createStoreImpl;

// react store bindings

const identity = (arg) => arg;
function useStore(api, selector = identity) {
  const slice = useSyncExternalStoreWithSelector(
    api.subscribe,
    api.getState,
    api.getInitialState,
    selector,
    shallow
  );
  return slice;
}

const createImpl = (createState) => {
  const api =
    typeof createState === "function" ? createStore(createState) : createState;
  const useBoundStore = (selector) => useStore(api, selector);
  Object.assign(useBoundStore, api);
  return useBoundStore;
};

export const create = (createState) =>
  createState ? createImpl(createState) : createImpl;

export function useShallow(selector) {
  const prev = useRef();

  return (state) => {
    const next = selector(state);
    return shallow(prev.current, next) ? prev.current : (prev.current = next);
  };
}

function shallow(objA, objB) {
  if (Object.is(objA, objB)) {
    return true;
  }

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  if (objA instanceof Map && objB instanceof Map) {
    if (objA.size !== objB.size) return false;

    for (const [key, value] of objA) {
      if (!Object.is(value, objB.get(key))) {
        return false;
      }
    }
    return true;
  }

  if (objA instanceof Set && objB instanceof Set) {
    if (objA.size !== objB.size) return false;

    for (const value of objA) {
      if (!objB.has(value)) {
        return false;
      }
    }
    return true;
  }

  const keysA = Object.keys(objA);
  if (keysA.length !== Object.keys(objB).length) {
    return false;
  }
  for (const keyA of keysA) {
    if (
      !Object.prototype.hasOwnProperty.call(objB, keyA) ||
      !Object.is(objA[keyA], objB[keyA])
    ) {
      return false;
    }
  }
  return true;
}
