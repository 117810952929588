import React from 'react';
import './Switch.scss';

const Switch = ({ id, disabled = false, isOn, handleToggle, onColor = '#00BB5A' }) => {
    return (
        <div className='react-switch-wrapper'>
            <input
                checked={isOn}
                onChange={handleToggle}
                className="react-switch-checkbox"
                id={`react-switch-new-${id ?? ''}`}
                disabled={disabled}
                type="checkbox"
            />
            <label
                style={{ background: isOn && onColor }}
                className="react-switch-label"
                htmlFor={`react-switch-new-${id ?? ''}`}
            >
                <span className={`react-switch-button`} />
            </label>
        </div>
    );
};

export default Switch;