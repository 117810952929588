import { createContext, useContext, useEffect, useState } from "react";

import { emitter } from "components/SuperTabs/SuperTabs";
import { PAGE_IDS } from "components/Navigation";

export const ProfileContext = createContext({
    selectedContactTypes: [],
    updateSelectedContactType: (id, contactType) => { },
});

export function ProfileProvider({ children }) {

    const [selectedContactTypes, setSelectedContactTypes] = useState({});

    const updateSelectedContactType = (id, contactType) => {
        setSelectedContactTypes((prev) => {
            const temp = { ...prev };
            temp[id] = contactType;
            return temp;
        });
    }

    useEffect(() => {
        const unsub = emitter.subscribe('superTabClose', ({ appId, isSubTab, tab }) => {
            if (appId === PAGE_IDS.PROFILE && isSubTab) {
                const id = tab.id;
                const removeTabFromState = ({ [id]: _, ...newState }) => newState
                setSelectedContactTypes(removeTabFromState);
                // deleteScrollPosition(`profile--${id}`);
            }
        })
        return unsub;
    }, []);

    return (
        <ProfileContext.Provider
            value={{
                selectedContactTypes,
                updateSelectedContactType,
            }}
        >
            {children}
        </ProfileContext.Provider>
    );

}

export function useProfileContext() {
    return useContext(ProfileContext);
}