import './SuccessPage.scss';

export default function SuccessPage() {
  return (
    <div className='success-page'>
      <div className='success'>
        <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="90" height="90" rx="45" fill="#00BB5A" fillOpacity="0.2" />
          <rect x="20" y="20" width="50" height="50" rx="25" fill="white" />
          <path d="M41.5 56.5L59.125 38.875L55.625 35.375L41.5 49.5L34.375 42.375L30.875 45.875L41.5 56.5ZM45 70C41.5417 70 38.2917 69.3433 35.25 68.03C32.2083 66.7167 29.5625 64.9358 27.3125 62.6875C25.0625 60.4392 23.2817 57.7933 21.97 54.75C20.6583 51.7067 20.0017 48.4567 20 45C19.9983 41.5433 20.655 38.2933 21.97 35.25C23.285 32.2067 25.0658 29.5608 27.3125 27.3125C29.5592 25.0642 32.205 23.2833 35.25 21.97C38.295 20.6567 41.545 20 45 20C48.455 20 51.705 20.6567 54.75 21.97C57.795 23.2833 60.4408 25.0642 62.6875 27.3125C64.9342 29.5608 66.7158 32.2067 68.0325 35.25C69.3492 38.2933 70.005 41.5433 70 45C69.995 48.4567 69.3383 51.7067 68.03 54.75C66.7217 57.7933 64.9408 60.4392 62.6875 62.6875C60.4342 64.9358 57.7883 66.7175 54.75 68.0325C51.7117 69.3475 48.4617 70.0033 45 70Z" fill="#00BB5A" />
        </svg>
        <h1>Success!</h1>
        <p>Form has been submitted successfully.</p>
      </div>
    </div>
  )
}
