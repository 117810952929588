import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { StenoRenderer } from "react-steno";
import { useWikiContext } from "../context";
import { format } from "utils/date";
import useScrollRestoration from "hooks/useScrollRestoration";
import { convertUTCToTimezone } from "utils/Common";
import "./ViewArticle.scss";

export default function ViewArticle() {
  const { id } = useParams();
  const history = useHistory()
  const { articlesCache } = useWikiContext();
  const data = articlesCache.get(id).data;

  const ref = useScrollRestoration(`wiki-article-view-${id}`);
  return (
    <div className="view-article" ref={ref}>
      <div className='toggle-button-wrapper'>
        <button
          onClick={() => {
            const searchParams = new URLSearchParams(history.location.search);
            searchParams.set("edit", "true");
            history.replace(`${history.location.pathname}?${searchParams.toString()}`);
          }}
        >
          Viewing
        </button>
      </div>
      <h1>{data.title}</h1>
      {data.featured_image_url && (
        <img
          className="article-pic"
          src={data.featured_image_url}
          alt="article-pic"
        />
      )}
      {data?.authors?.length > 0 && (
        <div className="author-details-wrapper">
          <img src={data.authors[0].image_url} alt="author-pic" />
          <div className="author-details">
            <h6>{data.authors[0].name}</h6>
            <span>
              {format(
                convertUTCToTimezone(data.created_at),
                "dddd, MMM DD, YYYY",
              )}
            </span>
          </div>
        </div>
      )}
      {data?.tags?.length > 0 && (
        <div className="tags">
          {data.tags.map((tag) => (
            <span className="tag" key={tag.id}>
              {tag.tag}
            </span>
          ))}
        </div>
      )}
      <StenoRenderer html={data.body} />
    </div>
  );
}
