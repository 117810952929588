import { useState } from "react";
// components
import { MouseTooltip } from "components/ToolTip";
import { MultiPeriodPickerPanel } from "components/MultiPeriodPicker/MultiPeriodPicker";
import ClickOutsideListner from "components/ClickOutsideListner";
// utils
import {
  addMonths,
  addQuarters,
  addYears,
  format,
  monthsToQuarters,
  subMonths,
  subQuarters,
  subYears,
  addDays,
  subDays,
  getFirstAndLastDateOfMonth,
} from "utils/date";
import { convertTimeZone } from '../utils'
// hooks
import useClickPreventionOnDoubleClick from "hooks/useClickPreventionOnDoubleClick";
// context
import { useTeamMemberFilter } from "../context/teamMemberFilterContext";
// styles
import "./AttendanceMultiDatePicker.scss";

function getQuarterFormatStr(date) {
  return `Q${monthsToQuarters(date.getMonth())} ${date.getFullYear()}`;
}

export default function AttendanceMultiDatePicker() {
  const { startDate, endDate, handleDateChange, resourceStartDate } =
    useTeamMemberFilter();

  const [showDatePicker, setShowDatePicker] = useState(false);
  function toggleDatePicker() {
    setShowDatePicker(() => !showDatePicker);
  }

  const [pickerType, setPickerType] = useState("month");

  function onDateChange(props) {
    const pickerType = props.pickerType;
    const range = props.ranges[0];
    const startDate = range[0];
    const endDate = range[1];

    if (startDate && endDate) {
      handleDateChange(startDate, endDate);
      setPickerType(() => pickerType);
      toggleDatePicker();
    }
  }

  function handleToday() {
    let date = convertTimeZone();
    const { firstDate, lastDate } = getFirstAndLastDateOfMonth(date);
    const formattedFirstDate = format(firstDate);
    const formattedLastDate = format(lastDate);
    if (format(new Date(startDate)) === formattedFirstDate && format(new Date(endDate)) === formattedLastDate) {
      return;
    }
    handleDateChange(formattedFirstDate, formattedLastDate);
  }

  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(toggleDatePicker, handleToday);


  function handleLeftArrowClick() {
    if (pickerType === "date") {
      handleDateChange(subDays(startDate, 1), subDays(endDate, 1));
    } else if (pickerType === "month") {
      handleDateChange(subMonths(startDate, 1), subMonths(endDate, 1));
    } else if (pickerType === "quarter") {
      handleDateChange(subQuarters(startDate, 1), subQuarters(endDate, 1));
    } else if (pickerType === "year") {
      handleDateChange(subYears(startDate, 1), subYears(endDate, 1));
    }
  }

  function handleRightArrowClick() {
    if (pickerType === "date") {
      handleDateChange(addDays(startDate, 1), addDays(endDate, 1));
    } else if (pickerType === "month") {
      handleDateChange(addMonths(startDate, 1), addMonths(endDate, 1));
    } else if (pickerType === "quarter") {
      handleDateChange(addQuarters(startDate, 1), addQuarters(endDate, 1));
    } else if (pickerType === "year") {
      handleDateChange(addYears(startDate, 1), addYears(endDate, 1));
    }
  }

  function getReadableDateString() {
    let formattedStartDate = '', formattedEndDate = '';

    if (pickerType === "date") {
      formattedStartDate = format(startDate, "DD.MM.YYYY");
      formattedEndDate = format(endDate, "DD.MM.YYYY");
    } else if (pickerType === "month") {
      formattedStartDate = format(startDate, "MMM YYYY");
      formattedEndDate = format(endDate, "MMM YYYY");
    } else if (pickerType === "quarter") {
      formattedStartDate = getQuarterFormatStr(new Date(startDate));
      formattedEndDate = getQuarterFormatStr(new Date(endDate));
    } else if (pickerType === "year") {
      formattedStartDate = format(startDate, "YYYY");
      formattedEndDate = format(endDate, "YYYY");
    }

    return formattedStartDate === formattedEndDate ? formattedStartDate : `${formattedStartDate} - ${formattedEndDate}`;
  }

  // const isDarkMode = document.body.dataset.theme === "dark";

  return (
    <div className="multi-datepicker">
      {/* Left Arrow */}
      <button
        className="multi-datepicker-left-arrow"
        onClick={handleLeftArrowClick}
      ></button>

      <div className="multi-datepicker-controls">
        {/* Formatted date */}
        <MouseTooltip
          show={!showDatePicker}
          content="Select date or Double click for this month"
        >
          <button
            className="multi-datepicker-selected-date"
            // onClick={toggleDatePicker}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
          >
            {getReadableDateString()}
          </button>
        </MouseTooltip>
        {/* Date picker modal */}
        <div className="multi-datepicker-modal">
          {showDatePicker && (
            <ClickOutsideListner onOutsideClick={toggleDatePicker}>
              <div className="multi-datepicker-modal-inner">
                <MultiPeriodPickerPanel
                  onChange={onDateChange}
                  allowClear={false}
                  showOkCancelBtns={true}
                  showInline
                  disableBenchmarkChange={true}
                  singleRangeonly={true}
                  showPreDefinedDates={false}
                  disableBeforeDates={resourceStartDate}
                  changeView={pickerType}
                />
              </div>
            </ClickOutsideListner>
          )}
        </div>
      </div>

      {/* Right Arrow */}
      <button
        className="multi-datepicker-right-arrow"
        onClick={handleRightArrowClick}
      ></button>
    </div>
  );
}
