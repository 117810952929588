import React, { useState, useEffect, useRef } from 'react';
import SuperPager from 'super-pager';
import * as Constants from '../../components/Constants.js';
import { withKeepAlive } from 'components/KeepAlive.js';
import { PAGE_IDS } from 'components/Navigation.js';

// import { getUser } from '../../utils/Common'; //Import Common Functions
import APIService from '../../services/apiService'; //Import Services
import SpeedSelect from 'react-speedselect';
import subjectObj from '../../subjects/Subject1';
import '../../styles/Department.scss';
import { alertService } from 'services/alertService.js';

const Organization = (props) => {
  const [departmentError, setDepartmentError] = useState(false);
  // const [departmentInprocess, setDepartmentInprocess] = useState(false);
  const [departmentMessage, setDepartmentMessage] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [departmentList, setDepartmentList] = useState(null);

  const [designationError, setDesignationError] = useState(false);
  // const [designationInprocess, setDesignationInprocess] = useState(false);
  const [designationMessage, setDesignationMessage] = useState('');
  const [designation, setDesignation] = useState('');
  const [designationList, setDesignationList] = useState(null);
  const [designationDepartment, setDesignationDepartment] = useState('');

  const [skillError, setSkillError] = useState(false);
  // const [skillInprocess, setSkillInprocess] = useState(false);
  const [skillMessage, setSkillMessage] = useState('');
  const [skill, setSkill] = useState('');
  const [skillList, setSkillList] = useState(null);
  const skillListRef = useRef();
  skillListRef.current = skillList;

  const [statusError, setStatusError] = useState(false);
  // const [statusInprocess, setStatusInprocess] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [status, setStatus] = useState('');
  const [statusList, setStatusList] = useState(null);

  const abortController = useRef(new AbortController());


  const [editInfo, setEditInfo] = useState({ type: '', id: '' });

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    subjectObj.notify({ page_title: 'Organization' }); //update page title in header
    if (!departmentList) {
      handleLoadDepartmentList();
    }
    if (!designationList) {
      handleLoadDesignationList();
    }
    if (!skillList) {
      handleLoadSkillList();
    }
    if (!statusList) {
      handleLoadStatusList();
    }

    const controller = abortController.current;
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Department Methods
  function handleLoadDepartmentList() {
    const apiPayLoad = null;

    APIService.apiRequest(Constants.API_BASE_URL + '/department', apiPayLoad, false, 'GET', abortController.current)
      .then(response => {
        if (response.status === 1 && response.output !== undefined && response.output) {
          setDepartmentList(response.output)
        } else {
          console.log(response.msg);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });

    return false;
  }


  function handleNameChange(e) {
    setDepartmentName(e.target.value);
  }

  function handleDepartmentSubmit(e) {
    e.preventDefault();
    if (!departmentName.trim()) {
      setDepartmentError(true);
      setDepartmentMessage('Name cannot be blank');
      return;
    }

    //Input Validations
    // setDepartmentInprocess(true);
    setDepartmentError(false);

    const apiPayLoad = { "name": departmentName };

    APIService.apiRequest(Constants.API_BASE_URL + '/department', apiPayLoad)
      .then(response => {
        if (response.status === 1) {
          // setDepartmentInprocess(true);
          setDepartmentList(oldDepartmentList => [...oldDepartmentList, { id: response.id, name: departmentName }]);
          setDepartmentError(false);
          setDepartmentMessage('Department added successfully!');
          setDepartmentName('')
        } else {
          // setDepartmentInprocess(false);
          setDepartmentError(true);
          setDepartmentMessage(response.msg || response.message);
        }
      })
      .catch(err => {
        // setDepartmentInprocess(false);
        setDepartmentError(true);
        setDepartmentMessage(err.msg);
      });

    return false;
  }

  // Designation Methods
  function handleLoadDesignationList() {
    const apiPayLoad = null;

    APIService.apiRequest(Constants.API_BASE_URL + '/designation', apiPayLoad, false, 'GET', abortController.current)
      .then(response => {
        if (response.status === 1 && response.output !== undefined && response.output) {
          setDesignationList(response.output)
        } else {
          console.log(response.msg);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });

    return false;
  }

  //On Select Change
  function onOptionSelect(event, id) {
    if (id === 'department_id') {
      setDesignationDepartment(event);
    }
  }

  function handleDesignationChange(e) {
    setDesignation(e.target.value);
  }

  function handleDesignationSubmit(e) {
    e.preventDefault();

    let errMsg = '';

    if (!designationDepartment) {
      errMsg = 'Please select department';
    }
    if (!designation.trim()) {
      errMsg = 'Name cannot be blank';
    }
    if (!designation && !designationDepartment) {
      errMsg = 'Please select department and fill the name'
    }

    if (errMsg) {
      setDesignationError(true);
      setDesignationMessage(errMsg);
      return;
    }

    //Input Validations
    // setDesignationInprocess(true);
    setDesignationError(false);

    const apiPayLoad = { "name": designation, 'department_id': designationDepartment.id };

    APIService.apiRequest(Constants.API_BASE_URL + '/designation', apiPayLoad)
      .then(response => {
        if (response.status === 1) {
          setDesignationList(oldDesignationList => [...oldDesignationList, { id: response.id, name: designation, department_id: designationDepartment.id }]);
          // setDesignationInprocess(true);
          setDesignationError(false);
          setDesignationMessage('Designation added successfully!');
          setDesignation('')

        } else {
          // setDesignationInprocess(false);
          setDesignationError(true);
          setDesignationMessage(response.msg || response.message);
        }
      })
      .catch(err => {
        // setDesignationInprocess(false);
        setDesignationError(true);
        setDesignationMessage(err.msg);
      });

    return false;
  }


  // Skill Methods
  function handleLoadSkillList({ pageNumber = null, pageSize = null } = {}) {
    const apiPayLoad = null;
    let _pageNumber = pageNumber ? pageNumber : !skillListRef.current ? 1 : skillListRef.current.meta.page_number + 1;

    APIService.apiRequest(Constants.API_BASE_URL + `/skill?page_number=${_pageNumber}&page_size=${pageSize ?? 100}`, apiPayLoad, false, 'GET', abortController.current)
      .then(response => {
        if (response.status === 1 && response.output !== undefined && response.output) {
          setSkillList((oldSkillList) => !oldSkillList ? response.output : { meta: pageSize ? oldSkillList.meta : response.output.meta, data: [...oldSkillList.data, ...response.output.data] });
        } else {
          console.log(response.msg);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });

    return false;
  }

  function handleSkillChange(e) {
    setSkill(e.target.value);
  }

  function handleSkillSubmit(e) {
    e.preventDefault();

    if (!skill.trim()) {
      setSkillError(true);
      setSkillMessage('Name cannot be blank');
      return;
    }

    //Input Validations
    // setSkillInprocess(true);
    setSkillError(false);

    const apiPayLoad = { "name": skill };

    APIService.apiRequest(Constants.API_BASE_URL + '/skill', apiPayLoad)
      .then(response => {
        if (response.status === 1) {
          if (skillListRef.current.data.length === skillListRef.current.meta.total_results) {
            setSkillList(oldSkillList => {
              let oldSkillListData = structuredClone(oldSkillList);
              oldSkillListData.data.push({ id: response.id, name: skill });
              oldSkillListData.meta.total_results = oldSkillListData.meta.total_results + 1;
              return oldSkillListData;
            });
          }
          // setSkillInprocess(true);
          setSkillError(false);
          setSkillMessage('Skill added successfully!');
          setSkill('');
        } else {
          // setSkillInprocess(false);
          setSkillError(true);
          setSkillMessage(response.msg || response.message);
        }
      })
      .catch(err => {
        // setSkillInprocess(false);
        setSkillError(true);
        setSkillMessage(err.msg);
      });

    return false;
  }

  // Status Methods
  function handleLoadStatusList() {
    const apiPayLoad = null;

    APIService.apiRequest(Constants.API_BASE_URL + '/status', apiPayLoad, false, 'GET', abortController.current)
      .then(response => {
        if (response.status === 1 && response.output !== undefined && response.output) {
          setStatusList(response.output)
        } else {
          console.log(response.msg);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });

    return false;
  }

  function handleStatusChange(e) {
    setStatus(e.target.value);
  }

  function handleStatusSubmit(e) {
    e.preventDefault();

    if (!status.trim()) {
      setStatusError(true);
      setStatusMessage('Name cannot be blank');
      return;
    }

    //Input Validations
    // setStatusInprocess(true);
    setStatusError(false);

    const apiPayLoad = { "name": status };

    APIService.apiRequest(Constants.API_BASE_URL + '/status', apiPayLoad)
      .then(response => {
        if (response.status === 1) {
          // setStatusInprocess(true);
          setStatusList(oldStatusList => [...oldStatusList, { id: response.id, name: apiPayLoad.name }]);
          setStatusError(false);
          setStatusMessage('Status added successfully!');
          setStatus('');

        } else {
          // setStatusInprocess(false);
          setStatusError(true);
          setStatusMessage(response.msg || response.message);
        }
      })
      .catch(err => {
        // setStatusInprocess(false);
        setStatusError(true);
        setStatusMessage(err.msg);
      });

    return false;
  }

  const resetEditInfo = () => {
    setEditInfo({ id: '', type: '', value: '' });
  }

  function deleteDepartment(id) {
    let departmentIndexBKP = departmentList.findIndex((department) => department.id === id);
    let departmentBKP = departmentList[departmentIndexBKP];
    setDepartmentList((oldDepartmentList) => {
      let newDepartmentList = oldDepartmentList.filter((department) => department.id !== id);
      return newDepartmentList;
    });

    APIService.apiRequest(Constants.API_BASE_URL + `/department/${id}`, null, false, 'DELETE')
      .then(response => {
        if (response.status === 1) {

        } else {
          console.log(response.msg);
          // insert the deleted department back to the list
          setDepartmentList((oldDepartmentList) => {
            let newDepartmentList = [...oldDepartmentList];
            newDepartmentList.splice(departmentIndexBKP, 0, departmentBKP);
            return newDepartmentList;
          });
          alertService.error(`Error deleting department: ${response.msg}`);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });
  }

  function deleteStatus(id) {
    let statusIndexBKP = statusList.findIndex((status) => status.id === id);
    let statusBKP = statusList[statusIndexBKP];

    setStatusList((oldStatusList) => {
      let newDepartmentList = oldStatusList.filter((status) => status.id !== id);
      return newDepartmentList;
    });

    APIService.apiRequest(Constants.API_BASE_URL + `/status/${id}`, null, false, 'DELETE')
      .then(response => {
        if (response.status === 1) {
        } else {
          console.log(response.msg);
          // insert the deleted status back to the list
          setStatusList((oldStatusList) => {
            let newStatusList = [...oldStatusList];
            newStatusList.splice(statusIndexBKP, 0, statusBKP);
            return newStatusList;
          });
          alertService.error(`Error deleting status: ${response.msg}`);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });
  }

  function deleteDesignation(id) {
    let designationIndexBKP = designationList.findIndex((status) => status.id === id);
    let designationBKP = designationList[designationIndexBKP];

    //remove designation from the list
    setDesignationList((oldDesignationList) => {
      let newDesignationList = oldDesignationList.filter((designation) => designation.id !== id);
      return newDesignationList;
    });

    APIService.apiRequest(Constants.API_BASE_URL + `/designation/${id}`, null, false, 'DELETE')
      .then(response => {
        if (response.status === 1) {
        } else {
          console.log(response.msg);
          // insert the deleted designation back to the list
          setDesignationList((oldDesignationList) => {
            let newDesignationList = [...oldDesignationList];
            newDesignationList.splice(designationIndexBKP, 0, designationBKP);
            return newDesignationList;
          });
          alertService.error(`Error deleting designation: ${response.msg}`);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });
  }

  function deleteSkill(id) {
    let skillIndexBKP = skillList.data.findIndex((skill) => skill.id === id);
    let skillBKP = { ...skillList.data[skillIndexBKP] };
    let dataLength = skillList.data.length;

    setSkillList((oldSkillList) => {
      let newSkillList = JSON.parse(JSON.stringify(oldSkillList));
      newSkillList.data = oldSkillList.data.filter((skill) => skill.id !== id);
      newSkillList.meta.total_results = oldSkillList.meta.total_results - 1;
      return newSkillList;
    });

    APIService.apiRequest(Constants.API_BASE_URL + `/skill/${id}`, null, false, 'DELETE')
      .then(response => {
        if (response.status === 1) {
          if (skillListRef.current.data.length !== skillListRef.current.meta.total_results) {
            handleLoadSkillList({ pageNumber: dataLength, pageSize: 1 });
          }
        } else {
          console.log(response.msg);
          // insert the deleted skill back to the list
          setSkillList((oldSkillList) => {
            let newSkillList = JSON.parse(JSON.stringify(oldSkillList));
            newSkillList.data.splice(skillIndexBKP, 0, skillBKP);
            newSkillList.meta.total_results = oldSkillList.meta.total_results + 1;
            return newSkillList;
          });
          alertService.error(`Error deleting skill: ${response.msg}`);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });
  }

  function editDepartment(id) {
    let apiPayLoad = { name: editInfo.value };
    let index = departmentList.findIndex((department) => department.id === id);
    let deptNameBkp = departmentList[index].name;
    if (apiPayLoad.name === deptNameBkp) {
      alertService.info('No changes made');
      return;
    }
    setDepartmentList((oldDepartmentList) => {
      let oldDepartmentListCopy = [...oldDepartmentList];
      oldDepartmentListCopy[index].name = apiPayLoad.name;
      let newDepartmentList = [...oldDepartmentList];
      return newDepartmentList;
    });
    resetEditInfo();

    APIService.apiRequest(Constants.API_BASE_URL + `/department/${id}`, apiPayLoad, false, 'PUT')
      .then(response => {
        if (response.status === 1) {

        } else {
          console.log(response.msg);
          setDepartmentList((oldDepartmentList) => {
            let oldDepartmentListCopy = [...oldDepartmentList];
            oldDepartmentListCopy[index].name = deptNameBkp;
            let newDepartmentList = [...oldDepartmentList];
            return newDepartmentList;
          });
          alertService.error(`Error occured while editing department - ${response.msg}`);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });
  }

  function editStatus(id) {
    let apiPayLoad = { name: editInfo.value };
    let index = statusList.findIndex((status) => status.id === id);
    let statusNameBkp = statusList[index].name;
    if (apiPayLoad.name === statusNameBkp) {
      alertService.info('No changes made');
      return;
    }
    setStatusList((oldStatusList) => {
      let oldStatusListCopy = [...oldStatusList];
      oldStatusListCopy[index].name = apiPayLoad.name;
      return oldStatusListCopy;
    });
    resetEditInfo();

    APIService.apiRequest(Constants.API_BASE_URL + `/status/${id}`, apiPayLoad, false, 'PUT')
      .then(response => {
        if (response.status === 1) {

        } else {
          console.log(response.msg);
          setStatusList((oldStatusList) => {
            let oldStatusListCopy = [...oldStatusList];
            oldStatusListCopy[index].name = statusNameBkp;
            return oldStatusListCopy;
          });
          alertService.error(`Error occured while editing status - ${response.msg}`);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });
  }

  function editDesignation(id) {
    let apiPayLoad = { name: editInfo.value, department_id: editInfo.department.id };
    let index = designationList.findIndex((desig) => desig.id === id);
    let desigBKP = { ...designationList[index] };
    if (apiPayLoad.name === desigBKP.name && apiPayLoad.department_id === desigBKP.department_id) {
      alertService.info('No changes made');
      return;
    }
    setDesignationList((oldDesignationList) => {
      let oldDesignationListCopy = [...oldDesignationList];
      oldDesignationListCopy[index].name = apiPayLoad.name;
      oldDesignationListCopy[index].department_id = apiPayLoad.department_id;
      return oldDesignationListCopy;
    });
    resetEditInfo();

    APIService.apiRequest(Constants.API_BASE_URL + `/designation/${id}`, apiPayLoad, false, 'PUT')
      .then(response => {
        if (response.status === 1) {

        } else {
          console.log(response.msg, desigBKP);
          setDesignationList((oldDesignationList) => {
            let oldDesignationListCopy = [...oldDesignationList];
            oldDesignationListCopy[index].name = desigBKP.name;
            oldDesignationListCopy[index].department_id = desigBKP.department_id;
            return oldDesignationListCopy;
          });
          alertService.error(`Error occured while editing designation - ${response.msg}`);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });
  }

  function editSkill(id) {
    let apiPayLoad = { name: editInfo.value };
    let index = skillList.data.findIndex((status) => status.id === id);
    let skillNameBkp = skillList.data[index].name;
    if (apiPayLoad.name === skillNameBkp) {
      alertService.info('No changes made');
      return;
    }
    setSkillList((oldSkillList) => {
      let oldSkillListCopy = JSON.parse(JSON.stringify(oldSkillList));
      oldSkillListCopy.data[index].name = apiPayLoad.name;
      return oldSkillListCopy;
    });
    resetEditInfo();

    APIService.apiRequest(Constants.API_BASE_URL + `/skill/${id}`, apiPayLoad, false, 'PUT')
      .then(response => {
        if (response.status === 1) {

        } else {
          console.log(response.msg);
          setSkillList((oldSkillList) => {
            let oldSkillListCopy = JSON.parse(JSON.stringify(oldSkillList));
            oldSkillListCopy.data[index].name = skillNameBkp;
            return oldSkillListCopy;
          });
          alertService.error(`Error occured while editing skill - ${response.msg}`);
        }
      })
      .catch(err => {
        console.log(err.msg);
      });
  }

  return (
    <div className="app-wrapper" id='organization-wrapper'>
      {/* <div id="app-sub-header">
        <h2 className="page-title">Organization</h2>
      </div> */}

      <div className="container">
        {/* Department Section */}
        <div id="department-wrapper" className="section">
          <div className="col-50">
            <h3>Add Department</h3>

            <form id="frm-department" method="POST" onSubmit={(e) => handleDepartmentSubmit(e)} className="custom-form">
              {(departmentMessage !== '') &&
                <div className={'alert ' + (departmentError ? 'error' : 'success')}>{departmentMessage}</div>
              }

              <div className="field-wrapper">
                <label className="label" htmlFor="txt-name">Name</label>
                <input type="text" id="txt-name" name="txt-name" value={departmentName} className="field-control" onChange={(e) => handleNameChange(e)} />
              </div>

              <div className="field-wrapper">
                <input type="submit" id="btn-submit" name="btn-submit" value="Submit" className="btn outline xs btn-submit" />
              </div>
            </form>
          </div>

          <div className="col-50">
            <div className="table-list">
              <div className="header-wrapper">
                <div className="item id">ID</div>
                <div className="item name">Name</div>
                <div className="item action">Action</div>
              </div>

              {departmentList &&
                departmentList.map((item, i) => {
                  return (
                    <div className="item-wrapper" key={i}>
                      <div className="item id">{item.id}</div>
                      <div className="item name">
                        {editInfo.type === 'dept' && editInfo.id === item.id ? (
                          <input
                            type='text'
                            value={editInfo.value}
                            onChange={(e) => {
                              setEditInfo({ ...editInfo, value: e.target.value })
                            }}
                          />
                        ) : (
                          <span>{item.name}</span>
                        )}
                      </div>
                      <div className="item action">
                        {editInfo.type === 'dept' && editInfo.id === item.id ? (
                          <>
                            <div className="btn-wrapper">
                              <button className="btn outline xs" onClick={() => editDepartment(item.id)}>
                                Submit
                              </button>
                            </div>
                            <div className="btn-wrapper">
                              <button className="btn outline xs" onClick={resetEditInfo}>
                                Cancel
                              </button>
                            </div>
                          </>
                        ) : (<>
                          <div className="btn-wrapper">
                            <button className="btn outline xs" onClick={() => setEditInfo({ id: item.id, type: 'dept', value: item.name })}>
                              edit
                            </button>
                          </div>
                          <div className="btn-wrapper">
                            <button className="btn outline xs" onClick={() => deleteDepartment(item.id)}>
                              delete
                            </button>
                          </div>
                        </>
                        )}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        {/* Designation Section */}
        <div id="designation-wrapper" className="section">
          <div className="col-50">
            <h3>Add Designation</h3>

            <form id="frm-designation" method="POST" onSubmit={(e) => handleDesignationSubmit(e)} className="custom-form">
              {(designationMessage !== '') &&
                <div className={'alert ' + (designationError ? 'error' : 'success')}>{designationMessage}</div>
              }

              {departmentList &&
                <div className="field-wrapper">
                  {/* <label className="label" htmlFor="txt-department">Department</label> */}
                  <SpeedSelect
                    options={departmentList}
                    selectedOption={designationDepartment}
                    onSelect={(e) => onOptionSelect(e, 'department_id')}
                    displayKey='name'
                    uniqueKey='id'
                    maxHeight={120}
                    isLabelClickable={true}
                    prominentLabel='Department'
                  />
                </div>
              }

              <div className="field-wrapper">
                <label className="label" htmlFor="txt-designation">Designation</label>
                <input type="text" id="txt-designation" name="txt-designation" value={designation} className="field-control" onChange={(e) => handleDesignationChange(e)} />
              </div>

              <div className="field-wrapper">
                <input type="submit" id="btn-designation-submit" name="btn-designation-submit" value="Submit" className="btn outline xs btn-submit" />
              </div>
            </form>
          </div>

          <div className="col-50">
            <div className="table-list">
              <div className="header-wrapper">
                <div className="item id">ID</div>
                <div className="item name">Name</div>
                <div className="item department">Department</div>
                <div className="item action">Action</div>
              </div>

              {(designationList && departmentList) &&
                designationList.map((item, i) => {
                  let index = departmentList.findIndex(o => o.id === item.department_id);
                  let department = departmentList[index];
                  let department_name = department?.['name'];

                  return (
                    <div className="item-wrapper" key={i}>
                      <div className="item id">{item.id}</div>
                      <div className="item name">
                        {editInfo.type === 'desig' && editInfo.id === item.id ? (
                          <input
                            type='text'
                            value={editInfo.value}
                            onChange={(e) => {
                              setEditInfo({ ...editInfo, value: e.target.value })
                            }}
                          />
                        ) : (
                          <span>{item.name}</span>
                        )}
                      </div>
                      <div className="item department">
                        {editInfo.type === 'desig' && editInfo.id === item.id ? (
                          <SpeedSelect
                            options={departmentList}
                            selectedOption={editInfo.department}
                            onSelect={(e) => setEditInfo({ ...editInfo, department: e })}
                            displayKey='name'
                            uniqueKey='id'
                            maxHeight={120}
                          />
                        ) : (
                          <span>{department_name}</span>
                        )}
                      </div>
                      <div className="item action">
                        {editInfo.type === 'desig' && editInfo.id === item.id ? (
                          <>
                            <div className="btn-wrapper">
                              <button className="btn outline xs" onClick={() => editDesignation(item.id)}>
                                Submit
                              </button>
                            </div>
                            <div className="btn-wrapper">
                              <button className="btn outline xs" onClick={resetEditInfo}>
                                Cancel
                              </button>
                            </div>
                          </>
                        ) : (<>
                          <div className="btn-wrapper">
                            <button className="btn outline xs" onClick={() => setEditInfo({ id: item.id, type: 'desig', value: item.name, department })}>
                              edit
                            </button>
                          </div>
                          <div className="btn-wrapper">
                            <button className="btn outline xs" onClick={() => deleteDesignation(item.id)}>
                              delete
                            </button>
                          </div>
                        </>
                        )}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        {/* Status Section */}
        <div id="status-wrapper" className="section">
          <div className="col-50">
            <h3>Add Status</h3>

            <form id="frm-status" method="POST" onSubmit={(e) => handleStatusSubmit(e)} className="custom-form">
              {(statusMessage !== '') &&
                <div className={'alert ' + (statusError ? 'error' : 'success')}>{statusMessage}</div>
              }

              <div className="field-wrapper">
                <label className="label" htmlFor="txt-status">Name</label>
                <input type="text" id="txt-status" name="txt-status" value={status} className="field-control" onChange={(e) => handleStatusChange(e)} />
              </div>

              <div className="field-wrapper">
                <input type="submit" id="btn-status-submit" name="btn-status-submit" value="Submit" className="btn outline xs btn-submit" />
              </div>
            </form>
          </div>

          <div className="col-50">
            <div className="table-list">
              <div className="header-wrapper">
                <div className="item id">ID</div>
                <div className="item name">Name</div>
                <div className="item action">Action</div>
              </div>

              {statusList &&
                statusList.map((item, i) => {
                  return (
                    <div className="item-wrapper" key={i}>
                      <div className="item id">{item.id}</div>
                      <div className="item name">
                        {editInfo.type === 'status' && editInfo.id === item.id ? (
                          <input
                            type='text'
                            value={editInfo.value}
                            onChange={(e) => {
                              setEditInfo({ ...editInfo, value: e.target.value })
                            }}
                          />
                        ) : (
                          <span>{item.name}</span>
                        )}
                      </div>
                      <div className="item action">
                        {editInfo.type === 'status' && editInfo.id === item.id ? (
                          <>
                            <div className="btn-wrapper">
                              <button className="btn outline xs" onClick={() => editStatus(item.id)}>
                                Submit
                              </button>
                            </div>
                            <div className="btn-wrapper">
                              <button className="btn outline xs" onClick={resetEditInfo}>
                                Cancel
                              </button>
                            </div>
                          </>
                        ) : (<>
                          <div className="btn-wrapper">
                            <button className="btn outline xs" onClick={() => setEditInfo({ id: item.id, type: 'status', value: item.name })}>
                              edit
                            </button>
                          </div>
                          <div className="btn-wrapper">
                            <button className="btn outline xs" onClick={() => deleteStatus(item.id)}>
                              delete
                            </button>
                          </div>
                        </>
                        )}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        {/* Skills Section */}
        <div id="skills-wrapper" className="section">
          <div className="col-50">
            <h3>Add Skill</h3>

            <form id="frm-skill" method="POST" onSubmit={(e) => handleSkillSubmit(e)} className="custom-form">
              {(skillMessage !== '') &&
                <div className={'alert ' + (skillError ? 'error' : 'success')}>{skillMessage}</div>
              }

              <div className="field-wrapper">
                <label className="label" htmlFor="txt-skill">Name</label>
                <input type="text" id="txt-skill" name="txt-skill" value={skill} className="field-control" onChange={(e) => handleSkillChange(e)} />
              </div>

              <div className="field-wrapper">
                <input type="submit" id="btn-skill-submit" name="btn-skill-submit" value="Submit" className="btn outline xs btn-submit" />
              </div>
            </form>
          </div>

          <div className="col-50">
            <div className="table-list">
              <div className="header-wrapper">
                <div className="item id">ID</div>
                <div className="item name">Name</div>
                <div className="item action">Action</div>
              </div>

              {skillListRef.current && skillListRef.current?.data?.length > 0 && <SuperPager
                type="infiniteScroll"
                isTable={false}
                dataLength={skillListRef.current.data.length}
                loadMore={handleLoadSkillList}
                hasMore={skillListRef.current.meta.page_number < skillListRef.current.meta.num_pages}
                wrapper={true}
                children={skillList &&
                  skillList.data.map((item, i) => {
                    return (
                      <div className="item-wrapper" key={i}>
                        <div className="item id">{item.id}</div>
                        <div className="item name">
                          {editInfo.type === 'skill' && editInfo.id === item.id ? (
                            <input
                              type='text'
                              value={editInfo.value}
                              onChange={(e) => {
                                setEditInfo({ ...editInfo, value: e.target.value })
                              }}
                            />
                          ) : (
                            <span>{item.name}</span>
                          )}
                        </div>
                        <div className="item action">
                          {editInfo.type === 'skill' && editInfo.id === item.id ? (
                            <>
                              <div className="btn-wrapper">
                                <button className="btn outline xs" onClick={() => editSkill(item.id)}>
                                  Submit
                                </button>
                              </div>
                              <div className="btn-wrapper">
                                <button className="btn outline xs" onClick={resetEditInfo}>
                                  Cancel
                                </button>
                              </div>
                            </>
                          ) : (<>
                            <div className="btn-wrapper">
                              <button className="btn outline xs" onClick={() => setEditInfo({ id: item.id, type: 'skill', value: item.name })}>
                                edit
                              </button>
                            </div>
                            <div className="btn-wrapper">
                              <button className="btn outline xs" onClick={() => deleteSkill(item.id)}>
                                delete
                              </button>
                            </div>
                          </>
                          )}
                        </div>
                      </div>
                    )
                  })
                }
              />}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default withKeepAlive(Organization, PAGE_IDS.ORGANIZATION);