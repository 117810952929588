export default function ResumeInput({ onChange }) {
  const selectFile = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.pdf'
    input.onchange = (e) => {
      const file = e.target.files[0]
      if (file) onChange(file)
      input.remove()
    }

    input.click()
  }

  return (
    <button type="button" className="pill" onClick={selectFile}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <path
          fill="#646464"
          d="M15.204 8.08a.804.804 0 0 0-.566.233.788.788 0 0 0-.234.56v4.751c0 .21-.085.412-.235.56a.804.804 0 0 1-.566.232H2.401a.804.804 0 0 1-.566-.232.788.788 0 0 1-.235-.56V8.873a.788.788 0 0 0-.234-.56.804.804 0 0 0-1.132 0 .788.788 0 0 0-.234.56v4.751c0 .63.253 1.235.703 1.68.45.446 1.06.696 1.698.696h11.202c.637 0 1.248-.25 1.698-.696.45-.445.703-1.05.703-1.68V8.873a.788.788 0 0 0-.234-.56.804.804 0 0 0-.566-.232Z"
        />
        <path
          fill="#646464"
          d="M4.671 4.619h2.667v5.278c0 .175.07.343.195.467a.67.67 0 0 0 .943 0 .656.656 0 0 0 .195-.467V4.62h2.667a.671.671 0 0 0 .616-.407.653.653 0 0 0-.145-.72L8.476.194A.667.667 0 0 0 7.75.05a.667.667 0 0 0-.216.144L4.199 3.492a.658.658 0 0 0 .472 1.127Z"
        />
      </svg>
      Upload .pdf File
    </button>
  )
}
