import React, { useEffect, useRef, useState } from "react";
import SuperPager from "super-pager";

// components
import ClickOutsideListner from "components/ClickOutsideListner";

// services
import { alertService } from "services/alertService";
import APIService from "services/apiService";

// utils
import { debounce } from "utils/Common";

// styles
import "./AddTags.scss";
import { WIKI_TAGS_URL } from "../constants";

export default function AddTags({ value, onChange }) {
  const [inputArray, setInputArray] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState([]);
  const [pagination, setPagination] = useState({
    no_of_pages: 0,
    current_page: 0,
    total_count: 0,
  });

  const inputElRef = useRef();
  let controller = new AbortController();

  // state that manages the active suggestion index
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);

  const tagContainerDOMRef = useRef();
  const suggestionListDOMRef = useRef();

  // This useEffect is responsible to fix the auto complete suggestion list is overflowing the tag container
  // Like positioning element dynamically if they are overflowing its parent element
  useEffect(() => {
    if (showOptions) {
      const containerEl = tagContainerDOMRef.current;
      const suggestionListEl = suggestionListDOMRef.current;

      if (suggestionListEl) {
        const containerElRect = containerEl.getBoundingClientRect();
        const suggestionListElRect = suggestionListEl.getBoundingClientRect();

        if (suggestionListElRect.right > containerElRect.right) {
          // The below style will automatically removed when the suggestionListEl is unmounted
          suggestionListEl.style.right = "0px";
          suggestionListEl.style.left = "";
        } else {
          suggestionListEl.style.right = "";
          suggestionListEl.style.left = "0px";
        }
      }
    }
  }, [showOptions, value]);

  useEffect(() => {
    if (value && value.length > 0) {
      setInputArray(value);
    }
  }, [value]);

  async function fetchtags({ searchString, page_number = 1 }) {
    const url = new URL(WIKI_TAGS_URL);
    url.searchParams.append("search_string", searchString);
    url.searchParams.set("page_size", 100);
    url.searchParams.set("page_number", page_number);

    const response = await APIService.apiRequest(
      url.toString(),
      null,
      false,
      "GET",
      controller,
    );

    return response;
  }

  // Debounce is used to avoid multiple API calls if user abuse the search input field by clicking enter multiple times
  const createNewtag = debounce((tagName) => {
    setShowOptions(false);
    alertService.info("Adding new tag");
    APIService.apiRequest(
      WIKI_TAGS_URL,
      {
        tag: tagName,
        slug: `${tagName.toLowerCase().replace(/ /g, "-")}`,
        is_mandatory: false,
        is_deleted: false,
      },
      false,
      "POST",
    )
      .then((response) => {
        alertService.success("New tag has been added");
        const newOption = { ...response, tag: tagName, id: response.id };
        const newInputArray = [...inputArray, newOption];
        onChange(newInputArray);
        setInputArray(newInputArray);
        inputElRef.current.value = "";
      })
      .catch((error) => {
        console.log("New tag errr", error);
        // TODO: Need to add error handling later
        alertService.error("Something went wrong");
      });
  }, 600);

  function removeItem(array, item) {
    const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1);
    }
    return [...array];
  }

  function handleRemoveTag(index) {
    const newInputArray = removeItem(inputArray, inputArray[index]);
    onChange(newInputArray);
    setInputArray(newInputArray);
  }

  const handleInputChange = debounce(async (e) => {
    const searchString = e.target.value.trim();
    setShowOptions(false);

    if (searchString === "") {
      return;
    }

    try {
      setLoadingOptions(true);
      const response = await fetchtags({ searchString });
      setActiveSuggestionIndex(0);
      setPagination(() => response.meta.pagination);
      setOptions(() => response.data);
      setShowOptions(true);
      setLoadingOptions(false);
      scrollToActiveSuggestion(0);
    } catch (error) {
      setOptions([]);
      setShowOptions(true);
      setLoadingOptions(false);
      alertService.error("Something went wrong while fetching tags");
      console.log(error);
    }
  }, 600);

  async function loadMoretags() {
    try {
      setLoadingOptions(true);
      const response = await fetchtags({
        searchString: inputElRef.current.value,
        page_number: pagination.current_page + 1,
      });
      setPagination(() => response.output.meta);
      setOptions((prevOptions) => [...prevOptions, ...response.output.data]);
    } catch (error) {
      alertService.error("Something went wrong while fetching tags");
      console.log(error);
    } finally {
      setLoadingOptions(false);
    }
  }

  function handletagSelect(option) {
    const istagAlreadyAdded = inputArray.some((tag) => tag.id === option.id);

    if (istagAlreadyAdded) {
      alertService.error("tag already added");
      return;
    }

    const newInputArray = [...inputArray, option];
    onChange(newInputArray);
    setInputArray(newInputArray);
    inputElRef.current.value = "";
    inputElRef.current.focus();
  }

  function scrollToActiveSuggestion(index) {
    document
      ?.getElementById(`suggestion-${index}`)
      ?.scrollIntoView({ block: "nearest" });
  }

  function handleInputKeyDown(e) {
    if (e.key === "Enter" && e.target.value !== "") {
      if (options.length > 0) {
        handletagSelect(options[activeSuggestionIndex]);
      } else {
        createNewtag(e.target.value);
      }
    }

    if (e.key === "ArrowDown") {
      if (options.length - 1 > activeSuggestionIndex) {
        let index = activeSuggestionIndex + 1;
        setActiveSuggestionIndex(index);
        scrollToActiveSuggestion(index);
      }
    }

    if (e.key === "ArrowUp") {
      let index = activeSuggestionIndex - 1;
      if (index >= 0) {
        setActiveSuggestionIndex(index);
        scrollToActiveSuggestion(index);
      }
    }

    if (e.key === "Escape") {
      setShowOptions(false);
      inputElRef.current.value = "";
    }

    if (
      e.key === "Backspace" &&
      e.target.value === "" &&
      inputArray.length > 0
    ) {
      handleRemoveTag(inputArray.length - 1);
    }
  }

  const [showTagAddInput, setShowTagAddInput] = useState(value.length > 0);

  return (
    <div className="tag-wrapper">
      {!showTagAddInput ? (
        <button
          className="add-tags-btn"
          onClick={() => {
            setShowTagAddInput(true);
            setTimeout(() => inputElRef.current.focus(), 0);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="none"
          >
            <path
              fill="#B1B2B3"
              d="M5.666 10H4.334V5.666H0V4.334h4.334V0h1.332v4.334H10v1.332H5.666V10Z"
            />
          </svg>
          <span>Add Tags</span>
        </button>
      ) : (
        <div id="tag-select-container" ref={tagContainerDOMRef}>
          {inputArray?.map((tag, index) => {
            return (
              <div key={index} className="tag-tag">
                {tag.tag}
                <span
                  className="tag-tag-close-btn"
                  onClick={() => handleRemoveTag(index)}
                ></span>
              </div>
            );
          })}
          <div className="tag-input-container">
            {/* Both onBlur and onFocus are not required to handle click outside. Coz we are using ClickOutsideListner component which handles click outside */}
            <input
              ref={inputElRef}
              className="tag-input-box"
              type="text"
              placeholder="Search or add new"
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
            />
            {loadingOptions ? <div className="loader"></div> : null}
            {showOptions && (
              <ClickOutsideListner onOutsideClick={() => setShowOptions(false)}>
                <div
                  className={`tag-suggestion-list ${
                    showOptions && options.length > 0
                      ? "show-suggestion-list"
                      : "no-suggestion"
                  }`}
                  ref={suggestionListDOMRef}
                >
                  {options.length > 0 ? (
                    <ul>
                      <SuperPager
                        type="infiniteScroll"
                        isTable={false}
                        dataLength={options.length}
                        loadMore={loadMoretags}
                        hasMore={
                          pagination.current_page < pagination.no_of_pages
                        }
                        wrapper={true}
                        children={options?.map((option, index) => {
                          const isActive = activeSuggestionIndex === index;
                          const isSelected = inputArray.some(
                            (tag) => tag.id === option.id,
                          );

                          return (
                            <li
                              key={index}
                              className={`${isActive ? "active" : ""} ${
                                isSelected ? "selected" : ""
                              }`}
                              id={`suggestion-${index}`}
                              onClick={() => handletagSelect(option)}
                            >
                              {option.tag}
                            </li>
                          );
                        })}
                      />
                    </ul>
                  ) : (
                    <em>
                      No suggestions, you can add your own tag, just press enter
                      after typing!
                    </em>
                  )}
                </div>
              </ClickOutsideListner>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
