import { useEffect, useRef, useState } from "react";
import { useSocket } from "socket";
import LineLoader from "components/LineLoader";
import { AL_BASE_URL } from "views/Attendance/constants";
import APIService from "services/apiService";
import { format, formatTimeInSeconds } from "utils/date";
import "./BreakPopup.scss";
import { convertTimeZoneToUTC, convertUTCToTimezone } from "utils/Common";

export function BreakPopup({ data }) {
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const { break_count = 0, clock_in_time } = data;

  const socket = useSocket();
  const abortControllerRef = useRef(new AbortController());
  useEffect(() => () => abortControllerRef.current.abort(), []);

  async function getBreakDetails() {
    setIsLoading(true);
    try {
      const url = new URL(AL_BASE_URL + "/breaks");
      url.searchParams.append("resource_id", data.resource_id);
      url.searchParams.append("clock_in_time", format(convertTimeZoneToUTC(clock_in_time), "YYYY-MM-DD HH:mm:ss"));

      const response = await APIService.apiRequest(
        url.toString(),
        null,
        null,
        "GET",
        abortControllerRef.current
      );

      if (response.status === 1) {
        const logs = response.output.map((log) => ({
          ...log,
          break_start_time: convertUTCToTimezone(log.break_start_time),
          break_end_time: log.break_end_time === "NA" ? log.break_end_time : convertUTCToTimezone(log.break_end_time),
        }));
        setLogs(logs);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => getBreakDetails(), []);
  useEffect(() => {
    const updateBreakLogs = ({ data: log }) => {
      if (data.resource_id === log.resource_id && data.date === log.date) {
        getBreakDetails();
      }
    }

    socket.on("attendance", updateBreakLogs);
    return () => {
      socket.off("attendance", updateBreakLogs);
    };
  }, [data.date, data.resource_id]);

  return (
    <div className="break-popup">
      <LineLoader
        show={isLoading}
        position="absolute"
        style={{ top: '0' }}
      />
      <table className="table">
        <thead>
          <tr>
            <th>Break Start</th>
            <th>Break End</th>
            <th>Late Time</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && logs.length === 0 ? (
            <>
              {Array(break_count)
                .fill("")
                .map((_, index) => {
                  return (
                    <tr className="empty" key={index}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                })}
            </>
          ) : (
            <>
              {logs.map((log, index) => {
                const startTime = format(log.break_start_time, "hh:mm:ss A");
                const breakTime =
                  log.break_end_time === "NA"
                    ? ""
                    : format(log.break_end_time, "hh:mm:ss A");
                const lateTime =
                  log.late_break_time === "NA" ? "" : log.late_break_time;
                const totalBreakTime =
                  log.total_break_time === "NA" ? "" : log.total_break_time;
                const hasLateTime = lateTime > 0;

                return (
                  <tr key={index}>
                    <td>{startTime}</td>
                    <td>{breakTime ? breakTime : "-"}</td>
                    <td className={`${hasLateTime ? "late" : ""}`}>
                      {hasLateTime ? formatTimeInSeconds(lateTime) : "-"}
                    </td>
                    <td>
                      {totalBreakTime
                        ? formatTimeInSeconds(totalBreakTime)
                        : "-"}
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default BreakPopup;

