import {
  useCallback,
  useMemo,
  useEffect,
  useState,
  createContext,
  useContext,
} from "react";
import { useTabContext } from "components/SuperTabs/TabContext";
import { emitter } from "components/SuperTabs/SuperTabs";
import { PAGE_IDS } from "components/Navigation";
import { clearScrollPositions } from "hooks/useScrollRestoration";
import { WIKI_ARTICLES_URL } from "./constants";
import APIService from "services/apiService";
import { SITE_PREFIX } from "components/Constants";

export const WikiContext = createContext();

export const WIKI_ARTICLE_KEY = SITE_PREFIX + "wiki_article_";
export const getWikiArticleKey = (id) => `${WIKI_ARTICLE_KEY}${id}`;

export function WikiProvider({ children }) {
  const { activeTab } = useTabContext();

  // Home page state
  const VIEW = useMemo(
    () => ({
      GRID: "grid",
      LIST: "list",
    }),
    [],
  );

  const getInitialPageMeta = useCallback(
    () => ({
      filtered: false,
      pagination: {
        total_count: 0,
        no_of_pages: 0,
        current_page: 0,
      },
      verbose: false,
    }),
    [],
  );

  const [selectedView, setSelectedView] = useState(VIEW.LIST);
  const [search, setSearch] = useState("");
  const [activeTabId, setActiveTabId] = useState(null);
  const [loadingArticles, setLoadingArticles] = useState(true);
  const [articles, setArticles] = useState([]);

  const pageSize = useMemo(() => {
    const tableHeight = window.innerHeight - 90;
    return parseInt((tableHeight / 32) * 1.2);
  }, []);
  const [pageMeta, setPageMeta] = useState(getInitialPageMeta());

  const [isInitialArticlesFetch, setIsInitialArticlesFetch] = useState(true);

  const fetchArticles = async ({
    page_number = 1,
    search_string = "",
  } = {}) => {
    setLoadingArticles(true);
    try {
      const url = new URL(WIKI_ARTICLES_URL);
      url.searchParams.append("verbose", true);
      url.searchParams.append("page_number", page_number);
      url.searchParams.append("page_size", pageSize);

      if (search_string.trim().length > 0) {
        url.searchParams.append("search_string", search_string);
      }

      const res = await APIService.apiRequest(
        url.toString(),
        null,
        false,
        "GET",
      );

      if (isInitialArticlesFetch) {
        setIsInitialArticlesFetch(false);
      }

      if (page_number === 1) {
        setArticles(res.data);
      } else {
        setArticles((prev) => [...prev, ...res.data]);
      }
      setPageMeta(res.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingArticles(false);
    }
  };

  const { openSuperTabOnRowClick, closeTab } = useTabContext();
  const openArticle = (article) => {
    const tab = {
      id: article.id,
      name: article.title,
      url: `/wiki/article/${article.id}`,
      showEditButton: true,
      isEditMode: false,
    };
    openSuperTabOnRowClick({ tab });
  };

  const openArticleInEditMode = (article) => {
    const tab = {
      id: article.id,
      name: article.title,
      url: `/wiki/article/${article.id}?edit=true`,
      showEditButton: true,
      isEditMode: true,
    };
    openSuperTabOnRowClick({ tab });
  };

  const deleteArticle = async (article) => {
    try {
      const url = new URL(WIKI_ARTICLES_URL);
      url.searchParams.append("article_id", article.id);
      const res = await APIService.apiRequest(
        url.toString(),
        null,
        false,
        "DELETE",
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const addArticleInList = (article) => {
    if (pageMeta.pagination.current_page === pageMeta.pagination.no_of_pages) {
      setArticles((prev) => [...prev, article]);
    }
  };

  const updateArticleInList = (article) => {
    const updatedArticles = articles.map((a) => {
      if (a.id === article.id) {
        return article;
      }
      return a;
    });
    setArticles(updatedArticles);
  };

  const removeArticleInList = (article) => {
    const updatedArticles = articles.filter((a) => a.id !== article.id);
    setArticles(updatedArticles);

    if (activeTab?.subTabs?.length > 0) {
      const tabData = activeTab.subTabs.find(
        (tab) => tab.id === article.id,
      );

      if (tabData) {
        closeTab({
          tab: tabData,
          isSubTab: true,
        });
      }
    }
  };

  // articles data state
  const [articlesCache, setArticlesCache] = useState(() => {
    const cache = new Map();
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(WIKI_ARTICLE_KEY)) {
        try {
          const tabId = key.replace(WIKI_ARTICLE_KEY, "");
          const article = JSON.parse(localStorage.getItem(key));
          if (article) cache.set(tabId, article);
        } catch (error) { }
      }
    }

    return cache;
  });

  useEffect(() => {
    const unsub = emitter.subscribe("superTabClose", (e) => {
      if (e.appId === PAGE_IDS.WIKI && e.isSubTab) {
        const tabId = String(e.tab.id);
        if (articlesCache.has(tabId)) {
          clearScrollPositions(`wiki-article-view-${tabId}`);
          clearScrollPositions(`wiki-article-edit-${tabId}`);
          setArticlesCache((prev) => {
            prev.delete(tabId);
            return new Map(prev);
          });
        }

        localStorage.removeItem(getWikiArticleKey(tabId));
      }
    });

    return unsub;
  }, [articlesCache]);

  useEffect(() => () => clearScrollPositions("wiki"), []);

  return (
    <WikiContext.Provider
      value={{
        VIEW,
        selectedView,
        setSelectedView,
        activeTabId,
        setActiveTabId,
        search,
        setSearch,
        loadingArticles,
        isInitialArticlesFetch,
        articles,
        pageSize,
        pageMeta,
        fetchArticles,
        openArticle,
        openArticleInEditMode,
        deleteArticle,
        addArticleInList,
        updateArticleInList,
        removeArticleInList,
        articlesCache,
        setArticlesCache,
      }}
    >
      {children}
    </WikiContext.Provider>
  );
}

export const useWikiContext = () => {
  const context = useContext(WikiContext);
  if (!context) {
    throw new Error("useWikiContext must be used within a WikiProvider");
  }

  return context;
};
