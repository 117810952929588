import {
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useState,
  useMemo,
} from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  // Placement,
  FloatingPortal,
  FloatingFocusManager,
  useHover,
  // useId,
} from "@floating-ui/react";

export function usePopover({
  initialOpen = false,
  placement = "bottom",
  modal,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  mouseover = false,
  disabled = false, // Add disabled prop
}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState();
  const [descriptionId, setDescriptionId] = useState();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open: disabled ? false : open, // Set open to false if disabled
    onOpenChange: disabled ? () => { } : setOpen, // Disable setOpen if disabled
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "end",
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  });

  const context = data.context;

  const hover = useHover(context, { enabled: mouseover && !disabled, move: true });
  const click = useClick(context, { enabled: (controlledOpen == null) && !disabled });
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const interactions = useInteractions([hover, click, dismiss, role]);

  return useMemo(
    () => ({
      open: disabled ? false : open,
      setOpen: disabled ? () => { } : setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, data, interactions, modal, labelId, descriptionId, disabled]
  );
}

const PopoverContext = createContext(null);

export function usePopoverContext() {
  const context = useContext(PopoverContext);

  if (context == null) {
    throw new Error("usePopoverContext must be used within a Popover");
  }

  return context;
}

export function Popover({
  children,
  modal = false,
  mouseover = false,
  ...restOptions
}) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options
  const popover = usePopover({ modal, mouseover, ...restOptions });

  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  );
}

export const PopoverTrigger = forwardRef(
  ({ children, asChild = false, ...props }, propRef) => {
    const context = usePopoverContext();
    const childrenRef = children.ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          "data-state": context.open ? "open" : "closed",
        })
      );
    }

    return (
      <button
        ref={ref}
        type="button"
        data-state={context.open ? "open" : "closed"}
        {...context.getReferenceProps(props)}
      >
        {children}
      </button>
    );
  }
);

export const PopoverContent = forwardRef(({ style, root = null, ...props }, propRef) => {
  const { context: floatingContext, ...context } = usePopoverContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!floatingContext.open) return null;

  return (
    <FloatingPortal root={root}>
      <FloatingFocusManager context={floatingContext} modal={context.modal}>
        <div
          ref={ref}
          style={{ ...context.floatingStyles, ...style, outline: "none" }}
          aria-labelledby={context.labelId}
          aria-describedby={context.descriptionId}
          {...context.getFloatingProps(props)}
        >
          {props.children}
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  );
});

export const PopoverClose = forwardRef(({ children, asChild = false, ...props }, ref) => {
  const { setOpen } = usePopoverContext();

  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      {
        ref,
        ...children.props,
        ...props,
        onClick: (event) => {
          children.props.onClick?.(event);
          setOpen(false);
        }
      }
    );
  }

  return (
    <button
      ref={ref}
      type="button"
      {...props}
      onClick={(event) => {
        props?.onClick?.(event);
        setOpen(false);
      }}
    >
      {children}
    </button>
  );
});
