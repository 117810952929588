import React, { useState, useEffect } from 'react';

import APIService from "services/apiService";
import { alertService } from 'services/alertService';

import { useParams } from 'react-router-dom';
import { useTabContext } from 'components/SuperTabs/TabContext';
import { usePerformanceContext } from '../context';
import useScrollRestoration from 'hooks/useScrollRestoration';

import SpeedSelect from 'react-speedselect';
import LineLoader from 'components/LineLoader';
import ClickOutsideListner from 'components/ClickOutsideListner';
import Avatar from 'components/Avatar';
import Credit from './Credit';
import SearchRecipient from './SearchRecipient';

import { API_BASE_URL, SITE_PREFIX } from 'components/Constants';
import { getUser, isEmpty } from 'utils/Common';
import { BADGES, STATUS } from './../Constants'

import './styles.scss';

const PerformanceConsole = () => {
    const { activeSubTab: candidateInfo = {} } = useTabContext();
    const {
        loggedInUserResId,
        individualTabMetrics, updateIndividualTabMetricsData,
        selectedMetrics, updateSelectedMetrics,
        savedTabStates, updateSavedTabStates,
    } = usePerformanceContext();
    const { id } = useParams();
    const [loadingCreditDetails, setLoadingCreditDetails] = useState(false);

    const savedTabState = savedTabStates[id] || {};
    const metrics = individualTabMetrics[id];
    const selectedMetric = selectedMetrics[id];
    const isSavedTabStateEmpty = isEmpty(savedTabState);

    const { firstName, lastName, id: resourceId, designation, department, department_id, imgSrc } = candidateInfo;
    const { type, activeTab, reason, receivedSearchString, givenSearchString, showFilters, recipientsList, creditReceivedFromList, creditGivenToList, receivedMetricList, givenMetricList, selectedMetricFrom, selectedMetricTo, selectedReceivedMetricFilter, selectedGivenMetricFilter, receivedStatus, givenStatus } = savedTabState;

    const userInfo = getUser();
    const recipientList = id === 'my-profile' ? recipientsList : [{ id: resourceId, firstName: firstName, lastName: lastName, imgUrl: imgSrc }];

    useEffect(() => {
        if (isSavedTabStateEmpty) updateSavedTabStates(id, 'newTab');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSavedTabStateEmpty]);

    const abortController = new AbortController();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => abortController.abort(), []);

    const [creditsReceived, setCreditsReceived] = useState(null);
    const [creditsGiven, setCreditsGiven] = useState(null);
    const [filteredCreditsReceived, setFilteredCreditsReceived] = useState(null);
    const [filteredCreditsGiven, setFilteredCreditsGiven] = useState(null);

    useEffect(() => {
        if (!(id.includes('home') || window.location.pathname.includes('/performance'))) return;
        if (isSavedTabStateEmpty) return;
        getMetrics();
        getCredits(id === 'my-profile' ? loggedInUserResId : id, 'given');
        getCredits(id === 'my-profile' ? loggedInUserResId : id, 'received');
        getCreditFilter('given');
        getCreditFilter('received');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isSavedTabStateEmpty]);

    const onScroll = (elem_id) => {
        let e = document.getElementById(elem_id);
        // console.log('values', e.scrollTop, e.scrollHeight, e.clientHeight, e.scrollTop + e.clientHeight);
        let metaDataCopy = elem_id === 'credit-given' ?
            filteredCreditsGiven ? filteredCreditsGivenMeta : creditsGivenMeta :
            filteredCreditsReceived ? filteredCreditsReceivedMeta : creditsReceivedMeta;
        //scrollTop, scrollHeight, clientHeight
        // if (!searchResults && !leaderBoard) return;
        const hasData = metaDataCopy.page_number < metaDataCopy.num_pages;
        // if (!hasData) {
        //     // window.removeEventListener('scroll', onScroll);
        //     return;
        // }
        // if (window.innerHeight + Math.ceil(window.scrollY) >= document.body.scrollHeight) {
        //     console.log(`reached bottom`);
        //     hasData && !loadingLeaderBoard && getLeaderBoard(metaDataCopy.page_number + 1, true);
        // }
        if (Math.ceil(e.scrollTop) + e.clientHeight >= e.scrollHeight) {
            // console.log(`url_next reached bottom`, id, metaDataCopy, hasData);
            hasData && (elem_id === 'credit-given' ? !loadingGiveCreditInProcess : !loadingReceiveCreditInProcess) && getCredits(id === 'my-profile' ? loggedInUserResId : id, elem_id.replace('credit-', ''), ['loadNext', metaDataCopy.page_number + 1]);
            // hasData && getCredits(id === 'my-profile' ? loggedInUserResId : id, elem_id.replace('credit-', ''), ['loadNext', metaDataCopy.page_number + 1]);
        }
    }

    const [giveOrReceiveCreditInProcess, setGiveOrReceiveCreditInProcess] = useState(false);
    const [loadingReceiveCreditInProcess, setLoadingReceiveCreditInProcess] = useState(false);
    const [loadingGiveCreditInProcess, setLoadingGiveCreditInProcess] = useState(false);
    const [loadingMetrics, setLoadingMetrics] = useState(false);

    const [creditsReceivedMeta, setCreditsReceivedMeta] = useState(null);
    const [creditsGivenMeta, setCreditsGivenMeta] = useState(null);
    const [filteredCreditsReceivedMeta, setFilteredCreditsReceivedMeta] = useState(null);
    const [filteredCreditsGivenMeta, setFilteredCreditsGivenMeta] = useState(null);

    const [showAddRecipientForm, setShowAddRecipientForm] = useState(false);

    const getMetrics = () => {
        setLoadingMetrics(true);
        const url = new URL(API_BASE_URL + '/performance/metric');
        if (department_id) url.searchParams.append('department_id', department_id);
        APIService.apiRequest(url.toString(), null, false, 'GET', abortController)
            .then(response => {
                if (response.status === 1) {
                    setLoadingMetrics(false);
                    updateIndividualTabMetricsData(id, response.data);
                    !selectedMetric && updateSelectedMetrics(id, response.data && response.data.length > 0 ? response.data[0].id : null)
                    // console.log('getMetrics - if response', response);
                } else {
                    setLoadingMetrics(false);
                    if (!(response instanceof DOMException && response.name === 'AbortError')) {
                        alertService.error(`Error occured while fetching metrics`);
                    }
                    console.log('getMetrics - else response', response.message);
                }
            }).catch(err => {
                setLoadingMetrics(false);
                alertService.error(`Error: ${err.msg}`);
                console.log('getMetrics - error', err);
            });
    }

    const getCreditFilter = (action) => {
        APIService.apiRequest(API_BASE_URL + `/performance/credit_filter?resource_id=${id === 'my-profile' ? loggedInUserResId : id}&action=${action}`, null, false, 'GET', abortController)
            .then(response => {
                if (response.status === 1) {
                    // console.log('getCreditFilter - if response', response);
                    if (action === 'received') {
                        updateSavedTabStates(id, 'filterReceivedMetrics', response);
                        // updateSavedTabStates(id, 'receivedMetricList', response.output.metric);
                        // setReceivedBadges(response.output.metric);
                    } else {
                        updateSavedTabStates(id, 'filterGivenMetrics', response);
                    }
                } else {
                    if (!(response instanceof DOMException && response.name === 'AbortError')) {
                        alertService.error(`Error occured while fetching credit filter details`);
                    }
                    console.log('getCreditFilter - else response', response.message);
                }
            }).catch(err => {
                alertService.error(`Error: ${err.msg}`);
                console.log('getCreditFilter - error', err);
            });
    }

    const getCredits = (resId, action = 'given', value = ['', ''], clearFilters = false) => {
        const hasGivenFilter = !clearFilters ? (value[0] !== 'loadNext' && action === 'given' && value[0] && (value[0] === 'status' ? value[1] !== 'All' : false)) || givenSearchString || selectedGivenMetricFilter || selectedMetricTo || givenStatus !== 'All' : false;
        const hasReceivedFilter = !clearFilters ? (value[0] !== 'loadNext' && action !== 'given' && value[0] && (value[0] === 'status' ? value[1] !== 'All' : false)) || receivedSearchString || selectedReceivedMetricFilter || selectedMetricFrom || receivedStatus !== 'All' : false;

        let sessionStorageObj = sessionStorage[SITE_PREFIX + 'performance'] && JSON.parse(sessionStorage[SITE_PREFIX + 'performance']);

        if ((receivedSearchString || selectedReceivedMetricFilter || selectedMetricFrom) && sessionStorageObj?.hasOwnProperty(id + 'rf')) {
            setFilteredCreditsReceived(sessionStorageObj[id + 'rf']);
            // console.log('sessionStorageObj rf', sessionStorageObj[id + 'rf'])
        }
        if ((givenSearchString || selectedGivenMetricFilter || selectedMetricTo) && sessionStorageObj?.hasOwnProperty(id + 'gf')) {
            setFilteredCreditsGiven(sessionStorageObj[id + 'gf'])
            // console.log('sessionStorageObj rg', sessionStorageObj[id + 'rg'])
        }
        if (sessionStorageObj?.hasOwnProperty(id + 'r')) {
            setCreditsReceived(sessionStorageObj[id + 'r']);
            // console.log('sessionStorageObj r', id, sessionStorageObj[id + 'r'])
        }
        if (sessionStorageObj?.hasOwnProperty(id + 'g')) {
            setCreditsGiven(sessionStorageObj[id + 'g']);
            // console.log('sessionStorageObj g', id, sessionStorageObj[id + 'g'])
        }

        // let filteredCreditsReceivedData = (receivedSearchString || selectedReceivedMetricFilter || selectedMetricFrom) && sessionStorageObj?.hasOwnProperty(id + 'rf');
        // let creditsGivenData = sessionStorageObj?.hasOwnProperty(id + 'g');
        // let filteredCreditsGivenData = (givenSearchString || selectedGivenMetricFilter || selectedMetricTo) && sessionStorageObj?.hasOwnProperty(id + 'gf');

        // console.log('test data', creditsReceivedData, filteredCreditsReceivedData, creditsGivenData, filteredCreditsGivenData);

        // TODO fix below condition
        // !hasFilter && (action === 'given' ? setLoadingGiveCreditInProcess(true) : setLoadingReceiveCreditInProcess(true));
        action === 'given' ? setLoadingGiveCreditInProcess(true) : setLoadingReceiveCreditInProcess(true);
        value[0] === 'loadNext' && setLoadingCreditDetails(true);

        let url = API_BASE_URL + `/performance/credit?resource_id=${resId}&action=${action}&page_size=30&page_number=${value[0] === 'loadNext' ? value[1] : 1}`;
        if (!clearFilters) {
            url = url
                + (action === 'received' && (value[0] === 'metric' || selectedReceivedMetricFilter) ? `&metric_id=${value[0] === 'metric' ? value[1] : selectedReceivedMetricFilter.id}` : '')
                + (action === 'given' && (value[0] === 'metric' || selectedGivenMetricFilter) ? `&metric_id=${value[0] === 'metric' ? value[1] : selectedGivenMetricFilter.id}` : '')
                + (action === 'received' && (value[0] === 'search' || receivedSearchString) ? `&search_string=${value[0] === 'search' ? value[1] : receivedSearchString}` : '')
                + (action === 'given' && (value[0] === 'search' || givenSearchString) ? `&search_string=${value[0] === 'search' ? value[1] : givenSearchString}` : '')
                + (action === 'received' && (value[0] === 'user' || selectedMetricFrom) ? `&evaluator_resource_id=${value[0] === 'user' ? value[1] : selectedMetricFrom.id}` : '')
                + (action === 'given' && (value[0] === 'user' || selectedMetricTo) ? `&recipient_resource_id=${value[0] === 'user' ? value[1] : selectedMetricTo.id}` : '')
                // + (action === 'received' &&  ((value[0] === 'status' && value[1] !== 'All') || (receivedStatus && receivedStatus !== 'All')) ? `&status=${value[0] === 'status' ? value[1] === 'Accepted' ? 1 : 0 : receivedStatus === 'Accepted' ? 1 : 0}` : '')
                + (action === 'received' && (value[0] === 'status' || receivedStatus) ?
                    value[0] === 'status' ?
                        value[1] !== 'All' ?
                            `&status=${value[1] === 'Accepted' ? 1 : 0}`
                            : ''
                        : receivedStatus !== 'All' ?
                            `&status=${receivedStatus === 'Accepted' ? 1 : 0}`
                            : ''
                    : ''
                )
                + (action === 'given' && (value[0] === 'status' || givenStatus) ?
                    value[0] === 'status' ?
                        value[1] !== 'All' ?
                            `&status=${value[1] === 'Accepted' ? 1 : 0}`
                            : ''
                        : givenStatus !== 'All' ?
                            `&status=${givenStatus === 'Accepted' ? 1 : 0}`
                            : ''
                    : ''
                )
        }

        // `&status=${value[0] === 'status' ? value[1] === 'Accepted' ? 1 : 0 : receivedStatus === 'Accepted' ? 1 : 0}` : '')
        // + (action === 'given' && ((value[0] === 'status' && value[1] !== 'All') || (givenStatus && givenStatus !== 'All')) ? `&status=${value[0] === 'status' ? value[1] === 'Accepted' ? 1 : 0 : givenStatus === 'Accepted' ? 1 : 0}` : '')
        // +('&status=0')
        // console.log('url_next', url);
        // if (value[0] === 'loadNext') return;
        // GET https://dev-api.hrbase.net/data_stream/performance/credit?resource_id=402&action=given&recipient_resource_id=395&metric_id=1&status=0&evaluator_resource_id=402&search_string='test'
        // APIService.apiRequest(API_BASE_URL + `/performance/credit?resource_id=${resId}&action=${action}`, null, false, 'GET')
        APIService.apiRequest(url, null, false, 'GET', abortController)
            .then(response => {
                if (response.status === 1) {
                    let sessionObj = sessionStorage[SITE_PREFIX + 'performance'] && JSON.parse(sessionStorage[SITE_PREFIX + 'performance']);
                    // console.log('sessionObj', sessionObj);
                    let obj = {};
                    // !hasFilter && (action === 'given' ? setLoadingGiveCreditInProcess(false) : setLoadingReceiveCreditInProcess(false));
                    action === 'given' ? setLoadingGiveCreditInProcess(false) : setLoadingReceiveCreditInProcess(false);
                    if (action === 'given') {
                        if (hasGivenFilter) {
                            let copyFilteredCreditsGiven = value[0] === 'loadNext' ? [...filteredCreditsGiven, ...response.data] : response.data;
                            obj[id + 'gf'] = copyFilteredCreditsGiven;
                            setFilteredCreditsGiven(copyFilteredCreditsGiven);
                            setFilteredCreditsGivenMeta(response.meta);
                        } else {
                            let copyCreditsGiven = value[0] === 'loadNext' ? [...creditsGiven, ...response.data] : response.data;
                            obj[id + 'g'] = copyCreditsGiven;
                            setCreditsGiven(copyCreditsGiven);
                            setCreditsGivenMeta(response.meta);
                        }
                    } else {
                        if (hasReceivedFilter) {
                            let copyFilteredCreditsReceived = value[0] === 'loadNext' ? [...filteredCreditsReceived, ...response.data] : response.data;
                            obj[id + 'rf'] = copyFilteredCreditsReceived;
                            setFilteredCreditsReceived(copyFilteredCreditsReceived);
                            setFilteredCreditsReceivedMeta(response.meta);
                        } else {
                            let copyCreditsReceived = value[0] === 'loadNext' ? [...creditsReceived, ...response.data] : response.data;
                            obj[id + 'r'] = copyCreditsReceived;
                            setCreditsReceived(copyCreditsReceived);
                            setCreditsReceivedMeta(response.meta);
                        }
                    }

                    if (!sessionStorage[SITE_PREFIX + 'performance']) {
                        // console.log('not found', SITE_PREFIX + 'performance')
                        sessionStorage.setItem(SITE_PREFIX + 'performance', JSON.stringify(obj));
                    } else {
                        // console.log('found', {...obj, ...sessionObj}, obj, sessionObj)
                        sessionStorage.setItem(SITE_PREFIX + 'performance', JSON.stringify({ ...sessionObj, ...obj }));
                    }
                    // console.log('getCredits - if response', response);
                } else {
                    console.log('getCredits - else response', response.message);
                    if (!(response instanceof DOMException && response.name === 'AbortError')) {
                        alertService.error(`Error occured while fetching the credit details`);
                    }
                    // !hasFilter && (action === 'given' ? setLoadingGiveCreditInProcess(false) : setLoadingReceiveCreditInProcess(false));
                    action === 'given' ? setLoadingGiveCreditInProcess(false) : setLoadingReceiveCreditInProcess(false);
                }
                value[0] === 'loadNext' && setLoadingCreditDetails(false);
            }).catch(err => {
                alertService.error(`Error: ${err.msg}`);
                // !hasFilter && (action === 'given' ? setLoadingGiveCreditInProcess(false) : setLoadingReceiveCreditInProcess(false));
                action === 'given' ? setLoadingGiveCreditInProcess(false) : setLoadingReceiveCreditInProcess(false);
                value[0] === 'loadNext' && setLoadingCreditDetails(false);
                console.log('getCredits - error', err);
            });
    }

    const handleSubmitBtn = () => {
        if (!reason) { alertService.error('Reason is mandatory'); return; }
        if (!recipientList.length) { alertService.error('Please add a recipient'); return; }

        // TODO: update below resource ID with recipient list ids
        // const resId = id === 'my-profile' ? 402 : +id;
        const resId = id === 'my-profile' ? recipientList.map(r => r.id).toString() : String(id);
        let apiPayload = {
            metric_id: selectedMetric,
            is_requested: type === 'request' ? 1 : 0,
            is_given: type !== 'request' ? 1 : 0,
            reason: reason,
            recipient_resource_id: type === 'request' ? String(loggedInUserResId) : resId,
            evaluator_resource_id: type === 'request' ? resId : String(loggedInUserResId)
        };
        // console.log('handleSubmitBtn payload', apiPayload);

        // if (id === 'my-profile') {
        //     alertService.warning('Please divert - Work in progress');
        //     return;
        // }

        setGiveOrReceiveCreditInProcess(true);
        // POST https://dev-api.hrbase.net/data_stream/performance/new_credit
        APIService.apiRequest(API_BASE_URL + '/performance/new_credit', apiPayload, false, 'POST')
            .then(response => {
                if (response.status === 1) {
                    setGiveOrReceiveCreditInProcess(false);
                    // console.log('getDept - if response', response);
                    alertService.success(response.message);
                    // setReason('');
                    // updateSavedTabStates(id, 'reason', '');
                    // id === 'my-profile' && setRecipientList([]);
                    updateSavedTabStates(id, 'reset', '');
                    getCredits(id === 'my-profile' ? loggedInUserResId : id, type === 'request' ? id === 'my-profile' ? 'received' : 'given' : id === 'my-profile' ? 'given' : 'received');
                } else {
                    console.log('getDept - else response', response.message);
                    if (!(response instanceof DOMException && response.name === 'AbortError')) {
                        alertService.error(`Error occured while saving the credit`);
                    }
                    setGiveOrReceiveCreditInProcess(false);
                }
            }).catch(err => {
                alertService.error(`Error: ${err.msg}`);
                setGiveOrReceiveCreditInProcess(false);
                console.log('getDept - error', err);
            });
    }

    const handleResetBtn = () => {
        // setSelectedMetric(metrics && metrics.length ? metrics[0].id : null);
        updateSelectedMetrics(id, metrics && metrics.length ? metrics[0].id : null);
        // setType('request');
        // setReason('');
        updateSavedTabStates(id, 'reset', '');
        // id === 'my-profile' && setRecipientList([]);
    }

    const handleClearBtn = (action) => {
        let filtersPresent = false;
        if (action === 'given') {
            const { selectedMetricTo, selectedGivenMetricFilter, givenSearchString, givenStatus } = savedTabState
            filtersPresent = selectedMetricTo || selectedGivenMetricFilter || givenSearchString || givenStatus !== "All";
        } else {
            const { selectedMetricFrom, selectedReceivedMetricFilter, receivedSearchString, receivedStatus } = savedTabState
            filtersPresent = selectedMetricFrom || selectedReceivedMetricFilter || receivedSearchString || receivedStatus !== 'All';
        }

        if (filtersPresent) {
            action === 'given' ? setFilteredCreditsGiven(null) : setFilteredCreditsReceived(null);
            updateSavedTabStates(id, action === 'given' ? 'clearGivenFilterValue' : 'clearReceivedFilterValue');
            deleteStorageData(action);
            getCredits(id === 'my-profile' ? loggedInUserResId : id, action, ["", ""], true);
            // ((action === 'given' && filteredCreditsGiven) || (action === 'received' && filteredCreditsReceived)) && getCredits(id === 'my-profile' ? loggedInUserResId : id, action, ['reset', '']);
        }
    }

    const deleteStorageData = (action) => {
        let sessionObj = sessionStorage[SITE_PREFIX + 'performance'] && JSON.parse(sessionStorage[SITE_PREFIX + 'performance']);
        delete sessionObj[String(id) + (action === 'given' ? 'gf' : 'rf')];
        sessionStorage.setItem(SITE_PREFIX + 'performance', JSON.stringify(sessionObj));
    }

    const addRecipient = (resource_id, firstName, lastName, imgUrl) => {
        if (recipientsList.some(r => r.id === +resource_id)) {
            alertService.warning('User is already added in the recipient list');
            return;
        }
        // setRecipientList([...recipientList, { id: resource_id, firstName: firstName, lastName: lastName, imgUrl: imgUrl }]);
        updateSavedTabStates(id, 'recipientsList', [...recipientsList, { id: resource_id, firstName: firstName, lastName: lastName, imgUrl: imgUrl }]);
    }

    // const togglePublicVisibility = (id, isPublic) => {
    //     setCreditsReceived(creditsReceived.map(c => c.credit_id === id ? { ...c, is_public: isPublic === 1 ? 0 : 1 } : c));
    //     const apiPayLoad = { is_public: isPublic === 1 ? 0 : 1 }
    //     // PUT https://dev-api.hrbase.net/data_stream/performance/public_toggle/<credit_id>
    //     APIService.apiRequest(API_BASE_URL + `/performance/public_toggle/${id}`, apiPayLoad, false, 'PUT')
    //         .then(response => {
    //             if (response.status === 1) {
    //                 // alertService.success(isPublic === 1 ? 'Credit is now private' : 'Credit is now public');
    //                 // console.log('togglePublicVisibility - if response', response);
    //             } else {
    //                 console.log('togglePublicVisibility - else response', response.message);
    //                 if (!(response instanceof DOMException && response.name === 'AbortError')) {
    //                     alertService.error(`Error occured while toggling the visiblity`);
    //                 }
    //                 setCreditsReceived(creditsReceived.map(c => c.credit_id === id ? { ...c, is_public: isPublic } : c));
    //             }
    //         }).catch(err => {
    //             alertService.error(`Error: ${err.msg}`);
    //             console.log('togglePublicVisibility - error', err);
    //             setCreditsReceived(creditsReceived.map(c => c.credit_id === id ? { ...c, is_public: isPublic } : c));
    //         });
    // }

    const handleApprove = (creditId, status, isPublic) => {
        let updatedCreditsGiven = creditsGiven.map(c => c.credit_id === creditId ? { ...c, status: 'Accepted', is_public: 1 } : c);
        let updatedFilteredCreditsGiven = filteredCreditsGiven && filteredCreditsGiven.map(c => c.credit_id === creditId ? { ...c, status: 'Accepted', is_public: 1 } : c);
        setCreditsGiven(updatedCreditsGiven);
        filteredCreditsGiven && setFilteredCreditsGiven(updatedFilteredCreditsGiven);
        // PUT https://dev-api.hrbase.net/data_stream/performance/approve_credit/<credit_id>
        APIService.apiRequest(API_BASE_URL + `/performance/approve_credit/${creditId}`, null, false, 'PUT')
            .then(response => {
                if (response.status === 1) {
                    // console.log('handleApprove - if response', response);
                    let sessionStorageObj = sessionStorage[SITE_PREFIX + 'performance'] && JSON.parse(sessionStorage[SITE_PREFIX + 'performance']);
                    sessionStorageObj[id + 'g'] = updatedCreditsGiven;
                    if (filteredCreditsGiven) sessionStorageObj[id + 'gf'] = updatedFilteredCreditsGiven;
                    sessionStorage.setItem(SITE_PREFIX + 'performance', JSON.stringify(sessionStorageObj));
                } else {
                    console.log('handleApprove - else response', response.message);
                    if (!(response instanceof DOMException && response.name === 'AbortError')) {
                        alertService.error(`Error occured while approving a credit`);
                    }
                    setCreditsGiven(creditsGiven.map(c => c.credit_id === creditId ? { ...c, status: status, is_public: isPublic } : c));
                    filteredCreditsGiven && setFilteredCreditsGiven(filteredCreditsGiven.map(c => c.credit_id === creditId ? { ...c, status: status, is_public: isPublic } : c));
                }
            }).catch(err => {
                setCreditsGiven(creditsGiven.map(c => c.credit_id === creditId ? { ...c, status: status, is_public: isPublic } : c));
                filteredCreditsGiven && setFilteredCreditsGiven(filteredCreditsGiven.map(c => c.credit_id === creditId ? { ...c, status: status, is_public: isPublic } : c));
                alertService.error(`Error: ${err.msg}`);
                console.log('handleApprove - error', err);
            });
    }

    const handleAccept = (creditId, status, isPublic) => {
        let updatedCreditsReceived = creditsReceived.map(c => c.credit_id === creditId ? { ...c, status: 'Accepted', is_public: 1 } : c);
        let updatedFilteredCreditsReceived = filteredCreditsReceived && filteredCreditsReceived.map(c => c.credit_id === creditId ? { ...c, status: 'Accepted', is_public: 1 } : c);
        setCreditsReceived(updatedCreditsReceived);
        filteredCreditsReceived && setFilteredCreditsReceived(updatedFilteredCreditsReceived);
        // return;
        // PUT https://dev-api.hrbase.net/data_stream/performance/accept_credit/<credit_id>
        APIService.apiRequest(API_BASE_URL + `/performance/accept_credit/${creditId}`, null, false, 'PUT')
            .then(response => {
                if (response.status === 1) {
                    // alertService.success(response.msg);
                    // console.log('handleAccept - if response', response);
                    let sessionStorageObj = sessionStorage[SITE_PREFIX + 'performance'] && JSON.parse(sessionStorage[SITE_PREFIX + 'performance']);
                    sessionStorageObj[id + 'r'] = updatedCreditsReceived;
                    if (filteredCreditsReceived) sessionStorageObj[id + 'rf'] = updatedFilteredCreditsReceived;
                    sessionStorage.setItem(SITE_PREFIX + 'performance', JSON.stringify(sessionStorageObj));
                } else {
                    console.log('handleAccept - else response', response.message);
                    if (!(response instanceof DOMException && response.name === 'AbortError')) {
                        alertService.error(`Error occured while accepting a credit`);
                    }
                    setCreditsReceived(creditsReceived.map(c => c.credit_id === creditId ? { ...c, status: status, is_public: isPublic } : c));
                    filteredCreditsReceived && setFilteredCreditsReceived(filteredCreditsReceived.map(c => c.credit_id === creditId ? { ...c, status: status, is_public: isPublic } : c));
                }
            }).catch(err => {
                setCreditsReceived(creditsReceived.map(c => c.credit_id === creditId ? { ...c, status: status, is_public: isPublic } : c));
                filteredCreditsReceived && setFilteredCreditsReceived(filteredCreditsReceived.map(c => c.credit_id === creditId ? { ...c, status: status, is_public: isPublic } : c));
                alertService.error(`Error: ${err.msg}`);
                console.log('handleAccept - error', err);
            });
    }

    // const handleDispute = (id) => {
    //     // PUT https://dev-api.hrbase.net/data_stream/performance/dispute_credit/<credit_id>
    //     APIService.apiRequest(API_BASE_URL + `/performance/dispute_credit/${id}`, null, false, 'PUT')
    //         .then(response => {
    //             if (response.status === 1) {
    //                 setCreditsReceived(creditsReceived.map(c => c.credit_id === id ? {...c, status: 'Disputed'} : c));
    //                 alertService.success(response.msg);
    //                 console.log('handleDispute - if response', response);
    //             } else {
    //                 console.log('handleDispute - else response', response.msg);
    //             }
    //         }).catch(err => {
    //             alertService.error(`Error: ${err.msg}`);
    //             console.log('handleDispute - error', err);
    //         });
    // }

    const handleRevoke = (creditId, type) => {
        APIService.apiRequest(API_BASE_URL + `/performance/credit/${creditId}`, null, false, 'DELETE')
            .then(response => {
                if (response.status === 1) {
                    // setCreditsGiven(creditsGiven.map(c => c.credit_id === id ? {...c, status: 'Revoked'} : c));
                    alertService.success(response.message);
                    // if (type === 'received') {
                    //     filteredCreditsReceived && setFilteredCreditsReceived(filteredCreditsReceived.filter(c => c.credit_id !== creditId));
                    // } else {
                    //     filteredCreditsGiven && setFilteredCreditsGiven(filteredCreditsGiven.filter(c => c.credit_id !== creditId));
                    // }

                    let sessionStorageObj = sessionStorage[SITE_PREFIX + 'performance'] && JSON.parse(sessionStorage[SITE_PREFIX + 'performance']);
                    if (type === 'received') {
                        let updatedCreditsReceived = creditsReceived.filter(c => c.credit_id !== creditId);
                        let updatedFilteredCreditsReceived = filteredCreditsReceived && filteredCreditsReceived.filter(c => c.credit_id !== creditId);
                        sessionStorageObj[id + 'r'] = updatedCreditsReceived;
                        if (filteredCreditsReceived) sessionStorageObj[id + 'rf'] = updatedFilteredCreditsReceived;
                        setCreditsReceived(updatedCreditsReceived);
                        filteredCreditsReceived && setFilteredCreditsReceived(updatedFilteredCreditsReceived);
                    } else {
                        let updatedCreditsGiven = creditsGiven.filter(c => c.credit_id !== creditId);
                        let updatedFilteredCreditsGiven = filteredCreditsGiven && filteredCreditsGiven.filter(c => c.credit_id !== creditId)
                        sessionStorageObj[id + 'g'] = updatedCreditsGiven;
                        if (filteredCreditsGiven) sessionStorageObj[id + 'gf'] = updatedFilteredCreditsGiven;
                        setCreditsGiven(updatedCreditsGiven);
                        filteredCreditsGiven && setFilteredCreditsGiven(updatedFilteredCreditsGiven);
                    }
                    sessionStorage.setItem(SITE_PREFIX + 'performance', JSON.stringify(sessionStorageObj));
                    getCredits(id === 'my-profile' ? loggedInUserResId : id, type);
                    // console.log('handleRevoke - if response', response);
                } else {
                    if (!(response instanceof DOMException && response.name === 'AbortError')) {
                        alertService.error(`Error occured while revoking a credit`);
                    }
                    console.log('handleRevoke - else response', response.message);
                }
            }).catch(err => {
                alertService.error(`Error: ${err.msg}`);
                console.log('handleRevoke - error', err);
            });
    }

    const updateCommentCount = (type, id, count) => {
        let creditDetailsCopy = type === 'received' ? JSON.parse(JSON.stringify(filteredCreditsReceived ? filteredCreditsReceived : creditsReceived)) : JSON.parse(JSON.stringify(filteredCreditsGiven ? filteredCreditsGiven : creditsGiven));
        creditDetailsCopy = creditDetailsCopy.map(c => c.credit_id === id ? { ...c, notes_count: String(count) } : c);

        type === 'received' ?
            filteredCreditsReceived ? setFilteredCreditsReceived(creditDetailsCopy) : setCreditsReceived(creditDetailsCopy) :
            filteredCreditsGiven ? setFilteredCreditsGiven(creditDetailsCopy) : setCreditsGiven(creditDetailsCopy);
    }

    const getCreditContent = (credits) => {
        return credits.map((credit) => {
            return <Credit
                key={credit.credit_id}
                type={activeTab === 'Credit Received' ? 'received' : 'given'}
                creditDetails={credit}
                // togglePublicVisibility={togglePublicVisibility}
                handleApprove={handleApprove}
                handleAccept={handleAccept}
                // handleDispute={handleDispute}
                handleRevoke={handleRevoke}
                loggedInUserResId={loggedInUserResId}
                updateCommentCount={updateCommentCount}
            />
        })
    }

    const metricsContent = metrics?.map(metric => <div className={'metric' + (selectedMetric === metric.id ? ' selected-item' : '')} key={metric.id}
        // onClick={() => setSelectedMetric(metric.id)}
        onClick={() => updateSelectedMetrics(id, metric.id)}
    >
        <span className={'metric-icon ' + (BADGES.includes(metric.badge) ? metric.badge : 'icon-badge-1')}></span>
        <div className='metric-details'>
            <p>{metric.name}</p>
            <p className='description'>{metric.description}</p>
        </div>
    </div>);
    const creditsGivenContent = (filteredCreditsGiven || creditsGiven) && getCreditContent(filteredCreditsGiven ? filteredCreditsGiven : creditsGiven);
    const creditsReceivedContent = (filteredCreditsReceived || creditsReceived) && getCreditContent(filteredCreditsReceived ? filteredCreditsReceived : creditsReceived);
    // const creditsReceivedContent = creditsReceived && getCreditContent(creditsReceived);
    const badgesContent = receivedMetricList && receivedMetricList.filter(receivedBadge => receivedBadge.is_not_pending).map(receivedBadge => {
        return <span key={receivedBadge.id} title={receivedBadge.name} className={'metric-icon ' + (BADGES.includes(receivedBadge.badge) ? receivedBadge.badge : 'icon-badge-1')}></span>;
    });
    const recipeintContent = recipientList?.length > 0 && recipientList.map((recipeint, i) => {
        return <div className={`name-with-avatar ${id === 'my-profile' ? 'close-btn-wrapper' : ''}`} key={i}>
            {id !== 'my-profile' && <Avatar
                imgSrc={recipeint.imgUrl}
                firstName={recipeint.firstName}
                lastName={recipeint.lastName}
                alt={recipeint.firstName + "'s pic"}
                height='20px'
                width='20px'
                borderRadius='50%'
                fontSize='9px'
            />}
            <span>{`${recipeint.firstName} ${recipeint.lastName}`}</span>
            {id === 'my-profile' && <span
                className='close-btn'
                onClick={() => {
                    updateSavedTabStates(id, 'recipientsList', recipientList.filter(r => r.id !== recipeint.id))
                }}
            ></span>}
        </div>
    });

    return (
        <div id='performance-console-wrapper'>
            <LineLoader show={loadingCreditDetails} position='absolute' />
            {candidateInfo && !isEmpty(savedTabState) && <>
                <div className='fields-section'>
                    <div className='profile-info-top'>
                        <div className='profile-pic'>
                            <Avatar
                                imgSrc={imgSrc}
                                firstName={id === 'my-profile' ? userInfo.first_name : firstName}
                                lastName={id === 'my-profile' ? userInfo.last_name : lastName}
                                fontSize='40px'
                                borderRadius='5px'
                            />
                        </div>
                        <div className='badge-container'>{badgesContent}</div>
                        <div className='profile-name-wrapper'>
                            <h3 className='name'>{id === 'my-profile' ? `${userInfo.first_name} ${userInfo.last_name}` : `${firstName} ${lastName}`}</h3>
                            <div className='info'>
                                <span className='pl-label'>Designation : </span>
                                <span className='value'>{designation}</span>
                            </div>
                            <div className='info'>
                                <span className='pl-label'>Department : </span>
                                <span className='value'>{department}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='filter-wrapper'>
                    <ul className='tabbar'>
                        <li>
                            <button
                                type='button'
                                className={'tab-button' + (activeTab === 'New Credit' ? ' active' : '')}
                                // onClick={() => setActiveTab('New Credit')}
                                onClick={() => updateSavedTabStates(id, 'activeTab', 'New Credit')}
                            >New Credit
                            </button>
                        </li>
                        <li>
                            <button
                                type='button'
                                className={'tab-button' + (activeTab === 'Credit Received' ? ' active' : '')}
                                // onClick={() => setActiveTab('Credit Received')}
                                onClick={() => updateSavedTabStates(id, 'activeTab', 'Credit Received')}
                            >Credit Received
                            </button>
                        </li>
                        <li>
                            <button
                                type='button'
                                className={'tab-button' + (activeTab === 'Credit Given' ? ' active' : '')}
                                // onClick={() => setActiveTab('Credit Given')}
                                onClick={() => updateSavedTabStates(id, 'activeTab', 'Credit Given')}
                            >Credit Given
                            </button>
                        </li>
                    </ul>
                    {activeTab !== 'New Credit' && <div className='search-wrapper'>
                        <div className="gl-search">
                            <input
                                type='search'
                                placeholder='Search'
                                title={'Press \'Enter\' key to search'}
                                onChange={(e) => {
                                    updateSavedTabStates(id, activeTab === 'Credit Received' ? 'receivedSearchString' : 'givenSearchString', e.target.value);
                                    if (e.target.value === '') {
                                        if (activeTab === 'Credit Received') {
                                            if (selectedReceivedMetricFilter === '' && selectedMetricFrom === '') {
                                                setFilteredCreditsReceived(null);
                                                deleteStorageData('received');
                                            }
                                        } else {
                                            if (selectedGivenMetricFilter === '' && selectedMetricTo === '') {
                                                setFilteredCreditsGiven(null);
                                                deleteStorageData('given');
                                            }
                                        }
                                    }
                                    // e.target.value === '' &&
                                    //     (activeTab === 'Credit Received' ? selectedReceivedMetricFilter === '' && selectedMetricFrom === '' && setFilteredCreditsReceived(null) :
                                    //         selectedGivenMetricFilter === '' && selectedMetricTo === '' && setFilteredCreditsGiven(null));
                                }}
                                onKeyDown={(e) => {
                                    e.key === 'Enter' && e.target.value !== '' && getCredits(id === 'my-profile' ? loggedInUserResId : id, activeTab === 'Credit Received' ? 'received' : 'given', ['search', e.target.value]);
                                }}
                                value={activeTab === 'Credit Received' ? receivedSearchString : givenSearchString}
                            />
                        </div>
                        <button
                            type='button'
                            className='clear-button'
                            onClick={() => handleClearBtn(activeTab === 'Credit Given' ? 'given' : 'received')}
                        >
                            Clear
                        </button>
                        <span
                            className='icon-funnel'
                            title='Show filters'
                            // onClick={() => setShowFilters(!showFilters)}
                            onClick={() => updateSavedTabStates(id, 'showFilters', !showFilters)}
                            style={{ background: (activeTab === 'Credit Given' && filteredCreditsGiven) || (activeTab === 'Credit Received' && filteredCreditsReceived) ? 'linear-gradient(170deg, #FFF, #FFF 20%, #2196f3 20%)' : '' }}
                        >
                        </span>
                    </div>}
                </div>
                <div className='tab-content'>
                    {activeTab === 'New Credit' ? <div className='new-credit-wrapper'>

                        <Metrics
                            id={id}
                            loadingMetrics={loadingMetrics}
                            metricsContent={metricsContent}
                            metrics={metrics}
                        />

                        <div className='new-credit-form-wrapper'>

                            <div className='type-wrapper'>
                                <span className='pl-label'>Type :</span>
                                <div className="option radio">
                                    <input type="radio" id="request" name="type" value="request"
                                        checked={type === "request"}
                                        // onChange={(e) => setType(e.target.value)}
                                        onChange={(e) => updateSavedTabStates(id, 'type', e.target.value)}
                                    />
                                    <label htmlFor="request">Request</label>
                                </div>
                                <div className="option radio">
                                    <input type="radio" id="give" name="type" value="give"
                                        checked={type === "give"}
                                        // onChange={(e) => setType(e.target.value)}
                                        onChange={(e) => updateSavedTabStates(id, 'type', e.target.value)}
                                    />
                                    <label htmlFor="give">Give</label>
                                </div>
                            </div>

                            <div className='recipient-wrapper'>
                                <span className='pl-label'>{id === 'my-profile' ? type === 'give' ? 'To* :' : 'From* :' : type === 'give' ? 'To :' : 'From :'}</span>
                                <div className='recipient-list-wrapper'>
                                    {recipeintContent && recipeintContent.length > 0 && recipeintContent}
                                </div>
                                {showAddRecipientForm && <ClickOutsideListner onOutsideClick={() => setShowAddRecipientForm(false)}>
                                    <SearchRecipient
                                        addRecipient={addRecipient}
                                        recipientsList={recipientsList}
                                        loggedInUserResId={loggedInUserResId}
                                    />
                                </ClickOutsideListner>}
                                {id === 'my-profile' && <div className='ml-auto'>
                                    {
                                        id === 'my-profile' && !showAddRecipientForm &&
                                        <button
                                            type='button'
                                            className='btn-plus'
                                            onClick={() => setShowAddRecipientForm(true)}
                                            title='Add recipients'
                                        >
                                        </button>
                                    }
                                    {recipientList && recipientList.length > 0 && <span className='recipient-count' title='Recipient count'>{recipientList && recipientList.length}</span>}
                                </div>}
                            </div>

                            <textarea
                                placeholder='Reason*'
                                value={reason}
                                // onChange={(e) => setReason(e.target.value)}
                                onChange={(e) => updateSavedTabStates(id, 'reason', e.target.value)}
                            ></textarea>

                            <div className='action-btn-wrapper'>
                                <button
                                    type='button'
                                    className={'btn-with-icon btn-small btn-submit ml-auto' + (giveOrReceiveCreditInProcess ? ' btn-disabled' : '')}
                                    onClick={handleSubmitBtn}
                                    disabled={giveOrReceiveCreditInProcess}
                                >
                                    <i></i>
                                    <span>Submit</span>
                                </button>
                                <button
                                    type='button'
                                    className='btn-with-icon btn-small btn-reset'
                                    onClick={handleResetBtn}
                                >
                                    <i></i>
                                    <span>Reset</span>
                                </button>
                            </div>

                        </div>
                    </div> : activeTab === 'Credit Received' ? (
                        <CreditWrapper
                            id={id}
                            isReceived={true}
                            showFilters={showFilters}
                            status={receivedStatus}
                            updateSavedTabStates={updateSavedTabStates}
                            getCredits={getCredits}
                            metricList={receivedMetricList}
                            selectedMetricFilter={selectedReceivedMetricFilter}
                            loggedInUserResId={loggedInUserResId}
                            recipientList={creditReceivedFromList}
                            selectedMetric={selectedMetricFrom}
                        >
                            <CreditReceived
                                id={id}
                                onScroll={onScroll}
                                loadingGiveCreditInProcess={loadingReceiveCreditInProcess}
                                filteredCreditsReceived={filteredCreditsReceived}
                                creditsReceived={creditsReceived}
                                creditsReceivedContent={creditsReceivedContent}
                            />
                        </CreditWrapper>
                    ) : (
                        <CreditWrapper
                            id={id}
                            isReceived={false}
                            showFilters={showFilters}
                            status={givenStatus}
                            updateSavedTabStates={updateSavedTabStates}
                            getCredits={getCredits}
                            metricList={givenMetricList}
                            selectedMetricFilter={selectedGivenMetricFilter}
                            loggedInUserResId={loggedInUserResId}
                            recipientList={creditGivenToList}
                            selectedMetric={selectedMetricTo}
                        >
                            <CreditGiven
                                id={id}
                                onScroll={onScroll}
                                loadingGiveCreditInProcess={loadingGiveCreditInProcess}
                                filteredCreditsGiven={filteredCreditsGiven}
                                creditsGiven={creditsGiven}
                                creditsGivenContent={creditsGivenContent}
                            />
                        </CreditWrapper>
                    )}
                </div>
            </>}
        </div>
    )
}

const Metrics = ({ id, loadingMetrics, metrics, metricsContent }) => {
    const metricsScrollRef = useScrollRestoration(`performance-metrics-${id}`);
    return <div ref={metricsScrollRef} className='metrics-wrapper'>
        {loadingMetrics && !metrics ? <div className="loader-container"><span className='loader'></span></div> : metricsContent}
    </div>
}

export const CreditGiven = ({ id, onScroll, loadingGiveCreditInProcess, filteredCreditsGiven, creditsGiven, creditsGivenContent }) => {
    const creditsGivenScrollRef = useScrollRestoration(`performance-credits-given-${id}`);
    return (
        <div id='credit-given' ref={creditsGivenScrollRef} onScroll={() => onScroll('credit-given')} className='credits-wrapper'>
            {(loadingGiveCreditInProcess && ((filteredCreditsGiven && !filteredCreditsGiven.length) || (creditsGiven && !creditsGiven.length)) ? <div className="loader-container"><span className='loader'></span></div> : (!creditsGivenContent || !creditsGivenContent.length) ? <p className='general-info'>No data to display</p> : creditsGivenContent)}
        </div>
    )
}
const CreditReceived = ({ id, onScroll, loadingReceiveCreditInProcess, filteredCreditsReceived, creditsReceived, creditsReceivedContent }) => {
    const creditsReceivedScrollRef = useScrollRestoration(`performance-credits-received-${id}`);
    console.log('creditsReceivedScrollRef', creditsReceivedScrollRef.current?.scrollTop);
    return (
        <div id='credit-received' ref={creditsReceivedScrollRef} onScroll={() => onScroll('credit-received')} className='credits-wrapper'>
            {(loadingReceiveCreditInProcess && ((filteredCreditsReceived && !filteredCreditsReceived.length) || (creditsReceived && !creditsReceived.length)) ? <div className="loader-container"><span className='loader'></span></div> : (!creditsReceivedContent || !creditsReceivedContent.length) ? <p className='general-info'>No data to display</p> : creditsReceivedContent)}
        </div>
    )
}

const CreditWrapper = ({ children, id, isReceived, showFilters, status, updateSavedTabStates, getCredits, metricList, selectedMetricFilter, loggedInUserResId, recipientList, selectedMetric }) => {
    return (
        <div className='content-wrapper'> {showFilters && <div className='filter-container'>
            {id === 'my-profile' && <SpeedSelect
                options={STATUS}
                // selectedOption={activeReporter}
                selectedOption={status}
                onSelect={(status) => {
                    // setActiveReporter(reporter);
                    updateSavedTabStates(id, isReceived ? 'receivedStatus' : 'givenStatus', status);
                    // if (!reporter.length && props.funnelFilter.searchString === '' && !props.funnelFilter.status.length && ((props.funnelFilter.selectedDateTab === 'created_at' && !props.funnelFilter.created_at.length) || (props.funnelFilter.selectedDateTab === 'updated_at' && !props.funnelFilter.updated_at.length))) {
                    //     setfilteredFunnelData(null);
                    //     removeSessionStorageData();
                    //     return;
                    // }
                    getCredits(id === 'my-profile' ? loggedInUserResId : id, isReceived ? 'received' : 'given', ['status', status]);
                }}
                displayKey='first_name'
                uniqueKey='id'
                selectLabel='Select'
                prominentLabel='Status'
            />}
            <SpeedSelect
                options={metricList}
                // selectedOption={activeReporter}
                selectedOption={selectedMetricFilter}
                onSelect={(metric) => {
                    // setActiveReporter(reporter);
                    updateSavedTabStates(id, isReceived ? 'selectedReceivedMetricFilter' : 'selectedGivenMetricFilter', metric);
                    // if (!reporter.length && props.funnelFilter.searchString === '' && !props.funnelFilter.status.length && ((props.funnelFilter.selectedDateTab === 'created_at' && !props.funnelFilter.created_at.length) || (props.funnelFilter.selectedDateTab === 'updated_at' && !props.funnelFilter.updated_at.length))) {
                    //     setfilteredFunnelData(null);
                    //     removeSessionStorageData();
                    //     return;
                    // }
                    getCredits(id === 'my-profile' ? loggedInUserResId : id, isReceived ? 'received' : 'given', ['metric', metric.id]);
                }}
                displayKey='name'
                uniqueKey='id'
                selectLabel='Select'
                prominentLabel='Metrics'
                disable={!metricList?.length}
            // dropdownSelectAllLabel='All'
            // multiple
            />
            <SpeedSelect
                options={recipientList}
                // selectedOption={activeReporter}
                selectedOption={selectedMetric}
                onSelect={(user) => {
                    // setActiveReporter(reporter);
                    updateSavedTabStates(id, isReceived ? 'selectedMetricFrom' : 'selectedMetricTo', user);
                    // if (!reporter.length && props.funnelFilter.searchString === '' && !props.funnelFilter.status.length && ((props.funnelFilter.selectedDateTab === 'created_at' && !props.funnelFilter.created_at.length) || (props.funnelFilter.selectedDateTab === 'updated_at' && !props.funnelFilter.updated_at.length))) {
                    //     setfilteredFunnelData(null);
                    //     removeSessionStorageData();
                    //     return;
                    // }
                    getCredits(id === 'my-profile' ? loggedInUserResId : id, isReceived ? 'received' : 'given', ['user', user.id]);
                }}
                displayKey='first_name'
                uniqueKey='id'
                selectLabel='Select'
                prominentLabel={isReceived ? 'From' : 'To'}
                disable={!recipientList?.length}
            // dropdownSelectAllLabel='All'
            // multiple
            />
        </div>}
            {children}
        </div>
    )
}

export default PerformanceConsole