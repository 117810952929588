import React from 'react'
import './RequestTypeMenu.scss';

const RequestTypeMenu = ({ data, toggleRequestForm }) => {
  return (
    <div className='request-menu'>
      {data.map((item, index) => {
        return (
          <div key={index} className='request-group'>
            <span className='request-label'>{item.label}</span>
            <div className='request-options-wrapper'>
              {
                item.options.map((option, index) =>
                  <button
                    className='request-option'
                    key={index}
                    onClick={() => toggleRequestForm(option)}
                  >
                    {option.name}
                  </button>
                )
              }
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default RequestTypeMenu