import { useEventListener } from "./useEventListener"

/**
 * 
 * @param {import("react").RefObject<HTMLElement>} ref 
 * @param {(e: MouseEvent) => void} handler 
 * @param {'mousedown' | 'mouseup'} [mouseEvent] 
 */
export function useOnClickOutside(
  ref,
  handler,
  mouseEvent = 'mouseup',
) {
  useEventListener(mouseEvent, event => {
    const el = ref?.current

    // Do nothing if clicking ref's element or descendent elements
    if (!el || el.contains(event?.target)) {
      return
    }

    handler(event)
  })
}