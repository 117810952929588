import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

// hooks
import { useHistory, useLocation } from "react-router-dom";
import useUserData from "hooks/useUserData";

// services
import APIService from "services/apiService";
import { alertService } from 'services/alertService';
import { disconnectSocket } from "socket";

// utils
import { API_BASE_URL, SITE_PREFIX } from "components/Constants";
import { getToken, getUTCDate, getUser, isElectronApp } from "utils/Common";
import { useTimerStore } from "components/Timer/store";

export const GlobalContext = createContext({
    organizationsList: [],
    selectedOrganization: null,
    isAppInActive: false,
    isSelectedOrgEmployee: false,
    hasTimerAccess: false,
    handleSelectedOrganizationChange: (selectedOrg) => { },
    handleMyTimeZoneChange: (selectedTimeZone) => { },
    timeZones: [],
    myTimeZone: '',
    isDSTActive: false,
    observesDST: false,
    checkIsDSTActive: (timezone) => { },
    doesObservesDST: (timezone) => { },
});

function getLastOpenedURLStorageKey(orgId) {
    return SITE_PREFIX + `last_opened_url_` + orgId;
}

function updateLastOpenedURL(orgId, url) {
    if (orgId && url) {
        sessionStorage.setItem(getLastOpenedURLStorageKey(orgId), url);
    }
}

function getLastOpenedURL(orgId) {
    return sessionStorage.getItem(getLastOpenedURLStorageKey(orgId));
}

export function GlobalProvider({ children }) {
    const abortControllerRef = useRef(new AbortController());
    const changeOrgInProgressRef = useRef(false);
    const timezoneTimeOutRef = useRef(null);
    const [isAppInActive, setIsAppInActive] = useState(false);
    const [organizationsList, setOrganizationsList] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [isSelectedOrgEmployee, setIsSelectedOrgEmployee] = useState(false);
    const [hasTimerAccess, setHasTimerAccess] = useState(false);
    const [timeZones, setTimeZones] = useState([]);
    const [myTimeZone, setMyTimeZone] = useState('');

    const { user } = useUserData();
    const isUserLoggedIn = !!user?.id;
    const history = useHistory();
    const location = useLocation();

    const updateSelectedOrganization = useCallback((data) => {
        let settings = localStorage.getItem(SITE_PREFIX + "settings");
        if (settings) settings = JSON.parse(settings);
        settings = { ...settings, selectedOrganization: data };
        localStorage.setItem(SITE_PREFIX + "settings", JSON.stringify(settings));
    }, []);

    const changeOrganization = useCallback(async (selectedOrg) => {
        const apiPayload = {
            organization_id: selectedOrg.id,
        }
        const response = await APIService.apiRequest(
            API_BASE_URL + `/change_org`,
            apiPayload,
            false,
            "PUT",
            abortControllerRef.current
        );
        if (response.status === 1) {
            updateLastOpenedURL(selectedOrganization.id, location.pathname);
            return response;
        } else {
            if (response?.message?.includes('aborted')) return;
            alertService.error(response.msg);
            throw new Error(`[refreshTokenData] - ${response?.msg || response?.message || 'Some error occured'}`);
        }
    }, [location.pathname, selectedOrganization?.id]);

    const refreshTokenData = useCallback(async (selectedOrg, getTimerToken) => {
        const apiPayload = {
            organization_id: selectedOrg.id,
        }
        const response = await APIService.apiRequest(
            API_BASE_URL + `/auth/rt`,
            apiPayload,
            false,
            "PUT",
            abortControllerRef.current,
            null,
            getTimerToken
        );
        if (+response.status === 1) {
            return response;
        } else {
            if (response?.message?.includes('aborted')) return;
            alertService.error(response.msg);
            throw new Error(`[refreshTokenData] - ${response?.msg || response?.message || 'Some error occured'}`);
        }
    }, []);

    const handleSelectedOrganizationChange = useCallback(async (selectedOrg) => {
        if (changeOrgInProgressRef.current) {
            abortControllerRef.current.abort();
            localStorage.setItem(SITE_PREFIX + 'reset_token_inprocess', 0); // reset to 0 so that it can process further (other apis)
        }
        if (abortControllerRef.current.signal.aborted) {
            abortControllerRef.current = new AbortController();
        }

        changeOrgInProgressRef.current = true;
        updateSelectedOrganization(selectedOrg);

        // const refreshTokenPromise = fetchRT(selectedOrg);
        const refreshTokenPromise = refreshTokenData(selectedOrg);
        const changeOrganizationPromise = changeOrganization(selectedOrg);

        Promise.all([refreshTokenPromise, changeOrganizationPromise])
            .then(([refreshTokenData, changeOrganizationData]) => {
                setIsSelectedOrgEmployee(selectedOrg?.department !== null);
                setSelectedOrganization(selectedOrg);
                useTimerStore.setState({ selectedOrganization: selectedOrg });
                disconnectSocket();
            })
            .catch((error) => {
                // Handle errors from either API request
                console.error('Error fetching data:', error);
            });
    }, [updateSelectedOrganization, refreshTokenData, changeOrganization]);

    const handleMyTimeZoneChange = useCallback((selectedTimeZone, initialLoad = false) => {
        const { dst_start_date, dst_end_date } = selectedTimeZone;
        const myTimeZone = { ...selectedTimeZone }

        if (dst_start_date && dst_end_date) {
            const dstStart = new Date(dst_start_date);
            const dstEnd = new Date(dst_end_date);
            const today = getUTCDate();

            // check if dstStart is in future and set the timeout to that date or else check if dstEnd is in future and set the timeout to that date
            let timeout = null;
            if (dstStart.getTime() > today.getTime()) {
                timeout = dstStart;
            } else if (dstEnd.getTime() > today.getTime()) {
                timeout = dstEnd;
            }

            clearTimeout(timezoneTimeOutRef.current);
            if (timeout) {
                const setReloadTimeout = (timeout) => {
                    const now = getUTCDate();
                    if (now.getTime() >= timeout.getTime()) {
                        window.location.reload();
                    } else {
                        const ms = timeout.getTime() - now.getTime();
                        const MAX_TIMEOUT = 2147483647;
                        const delay = Math.min(ms, MAX_TIMEOUT);
                        timezoneTimeOutRef.current = setTimeout(() => {
                            if (delay === MAX_TIMEOUT) {
                                setReloadTimeout(timeout);
                            } else {
                                window.location.reload();
                            }
                        }, delay);
                    }
                }

                setReloadTimeout(timeout);
            }
        }

        setMyTimeZone(myTimeZone);
        localStorage.setItem(SITE_PREFIX + 'my_timezone', JSON.stringify(myTimeZone));
        if (isElectronApp) window.ipcRender.send('message:myTimezoneFromWeb', myTimeZone);
        if (!initialLoad) window.location.reload();
    }, []);

    const checkIsDSTActive = useCallback((timezone, date = getUTCDate()) => {
        const { dst_start_date, dst_end_date } = timezone ?? myTimeZone;
        if (dst_start_date && dst_end_date) {
            const dstStart = new Date(dst_start_date).getTime();
            const dstEnd = new Date(dst_end_date).getTime();
            const today = date.getTime();

            return dstStart <= today && dstEnd >= today;
        }
        return false;
    }, [myTimeZone]);

    const doesObservesDST = useCallback((timezone) => {
        const { dst_start_date, dst_end_date } = timezone ?? myTimeZone;
        return !!(dst_start_date && dst_end_date);
    }, [myTimeZone]);

    const isDSTActive = useMemo(checkIsDSTActive, [checkIsDSTActive]);
    const observesDST = useMemo(doesObservesDST, [doesObservesDST]);

    const prevOrgIdRef = useRef(user?.organization_id);
    useEffect(() => {
        const orgId = selectedOrganization?.id;
        if (orgId && prevOrgIdRef.current !== orgId) {
            prevOrgIdRef.current = orgId;
            const lastOpenedURL = getLastOpenedURL(orgId);
            if (lastOpenedURL) {
                if (
                    lastOpenedURL === location.pathname ||
                    lastOpenedURL.includes("/recruiter") ||
                    lastOpenedURL.includes("/performance") ||
                    lastOpenedURL.includes("/profile")
                ) {
                    // force react router to re-render the component
                    history.push("/temp");
                    setTimeout(() => history.replace(lastOpenedURL), 0);
                } else {
                    history.push(lastOpenedURL);
                }
            } else {
                history.push("/");
            }
        }
    }, [
        history,
        location.pathname,
        selectedOrganization?.id,
    ]);

    const getOrganizations = useCallback(async ({ controller } = {}) => {
        try {
            const response = await APIService.apiRequest(
                API_BASE_URL + `/organization`,
                null,
                false,
                "GET",
                controller
            );
            if (response.status === 1) {
                const { organization_id } = user;
                const timerOrg = response.output.find(org => +org.is_timer === 1);
                const isDefaultOrg = timerOrg?.id === organization_id;
                let timerToken = null;
                if (isDefaultOrg) {
                    timerToken = getUser();
                    localStorage.setItem(SITE_PREFIX + "timer_token", getToken());
                } else if (timerOrg) {
                    await refreshTokenData({ id: timerOrg.id }, true);
                    timerToken = getUser("timer_token");
                }
                if (timerOrg && (timerToken.is_web_timer !== 0 || isElectronApp)) setHasTimerAccess(true)
                const selectedOrg = response.output.find(org => org.id === organization_id);
                setOrganizationsList(response.output);
                setIsSelectedOrgEmployee(selectedOrg?.department !== null);
                updateSelectedOrganization(selectedOrg);
                setSelectedOrganization(selectedOrg);
                useTimerStore.setState({ organizationsList: response.output, selectedOrganization: selectedOrg });
            } else {
                console.log('getOrganizations else', response);
                if (response?.message?.includes('aborted')) return;
                alertService.error(response.msg);
            }
        } catch (err) {
            console.log('getOrganizations catch', err);
            alertService.error(err.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSelectedOrganization]);

    const getAllTimeZones = useCallback(async ({ controller } = {}) => {
        try {
            const response = await APIService.apiRequest(
                API_BASE_URL + `/attendance/timezone`,
                null,
                false,
                "GET",
                controller
            );
            if (response.status === 1) {
                const { timezone_id: myTimezoneId } = getUser();
                const myTimezone = response.output.find(tz => tz.id === myTimezoneId);
                localStorage.setItem(SITE_PREFIX + 'timezones', JSON.stringify(response.output));
                setTimeZones(response.output);
                handleMyTimeZoneChange(myTimezone, true);
            } else {
                alertService.error(response.msg);
            }
        } catch (err) {
            alertService.warning(err.msg);
        };
    }, [handleMyTimeZoneChange]);

    useEffect(() => {
        const abortController = new AbortController();
        if (isUserLoggedIn) {
            getOrganizations({ controller: abortController });
        }

        return () => abortController.abort();
    }, [getOrganizations, isUserLoggedIn])

    useEffect(() => {
        const abortController = new AbortController();
        if (isUserLoggedIn) {
            getAllTimeZones({ controller: abortController });
        }

        return () => abortController.abort();
    }, [getAllTimeZones, isUserLoggedIn])

    useEffect(() => {
        return () => clearTimeout(timezoneTimeOutRef.current);
    }, [])

    useEffect(() => {
        const updateAppStatus = (val) => {
            setIsAppInActive(() => val);
        }
        if (isElectronApp) window.ipcRender.receive("screen-lock", updateAppStatus);
        return () => {
            if (isElectronApp) window.ipcRender.removeAllListeners("screen-lock");
        }
    }, [])

    return (
        <GlobalContext.Provider
            value={{
                organizationsList,
                selectedOrganization,
                isAppInActive,
                isSelectedOrgEmployee,
                hasTimerAccess,
                timeZones,
                myTimeZone,
                handleSelectedOrganizationChange,
                handleMyTimeZoneChange,
                isDSTActive,
                observesDST,
                checkIsDSTActive,
                doesObservesDST
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}

export function useGlobalContext() {
    return useContext(GlobalContext);
}