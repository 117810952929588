import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { alertService } from 'services/alertService.js';

export default function Autocomplete(props) {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [inputArray, setInputArray] = useState([]);
    const [input, setInput] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(0);

    // Variable to control memory leakage
    const mountedRef = useRef(false)

    const skillContainerDOMRef = useRef()
    const suggestionListDOMRef = useRef()

    const showAutoComplete = props.showAutocomplete[0]

    // This useEffect is responsible to fix the auto complete suggestion list is overflowing the skill container
    // Like positioning element dynamically if they are overflowing its parent element
    useEffect(() => {
        const containerEl = skillContainerDOMRef.current
        const suggestionListEl = suggestionListDOMRef.current

        if (suggestionListEl) {
            const containerElRect = containerEl.getBoundingClientRect()
            const suggestionListElRect = suggestionListEl.getBoundingClientRect()

            if (suggestionListElRect.right > containerElRect.right) {
                // The below style will automatically removed when the suggestionListEl is unmounted
                suggestionListEl.style.right = '0px'
            }
        }
    }, [showAutoComplete])

    const onClick = async (suggestion) => {
        // If suggestion is a string, then it is a non-suggestion item or an empty input value
        if (typeof suggestion === 'string' && props.addNonSuggestionItem) {
            if (suggestion === '' || suggestion.trim() === '') {
                alertService.error('Please type skill');
                return;
            }

            const newOption = await props.onCreateOption(suggestion.trim())

            props.setOptions((prev) => [...prev, newOption]);
            const newInputArray = [...inputArray, newOption];

            props.onSelect(newInputArray);
            setInputArray(newInputArray);
            setInput('');
            setSelectedIndex(0);
            setActiveSuggestionIndex(0);
            props.showAutocomplete[1](false);
        } else {
            // If suggestion is an object, then it is a suggestion item
            setFilteredSuggestions([]);
            const inputHasSuggestion = inputArray.some((item) => item.name === suggestion.name);

            if (!inputHasSuggestion) {
                const newInputArray = [
                    ...inputArray,
                    {
                        name: suggestion.name,
                        id: suggestion.id,
                    },
                ];

                props.onSelect(newInputArray);
                setInputArray(newInputArray);
            }

            setInput('');
            setSelectedIndex(0);
            setActiveSuggestionIndex(0);
            props.showAutocomplete[1](false);
        }
    };

    const onChange = (e) => {
        const userInput = e.target.value;
        const unLinked = props.options.filter(
            (suggestion) =>
                suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setInput(e.target.value);
        setFilteredSuggestions(unLinked);
        setSelectedIndex(0);
        setActiveSuggestionIndex(0);
        props.showAutocomplete[1](true);
    };

    const handleCrossButton = (index) => {
        inputArray.splice(index, 1);
        props.onSelect(inputArray);
        setInputArray([...inputArray])
    }

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            if (filteredSuggestions.length > 0 && input !== "") {
                onClick(filteredSuggestions[activeSuggestionIndex]);
            } else if (props.addNonSuggestionItem) {
                onClick(input)
            }
        }

        if (e.key === "ArrowDown") {
            if (filteredSuggestions.length - 1 > selectedIndex) {
                let index = selectedIndex + 1;
                setSelectedIndex(index);
                setActiveSuggestionIndex(index);
                document
                    .getElementById(`suggestion-${filteredSuggestions[index].id}`)
                    .scrollIntoView({ block: 'nearest' });
            }
        }

        if (e.key === "ArrowUp") {
            let index = selectedIndex - 1;
            if (index >= 0) {
                setSelectedIndex(index);
                setActiveSuggestionIndex(index);
                document
                    .getElementById(`suggestion-${filteredSuggestions[index].id}`)
                    .scrollIntoView({ block: 'nearest' });
            }
        }

        if (e.key === "Escape") {
            props.showAutocomplete[1](false);
        }

        if (e.key === "Backspace" && input === "" && inputArray.length > 0) {
            handleCrossButton(inputArray.length - 1);
        }
    }

    useEffect(() => {
        setInputArray([...props.selectedOption]);
        if (!mountedRef.current) return;
    }, [props.selectedOption])

    useEffect(() => {
        setInput("");
        props.handleClose();
        if (!mountedRef.current) return;
    }, [props.tabId]) //eslint-disable-line

    return (
        <div id='skill-container' ref={skillContainerDOMRef}>
            {inputArray.map((value, i) => {
                if (!value?.name) return null;

                return (
                    <div key={i} className='subskill-container'>
                        {value.name}
                        <span className='close-bt' onClick={() => handleCrossButton(i)}></span>
                    </div>
                );
            })}
            <div className='skill-input'>
                <input
                    type='text'
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={input}
                    placeholder={props.placeholder ? props.placeholder : 'Enter'}
                />
                {props.showAutocomplete[0] && input && (
                    <>
                        {filteredSuggestions.length ? (
                            <ul className="suggestions" ref={suggestionListDOMRef}>
                                {filteredSuggestions.map((suggestion, index) => {
                                    let classnames = ''

                                    if (index === activeSuggestionIndex) {
                                        classnames = `${classnames} suggestion-active`;
                                    }

                                    for (let i = 0; i < inputArray.length; i++) {
                                        if (suggestion.name === inputArray[i].name) {
                                            classnames = `${classnames} suggestion-selected`;
                                        }
                                    }

                                    return (
                                        <li
                                            key={index}
                                            id={`suggestion-${suggestion.id}`}
                                            className={classnames}
                                            onClick={() => onClick(suggestion)}>
                                            {suggestion.name}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            props.addNonSuggestionItem ?
                                <div className="no-suggestions" onClick={() => onClick(input)}>
                                    <em>No suggestions, you can add your own skill, just press enter after typing!</em>
                                </div>
                                :
                                <div className="no-suggestions">
                                    <em>No suggestions!</em>
                                </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}