import { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { AL_BASE_URL } from "./constants";
// components
// import Loading from "views/Loading";
import Timer from "components/Timer/Timer";
import LineLoader from "components/LineLoader";
import AttendanceDatePicker from "./components/AttendanceDatePicker";
import AttendanceMultiDatePicker from "./components/AttendanceMultiDatePicker";
import AttendanceFilterBy from "./components/AttendanceFilterBy";
// import AttendanceAnalysis from "./components/AttendanceAnalysis";
import RequestsModal from "./components/RequestsModal";
import AttendanceTable from "./components/AttendanceTable";
import RequestButtons from "./components/RequestButtons";
import { withKeepAlive } from "components/KeepAlive";
import { PAGE_IDS } from "components/Navigation";
import TeamMemberAttendanceFilterBy from "./components/TeamMemberAttendanceFilterBy";
import { emitter, eventsEnum } from "components/header/AttendanceTabOptions";
import UserAnalytics from "./components/UserAnalytics";
// context
import { AttendanceFilterProvider } from "./context";
import {
  TeamMemberFilterProvider,
  useTeamMemberFilter,
} from "./context/teamMemberFilterContext";
import { useGlobalContext } from "contexts/GlobalContext";
// hooks
import useWindowDimensions from "hooks/useWindowDimensions";
import useUserData from "hooks/useUserData";
// services
import APIService from "services/apiService";
import { alertService } from "services/alertService";
// styles
import "./index.scss";

function Attendance() {
  const { user } = useUserData();
  const { width } = useWindowDimensions();
  const isDesktopVersion = width > 640;

  const { isSelectedOrgEmployee } = useGlobalContext();

  // const [isLoading, setIsLoading] = useState(false);
  const [loggedInUser] = useState(() => {
    return isSelectedOrgEmployee ? { resource_id: user?.resource?.id } : null;
  });

  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [showAnalyticsFor, setShowAnalyticsFor] = useState(null);
  const requestsModalRef = useRef(null);
  const modalTimeoutRef = useRef(null);
  useEffect(() => () => clearTimeout(modalTimeoutRef.current), []);

  const toggleRequestsModal = useCallback(() => {
    clearTimeout(modalTimeoutRef.current);
    if (showRequestsModal && requestsModalRef.current instanceof HTMLElement) {
      requestsModalRef.current.classList.remove("show");
      modalTimeoutRef.current = setTimeout(() => {
        setShowRequestsModal(false);
        emitter.emit(eventsEnum.ON_REQUEST_MODAL_OPEN, false);
      }, 200);
    } else {
      setShowRequestsModal(true);
      emitter.emit(eventsEnum.ON_REQUEST_MODAL_OPEN, true);
      modalTimeoutRef.current = setTimeout(() => {
        if (requestsModalRef.current instanceof HTMLElement) {
          const modalEl = requestsModalRef.current;
          modalEl.classList.add("show");
          clearInterval(modalTimeoutRef.current);
        }
      }, 1);
    }
  }, [showRequestsModal]);

  const toggleAnalytics = (e, resourceId) => {
    setShowAnalyticsFor(resourceId === showAnalyticsFor ? null : resourceId);
  }

  useEffect(() => {
    const unsub = emitter.subscribe(
      eventsEnum.TOGGLE_REQUESTS,
      toggleRequestsModal
    );
    return unsub;
  }, [toggleRequestsModal]);

  // Leave Options
  const [leaveOptions, setLeaveOptions] = useState([]);
  useEffect(() => {
    const abortController = new AbortController();
    async function getLeaveOptions() {
      try {
        const response = await APIService.apiRequest(
          AL_BASE_URL + "/leave_type",
          null,
          false,
          "GET",
          abortController
        );

        if (response.status === 1) {
          setLeaveOptions(() =>
            response.output.map((item) => {
              return {
                name: item.name,
                id: item.id,
              };
            })
          );
        }
      } catch (error) {
        alertService.warning(error.message);
      }
    }

    getLeaveOptions();

    return () => abortController.abort();
  }, []);

  const location = useLocation();

  function openRequestModal() {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("request_id") && searchParams.has("resource_id")) {
      // const resource_id = parseInt(searchParams.get("resource_id"));
      // if (resource_id !== loggedInUser?.resource_id && isManager) {
      //   toggleTeamRequestsModal();
      //   emitter.emit(eventsEnum.ON_TEAM_REQUEST_MODAL_OPEN);
      // } else {
      //   toggleMyRequestsModal();
      //   emitter.emit(eventsEnum.ON_MY_REQUEST_MODAL_OPEN, true);
      // }
      toggleRequestsModal();
      emitter.emit(eventsEnum.ON_REQUEST_MODAL_OPEN, true);
    }
  }

  useEffect(() => {
    if (loggedInUser) openRequestModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  const [showLineLoader, setShowLineLoader] = useState(false);

  return (
    <div>
      <div className="attendance">
        <LineLoader show={showLineLoader} position="absolute" />
        <AttendanceFilterProvider>
          <TeamMemberFilterProvider>
            <div className="attendance__filters">
              {/* Left Side Filters */}
              <AttendanceFilters isDesktopVersion={isDesktopVersion} />

              {/* Right Side Filters */}
              {isDesktopVersion ? (
                <RequestButtons
                  showRequestsModal={showRequestsModal}
                  toggleRequestsModal={toggleRequestsModal}
                  loggedInUser={loggedInUser}
                />
              ) : (
                <Timer isMobile hasFullAccess={user?.is_web_timer === 1} />
              )}
            </div>

            <AttendanceTable
              loggedInUser={loggedInUser}
              showLineLoader={showLineLoader}
              setShowLineLoader={setShowLineLoader}
              showAnalyticsFor={showAnalyticsFor}
              toggleAnalytics={toggleAnalytics}
            />
          </TeamMemberFilterProvider>

          {showRequestsModal && (
            <RequestsModal
              ref={requestsModalRef}
              toggle={toggleRequestsModal}
              loggedInUser={loggedInUser}
              leaveOptions={leaveOptions}
            />
          )}

          {showAnalyticsFor ? (
            <UserAnalytics
              id={showAnalyticsFor}
              isLoggedUser={loggedInUser?.resource_id === showAnalyticsFor}
              toggleAnalytics={toggleAnalytics}
            />
          ) : null}
        </AttendanceFilterProvider>
      </div>
    </div>
  );
}

function AttendanceFilters({ isDesktopVersion }) {
  const { showTeamMemberFilters } = useTeamMemberFilter();
  return (
    <>
      <div className="attendance__filters__left">
        {!showTeamMemberFilters ? (
          <AttendanceDatePicker />
        ) : (
          <AttendanceMultiDatePicker />
        )}
        {isDesktopVersion && <span className="pipe"></span>}
        {!showTeamMemberFilters ? (
          <AttendanceFilterBy />
        ) : (
          <TeamMemberAttendanceFilterBy />
        )}
        {/* 
          <span className="pipe hide-on-mobile"></span>
          <AttendanceAnalysis /> 
        */}
      </div>
      {/* ! Below id is used to query container in AttendanceFilterBy. Please do change there too if needed */}
      <div id="mobile-search-input-portal-wrapper"></div>
    </>
  );
}

export default withKeepAlive(Attendance, PAGE_IDS.ATTENDANCE);
