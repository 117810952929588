import React from "react";
import "./SpecialShiftDetailCard.scss";
import { format } from "utils/date";
import { formatTime } from "../../utils";

export default function SpecialShiftDetailCard({ data }) {
  const specialShiftsData = data
    ?.reduce((acc, cur, index) => {
      const sameDateIndex = acc.findIndex(
        (shift) =>
          shift.start_date === cur.start_date && shift.end_date === cur.end_date
      );

      if (index === 0 || sameDateIndex === -1) {
        acc.push({
          start_date: cur.start_date,
          end_date: cur.end_date,
          timings: [
            {
              start_time: formatTime(cur.start_time.time),
              end_time: formatTime(cur.end_time.time),
            },
          ],
        });

        return acc;
      }

      acc[sameDateIndex].timings.push({
        start_time: formatTime(cur.start_time.time),
        end_time: formatTime(cur.end_time.time),
      });

      return acc;
    }, [])
    ?.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

  return (
    <div className="special-shifts-wrapper">
      <div className="special-shifts">
        <table>
          <tbody>
            {specialShiftsData?.map((shift, index) => {
              return (
                <tr className="special-shift" key={index}>
                  <td>
                    <div>
                      <div className="special-shift-date-wrapper">
                        <div className="special-shift-date-range">
                          <span className="special-shift-date-range-start">{format(new Date(shift.start_date), 'ddd, MMM DD')}</span>
                          <div className="hyphen"></div>
                          <span className="special-shift-date-range-end">{format(new Date(shift.end_date), 'ddd, MMM DD')}</span>
                        </div>
                      </div>

                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        {shift.timings.map((timing, timingIndex) => {
                          return (
                            <div className="special-shift-time-range" key={timingIndex}>
                              <span>{timing.start_time.replace(':', ' : ')}</span>
                              <div className="hyphen"></div>
                              <span>{timing.end_time.replace(':', ' : ')}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
