import './NotFound.scss';

export default function NotFound() {
  return (
    <div className="not-found">
      <div className="not-found-content">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
      </div>
    </div>
  )
}
