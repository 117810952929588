import "./EndShiftConfirmation.scss"

export default function EndShiftConfirmation({
  onYes,
  onNo,
  disableBtn,
}) {
  return (
    <div className="end-shift-confirmation">
      <span className="notice">You're allowed one clock-out reversal per day, and you've already used it. This next clock-out will be final.</span>
      <span className="notice confirmation-notice">Do you wish to proceed?</span>
      <div className="confirmation-buttons">
        <button className={`timer-options ${disableBtn ? 'disable-option' : ''}`} onClick={() => onYes()}>Yes</button>
        <button className={`timer-options ${disableBtn ? 'disable-option' : ''}`} onClick={() => onNo()}>Cancel</button>
      </div>
    </div>
  )
}
