import Avatar from 'components/Avatar';
import UserProfileDetailsCard from './UserProfileDetailsCard';
import './UserProfileCard.scss';
import { MouseTooltip } from 'components/ToolTip';

const UserProfileCard = ({ user, isLoggedInUser, children, selectedMemberId }) => {
    const { first_name, last_name, img_url } = user;

    return (
        <div
            className={
                "avatar-name-wrapper" +
                (isLoggedInUser ? " font-bold" : "")
            }
        >
            <div className='user-info-wrapper'>
                <MouseTooltip
                    delay={500}
                    show={selectedMemberId ? selectedMemberId === user.resource_id : true}
                    content={<UserProfileDetailsCard user={user} />}
                    style={{ padding: '0' }}
                    asChild
                >
                    <div className={`user-info ${isLoggedInUser ? " self" : ""}`}>
                        <Avatar
                            imgSrc={img_url}
                            firstName={first_name}
                            lastName={last_name}
                            fontSize={10}
                            height={22}
                            width={22}
                            borderRadius={isLoggedInUser ? 2 : '50%'}
                        />
                        <span>{`${first_name} ${last_name}`}</span>
                    </div>
                </MouseTooltip>
            </div>
            <div className="icon-chevron-right"></div>
            {children}
        </div>
    )
}

export default UserProfileCard