// import { useState } from "react";
// import { Redirect } from "react-router-dom";
// import {removeUserSession} from '../utils/Common';
import '../styles/Logout.scss';

export const Logout = () => {

    // const [loggedOut, setLoggedOut] = useState(false)
  
    // const logout = () => {
    //   removeUserSession();
    //   setLoggedOut(true)
  
    // };
  
    // if (loggedOut) {
    //   return <Redirect to="/login" push={true} />
    // }
  
    // return <a onClick={logout}>Logout</a>;
    return <button className="logout-icon"></button>;
  };
  