import { useEffect, Fragment, useRef, useCallback } from "react";
import io from "socket.io-client";
import { WEB_SOCKET_URL } from "components/Constants";
import { getToken } from "utils/Common";
import { alertService } from "services/alertService";
import { useGlobalContext } from "contexts/GlobalContext";
import { timerEventEmitter } from "components/Timer/Timer";

const EMIT_TIMEOUT = 15 * 1000;  // 15 seconds timeout for web socket
const TIMEOUT_MESSAGE = "operation has timed out";

export const socket = io(WEB_SOCKET_URL, {
  auth: (cb) => {
    cb({ token: getToken() });
  },
  transports: ["websocket"],
  autoConnect: false,
  reconnection: false,
  reconnectionAttempts: 50,
});

export function useSocket() {
  return socket;
}

function log(msg, data = '') {
  const date = new Date().toLocaleString();
  console.log(`[SOCKET] - ${date} - ${msg}`, data);
}

export function connectSocket() {
  log(`checking... isSocketConnected: ${socket.connected}`);
  socket.connect();
}

export function socketConnectionEnsurePromise() {
  return new Promise((resolve, reject) => {
    if (socket.connected) {
      resolve();
      return;
    }
    connectSocket();

    socket.once('connect', () => resolve());
    socket.once('connect_error', (error) => {
      log('socket connect error', error);
      timerEventEmitter.emit('fetchTimer', { reconnectSocket: true });
      timerEventEmitter.once('reconnectSocket', () => {
        connectSocket();
        socket.once('connect', () => {
          resolve();
        });
        socket.once('connect_error', (error) => {
          reject(error);
        });
      });
    });
  });
}

export function disconnectSocket() {
  socket.disconnect();
}

export const socketEmitService = ({ emitTimeout = EMIT_TIMEOUT, eventName, payload } = {}) => {
  return new Promise((resolve, reject) => {
    socketConnectionEnsurePromise()
      .then(() => {
        const emitParams = payload !== undefined ? [payload] : [];
        socket.timeout(emitTimeout).emit(eventName, ...emitParams, (err, res) => {
          if (res?.status === 1 && err?.message !== TIMEOUT_MESSAGE) {
            resolve(res); // Resolve the promise with the response
          } else {
            const errorMessage = res?.msg || err?.message || `There was a problem with "${eventName}" event. Please try again`;
            // alertService.error(errorMessage);
            reject({ message: errorMessage, ...(res && { ...res }) }); // Reject the promise with the error message
          }
        });
      })
      .catch((err) => {
        console.log('socketConnectionEnsurePromise rejected:', err);
        // alertService.error(err?.message);
        reject(err); // Reject the promise with the error
      });
  });
};


export function SocketConnectionManager({ children }) {
  const { isAppInActive } = useGlobalContext();
  const timeoutIDRef = useRef(null);

  const tryReconnect = useCallback((e) => {
    if (isAppInActive) return;
    timeoutIDRef.current = setTimeout(() => {
      socket.io.open((err) => {
        log('tryReconnect', err);
        if (err) {
          !isAppInActive && tryReconnect();
        }
      });
    }, 2000);
  }, [isAppInActive]);

  useEffect(() => {
    // connectSocket();

    socket.on("connect", () => {
      log(`connected`);
    });

    socket.on("disconnect", (reason, details) => {
      log(`disconnected due to reason:"${reason}", low-level reason:"${details?.message}"`);

      if (
        !['transport close', 'ping timeout', 'io client disconnect', 'transport error'].includes(reason)
      ) {
        alertService.warning(`[SOCKET] disconnected due to ${reason}`);
      }

      // if (reason === "io server disconnect") {
      //   // the disconnection was initiated by the server, you need to reconnect manually
      //   console.log(`[SOCKET] disconnected due to ${reason}, Reconnecting...`);
      //   socket.connect();
      // }
      if (reason !== "io client disconnect") {
        tryReconnect();
      }
    });

    // socket.io.on("close", tryReconnect);

    socket.on("connect_error", (error) => {
      if (error.message === "" || error.message === "timeout") {
        log(`connect_error due to "${error.message || 'double authentication'}" error. reconnecting...`, error);
        disconnectSocket();
        connectSocket();
      } else {
        log(`connect_error due to "${error.message}" error`, error);
      }
    });

    socket.io.on("error", (error) => {
      log(`error`, error);
    });

    return () => {
      socket.disconnect();
      if (timeoutIDRef.current) clearTimeout(timeoutIDRef.current);
    }
  }, [tryReconnect]);

  return <Fragment>{children}</Fragment>;
};