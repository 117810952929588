import {
  Component,
  Fragment,
  createContext,
  useContext,
  createRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

const AliveScopeContext = createContext();

export const AliveScope = ({ children }) => {
  const [nodes, setNodes] = useState(new Map());

  const getNodeCache = (id, children) => {
    if (nodes.has(id)) {
      return nodes.get(id);
    }

    const element = document.createElement("div");
    const newCache = { children, element };
    setNodes((prev) => {
      const temp = new Map(prev);
      temp.set(id, newCache);
      return temp;
    });

    return newCache;
  };

  const drop = (id) => {
    setNodes((prev) => {
      const temp = new Map(prev);
      temp.delete(id);
      return temp;
    });
  }

  const clear = () => {
    setNodes(new Map());
  }

  const updateScrollPosition = (id, revertScrollPosition) => {
    setNodes((prev) => {
      const temp = new Map(prev);
      const node = temp.get(id);
      if (node) {
        node.revertScrollPosition = revertScrollPosition;
        temp.set(id, node);
      }
      return temp;
    });
  };

  return (
    <AliveScopeContext.Provider
      value={{ getNodeCache, drop, clear, updateScrollPosition }}
    >
      {children}
      {[...nodes.entries()].map(([id, { children, element }]) => (
        <Fragment key={id}>{createPortal(children, element)}</Fragment>
      ))}
    </AliveScopeContext.Provider>
  );
};

export const useAliveScope = () => {
  const ctx = useContext(AliveScopeContext);
  if (!ctx) {
    throw new Error("useAliveScope must be used within a AliveScope");
  }
  return ctx;
};

class KeepAlive extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  componentDidMount() {
    const { id, children, getNodeCache } = this.props;

    const appendPortalElement = () => {
      const cache = getNodeCache(id, children);
      this.ref.current.appendChild(cache.element);
      if (typeof cache?.revertScrollPosition === "function") {
        cache.revertScrollPosition();
      }
    };

    appendPortalElement();
  }

  componentWillUnmount() {
    const { id, updateScrollPosition } = this.props;
    const el = this.ref.current;
    updateScrollPosition(id, saveScrollPosition(el));
  }

  render() {
    return <div ref={this.ref} />;
  }
}

export function withKeepAlive(Component, id) {
  return (props) => (
    <AliveScopeContext.Consumer>
      {(contextProps) => (
        <KeepAlive id={id} {...contextProps}>
          <Component {...props} />
        </KeepAlive>
      )}
    </AliveScopeContext.Consumer>
  );
}

function isScrollableNode(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

function saveScrollPosition(from) {
  const nodes = new Set(
    [...from.querySelectorAll("*")]
      .filter(isScrollableNode)
      .filter((node) => node.scrollLeft > 0 || node.scrollTop > 0)
  );

  const saver = Array.from(nodes).map((node) => [
    node,
    {
      x: node.scrollLeft,
      y: node.scrollTop,
    },
  ]);

  return () => {
    saver.forEach(([node, scrollPos]) => {
      node.scrollLeft = scrollPos.x;
      node.scrollTop = scrollPos.y;
    });
  };
}

export default KeepAlive;
