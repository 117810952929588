import { createContext, useContext, useState } from "react";
import { format } from "utils/date";

export const TeamMemberFilterContext = createContext({
  showTeamMemberFilters: false,
  toggleTeamMemberFilters: () => { },
  resourceStartDate: null,
  setResourceStartDate: (date) => { },
  startDate: null,
  endDate: null,
  handleDateChange: (startDate, endDate) => { },
  filterQuery: [],
  setFilterQuery: (query) => { },
  clear: () => { },
});

export function TeamMemberFilterProvider({ children }) {
  const [showTeamMemberFilters, setShowTeamMemberFilters] = useState(false);
  function toggleTeamMemberFilters(val) {
    setShowTeamMemberFilters(val);
  }

  const [resourceStartDate, setResourceStartDate] = useState(null)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterQuery, setFilterQuery] = useState([]);

  function handleDateChange(startDate, endDate) {
    setStartDate(format(startDate));
    setEndDate(format(endDate));
  }

  function clear() {
    setFilterQuery([]);
    setStartDate();
    setEndDate();
  }

  return (
    <TeamMemberFilterContext.Provider
      value={{
        showTeamMemberFilters,
        toggleTeamMemberFilters,
        resourceStartDate,
        setResourceStartDate,
        startDate,
        endDate,
        handleDateChange,
        filterQuery,
        setFilterQuery,
        clear,
      }}
    >
      {children}
    </TeamMemberFilterContext.Provider>
  );
}

export function useTeamMemberFilter() {
  return useContext(TeamMemberFilterContext);
}
