import React, { useState, useRef } from 'react';
import ShiftMenuIcon from 'views/Attendance/icons/ShiftMenuIcon';
import { SHIFT_COMPARE_OPTIONS, STANDARD_WORK_TIMINGS_OPTION_KEY } from 'views/Attendance/constants';
import { useOnClickOutside } from 'hooks/useClickOutside';
import './ShiftTotalHeader.scss';

const ShiftTotalHeader = ({ children, standarShiftAverage, selectedAverage, updateSelectedAverage }) => {
    const ref = useRef();

    const [showOptions, setShowOptions] = useState(false);

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    }
    useOnClickOutside(ref, () => toggleOptions());

    // const toggleSelectedDateType = (opt) => {
    //     updateSelectedAverage(opt);
    //     toggleOptions();
    // }

    return (
        <div className='shift-total-header'>
            {children}

            {/* <div className='menu-icon-wrapper' onClick={() => !showOptions && setShowOptions(true)}>
                <ShiftMenuIcon />
            </div>
            {showOptions && <div className='shift-total-options' ref={ref}>
                <div className='shift-total-options-title'>Compare time with</div>
                {SHIFT_COMPARE_OPTIONS.map((option, index) => {
                    return (
                        <div className='shift-total-options-group' key={index}>
                            {option.label && <div className='shift-total-options-group-title'>{option.label}</div>}
                            {option.options.map((subOption, subIndex) => {
                                const isStandardShiftAverageOptions = subOption.value === STANDARD_WORK_TIMINGS_OPTION_KEY;
                                return (
                                    <div className="option radio" key={`${index}-${subIndex}`}>
                                        <input
                                            type="radio"
                                            id={subOption.value}
                                            name={subOption.value}
                                            value={subOption.value}
                                            onChange={() => toggleSelectedDateType(subOption)}
                                            checked={standarShiftAverage ? isStandardShiftAverageOptions : selectedAverage?.value === subOption.value}
                                        />
                                        <label htmlFor={subOption.value}>{subOption.label}</label>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>} */}
        </div>
    )
}

export default ShiftTotalHeader