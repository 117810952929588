import React, { useState } from "react";

// components
import { usePopover } from "components/Popover";
import { MouseTooltip } from "components/ToolTip";
import Portal from "components/Portal";
import BreakPopup from "./BreakPopup";

import useWindowDimensions from "hooks/useWindowDimensions";

// utils
import { formatSecondsHhmm } from "utils/date";
import { isNotNone } from "../../utils";

// styles
import "./BreakDetails.scss";

const BreakDetails = ({ data }) => {
    const { break_count, punctual_break_time, late_break_time } = data;
    const hasTakenBreak = isNotNone(break_count);
    const hasLateCount = isNotNone(late_break_time) && +late_break_time > 0;

    const [open, setOpen] = useState(false)
    const { context, getFloatingProps, refs } = usePopover({ open, onOpenChange: setOpen })

    const isDesktopVersion = useWindowDimensions().width > 640

    return (
        <>
            <div
                ref={refs.setReference}
                className={
                    "user-details break-details" +
                    `${hasTakenBreak ? " cursor-pointer" : ""}` + 
                    `${open ? ' active' : ''}`
                }
                style={{
                    pointerEvents: hasTakenBreak ? "auto" : "none",
                }}
                onClick={() => setOpen(!open)}
            >
                {!isDesktopVersion && !hasTakenBreak ? (
                    <span>-</span>
                ) : hasTakenBreak ? (
                    <div className="break-count-wrapper">
                        <MouseTooltip
                            show={hasTakenBreak && !open}
                            content={
                                <div className="break-tooltip">
                                    <span>Break Count</span>
                                </div>
                            }
                            delay={500}
                        >
                            <span className="break-count instance-count">
                                {hasTakenBreak ? break_count : 0}
                            </span>
                        </MouseTooltip>
                        <div className="break-time-wrapper">
                            <MouseTooltip
                                show={hasTakenBreak && !open}
                                content={
                                    <div className="break-tooltip">
                                        <span>Punctual</span>
                                    </div>
                                }
                                delay={500}
                            >
                                <div className="punctual-break-count">
                                    {formatSecondsHhmm(punctual_break_time)}
                                </div>
                            </MouseTooltip>
                            <span className="plus-sign">+</span>
                            <MouseTooltip
                                show={!open}
                                content={
                                    <div className="break-tooltip">
                                        <span>Late</span>
                                    </div>
                                }
                                delay={500}
                            >
                                <div className={`late-break-count ${hasLateCount ? 'red' : ''}`}>
                                    {formatSecondsHhmm(hasLateCount ? late_break_time : 0)}
                                </div>
                            </MouseTooltip>
                        </div>
                    </div>
                ) : null}
            </div>
            {open && (
                <Portal>
                    <div
                        className="popup-fade-in"
                        ref={refs.setFloating}
                        style={{ ...context.floatingStyles, outline: "none" }}
                        aria-labelledby={context.labelId}
                        aria-describedby={context.descriptionId}
                        {...getFloatingProps()}
                    >
                        <BreakPopup data={data} />
                    </div>
                </Portal>
            )}
        </>
    );
};

export default BreakDetails;
