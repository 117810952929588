import { useState } from "react";

/**
 * @template T
 * @param {string} key
 * @param {T} defaultValue
 * @returns {[T, (newValue: T) => void]}
 */
export function useLocalStorage(key, defaultValue) {
  const [state, setState] = useState(() => {
    try {
      const value = localStorage.getItem(key);

      if (value) {
        return JSON.parse(value);
      } else {
        localStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (v) => {
    const newValue = typeof v === 'function' ? v(state) : v;
    try {
      localStorage.setItem(key, JSON.stringify(newValue));
    } catch (err) { }
    setState(newValue);
  };

  return [state, setValue];
};
