import './Oops.scss';

export default function Oops() {
  return (
    <div className="oops-page">
      <div className="oops">
        <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" fill="none"><rect width="90" height="90" fill="#CE2029" fillOpacity=".2" rx="45" /><rect width="50" height="50" x="20" y="20" fill="#fff" rx="25" /><path fill="#CE2029" d="M45 20c13.825 0 25 11.175 25 25S58.825 70 45 70 20 58.825 20 45s11.175-25 25-25Zm8.975 12.5L45 41.475 36.025 32.5 32.5 36.025 41.475 45 32.5 53.975l3.525 3.525L45 48.525l8.975 8.975 3.525-3.525L48.525 45l8.975-8.975-3.525-3.525Z" /></svg>
        <h1>Oops!</h1>
        <p>The hiring process for this position has officially concluded. Unfortunately, we are no longer accepting applications.</p>
      </div>
    </div>
  )
}
