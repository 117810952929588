import React from "react";
import ClickOutsideListner from "components/ClickOutsideListner";

const HeaderSubMenu = ({
    showMenu,
    setShowMenu,
    sortBy,
    menuOptions,
    onOptionClick,
}) => {
    return (
        <ClickOutsideListner onOutsideClick={() => setShowMenu(false)}>
            <div className="sub-menu">
                <span className="sub-menu-label">Sort By</span>
                {menuOptions.map((option, index) => {
                    return (
                        <div
                            key={index}
                            className="sub-menu-item"
                            onClick={() => {
                                setShowMenu(!showMenu);
                                onOptionClick(option.value);
                            }}
                        >
                            <span
                                className={
                                    sortBy.column === option.value
                                        ? " chevron" +
                                        (sortBy.order === "ascending"
                                            ? " chevron-up"
                                            : " chevron-down")
                                        : ""
                                }
                            >
                                {option.label}
                            </span>
                        </div>
                    );
                })}
            </div>
        </ClickOutsideListner>
    );
};

export default HeaderSubMenu;
