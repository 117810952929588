import React, { useRef, useState } from 'react';

// components
import SpeedSelect from 'react-speedselect';
import Panel from 'components/Panel';
import Accordion from 'components/Accordion';
import ClickOutsideListner from 'components/ClickOutsideListner';
import DatePicker from 'components/ReactCalendar/DatePicker';

// hooks
import useUserData from 'hooks/useUserData';
import useWindowDimensions from 'hooks/useWindowDimensions';

// utils and constants
import { format, subMonths, subYears, subDays } from "utils/date";
import { debounce, isEmpty } from "utils/Common";
import { requestStatusOptions, requestOptions, shiftTypeOptions, dateOptions } from './constants';

// styles
import './Filter.scss';

const Filter = ({ onChange, onClear, leaveTypeOptions, state, fakeLoader }) => {
    const { isManager } = useUserData();
    const { width } = useWindowDimensions();
    const isDesktopVersion = width > 640;
    const searchInputRef = useRef();
    const searchWrapperRef = useRef();

    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [expandedAccordion, setExpandedAccordion] = useState('selectedRequestStatus');

    const [isSearchExpanded, setIsSearchExpanded] = useState(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const request_id = searchParams.get("request_id");
        return !!request_id;
    });
    const [selectedDateType, setSelectedDateType] = useState(dateOptions[2]);
    const [focusedRangePicker, setFocusedRangePicker] = useState(null);
    const [startDateRange, setStartDateRange] = useState(null);
    const [endDateRange, setEndDateRange] = useState(null);

    const todayDate = format(new Date(), "YYYY-MM-DD");
    const yesterdayDate = format(subDays(todayDate, 1), "YYYY-MM-DD");
    const lastWeekDate = format(subDays(todayDate, 7), "YYYY-MM-DD");
    const lastMonthDate = format(subMonths(todayDate, 1), "YYYY-MM-DD");
    const pastThreeMonthsBeforeDate = format(
        subMonths(todayDate, 3),
        "YYYY-MM-DD"
    );
    const pastSixMonthsBeforeDate = format(subMonths(todayDate, 6), "YYYY-MM-DD");
    const lastYearDate = format(subYears(todayDate, 1), "YYYY-MM-DD");

    const expandAccordion = (val) => {
        if (val !== expandedAccordion) setExpandedAccordion(val);
    }

    const toggleFilterMenu = () => {
        setIsFilterMenuOpen(!isFilterMenuOpen);
        setFocusedRangePicker(null);
    }

    const toggleSelectedDateType = (data) => {
        setSelectedDateType(data);
        const date = state.dateCreatedAt || state.requestedAt || state.updatedAt;
        if (date) {
            const newState = { ...state, dateCreatedAt: '', requestedAt: '', updatedAt: '' };
            if (data.key === 'requested') {
                newState.dateCreatedAt = date;
            } else if (data.key === 'effective') {
                newState.requestedAt = date;
            } else {
                newState.updatedAt = date;
            }
            onChange(newState);
        } else {
            // Date type filter works with combination of date range.
            // In order avoid confusion for the user, display fake loader if date range is not selected.
            if (data.key !== selectedDateType.key) fakeLoader()
        }
    }

    const handleFilterChange = ({ isChecked, key, option, type }) => {
        let updatedVal = null;
        if (type === 'radio') {
            updatedVal = option;
        }
        if (type === 'checkbox') {
            updatedVal = isChecked ? [...state[key], option] : state[key].filter(item => item.id !== option.id);
        }
        onChange({ ...state, [key]: updatedVal });
    }

    function handleDateRadioChange(e) {
        const newState = { ...state, dateCreatedAt: '', requestedAt: '', updatedAt: '' };
        if (selectedDateType.key === 'requested') {
            newState.dateCreatedAt = e.target.value;
        } else if (selectedDateType.key === 'effective') {
            newState.requestedAt = e.target.value;
        } else {
            newState.updatedAt = e.target.value;
        }
        onChange(newState);
        if (startDateRange) setStartDateRange(null);
        if (endDateRange) setEndDateRange(null);
    }

    function handleDateChange(date) {
        let _date = date;
        let start_date = startDateRange, end_date = endDateRange;
        if (focusedRangePicker === 'start') {
            if (endDateRange < _date) {
                end_date = _date;
                setEndDateRange(_date);
            }
            start_date = _date;
            setStartDateRange(_date);
            setFocusedRangePicker(!endDateRange ? 'end' : null);
        } else {
            if (startDateRange > _date) {
                start_date = _date;
                setStartDateRange(_date);
            }
            end_date = _date;
            setEndDateRange(_date);
            setFocusedRangePicker(!startDateRange ? 'start' : null);
        }

        if (start_date && end_date) {
            const newState = { ...state, dateCreatedAt: '', requestedAt: '', updatedAt: '' };
            if (selectedDateType.key === 'requested') {
                newState.dateCreatedAt = `${start_date}_${end_date}`;
            } else if (selectedDateType.key === 'effective') {
                newState.requestedAt = `${start_date}_${end_date}`;
            } else {
                newState.updatedAt = `${start_date}_${end_date}`;
            }
            onChange(newState);
        }
    }

    const handleSearchInputChange = debounce((e) => {
        const searchText = e.target.value.trim();
        onChange({
            ...state,
            searchString: searchText,
        });
    }, 450);

    const handleClear = () => {
        searchInputRef.current.value = '';
        setExpandedAccordion('selectedRequestStatus');
        setIsSearchExpanded(false);
        setStartDateRange(null);
        setEndDateRange(null);
        setFocusedRangePicker(null);
        setSelectedDateType(dateOptions[2]);
        onClear();
    }

    const handleExpandSearch = () => {
        setIsSearchExpanded(true);
        if (searchInputRef.current) searchInputRef.current.focus();
    };

    const handleDatePickerToggle = (e) => {
        if (!['start-range', 'end-range'].includes(e.target.id) || (e.target.id === 'start-range' && focusedRangePicker === 'start') || (e.target.id === 'end-range' && focusedRangePicker === 'end')) {
            setFocusedRangePicker(null)
        }
    }

    const isFilterApplied = !(isEmpty(state.selectedRequestStatus) && isEmpty(state.selectedRequests) && isEmpty(state.selectedLeaveType) && isEmpty(state.selectedShiftType) && isEmpty(state.dateCreatedAt) && isEmpty(state.requestedAt) && isEmpty(state.updatedAt));

    return (
        <div className='filter-wrapper'>
            <div
                className={`filter-search-input ${isSearchExpanded ? "expand-search" : ""}`}
                onClick={() => !isSearchExpanded && handleExpandSearch()}
                ref={searchWrapperRef}
            >
                <input
                    type='text'
                    placeholder='Search'
                    ref={searchInputRef}
                    defaultValue={state.searchString}
                    onChange={handleSearchInputChange}
                    onBlur={() => !state.searchString && setIsSearchExpanded(false)}
                />
            </div>
            <div className='filter-container'>
                <Panel
                    isOpen={isFilterMenuOpen}
                    onClose={toggleFilterMenu}
                    top={`calc(var(--header-height-other-tab) + ${isDesktopVersion ? '(var(--modal-header-height) + var(--top-gap))' : '38px'})`}
                    button={<button className={`icon icon-filter ${isFilterMenuOpen ? 'open' : ''} ${isFilterApplied ? 'active' : ''}`} onClick={() => !isFilterMenuOpen && setIsFilterMenuOpen(true)}></button>}
                >
                    <div className='filter-panel'>
                        <Accordion
                            label='Request Status'
                            id='selectedRequestStatus'
                            isExpanded={expandedAccordion === 'selectedRequestStatus'}
                            onExpand={() => expandAccordion('selectedRequestStatus')}
                            data={requestStatusOptions.map(option => {
                                return {
                                    ...option,
                                    checked: state.selectedRequestStatus.some(item => item.id === option.id)
                                }
                            })}
                            onChange={handleFilterChange}
                        />
                        {isManager && <Accordion
                            label='Requests'
                            id='selectedRequests'
                            isExpanded={expandedAccordion === 'selectedRequests'}
                            onExpand={() => expandAccordion('selectedRequests')}
                            data={requestOptions.map(option => {
                                return {
                                    ...option,
                                    checked: state.selectedRequests.some(item => item.id === option.id)
                                }
                            })}
                            onChange={handleFilterChange}
                        />}
                        <Accordion
                            label='Leave Type'
                            id='selectedLeaveType'
                            isExpanded={expandedAccordion === 'selectedLeaveType'}
                            onExpand={() => expandAccordion('selectedLeaveType')}
                            data={leaveTypeOptions?.map(option => {
                                return {
                                    ...option,
                                    checked: state.selectedLeaveType.some(item => item.id === option.id)
                                }
                            })}
                            onChange={handleFilterChange}
                        />
                        <Accordion
                            label='Shift Type'
                            id='selectedShiftType'
                            isExpanded={expandedAccordion === 'selectedShiftType'}
                            onExpand={() => expandAccordion('selectedShiftType')}
                            data={shiftTypeOptions.map(option => {
                                return {
                                    ...option,
                                    checked: state.selectedShiftType.some(item => item.id === option.id)
                                }
                            })}
                            onChange={handleFilterChange}
                        />
                        <div className={`period-wrapper ${focusedRangePicker ? 'increase-height' : ''}`}>
                            <Accordion
                                label={<div className='period-header'>
                                    <div>Period</div>
                                    {expandedAccordion === 'selectedPeriod' && (
                                        <div className='attendance-speedselect'>
                                            <SpeedSelect
                                                options={dateOptions}
                                                displayKey="name"
                                                uniqueKey="id"
                                                disableSearch={true}
                                                selectedOption={selectedDateType}
                                                onSelect={toggleSelectedDateType}
                                                dropdownAlignment="right"
                                            />
                                        </div>
                                    )}
                                </div>}
                                id='selectedPeriod'
                                isFilterApplied={!(state.dateCreatedAt === "" && state.requestedAt === "" && state.updatedAt === "")}
                                isExpanded={expandedAccordion === 'selectedPeriod'}
                                onExpand={() => expandAccordion('selectedPeriod')}
                                children={<>
                                    <div className='date-range-wrapper'>
                                        {focusedRangePicker || startDateRange || endDateRange ? (
                                            <div className={`date-range ${startDateRange && endDateRange && !focusedRangePicker ? 'unfocused' : ''}`}>
                                                <button
                                                    id='start-range'
                                                    className={`select-date ${focusedRangePicker === 'start' ? 'active' : ''}`}
                                                    onClick={() => setFocusedRangePicker(prev => prev === 'start' ? null : 'start')}
                                                >
                                                    {startDateRange ?? 'Start Date'}
                                                </button>
                                                <button
                                                    id='end-range'
                                                    className={`select-date ${focusedRangePicker === 'end' ? 'active' : ''}`}
                                                    onClick={() => setFocusedRangePicker(prev => prev === 'end' ? null : 'end')}
                                                >
                                                    {endDateRange ?? 'End Date'}
                                                </button>

                                                {focusedRangePicker && <ClickOutsideListner onOutsideClick={handleDatePickerToggle}><div className='date-picker-wrapper'>
                                                    <DatePicker
                                                        showInline
                                                        date={focusedRangePicker === 'start' ? startDateRange : endDateRange}
                                                        onChange={handleDateChange}
                                                    />
                                                </div></ClickOutsideListner>}
                                            </div>
                                        ) : (
                                            <button
                                                className='select-date'
                                                onClick={() => setFocusedRangePicker('start')}
                                            >
                                                Select Date
                                            </button>
                                        )}
                                    </div>
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id="any-time"
                                            name="date"
                                            value={""}
                                            onChange={handleDateRadioChange}
                                            checked={state.dateCreatedAt === "" && state.requestedAt === "" && state.updatedAt === ""}
                                        />
                                        <label htmlFor="any-time">Any Time</label>
                                    </div>
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id="today"
                                            name="date"
                                            value={`${todayDate}_${todayDate}`}
                                            onChange={handleDateRadioChange}
                                            checked={
                                                (state.dateCreatedAt === `${todayDate}_${todayDate}` ||
                                                    state.updatedAt === `${todayDate}_${todayDate}` ||
                                                    state.requestedAt === `${todayDate}_${todayDate}`)
                                            }
                                        />
                                        <label htmlFor="today">Today</label>
                                    </div>
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id="yesterday"
                                            name="date"
                                            value={`${yesterdayDate}_${yesterdayDate}`}
                                            onChange={handleDateRadioChange}
                                            checked={
                                                (state.dateCreatedAt === `${yesterdayDate}_${yesterdayDate}` ||
                                                    state.updatedAt === `${yesterdayDate}_${yesterdayDate}` ||
                                                    state.requestedAt === `${yesterdayDate}_${yesterdayDate}`)
                                            }
                                        />
                                        <label htmlFor="yesterday">Yesterday</label>
                                    </div>
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id="last-7-days"
                                            name="date"
                                            value={`${lastWeekDate}_${todayDate}`}
                                            onChange={handleDateRadioChange}
                                            checked={
                                                (state.dateCreatedAt === `${lastWeekDate}_${todayDate}` ||
                                                    state.updatedAt === `${lastWeekDate}_${todayDate}` ||
                                                    state.requestedAt === `${lastWeekDate}_${todayDate}`)
                                            }
                                        />
                                        <label htmlFor="last-7-days">Last 7 days</label>
                                    </div>
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id="last-30-days"
                                            name="date"
                                            value={`${lastMonthDate}_${todayDate}`}
                                            onChange={handleDateRadioChange}
                                            checked={
                                                (state.dateCreatedAt === `${lastMonthDate}_${todayDate}` ||
                                                    state.updatedAt === `${lastMonthDate}_${todayDate}` ||
                                                    state.requestedAt === `${lastMonthDate}_${todayDate}`)
                                            }
                                        />
                                        <label htmlFor="last-30-days">Last 30 days</label>
                                    </div>
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id="last-3-months"
                                            name="date"
                                            value={`${pastThreeMonthsBeforeDate}_${todayDate}`}
                                            onChange={handleDateRadioChange}
                                            checked={
                                                (state.dateCreatedAt === `${pastThreeMonthsBeforeDate}_${todayDate}` ||
                                                    state.updatedAt === `${pastThreeMonthsBeforeDate}_${todayDate}` ||
                                                    state.requestedAt === `${pastThreeMonthsBeforeDate}_${todayDate}`)
                                            }
                                        />
                                        <label htmlFor="last-3-months">Last 3 months</label>
                                    </div>
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id="last-6-months"
                                            name="date"
                                            value={`${pastSixMonthsBeforeDate}_${todayDate}`}
                                            onChange={handleDateRadioChange}
                                            checked={
                                                (state.dateCreatedAt === `${pastSixMonthsBeforeDate}_${todayDate}` ||
                                                    state.updatedAt === `${pastSixMonthsBeforeDate}_${todayDate}` ||
                                                    state.requestedAt === `${pastSixMonthsBeforeDate}_${todayDate}`)
                                            }
                                        />
                                        <label htmlFor="last-6-months">Last 6 months</label>
                                    </div>
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            id="last-12-months"
                                            name="date"
                                            value={`${lastYearDate}_${todayDate}`}
                                            onChange={handleDateRadioChange}
                                            checked={
                                                (state.dateCreatedAt === `${lastYearDate}_${todayDate}` ||
                                                    state.updatedAt === `${lastYearDate}_${todayDate}` ||
                                                    state.requestedAt === `${lastYearDate}_${todayDate}`)
                                            }
                                        />
                                        <label htmlFor="last-12-months">Last 12 months</label>
                                    </div>
                                </>}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </div>
                </Panel>
            </div>
            <div className={`clear-container ${(isFilterApplied || state.searchString.trim()) ? 'show' : ''}`}>
                <div className='clear-container-inner'>
                    <span className='pipe'></span>
                    <button className='btn-clear' onClick={handleClear}>Clear</button>
                </div>
            </div>
        </div>
    )
}

export default Filter