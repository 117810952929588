import { getMyTimezoneOffset } from "utils/Common";

export const convertTimeToSeconds = (time) => {
    let [hours, mins, seconds] = time.split(':');
    hours = hours * 3600;
    mins = mins * 60;
    return +hours + +mins + +seconds;
}

export const convertTimeZone = (date = new Date()) => {
    let offset = getMyTimezoneOffset(date);
    if (!offset) return new Date();
    let newOffset = ((offset.split(':')[0] * 60) + +offset.split(':')[1]) * -1;
    date = new Date(date.getTime() - (newOffset * 60 * 1000));
    // return date.toISOString().replace('T', ' ').split('.')[0];
    return new Date(date.toISOString().replace('T', ' ').split('.')[0]);
}

const formatShiftDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

export function formatDates(inputDate, format) {

    if (!inputDate) return;
    let month = (+inputDate.getMonth() + 1 < 10 ? ('0' + (+inputDate.getMonth() + 1)) : (+inputDate.getMonth() + 1));
    let date = (+inputDate.getDate() < 10 ? ('0' + (inputDate.getDate())) : (inputDate.getDate()));
    let time = inputDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

    switch (format) {
        case 'yyyy-mm-dd':
            inputDate = inputDate.getFullYear() + '-' + month + '-' + date;
            break;

        case 'dd-mm-yyyy':
            inputDate = date + '-' + month + '-' + inputDate.getFullYear();
            break;

        case 'dd-mm-yyyy-hh-mm':
            inputDate = `${date}-${month}-${inputDate.getFullYear()} ${time}`;
            break;

        case 'www-mmm-dd-yyyy':
            inputDate = formatShiftDate.format(inputDate)
            break;

        default:
            inputDate = inputDate.getFullYear() + '-' + month + '-' + date;
            break;
    }

    return inputDate;
}

export function formatTime(input) {
    const isNegative = input.startsWith('-');

    let hours = parseInt(input.substring(0 + (isNegative ? 1 : 0), 2 + (isNegative ? 1 : 0)));
    let minutes = parseInt(input.substring(2 + (isNegative ? 1 : 0), 4 + (isNegative ? 1 : 0)));

    if (isNegative) {
        hours = 24 - hours;
        if (minutes) minutes = 60 - minutes;
    }

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    const formattedTime = date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

    return `${isNegative ? '-' : ''}${formattedTime}`;
}

export function getFormattedTimeAndDiff(dateString, selectedDateString) {
    const date = new Date(dateString);
    const selectedDate = new Date(selectedDateString);

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const time = hours + minutes;

    date.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    const timeDiff = date.getTime() - selectedDate.getTime();
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    const sign = diffDays >= 0 ? '+' : '-';

    return {
        time: formatTime(time),
        diff: diffDays === 0 ? '' : sign + Math.abs(diffDays)
    };
}

export function isNotNone(value) {
    return value !== "None";
}

export function isNone(value) {
    return value === "None";
}

export function getAwayTime(expected_back_at) {
    const currentDate = convertTimeZone();
    const expectedBackAtDate = new Date(expected_back_at);
    return (expectedBackAtDate - currentDate) / 1000;
}

export const addDates = (currentDate, days) => {
    let date = new Date(currentDate);
    date.setDate(date.getDate() + days);
    return date;
}

export const parseTime = (timeString) => {
    const [hours, minutes] = timeString.match(/\d{2}/g).map(Number);
    return hours * 60 + minutes;
}

export const calculateDurationInSeconds = (specialShifts) => {
    let totalDuration = 0;

    specialShifts.forEach(shift => {
        const startTime = parseTime(shift.special_shift_start_time.time);
        const endTime = parseTime(shift.special_shift_end_time.time);
        const timeOffset = shift.special_shift_end_time.time_offset;

        // Calculate the duration in seconds, considering time_offset
        let duration;
        if (timeOffset === 1) {
            // Shift ends on the next day
            duration = (endTime + 24 * 60 - startTime) * 60;
        } else {
            duration = (endTime - startTime + 24 * 60) % (24 * 60) * 60;
        }

        totalDuration += duration;
    });

    return totalDuration;
}

const compareTimeEntries = (entry1, entry2) => {
    return entry1.time === entry2.time && entry1.time_offset === entry2.time_offset;
}

export const compareSchedules = (schedule1, schedule2) => {
    if (schedule1.length !== schedule2.length) {
        return false;
    }
    const scheduleMap = new Map();

    // Populate the map with entries from schedule1
    for (const entry of schedule1) {
        const { weekday, start_time, end_time } = entry;
        scheduleMap.set(weekday, { start_time, end_time });
    }

    // Compare entries from schedule2 with the map
    for (const entry of schedule2) {
        const { weekday, start_time, end_time } = entry;
        const schedule1Entry = scheduleMap.get(weekday);

        if (!schedule1Entry ||
            !compareTimeEntries(schedule1Entry.start_time, start_time) ||
            !compareTimeEntries(schedule1Entry.end_time, end_time)) {
            return false;
        }
    }

    // If all entries in schedule2 are successfully compared, return true
    return true;
}

export function formatDecimalNum(num) {
    if (num % 1 !== 0) return num.toFixed(2).replace(/\.00$/, '');
    return num;
}