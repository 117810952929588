import React, { useCallback } from 'react';

// components
import Avatar from 'components/Avatar';

// utils
import { copyToClipBoard } from 'utils/Utils';
import { addHttpsToURLString } from 'utils/Common';

const Profile = ({ profile, onClick }) => {
    const formatProfileName = useCallback((profile) => {
        return `${profile.first_name} ${profile?.middle_name ?? ''} ${profile?.last_name ?? ''}`.trim();
    }, [])

    const isConfidential = useCallback((value) => {
        return value === 'Confidential' || value === 'confidential';
    }, [])

    return (
        <div className='box card' onClick={onClick}>
            <div className='profile-img'>
                <Avatar
                    imgSrc={profile.img_url ? profile.img_url : null}
                    alt={profile.first_name ? `${profile.first_name}'s pic` : 'No Image'}
                    firstName={profile.first_name}
                    lastName={profile.last_name}
                    height={'100%'}
                    width={'100%'}
                    fontSize={35}
                    borderRadius={'2px'}
                />
            </div>
            <div className='profile-info'>
                <label className='profile_id'>{profile.id}</label>
                <h3>
                    {formatProfileName(profile)}
                </h3>
                <div className='social-buttons-wrapper'>
                    <ul>
                        {/* Phone */}
                        <li>
                            {profile.phone && !isConfidential(profile.phone) ? (
                                <button
                                    className='phone'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        copyToClipBoard(profile.phone);
                                    }}
                                ></button>
                            ) : (
                                <button className='phone-fade' onClick={(e) => e.stopPropagation()}></button>
                            )}
                        </li>
                        {/* Email */}
                        <li>
                            {profile.email && !isConfidential(profile.email) ? (
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                <a
                                    className='email'
                                    href={'mailto:' + profile.email}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                </a>
                            ) : (
                                <button
                                    className='email-fade'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                ></button>
                            )}
                        </li>
                        {/* LinkedIn link */}
                        <li>
                            {profile?.linkedin ? (
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                <a
                                    className='linkedin'
                                    href={addHttpsToURLString(profile.linkedin)}
                                    target='_blank'
                                    rel='noreferrer'
                                    onClick={(e) => e.stopPropagation()}
                                ></a>
                            ) : (
                                <button className='linkedin-fade' onClick={(e) => e.stopPropagation()}></button>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default Profile;
