import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTabContext } from './TabContext';
import ClickOutsideListner from 'components/ClickOutsideListner';
import './TabList.scss';

const TabList = ({ closeList, handleTabClick, id, isTabExpanded, onAddButtonClick, onClose, showAddButton = true, showTabStack, tabs, parentIndex, tabListSearch, setTabListSearch }) => {
    const timeOutRef = useRef(null);
    const searchWrapperRef = useRef(null);
    // const { setOpeningUnexpandedSubTab, openingUnexpandedSubTabTimeoutRef, setSubTabChange, } = useTabContext();
    const { activeTab, activeSubTab, updateSubTabChange, } = useTabContext();
    const [searchExpanded, setSearchExpanded] = useState(false);
    const [showList, setShowList] = useState(showTabStack === id);
    const [position, setPosition] = useState({});

    useEffect(() => {
        if (showTabStack === '') {
            setShowList(showTabStack === id);
        } else {
            timeOutRef.current = setTimeout(() => {
                setShowList(showTabStack === id);
                if (showTabStack !== id) {
                    setSearchExpanded(false);
                }
            }, 200);
        }

        return () => clearTimeout(timeOutRef.current);
    }, [showTabStack, id]);

    useLayoutEffect(() => {
        const popupElem = document.querySelector(`.stack-${id}`);
        let position = null;
        if (popupElem) {
            const popupRect = popupElem.getBoundingClientRect();
            const windowWidth = window.innerWidth;

            if ((popupRect.right + 300) > windowWidth) {
                position = { right: `${window.innerWidth - popupRect?.right}px` };
            } else {
                position = { left: `${popupRect?.left + 5}px` }
            }
        }
        if (position && showTabStack) setPosition(position);
    }, [id, showTabStack]);

    const handleSearch = (e) => {
        setTabListSearch(e.target.value);
    }
    const expandSearch = () => {
        if (!searchExpanded) {
            setSearchExpanded(true)
            searchWrapperRef.current.focus();
        }
    }
    const handlePlusButtonClick = () => {
        onAddButtonClick();
        closeList();
    }
    const handleOutsideClick = (e) => {
        if (e.target.className.includes('tab-list')) return;
        showAddButton && setSearchExpanded(false);
        setTabListSearch('');
        setShowList(false);
        closeList();
    }

    const filterTabs = (tabList) => {
        const searchString = tabListSearch.toLowerCase().trim();
        if (!searchString) return tabList;

        const exactMatches = [];
        const otherMatches = [];
        tabList.forEach((tab) => {
            const title =
                tab.title ||
                tab.name ||
                (tab?.firstName
                    ? `${tab.firstName} ${tab.lastName || ""}`
                    : "Untitled");

            const tabTitle = title.toLowerCase().trim();
            if (tabTitle.includes(searchString)) {
                const nameParts = tabTitle.split(" ");
                let isExactMatch = false;

                for (let i = 0; i < nameParts.length; i++) {
                    if (nameParts[i].startsWith(searchString)) {
                        if (!exactMatches[i]) exactMatches[i] = [];
                        exactMatches[i].push(tab);
                        isExactMatch = true;
                        break; // no need to check further parts
                    }
                }

                if (!isExactMatch) {
                    otherMatches.push(tab);
                }
            }
        });

        return exactMatches.flat().concat(otherMatches);
    };

    const filteredTabs = filterTabs(tabs);

    return (
        <ClickOutsideListner onOutsideClick={handleOutsideClick}>
            <div className={`tab-list-wrapper ${showList ? 'show' : ''}`} style={position}>
                <div className="tab-list">
                    <div className='tab-list-header'>
                        {showAddButton && <>
                            <button className='icon-add' onClick={handlePlusButtonClick}>Tab</button>
                            <span className='pipe'></span>
                        </>}
                        <div className={`tab-list-search-wrapper ${!showAddButton ? 'no-add-tab' : ''} ${searchExpanded || !showAddButton ? 'expanded' : ''}`} onClick={expandSearch}>
                            <div className='tab-list-search-wrapper-inner'>
                                <input ref={searchWrapperRef} type='text' className='tab-list-search' value={tabListSearch} onChange={handleSearch} />
                                {tabListSearch.trim() && <button
                                    className="tab-list-close tab-close-btn"
                                    onClick={() => setTabListSearch('')}
                                ></button>}
                            </div>
                        </div>
                    </div>
                    <div className="tab-list-open-tabs">
                        <div className='tab-list-tabs-wrapper'>
                            {filteredTabs.length > 0 ? (
                                filteredTabs.map((tab, index) => {
                                    const title = tab.title || tab.name || (tab?.firstName ? `${tab.firstName} ${tab.lastName || ''}` : 'Untitled');
                                    return (
                                        <NavLink
                                            className={`tab-list-open-tab ${id === activeTab.id && tab.id === activeSubTab.id ? 'active' : ''}`}
                                            key={tab.id || index}
                                            onClick={(e) => {
                                                // e.preventDefault();
                                                handleTabClick({ tab, isSubTab: true, parentId: id, sort: true });
                                                // clearTimeout(openingUnexpandedSubTabTimeoutRef.current);
                                                updateSubTabChange({
                                                    parentTabId: id,
                                                    newTab: tab,
                                                    oldTab: tabs.find((t) => t.open_order === 1),
                                                });
                                                // setOpeningUnexpandedSubTab(true);
                                                // openingUnexpandedSubTabTimeoutRef.current = setTimeout(() => {
                                                //     setOpeningUnexpandedSubTab(false);
                                                // }, 300);
                                                closeList();
                                                setShowList(false);
                                            }}
                                            to={tab.url}
                                        >
                                            {title}
                                            {!tab.isFixed && <span
                                                className="tab-list-close-btn tab-close-btn"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onClose({ tab, isSubTab: true, parentIndex });
                                                    closeList();
                                                    setShowList(false);
                                                }}
                                            ></span>}
                                        </NavLink>
                                    )
                                })
                            ) : (
                                <div className='tab-list-no-data'>No data found</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ClickOutsideListner>
    );
};

export default TabList;
