import SpeedSelect from "react-speedselect";
import "./CompanyDocsModal.scss";
import { forwardRef, useState } from "react";
import Avatar from "components/Avatar";

const roleOptions = [
  { id: 1, name: "Admin" },
  { id: 2, name: "Editor" },
  { id: 3, name: "Viewer" }
]

const CompanyDocsModal = forwardRef((_, ref) => {
  const [selectedRole, setSelectedRole] = useState(3);
  const [showUserSearchInput, setShowUserSearchInput] = useState(false);
  const [searchUserList, setSearchUserList] = useState([]);
  const [users, setUsers] = useState([
    { name: "Ramesh Suthar", role: 3 },
    { name: "Chandan Kumar", role: 3 },
    { name: "Vineet Kumar", role: 3 },
  ])

  const addUser = (user) => {
    setUsers([...users, user]);
    setShowUserSearchInput(false);
    setSearchUserList([]);
  }

  const removeUser = (index) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    setUsers(newUsers);
  }

  return (
    <div className='company-docs-modal' ref={ref}>
      <div className="modal-header">
        <h6>
          <span className="icon-folder"></span>
          <span>Company Docs</span>
        </h6>
      </div>
      <div className="modal-body">
        <div className="role-select-options react-speedselect-option">
          <SpeedSelect
            className="role-select"
            options={roleOptions}
            displayKey="name"
            uniqueKey="id"
            disableSearch={true}
            selectedOption={roleOptions.find(
              (option) => option.id === selectedRole
            )}
            onSelect={(option) => setSelectedRole(option.id)}
            prominentLabel="Role"
          />
        </div>
        <div className="user-list-wrapper">
          <div className="add-user">
            <button
              className="add-user-btn"
              onClick={() => setShowUserSearchInput(true)}
            >
              <span className="icon-plus"></span>
              <span>User</span>
            </button>
            {showUserSearchInput && (
              <input
                type="text"
                className="user-search-input"
                placeholder="Type Name or mail id"
                autoFocus
                onBlur={(e) => {
                  if (e.target.value.trim() === "") {
                    setShowUserSearchInput(false);
                    setSearchUserList([]);
                  }
                }}
                onInput={(e) =>
                  setSearchUserList([{ name: "Vaibhav Singh" }])
                }
              />
            )}
          </div>
          {searchUserList.length > 0 ? (
            <div className="search-user-list">
              {searchUserList.map((user, index) => {
                const firstName = user.name.split(" ")[0];
                const lastName = user.name.split(" ")[1];
                return (
                  <div className="search-user" key={index}>
                    <div className="search-user-info">
                      <Avatar
                        firstName={firstName}
                        lastName={lastName}
                        width="20px"
                        height="20px"
                        borderRadius="2px"
                      />
                      <div className="user-name">{user.name}</div>
                    </div>
                    <button
                      className="user-cta"
                      onClick={() =>
                        addUser({
                          name: user.name,
                          role: selectedRole,
                        })
                      }
                    >
                      Add
                    </button>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="user-list">
              {users.map((user, index) => {
                const firstName = user.name.split(" ")[0];
                const lastName = user.name.split(" ")[1];
                return (
                  <div className="user" key={index}>
                    <div className="user-info">
                      <Avatar
                        firstName={firstName}
                        lastName={lastName}
                        width="20px"
                        height="20px"
                        borderRadius="2px"
                      />
                      <div className="user-name">{user.name}</div>
                    </div>
                    <div className="user-cta">
                      <div className="react-speedselect-option">
                        <SpeedSelect
                          options={roleOptions}
                          displayKey="name"
                          uniqueKey="id"
                          disableSearch={true}
                          selectedOption={roleOptions.find(
                            (option) => option.id === user.role
                          )}
                          onSelect={(option) => { }}
                          prominentLabel=""
                        />
                      </div>
                      <button className="icon-minus" onClick={() => removeUser(index)}></button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

export default CompanyDocsModal 
