import React, { useContext, useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react';

//npm components and tools
import SuperPager from 'super-pager';
//services
import APIService from 'services/apiService';
import { alertService } from 'services/alertService';

//context
import { TimeWorkerContext } from 'Main.js';
import { useAttendanceFilters } from 'views/Attendance/context';
import { useTeamMemberFilter } from 'views/Attendance/context/teamMemberFilterContext';
import { connectSocket, useSocket } from 'socket';
import { useGlobalContext } from 'contexts/GlobalContext';

//constant
import { AL_BASE_URL, BREAK_SUB_OPTIONS, INACTIVITY_SUB_OPTIONS } from '../../constants';
import * as Constants from 'components/Constants';

//utils
import { convertTimeZone, getFormattedTimeAndDiff, getAwayTime, isNotNone, isNone } from '../../utils';
import { format, formatTimeInSeconds, isDate, isWithinInterval, subDays } from 'utils/date';
import { convertHhmmToUserTimezone, convertUTCToTimezone, getDateFromHhmm, getMyTimezoneOffset, getUser, isElectronApp } from 'utils/Common';

//hooks
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useLocalStorage } from 'hooks/useLocalStorage';
import useClickPreventionOnDoubleClick from "hooks/useClickPreventionOnDoubleClick";

//components
import TeamMemberAttendanceTable from './TeamMemberAttendanceTable';
import TeamMemberCard from './TeamMemberCard';
import HeaderSubMenu from './HeaderSubMenu'
import AttendanceValue from './AttendanceValue';
import BreakDetails from './BreakDetails';
import ShiftTotalHeader from './ShiftTotalHeader';
import UserProfileCard from './UserProfileCard';
// import BreakPopup from './BreakPopup';
import { MouseTooltip } from 'components/ToolTip';
import InactivityDetails from './InactivityDetails';
import ClockTimeDetails from './ClockTimeDetails';
import ShiftLog, { SkeletonShiftLog } from './ShiftLog';

//styles
import './AttendanceTable.scss';

const SORT_BY_STATE_LOCAL_KEY = Constants.SITE_PREFIX + 'attendanceSortBy';

let ac = new AbortController();

const AttendanceTable = ({ showLineLoader, setShowLineLoader, showAnalyticsFor, toggleAnalytics }) => {
  const socket = useSocket();
  const { hasTimerAccess, isAppInActive } = useGlobalContext();
  const { date: selectedDate, search, department, attendance, status, departmentOptions, isFilterApplied, handleDateChange, updatePinnedUsers, pinnedUsers } = useAttendanceFilters();

  const attendanceLoadTimeStampRef = useRef(new Date());
  const officeFloorRef = useRef(null);
  const pinnedOfficeFloorRef = useRef(null);
  const showPinnedDataRef = useRef(null);
  const selectedDateRef = useRef(selectedDate);
  const prevSelectedDateRef = useRef(selectedDate);
  const sortByRef = useRef(null);
  const loadingFirstPageDataRef = useRef(false);
  const loggedInUserRef = useRef({ resource_id: getUser().resource_id });
  const pinnedUsersRef = useRef(pinnedUsers);
  const customSortFnRef = useRef(null);
  const retryAttemptRef = useRef(0);
  const retryAttemptTimeoutRef = useRef(null);

  const searchRef = useRef(null);
  const departmentRef = useRef(null);
  const attendanceRef = useRef(null);
  const statusRef = useRef(null);
  const bufferLoadedTimeRef = useRef(null);

  const scrollPositionRef = useRef(null);

  const tableHeight = window.innerHeight - (36 + 56 + 2.5 + 15); // 36 is the height of header, 56 is attendance filter and 15 is the padding bottom
  const tableEmptyRowsCount = Math.round((tableHeight / 32) - 1); // 32 is the height of each row and -1 is for header row
  const [rowHeight] = useState(tableHeight / tableEmptyRowsCount)
  let PAGE_SIZE = parseInt((tableHeight / 32) * 1.2);
  let BUFFER_SIZE = Math.ceil(PAGE_SIZE * 0.1);
  const timeWorkerInstance = useContext(TimeWorkerContext);

  const [loadingData, setLoadingData] = useState(false);
  const [loadingFirstPageData, setLoadingFirstPageData] = useState(false);
  const [officeFloor, setOfficeFloor] = useState(null);
  officeFloorRef.current = officeFloor;
  const [pinnedOfficeFloor, setPinnedOfficeFloor] = useState(null);
  pinnedOfficeFloorRef.current = pinnedOfficeFloor;
  const [userShiftDetails, setUserShiftDetails] = useState({});
  const [prevSelectedDate, setPrevSelectedDate] = useState(selectedDate);
  const isTimerStartedRef = useRef(false);
  const [isPinningInProgress, setIsPinningInProgress] = useState(false);
  const [sortBy, setSortBy] = useLocalStorage(SORT_BY_STATE_LOCAL_KEY, { column: 'office_floor', order: 'ascending', update: false });
  const [showBreaksMenu, setShowBreaksMenu] = useState(false);
  const [showInactivityMenu, setShowInactivityMenu] = useState(false);
  const [showPinnedData, setShowPinnedData] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  showPinnedDataRef.current = showPinnedData;
  const [expandedCard, setExpandedCard] = useState('');
  const [showUserDetails, setShowUserDetails] = useState('');

  const formattedSelectedDate = format(prevSelectedDate);

  const calculateTotalTime = useCallback((val1, val2) => {
    const isVal1NotNone = isNotNone(val1);
    const isVal2NotNone = isNotNone(val2);

    if (!isVal1NotNone && !isVal2NotNone) {
      return 'None';
    } else if (!isVal1NotNone && isVal2NotNone) {
      return val2;
    } else if (isVal1NotNone && !isVal2NotNone) {
      return val1;
    } else {
      return val1 + val2;
    }
  }, []);

  const mergeArrayOfObjects = useCallback((arr1, arr2) => {
    const merged = [].concat(arr1);
    for (let i = 0; i < arr2.length; i++) {
      const obj = arr2[i];
      const index = merged.findIndex((item) => item.resource_id === obj.resource_id);
      if (index === -1) {
        merged.push(obj);
      } else {
        merged.splice(index, 1);
        merged.push(arr2[i]);
      }
    }
    return merged;
  }, []);

  const updateIsBufferFlag = useCallback((data, allDataFetched = null) => {
    let isAllDataFetched = allDataFetched ? allDataFetched : data.length === officeFloorRef.current?.[formattedSelectedDate]?.meta?.total_results;
    if (showPinnedDataRef.current) isAllDataFetched = allDataFetched ? allDataFetched : data.length === pinnedOfficeFloorRef.current?.[formattedSelectedDate]?.meta?.total_results;
    return data.map((candidate, index) => {
      // Do not update buffer flag to true if all data is fetched
      if (!isAllDataFetched && (data.length - index <= BUFFER_SIZE)) {
        return { ...candidate, isBuffer: true }
      } else {
        return { ...candidate, isBuffer: false }
      };
    })
  }, [BUFFER_SIZE, formattedSelectedDate]);

  const getOfficeFloorData = useCallback(({ controller = ac, startingPoint = 0, showLoader = true, loadPinnedData = false, pageSize = PAGE_SIZE } = {}) => {
    let abortController = controller;
    if (abortController.signal.aborted) {
      ac = new AbortController();
      abortController = ac;
    }
    let date = format(selectedDate);
    let url = new URL(AL_BASE_URL + '/home_page/');
    const dataExists = officeFloor?.hasOwnProperty(date);
    const isInitialLoad = startingPoint === 0;
    url.searchParams.append('page_size', pageSize);
    url.searchParams.append('start_date', date);
    url.searchParams.append('end_date', date);

    const pinnedData = showPinnedData || loadPinnedData;

    if (search) {
      url.searchParams.append('search_string', search);
    }

    let filtersQuery = [];
    if (department.length && departmentOptions.length !== department.length) {
      filtersQuery.push(`department:${department.map(dept => dept.id)}`);
    }
    if (attendance.length) {
      filtersQuery.push(`attendance:${attendance}`);
    }
    if (pinnedData) {
      filtersQuery.push(`is_pinned`);
    }
    if (status.length && status.length !== 3) {
      filtersQuery.push(`status:${status}`);
    }
    if (filtersQuery.length > 0) {
      url.searchParams.append('filters', filtersQuery.join(';'));
    }
    // if(filters && filterValue[0] !== 'reset') url.searchParams.append('filters', filters);
    url.searchParams.append('sort_key', sortBy.column.replace('-', '_'));
    url.searchParams.append('sort_desc', sortBy.order === 'ascending' ? 0 : 1);
    // if (searchRef.current && filterValue[0] !== 'reset') url.searchParams.append('search_string', searchRef.current);

    // if (pageNumber !== 1) {
    // url.searchParams.append('starting_point', startingPoint);
    // } else {
    //   url.searchParams.append('page_number', pageNumber);
    // }

    // TODO: Need to work on starting point
    url.searchParams.append('starting_point', isInitialLoad ? startingPoint : startingPoint - BUFFER_SIZE);
    // url.searchParams.append('starting_point', startingPoint !== null ? startingPoint : dataLength || 0);
    // if (pageNumber !== 1) url.searchParams.append('starting_point', 1);
    url = url.toString();
    // if (filters && filterValue[0] !== 'reset') url += `&filters=${filters}`;
    // return;
    // To avoid flickering while sorting using total hours we use loadingFirstPageDataRef
    if (startingPoint === 0) {
      loadingFirstPageDataRef.current = true;
      setLoadingFirstPageData(true);
    }
    setLoadingData(true);
    setShowLineLoader(true);
    if (dataExists && isInitialLoad) {
      setPrevSelectedDate(selectedDate)
      prevSelectedDateRef.current = selectedDate;
    }
    APIService.apiRequest(url, null, false, 'GET', abortController)
      .then(response => {
        setShowSkeleton(false);
        if (response.status === 1) {
          if (retryAttemptRef.current > 0) {
            retryAttemptRef.current = 0;
          }
          if (!hasTimerAccess) connectSocket();
          if (!dataExists || !isInitialLoad) {
            setPrevSelectedDate(selectedDate);
            prevSelectedDateRef.current = selectedDate
          }
          if (isInitialLoad || pageSize === BUFFER_SIZE) bufferLoadedTimeRef.current = Date.now();
          const callback = oldOfficeFloor => {
            let oldOfficeFloorCopy = structuredClone(oldOfficeFloor);
            // if (!isFilterApplied && oldOfficeFloorCopy && oldOfficeFloorCopy[date]) {

            if (!isInitialLoad) {
              // oldOfficeFloorCopy[date].data = [...oldOfficeFloorCopy[date].data, ...response.output[date].data];
              let result = mergeArrayOfObjects(oldOfficeFloorCopy[date].data, response.output[date].data);
              oldOfficeFloorCopy[date].data = updateIsBufferFlag(result)
              // if (result.length < response.output[date].meta.total_results) {
              //   oldOfficeFloorCopy[date].data = customSort(result)
              // } else {
              //   oldOfficeFloorCopy[date].data = result;
              // }
            } else {
              if (oldOfficeFloorCopy === null) oldOfficeFloorCopy = {};
              oldOfficeFloorCopy[date] = response.output[date]
              oldOfficeFloorCopy[date].isFilteredData = isFilterApplied;
              const isAllDataFetched = response.output[date].data.length === response.output[date].meta.total_results;
              oldOfficeFloorCopy[date].data = isAllDataFetched ? oldOfficeFloorCopy[date].data : updateIsBufferFlag(oldOfficeFloorCopy[date].data);
            }

            return oldOfficeFloorCopy;
          }
          // TODO: No need to update the buffer flag for pinnedData callback. Please create a new one (by Sathish)
          pinnedData ? setPinnedOfficeFloor(callback) : setOfficeFloor(callback);
          // if (pinnedData) setPinnedUsersCount(response.output[date].meta.total_results);

          const formattedToday = format(convertTimeZone());
          const yesterday = new Date(formattedToday);
          yesterday.setDate(yesterday.getDate() - 1);
          const formattedYesterday = format(yesterday);

          if (!isAppInActive) {
            if (!isTimerStartedRef.current && (formattedToday === date || formattedYesterday === date)) {
              isTimerStartedRef.current = true;
              timeWorkerInstance.postMessage({
                command: 'worker:updateTotalHoursStatus',
                updateTotalHours: true,
              });
            } else if (formattedToday !== date && formattedYesterday !== date) {
              isTimerStartedRef.current = false;
              timeWorkerInstance.postMessage({
                command: 'worker:updateTotalHoursStatus',
                updateTotalHours: false,
              });
            }
          }

          // if (!loadPinnedData) {  // Do not connect socket while loading inital pinned data
          // if (!socketInstanceRef.current) {
          //   const socket = io(Constants.WEB_SOCKET_URL, {
          //     auth: { token: getToken() },
          //     transports: ['websocket'],
          //   });

          //   socket.on("connect_error", (e) => {
          //     console.log(`connect_error due to ${e.message}`, e);
          //     socket.auth.token = getToken();
          //     // socket.connect();
          //   });

          //   socket.on('attendance', (msg) => updateStatus(msg));
          //   setSocketInstance(socket);
          //   socketInstanceRef.current = socket;
          // } else 
          // }
          // if (!loadPinnedData && socketInstanceRef.current && !socketInstanceRef.current.connected) {
          //   console.log('isSocketConnected', socketInstanceRef.current, socketInstanceRef.current?.connected, loadPinnedData);
          //   socketInstanceRef.current.auth.token = getToken();
          //   socketInstanceRef.current.connect();
          // }

          if (startingPoint === 0) {
            loadingFirstPageDataRef.current = false;
            setLoadingFirstPageData(false);
          }
        } else {
          if (!(response instanceof DOMException && response.name === 'AbortError')) {
            if (navigator.onLine && response?.message === 'Failed to fetch' && retryAttemptRef.current < 3) {
              retryAttemptRef.current += 1;
              retryAttemptTimeoutRef.current = setTimeout(() => {
                getOfficeFloorData({ controller, startingPoint, showLoader, loadPinnedData, pageSize });
                retryAttemptTimeoutRef.current = null;
              }, 1000);
              return;
            }
            alertService.error(response.msg || `Error occured while fetching office floor data`);
            if (date !== format(prevSelectedDateRef.current)) {
              handleDateChange(() => prevSelectedDateRef.current);
            }
            if (startingPoint === 0) {
              loadingFirstPageDataRef.current = false;
              setLoadingFirstPageData(false);
            }
          }
        }
        if (!abortController?.signal?.aborted || response.msg === 'Failed to fetch') {
          setShowLineLoader(false);
          setLoadingData(false);
        }
      })
      .catch(err => {
        alertService.error(`Error: ${err.message}`);
        console.log('getOfficeFloorData - error', err);
        setShowSkeleton(false);
        if (startingPoint === 0) {
          loadingFirstPageDataRef.current = false;
          setLoadingFirstPageData(false);
        }
        if (!abortController?.signal?.aborted || err.message === 'Failed to fetch') {
          setShowLineLoader(false);
          setLoadingData(false);
        }
      })
  }, [PAGE_SIZE, selectedDate, officeFloor, showPinnedData, search, department, departmentOptions.length, attendance, status, sortBy.column, sortBy.order, BUFFER_SIZE, setShowLineLoader, hasTimerAccess, isAppInActive, mergeArrayOfObjects, updateIsBufferFlag, isFilterApplied, timeWorkerInstance, handleDateChange]);

  const customSort = useCallback((data, skipBuffer = false) => {
    let sortedData = structuredClone(data);
    const officeDataCopy = showPinnedDataRef.current ? pinnedOfficeFloorRef.current : officeFloorRef.current;
    let sortByColumn = sortByRef.current.column;
    let isAscending = sortByRef.current.order === 'ascending';

    if (sortByRef.current.column === 'office_floor') sortByColumn = 'first_name';
    if (sortByRef.current.column === 'shift_total') sortByColumn = 'total_time';
    if (sortByRef.current.column.includes('clock')) sortByColumn += '_time';
    sortByColumn = sortByColumn.replaceAll('-', '_');

    sortedData.sort((a, b) => {
      const aName = (a.first_name + a.last_name + a.resource_id).replaceAll(" ", "").toLocaleLowerCase();
      const bName = (b.first_name + b.last_name + b.resource_id).replaceAll(" ", "").toLocaleLowerCase();
      if (sortByColumn === 'total_break_time') {
        a['total_break_time'] = calculateTotalTime(a.punctual_break_time, a.late_break_time);
        b['total_break_time'] = calculateTotalTime(b.punctual_break_time, b.late_break_time);
      }
      if (a[sortByColumn] === b[sortByColumn] && sortByColumn !== 'first_name') {
        if (isAscending) {
          return aName > bName ? 1 : -1;
        } else {
          return aName < bName ? 1 : -1;
        }
      }
      if ((a[sortByColumn] === 'None' && isAscending) || (b[sortByColumn] === 'None' && !isAscending)) return -1;
      if ((a[sortByColumn] === 'None' && !isAscending) || (b[sortByColumn] === 'None' && isAscending)) return 1;
      if (sortByColumn === 'first_name') {
        const isASelf = a.resource_id === loggedInUserRef.current?.resource_id;
        const isBSelf = b.resource_id === loggedInUserRef.current?.resource_id;
        if (isASelf) {
          return isAscending ? -1 : 1;
        }
        if (isBSelf) {
          return isAscending ? 1 : -1;
        }
        return isAscending ? aName > bName ? 1 : -1 : aName < bName ? 1 : -1;
      }

      return isAscending ? a[sortByColumn] > b[sortByColumn] ? 1 : -1 : a[sortByColumn] < b[sortByColumn] ? 1 : -1;
    })

    const index = officeDataCopy?.[formattedSelectedDate]?.data?.findIndex(item => item.isBuffer);
    // Do not update buffer if buffer is not present which means all data is fetched
    if (!skipBuffer && index !== undefined && index !== -1) {
      let bufferLength = officeDataCopy?.[formattedSelectedDate]?.data?.filter(item => item.isBuffer).length;
      let shouldUpdateBuffer = false;
      for (let i = 0; i < bufferLength; i++) {
        if (sortedData?.[index + i]?.resource_id && officeDataCopy?.[formattedSelectedDate]?.data?.[index + i]?.resource_id !== sortedData[index + i].resource_id) {
          shouldUpdateBuffer = true;
          break;
        }
      }
      if (shouldUpdateBuffer && !loadingData) {
        // Check if the buffer can be called again after a 1-minute cooldown. This is to avoid calling buffer again and again
        const canCallBuffer = bufferLoadedTimeRef.current !== null && bufferLoadedTimeRef.current - Date.now() < -60000;
        console.log('call buffer update', new Date(), sortedData.map(e => `${e.first_name} ${e.total_time}`), officeDataCopy?.[formattedSelectedDate]?.data?.map(e => `${e.first_name} ${e.total_time}`));
        if (canCallBuffer) {
          console.log('call buffer update with buffer', new Date(), sortedData.map(e => `${e.first_name} ${e.total_time}`), officeDataCopy?.[formattedSelectedDate]?.data?.map(e => `${e.first_name} ${e.total_time}`));
          getOfficeFloorData({ startingPoint: index + 1, showLoader: false, loadPinnedData: showPinnedDataRef.current, pageSize: BUFFER_SIZE })
        }
      }
    }

    return skipBuffer ? sortedData : updateIsBufferFlag(sortedData);
  }, [BUFFER_SIZE, getOfficeFloorData, calculateTotalTime, formattedSelectedDate, updateIsBufferFlag, loadingData]);

  useEffect(() => {
    customSortFnRef.current = customSort;
  }, [customSort]);

  const updateStatus = useCallback(message => {
    const data = message.data;
    const resId = +data.resource_id;

    // let currentDate = format(convertTimeZone());
    const date = data.date;

    if (format(prevSelectedDateRef.current) === date) {
      // if (data.clock_in_time !== 'None' && formatDates(selectedDateRef.current, 'yyyy-mm-dd') === formatDates(new Date(data.clock_in_time), 'yyyy-mm-dd')) {
      let officeFloorCopy = structuredClone(showPinnedDataRef.current ? pinnedOfficeFloorRef.current : officeFloorRef.current);
      // Update user info on status change
      let { first_name, middle_name, last_name, resource_id, attendance, department, status, clock_in_time, clock_out_time, special_shift_time } = data;
      let isClockedIn = isNotNone(clock_in_time);
      let isSearchMatching = searchRef.current ? ((first_name + middle_name + last_name).trim().toLocaleLowerCase().includes(searchRef.current.toLocaleLowerCase()) || searchRef.current === String(resource_id)) : true;

      let isSameDept = departmentRef.current.length ? departmentRef.current.some(dept => dept.name === department) : true
      let isPinned = showPinnedDataRef.current ? pinnedUsersRef.current?.pinned_resource_ids?.split(',')?.includes(resource_id.toString()) : true;

      let includesOnLeave = attendanceRef.current.includes('on_leave');
      let includesOnSpecialShift = attendanceRef.current.includes('special_shift');

      let includesEarly = attendanceRef.current.includes('early');
      let includesLate = attendanceRef.current.includes('late');
      let includesOnTime = attendanceRef.current.includes('on_time');

      // let includesNotStarted = statusRef.current.includes('not_started');
      let includesOnline = statusRef.current.includes('online');
      let includesAway = statusRef.current.includes('on_break') && isClockedIn;
      // let includesAway = statusRef.current.includes('on_break');
      let includesClockedOut = statusRef.current.includes('clocked_out');

      let isLeaveMatching = includesOnLeave ? attendance.includes('leave') : true;
      let isSpecialShiftMatching = includesOnSpecialShift ? isNotNone(special_shift_time) : true;
      let isWorkingStatusMatching = (includesEarly || includesLate || includesOnTime) ? attendanceRef.current.includes(attendance) : true;
      let isPresenceMatching = (includesOnline || includesAway || includesClockedOut) ? isNotNone(clock_out_time) ? includesClockedOut : status === 'On' ? includesOnline : includesAway : true;

      let shiftStatusMatching = false;
      if (includesOnLeave && includesOnSpecialShift && (includesEarly || includesLate || includesOnTime)) {
        shiftStatusMatching = isLeaveMatching || isSpecialShiftMatching || isWorkingStatusMatching;
      } else if (includesOnLeave && includesOnSpecialShift) {
        shiftStatusMatching = isLeaveMatching || isSpecialShiftMatching;
      } else if (includesOnLeave && (includesEarly || includesLate || includesOnTime)) {
        shiftStatusMatching = isLeaveMatching || isWorkingStatusMatching;
      } else if (includesOnSpecialShift && (includesEarly || includesLate || includesOnTime)) {
        shiftStatusMatching = isSpecialShiftMatching || isWorkingStatusMatching;
      } else {
        shiftStatusMatching = isLeaveMatching && isSpecialShiftMatching && isWorkingStatusMatching;
      }

      // const expected_back_at = isDate(data.expected_back_at) ? format(convertTimeZone(new Date(data.expected_back_at)), 'YYYY-MM-DD HH:mm:ss') : data.expected_back_at;
      // const clockInDateTime = data.clock_in_time !== 'None' ? format(convertTimeZone(new Date(data.clock_in_time)), 'YYYY-MM-DD HH:mm:ss') : data.clock_in_time;
      // const clockOutDateTime = data.clock_out_time !== 'None' ? format(convertTimeZone(new Date(data.clock_out_time)), 'YYYY-MM-DD HH:mm:ss') : data.clock_out_time;

      // check if filter (dept, on leave, working, special shift, etc.), search string and page number matches the result
      if (isSearchMatching && isSameDept && isPresenceMatching && shiftStatusMatching && isPinned) {
        // If data matches with filters and already loaded then udpate the data
        if (officeFloorCopy?.[date]?.data?.some(cand => +cand.resource_id === +resId)) {
          officeFloorCopy[date].data = customSort(officeFloorCopy[date]?.data.map(cand => +cand.resource_id === +resId ? { ...data } : cand))
        } else {
          // If data matches with filters and not loaded already then add the user and remove last user after sorting
          // remove only if last page is not loaded

          // TODO: handle page size, meta here and sorting (once sorting is implemented)
          if (officeFloorCopy?.[date]?.meta?.total_results === officeFloorCopy?.[date]?.data?.length) {
            officeFloorCopy[date].meta.total_results += 1;
          }
          officeFloorCopy[date].data = customSort([...officeFloorCopy?.[date]?.data, { ...data }], true);
          if (officeFloorCopy?.[date]?.meta?.total_results !== officeFloorCopy?.[date]?.data?.length) {
            officeFloorCopy[date].data.pop();
          }
          const totalResults = officeFloorCopy[date].meta.total_results;
          const dataLength = officeFloorCopy[date].data.length;
          officeFloorCopy[date].data = updateIsBufferFlag(officeFloorCopy[date].data, totalResults === dataLength);
          // updateMeta('add');
        }
      } else {
        if (officeFloorCopy[date].data.some(cand => +cand.resource_id === +resId)) {
          // TODO: handle page content and meta since we are removing a candidate
          // No need to fetch last entry if we are using starting point
          // Make sure to fetch only last entry of that page by updating page number and page size
          officeFloorCopy[date].data = officeFloorCopy[date].data.filter(cand => +cand.resource_id !== +resId);
          officeFloorCopy[date].meta.total_results -= 1;
          // let shouldUpdate = updateMeta('subtract');
          // shouldUpdate && getHomePageData(['fetchLastEntry', homePageMetaRef.current.page_number * PAGE_SIZE], false);
        } else {
          // Do nothing
        }
      }
      showPinnedDataRef.current ? setPinnedOfficeFloor(() => officeFloorCopy) : setOfficeFloor(() => officeFloorCopy);
    }
  }, [customSort, updateIsBufferFlag]);

  // useEffect(() => {

  //   if (ac.signal.aborted) {
  //     ac = new AbortController();
  //   }
  //   getOfficeFloorData({ controller: ac, loadPinnedData: true });

  //   return () => {
  //     // socketInstanceRef.current && socketInstanceRef.current.disconnect();
  //     ac.abort();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => { pinnedUsersRef.current = pinnedUsers }, [pinnedUsers]);

  useEffect(() => {
    if (!isAppInActive) socket.on("attendance", updateStatus);
    return () => {
      if (!isAppInActive) socket.off("attendance", updateStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, isAppInActive]);

  useEffect(() => {
    const showLoader = selectedDate === selectedDateRef.current;
    selectedDateRef.current = selectedDate;
    searchRef.current = search;
    departmentRef.current = department;
    attendanceRef.current = attendance
    statusRef.current = status;
    sortByRef.current = sortBy;

    if (ac.signal.aborted) {
      ac = new AbortController();
    }
    // const onOnlineCallback = () => {
    //   if (isAppInActive) return;
    //   if (ac.signal.aborted) {
    //     ac = new AbortController();
    //   }
    //   retryAttemptTimeoutRef.current = setTimeout(() => {
    //     getOfficeFloorData({ controller: ac });
    //     retryAttemptTimeoutRef.current = null;
    //   }, 1000);
    // }
    // window.addEventListener('online', onOnlineCallback);
    getOfficeFloorData({ controller: ac, showLoader });

    // TODO: check and add this if user does not have timer privilege
    // if (isElectronApp) window.ipcRender.receive('refreshAttendanceData', getOfficeFloorData);

    return () => {
      // if (isElectronApp) window.ipcRender.removeAllListeners('refreshAttendanceData', getOfficeFloorData);
      // window.removeEventListener('online', onOnlineCallback);
      ac.abort();
      // if (retryAttemptTimeoutRef.current) clearTimeout(retryAttemptTimeoutRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, search, department, attendance, status, sortBy, showPinnedData]);

  useEffect(() => {
    const callback = () => {
      // if (window.navigator.onLine) {
      //   alertService.info(`[SOCKET] ${error.message}`);
      //   socketInstance.auth.token = getToken();
      //   socketInstance.connect();
      // }
      if (attendanceLoadTimeStampRef.current.getTime() + 5000 < new Date().getTime()) {
        getOfficeFloorData();
      }
    }

    socket.on("connect", callback);

    return () => socket.off("connect", callback);
  }, [getOfficeFloorData]);

  useEffect(() => {
    if (!hasTimerAccess) {
      if (isElectronApp) window.ipcRender.receive('refreshAttendanceData', getOfficeFloorData);
      attendanceLoadTimeStampRef.current = new Date();
    }

    return () => {
      if (!hasTimerAccess && isElectronApp) window.ipcRender.removeAllListeners('refreshAttendanceData', getOfficeFloorData);
    }
  }, [hasTimerAccess, getOfficeFloorData]);

  useEffect(() => {

    function stopTotalHoursUpdate() {
      timeWorkerInstance.postMessage({
        command: 'worker:updateTotalHoursStatus',
        updateTotalHours: false,
      });
      isTimerStartedRef.current = false;
      console.log('inside stopTotalHoursUpdate');
    }

    if (isElectronApp) {
      window.ipcRender.receive('stopInterval', () => {
        stopTotalHoursUpdate();
      })
    }

    function updateTotalHours(e) {
      const now = convertTimeZone();
      const todayDate = format(now, 'YYYY-MM-DD');
      const yesterdayDate = format(subDays(now, 1), 'YYYY-MM-DD')
      const selectedDate = format(selectedDateRef.current, 'YYYY-MM-DD');
      if (
        e.data.command === "updateTotalHours" &&
        [todayDate, yesterdayDate].includes(selectedDate)
      ) {
        const callback = (officeFloor) => {
          // const currentDate = format(convertTimeZone());
          const formattedSelectedDate = format(selectedDateRef.current);
          const tzOffset = getMyTimezoneOffset(formattedSelectedDate);
          let officeFloorCopy = structuredClone(officeFloor);
          if (officeFloorCopy?.hasOwnProperty(formattedSelectedDate)) {
            officeFloorCopy[formattedSelectedDate].data = officeFloorCopy[
              formattedSelectedDate
            ].data.map((user) => {
              const isUserOnline = user.status === "On";
              const isUserClockedIn = isNotNone(user.clock_in_time);
              const isUserClockedOut = isNotNone(user.clock_out_time);
              if (
                isUserOnline &&
                isNotNone(user.total_time) &&
                isUserClockedIn &&
                !isUserClockedOut
              ) {
                const total_time =
                  isElectronApp &&
                    user?.resource_id === loggedInUserRef.current?.resource_id
                    ? e.data.time || user.total_time
                    : user.total_time + 1;

                const [approved_start_time, approved_start_time_offset] =
                  convertHhmmToUserTimezone(user.approved_start_time, tzOffset);
                const [approved_end_time, approved_end_time_offset] =
                  convertHhmmToUserTimezone(user.approved_end_time, tzOffset);

                const startTime = getDateFromHhmm(approved_start_time, user.date);
                startTime.setDate(startTime.getDate() + approved_start_time_offset);

                const endTime = getDateFromHhmm(approved_end_time, user.date);
                endTime.setDate(endTime.getDate() + approved_end_time_offset);

                if (
                  isNone(user.holiday_name) &&
                  user.attendance !== "on_leave" &&
                  isNone(user.special_shift_time) &&
                  user.day_status === "working_day" &&
                  isWithinInterval(now, { start: startTime, end: endTime })
                ) {
                  user.overlap_seconds += total_time - user.total_time; // 2000 + (2111 - 2110) = 2001
                }

                return { ...user, total_time };
              }

              if (
                !isUserOnline &&
                isUserClockedIn &&
                !isUserClockedOut
              ) {
                const now = convertTimeZone();
                const isUserInactive = user.expected_back_at === "None";
                const isUserLate = isDate(user.expected_back_at)
                  ? now.getTime() > convertUTCToTimezone(user.expected_back_at).getTime()
                  : false;
                const inactivity_time =
                  isUserInactive && isNotNone(user.inactivity_time)
                    ? user.inactivity_time + 1
                    : user.inactivity_time;
                const late_break_time =
                  isUserLate &&
                    !isUserInactive &&
                    isNotNone(user.late_break_time)
                    ? user.late_break_time + 1
                    : user.late_break_time;
                const punctual_break_time =
                  !isUserLate &&
                    !isUserInactive &&
                    isNotNone(user.punctual_break_time)
                    ? user.punctual_break_time + 1
                    : user.punctual_break_time;

                return {
                  ...user,
                  late_break_time: late_break_time,
                  punctual_break_time: punctual_break_time,
                  inactivity_time: inactivity_time,
                };
              }

              return user;
            });
            if (sortByRef.current.update && !loadingFirstPageDataRef.current) {
              officeFloorCopy[formattedSelectedDate].data = customSortFnRef.current(
                officeFloorCopy[formattedSelectedDate].data
              );
            }
            // return sortByRef.current.column === 'shift_total' && !loadingFirstPageDataRef.current ? customSort(homePageDataCopy, sortByRef.current.column, sortByRef.current.order) : homePageDataCopy;
          }
          return officeFloorCopy;

          // let officeFloorCopy = officeFloor.map(cand => cand.status === 'On' && cand.total_time !== 'None' && cand.clock_in_time !== 'None' && cand.clock_out_time === 'None' ? { ...cand, total_time: cand.resource_id === isElectronApp && loggedInUser.resource_id ? e.data.time || cand.total_time : cand.total_time + 1 } : cand);
        };
        showPinnedDataRef.current
          ? setPinnedOfficeFloor(callback)
          : setOfficeFloor(callback);
      }
    }

    timeWorkerInstance.addEventListener("message", updateTotalHours, false);

    return () => {
      timeWorkerInstance.postMessage({
        command: 'worker:updateTotalHoursStatus',
        updateTotalHours: false,
      });
      isTimerStartedRef.current = false;
      timeWorkerInstance.removeEventListener("message", updateTotalHours, false)
    }

  }, [timeWorkerInstance]);

  const handleSort = (colName) => {
    const isSortableColumn = ['shift_total', 'punctual_break_time', 'late_break_time', 'inactivity_time', 'total_break_time'].includes(colName);
    const newSortBy = {
      column: colName,
      order: sortBy.column === colName ? (sortBy.order === 'ascending' ? 'descending' : 'ascending') : 'descending',
      update: isSortableColumn, // This is used for updating values dynamically
    };
    // TODO: This (zIndex change) is a temp work around for fixing tooltip issue. Need to remove once the tooltip issue is fixed
    const elem = document.querySelector('.attendance__filters');
    if (elem) elem.style.zIndex = 99999;
    setSortBy(newSortBy);
    setTimeout(() => {
      if (elem) elem.style.zIndex = 9;
    }, 0);
  };

  const clearSort = () => {
    setSortBy({ column: 'office_floor', order: 'ascending', update: false });
  }

  const toggleBreaksMenu = () => {
    setShowBreaksMenu(!showBreaksMenu);
  }

  const toggleInactivityMenu = () => {
    setShowInactivityMenu(!showInactivityMenu);
  }

  const isBreakSortingApplied = () => {
    return BREAK_SUB_OPTIONS.some(item => item.value === sortBy.column);
  }

  const isInactivitySortingApplied = () => {
    return INACTIVITY_SUB_OPTIONS.some(item => item.value === sortBy.column);
  }

  const [handleBreakHeaderClick, handleBreakHeaderDoubleClick] = useClickPreventionOnDoubleClick(toggleBreaksMenu, clearSort);
  const [handleInactivityHeaderClick, handleInactivityHeaderDoubleClick] = useClickPreventionOnDoubleClick(toggleInactivityMenu, clearSort);
  const [handleSortClick, handleSortDoubleClick] = useClickPreventionOnDoubleClick(handleSort, clearSort);

  const getBreaksHeader = () => {
    const isBreakSorting = isBreakSortingApplied();
    return <div className='sub-menu-wrapper header'>
      <MouseTooltip
        asChild
        delay={500}
        show={isBreakSorting && !showBreaksMenu}
        content={'Double click to remove sorting'}
        style={{ lineHeight: "17px" }}
      >
        <span
          className={'breaks-header' +
            (isBreakSorting
              ? " chevron" +
              (sortBy.order === "ascending"
                ? " chevron-up"
                : " chevron-down")
              : "")
          }
          onClick={handleBreakHeaderClick}
          onDoubleClick={() => {
            if (!showBreaksMenu && isBreakSorting) {
              handleBreakHeaderDoubleClick()
            }
          }}
        >
          Breaks
        </span>
      </MouseTooltip>
      {showBreaksMenu &&
        <HeaderSubMenu
          showMenu={showBreaksMenu}
          setShowMenu={setShowBreaksMenu}
          sortBy={sortBy}
          menuOptions={BREAK_SUB_OPTIONS}
          onOptionClick={handleSort}
        />
      }
    </div>
  }

  const getInactivityHeader = () => {
    const isInactivitySorting = isInactivitySortingApplied()
    return <div className='sub-menu-wrapper header'>
      <MouseTooltip
        asChild
        delay={500}
        show={isInactivitySorting && !showInactivityMenu}
        content={'Double click to remove sorting'}
        style={{ lineHeight: "17px" }}
      >
        <span
          className={'inactivity-header' +
            (isInactivitySorting
              ? " chevron" +
              (sortBy.order === "ascending"
                ? " chevron-up"
                : " chevron-down")
              : "")
          }
          onClick={handleInactivityHeaderClick}
          onDoubleClick={() => {
            if (!showInactivityMenu && isInactivitySorting) {
              handleInactivityHeaderDoubleClick()
            }
          }}
        >
          Inactivity
        </span>
      </MouseTooltip>
      {showInactivityMenu &&
        <HeaderSubMenu
          showMenu={showInactivityMenu}
          setShowMenu={setShowInactivityMenu}
          sortBy={sortBy}
          menuOptions={INACTIVITY_SUB_OPTIONS}
          onOptionClick={handleSort}
        />
      }
    </div>
  }

  const getClockInOutHeader = (key, val) => {
    const isSortingApplied = sortBy.column === key;
    return (
      <div className="header">
        <MouseTooltip
          asChild
          delay={500}
          show={isSortingApplied}
          content={'Double click to remove sorting'}
          style={{ lineHeight: "17px" }}
        >
          <span
            className={
              isSortingApplied
                ? ' chevron clock-in-out-header' +
                (sortBy.order === 'ascending' ? ' chevron-up' : ' chevron-down')
                : ''
            }
            onClick={() => handleSortClick(key)}
            onDoubleClick={() => isSortingApplied && handleSortDoubleClick()}
          >
            {val}
          </span>
        </MouseTooltip>
      </div>
    );
  };

  const getShiftTotalHeader = () => {
    const isSortingApplied = sortBy.column === 'shift_total';
    return (
      <div className='header'>
        <MouseTooltip
          asChild
          delay={500}
          show={isSortingApplied}
          content={'Double click to remove sorting'}
          style={{ lineHeight: "17px" }}
        >
          <span
            className={
              `shift-total ${isSortingApplied ? ' chevron' + (sortBy.order === 'ascending' ? ' chevron-up' : ' chevron-down') : ''}`
            }
            onClick={() => handleSort('shift_total')}
            onDoubleClick={() => isSortingApplied && handleSortDoubleClick()}
          >
            Total Hrs
          </span>
        </MouseTooltip>
        <span className='overlap-title'>Scheduled Hrs</span>
      </div>
    );
  };

  const [selectedMemberId, setSelectedMemberId] = useState(null);
  // const selectedMemberIdRef = useRef();
  const { toggleTeamMemberFilters } = useTeamMemberFilter();

  function handleMemberSelection(resourceId) {
    if (!loadingData) {
      scrollPositionRef.current = document.querySelector(selectedMemberId ? '.users-table' : '.attendance-table-wrapper').scrollTop;
      setSelectedMemberId(selectedMemberId === resourceId ? null : resourceId);
      toggleTeamMemberFilters(selectedMemberId !== resourceId);
    }
  }

  useLayoutEffect(() => {
    if (scrollPositionRef.current !== null) {
      document.querySelector(selectedMemberId ? '.users-table' : '.attendance-table-wrapper').scrollTop = scrollPositionRef.current;
      scrollPositionRef.current = null;
    }
  }, [selectedMemberId])

  const getSkeleton = () => {
    let result = [];
    for (let i = 0; i < (tableEmptyRowsCount - 1); i++) {
      result.push(
        <TeamMemberSkeletonRow selectedMemberId={selectedMemberId} key={`skeleton_row_${i}`} />
      );
    }

    return result;
  }

  const getOfficeFloorContent = (obj, isPinnedData = false) => {
    if (loadingData && !obj) return null;
    const formattedSelectedDate = format(prevSelectedDate);

    if (obj?.[formattedSelectedDate]?.data?.length > 0) {
      const { data, meta } = obj[formattedSelectedDate];
      const tzOffset = getMyTimezoneOffset(formattedSelectedDate);

      let result = data.map((employee, index) => {
        if (employee?.isBuffer) return null;

        let special_shift_time = employee.special_shift_time;
        if (Array.isArray(special_shift_time)) {
          special_shift_time = special_shift_time.map(shift => {
            const [start_time, start_time_offset] = convertHhmmToUserTimezone(shift.special_shift_start_time.time, tzOffset);
            const [end_time, end_time_offset] = convertHhmmToUserTimezone(shift.special_shift_end_time.time, tzOffset);
            return {
              special_shift_start_time: {
                time: start_time,
                time_offset: start_time_offset,
              },
              special_shift_end_time: {
                time: end_time,
                time_offset: end_time_offset,
              }
            }
          })
        }

        const [approved_start_time, approved_start_time_offset] = convertHhmmToUserTimezone(employee.approved_start_time, tzOffset);
        const [approved_end_time, approved_end_time_offset] = convertHhmmToUserTimezone(employee.approved_end_time, tzOffset);

        const emp = {
          ...employee,
          approved_start_time,
          approved_start_time_offset,
          approved_end_time,
          approved_end_time_offset,
          expected_back_at: isNotNone(employee.expected_back_at) ? convertUTCToTimezone(employee.expected_back_at) : employee.expected_back_at,
          clock_in_time: isNotNone(employee.clock_in_time) ? convertUTCToTimezone(employee.clock_in_time) : employee.clock_in_time,
          clock_out_time: isNotNone(employee.clock_out_time) ? convertUTCToTimezone(employee.clock_out_time) : employee.clock_out_time,
          special_shift_time
        }

        const isClockedIn = isNotNone(emp.clock_in_time);
        const isClockedOut = isNotNone(emp.clock_out_time);
        const isOnLeave = emp.attendance.includes("leave");
        const isOnline = emp.status === "On";
        const hasTakenBreak = isNotNone(emp?.break_count);
        const awayTime =
          !isOnline && isClockedIn && hasTakenBreak && isNotNone(emp.expected_back_at)
            ? getAwayTime(emp.expected_back_at)
            : null;
        const isEmpPinned = pinnedUsers?.pinned_resource_ids?.split(',')?.includes(emp.resource_id.toString());

        let expected_back_at =
          emp.expected_back_at === "None"
            ? ""
            : getFormattedTimeAndDiff(emp.expected_back_at, prevSelectedDate);
        let formattedExpectedBackAt = "",
          isOverdue = false;
        if (expected_back_at) {
          let d = convertTimeZone();
          let expectedTimePlus5Mins = new Date(emp.expected_back_at).getTime();
          formattedExpectedBackAt =
            expected_back_at.time +
            (expected_back_at.diff ? ` (${expected_back_at.diff})` : "");
          isOverdue = d > expectedTimePlus5Mins;
        }

        let status = "",
          tooltip = "";
        if (isOnline) {
          // if (emp?.special_shift_activity_tracked === 0) {
          //   status = "online-working-afk";
          //   tooltip = "Working AFK";
          // } else {
          // }
          status = "online";
          tooltip = "Online";
        } else {
          if (isClockedOut) {
            status = "sign-off";
            tooltip = "Signed off";
          } else {
            // we add leave class for both leave and not clocked in
            if (isOnLeave) {
              status = isClockedIn ? "away" : "leave";
              tooltip = isClockedIn
                ? `${isOverdue ? "Was expected back" : "Back"} at ${!emp.expected_back_at || emp.expected_back_at === "None"
                  ? "unknown"
                  : formattedExpectedBackAt
                }`
                : "On Leave";
            } else {
              status = isClockedIn ? "away" : "sign-off";
              tooltip = isClockedIn
                ? `${isOverdue ? "Was expected back" : "Back"} at ${!emp.expected_back_at || emp.expected_back_at === "None"
                  ? "unknown"
                  : formattedExpectedBackAt
                }`
                : "Not started";
            }
          }
        }
        const isDarkMode = document.body.dataset.theme === "dark";

        return (
          <tr key={`${index}-${emp.resource_id}`}>
            <td
              className={`name-container ${selectedMemberId
                ? emp.resource_id === selectedMemberId
                  ? "selected"
                  : "not-selected"
                : ""
                } ${!selectedMemberId && loadingData ? "disabled" : ""}`}
              onClick={() => handleMemberSelection(emp.resource_id)}
            >
              <MouseTooltip
                asChild
                delay={500}
                content={tooltip}
                style={{
                  lineHeight: "17px", color:
                    isOverdue && isClockedIn && !isClockedOut
                      ? "#F00"
                      : isDarkMode
                        ? "#B1B2B3"
                        : "#000",
                }}
              >
                <div className={`status-container ${status}`}></div>
              </MouseTooltip>
              <UserProfileCard
                resourceId={emp.resource_id}
                isLoggedInUser={loggedInUserRef.current?.resource_id === emp.resource_id}
                user={emp}
                selectedMemberId={selectedMemberId}
              >
                <div className="icon-wrapper">
                  <div className='icon icon-anlytics' id={`user-analytics-${emp.resource_id}`} onClick={(e) => {
                    e.stopPropagation();
                    if (showAnalyticsFor === emp.resource_id) return;
                    toggleAnalytics(e, emp.resource_id);
                  }}></div>
                  {/* <div className='icon icon-smiley'></div> */}
                  <MouseTooltip
                    asChild
                    delay={500}
                    content={isEmpPinned ? "Unpin user" : "Pin user"}
                    style={{ lineHeight: "17px" }}
                  >
                    <div
                      className={`icon ${isEmpPinned ? "icon-pin" : "icon-unpin"}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePinUser(emp.resource_id, isEmpPinned);
                      }}
                    ></div>
                  </MouseTooltip>
                </div>
              </UserProfileCard>
            </td>
            {!selectedMemberId && (
              <>
                <td className="capitalize">
                  <AttendanceValue
                    userShiftDetails={userShiftDetails}
                    setUserShiftDetails={setUserShiftDetails}
                    data={emp}
                    date={prevSelectedDate} />
                </td>
                <td>
                  <ClockTimeDetails data={emp} date={prevSelectedDate} />
                </td>
                <td>
                  <div className="user-details">
                    <BreakDetails data={emp} />
                    <div
                      className={
                        "bg-gray away-time" + (awayTime < 0 ? " red" : "")
                      }
                    >
                      {awayTime !== null && !isClockedOut
                        ? formatTimeInSeconds(Math.abs(awayTime))
                        : ""}
                    </div>
                  </div>
                </td>
                <td>
                  {isNotNone(emp.inactivity_count) && (
                    <InactivityDetails data={emp} />
                  )}
                </td>
                <td>
                  <ShiftLog log={emp} />
                </td>
              </>
            )}
          </tr>
        );
      });

      if (Math.ceil(meta.num_pages) <= 1) {
        const emptyRowsCount = (tableEmptyRowsCount - 1) - meta.total_results;
        if (emptyRowsCount > 0) {
          for (let i = 0; i < emptyRowsCount; i++) {
            result.push(
              <TeamMemberSkeletonRow
                key={`skeleton-row-${i}`}
                selectedMemberId={selectedMemberId}
                handleMemberSelection={handleMemberSelection}
              />
            );
          }
        }
      }

      return result;
    } else {
      return (
        <tr>
          <td colSpan={6} className="text-center">
            No data to display
          </td>
        </tr>
      );
    }
  };

  const handlePinUser = (resourceId, isPinned) => {
    if (isPinningInProgress) {
      alertService.info('An API request is already in process, try again.');
      return;
    }
    // const callback = oldOfficeFloor => {
    //   let officeFloorCopy = structuredClone(oldOfficeFloor);
    //   const formattedSelectedDate = format(selectedDate);
    //   if (officeFloorCopy?.hasOwnProperty(formattedSelectedDate)) {
    //     officeFloorCopy[formattedSelectedDate].data = officeFloorCopy[formattedSelectedDate].data.map(user => {
    //       if (user.resource_id === resourceId) {
    //         return { ...user, is_pinned: +!user.is_pinned }
    //       } else {
    //         return user;
    //       }
    //     });
    //   }
    //   return officeFloorCopy;
    // }
    let url = `${AL_BASE_URL}/pin_resource/${resourceId}`;
    // Backup for restoring the removed data while looking at pinned users
    let pinnedDataBKP = structuredClone(pinnedOfficeFloorRef.current);
    if (showPinnedDataRef.current) {
      if (isPinned) {
        setPinnedOfficeFloor(oldOfficeFloor => {
          let officeFloorCopy = structuredClone(oldOfficeFloor);
          const formattedSelectedDate = format(prevSelectedDate);
          if (officeFloorCopy?.hasOwnProperty(formattedSelectedDate)) {
            officeFloorCopy[formattedSelectedDate].data = officeFloorCopy[formattedSelectedDate].data.filter(user => {
              return user.resource_id !== resourceId;
            });
            officeFloorCopy[formattedSelectedDate].meta.total_results -= 1;
          }
          return officeFloorCopy;
        })
        if (selectedMemberId === resourceId) setSelectedMemberId(null);
      }
      // } else {
      // setOfficeFloor(callback);
    }
    setIsPinningInProgress(true);
    APIService.apiRequest(url, null, false, isPinned ? 'DELETE' : 'PUT')
      .then(response => {
        if (response.status === 1) {
          const newPinnedResourceIds = isPinned
            ? pinnedUsers.pinned_resource_ids.split(',').filter(id => +id !== resourceId).join(',')
            : (pinnedUsers.pinned_resource_ids ? `${pinnedUsers.pinned_resource_ids},` : '') + resourceId;

          const newPinnedCount = isPinned ? pinnedUsers.pinned_count - 1 : pinnedUsers.pinned_count + 1;

          updatePinnedUsers({
            pinned_resource_ids: newPinnedResourceIds,
            pinned_count: newPinnedCount,
          });

        } else {
          alertService.error(response.msg || `Error occured while pinning user`);
          if (showPinnedDataRef.current) {
            if (isPinned) {
              setPinnedOfficeFloor(() => pinnedDataBKP);
            }
          } else {
            // setOfficeFloor(callback);
          }
        }
      })
      .catch(err => {
        alertService.error(`Error: ${err.message}`);
        console.log('handlePinUser - error', err);
      })
      .finally(() => {
        setIsPinningInProgress(false);
      })
  }

  const { width } = useWindowDimensions()
  // const isDarkMode = document.body.dataset.theme === "dark";
  // const stylingOptions = isDarkMode ? TOOLTIP_STYLING_OPTIONS_DARK : TOOLTIP_STYLING_OPTIONS_LIGHT;

  return (
    <div
      className={`attendance-table-wrapper ${selectedMemberId ? 'side-by-side-table' : ''}`}
      style={{ "--table-row-height": `${rowHeight}px` }}
    >
      {width > 640 ? (
        <>
          <div className="users-table">
            <table id="all-users-table" className="attendance-table">
              <thead>
                <tr>
                  <th
                    className={
                      'office-floor-header' + (selectedMemberId ? ' team-member-header' : '')
                    }
                  >
                    <div className="office-floor-header-inner">
                      {!selectedMemberId ? (
                        <>
                          <div className="office-floor-header-label header">
                            <span
                              className={
                                sortBy.column === 'office_floor'
                                  ? ' chevron' +
                                  (sortBy.order === 'ascending' ? ' chevron-up' : ' chevron-down')
                                  : ''
                              }
                              onClick={() => handleSort('office_floor')}
                            >
                              Office Floor
                            </span>
                          </div>
                          {pinnedUsers?.pinned_count !== null && <MouseTooltip
                            asChild
                            delay={500}
                            content={showPinnedData ? 'Show all users' : 'Show pinned users'}
                            style={{ lineHeight: "17px" }}
                          >
                            <div
                              className={
                                'icon-wrapper pinned-wrapper' + (showPinnedData ? ' active' : '')
                              }
                              onClick={() => {
                                const isFilteredData = !showPinnedData ? pinnedOfficeFloor?.[formattedSelectedDate]?.isFilteredData : officeFloor?.[formattedSelectedDate]?.isFilteredData;
                                if (isFilteredData || isFilterApplied) setShowSkeleton(true);
                                setShowPinnedData(!showPinnedData)
                              }}
                            >
                              <div className="icon icon-pin"></div>
                              <span>{pinnedUsers?.pinned_count ?? ''}</span>
                            </div>
                          </MouseTooltip>
                          }
                        </>
                      ) : (
                        <div className="office-floor-header-label">
                          <span>Team Member</span>
                        </div>
                      )}
                    </div>
                  </th>
                  {!selectedMemberId && (
                    <>
                      <th style={{ width: '100px' }}>Shift</th>
                      <th style={{ width: '190px' }}>
                        <div className="clock-info-wrapper">
                          {getClockInOutHeader('clock_in', 'Clock-In')}
                          {getClockInOutHeader('clock_out', 'Clock-Out')}
                        </div>
                      </th>
                      <th
                        style={{ width: '274px' }}
                        className={`${showBreaksMenu || showInactivityMenu ? 'open-menu' : ''}`}
                      >
                        <div className="break-header">
                          <div className="break-info">
                            {getBreaksHeader()}
                          </div>
                          <div className="away-time">
                            <span>Away</span>
                          </div>
                        </div>
                      </th>
                      <th
                        style={{ width: '125px' }}
                        className={`header ${showBreaksMenu || showInactivityMenu ? 'open-menu' : ''
                          }`}
                      >
                        {getInactivityHeader()}
                      </th>
                      <th style={{ width: '206px', minWidth: '125px' }}>
                        <ShiftTotalHeader>
                          {getShiftTotalHeader()}
                        </ShiftTotalHeader>
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {(showPinnedData ?
                  !pinnedOfficeFloor || (loadingFirstPageData && showSkeleton) :
                  !officeFloor || (loadingFirstPageData && showSkeleton)) && getSkeleton()}
                {!showPinnedData && (officeFloor && (loadingFirstPageData ? !showSkeleton : true)) && (
                  <SuperPager
                    type="infiniteScroll"
                    isTable={true}
                    dataLength={
                      loadingData ? 0 : officeFloor[formattedSelectedDate]?.data?.length || null
                    }
                    loadMore={() =>
                      getOfficeFloorData({
                        startingPoint: officeFloor[formattedSelectedDate]?.data?.length,
                      })
                    }
                    hasMore={
                      officeFloor[formattedSelectedDate]?.data.length <
                      officeFloor[formattedSelectedDate]?.meta.total_results
                    }
                    wrapper={true}
                    children={getOfficeFloorContent(officeFloor)}
                  />
                )}
                {showPinnedData && (pinnedOfficeFloor && (loadingFirstPageData ? !showSkeleton : true)) && (
                  <SuperPager
                    type="infiniteScroll"
                    isTable={true}
                    dataLength={pinnedOfficeFloor[formattedSelectedDate]?.data?.length || null}
                    loadMore={() =>
                      getOfficeFloorData({
                        startingPoint: pinnedOfficeFloor[formattedSelectedDate]?.data?.length,
                      })
                    }
                    hasMore={
                      pinnedOfficeFloor[formattedSelectedDate]?.data.length <
                      pinnedOfficeFloor[formattedSelectedDate]?.meta.total_results
                    }
                    wrapper={true}
                    children={getOfficeFloorContent(pinnedOfficeFloor, true)}
                  />
                )}
              </tbody>
            </table>
          </div>
          {selectedMemberId && (
            <TeamMemberAttendanceTable
              isLoggedInUser={loggedInUserRef.current?.resource_id === selectedMemberId}
              resourceId={selectedMemberId}
              showLineLoader={showLineLoader}
              setShowLineLoader={setShowLineLoader}
              isTimerStarted={isTimerStartedRef.current}
              selectedDate={selectedDate}
              userShiftDetails={userShiftDetails}
              setUserShiftDetails={setUserShiftDetails}
            />
          )}
        </>
      ) : (
        <div className="team-members">
          {officeFloor ? (
            officeFloor[formattedSelectedDate]?.data?.length > 0 ? <SuperPager
              type="infiniteScroll"
              dataLength={officeFloor[formattedSelectedDate]?.data?.length || null}
              loadMore={() =>
                getOfficeFloorData({
                  startingPoint: officeFloor[formattedSelectedDate]?.data?.length,
                })
              }
              hasMore={
                officeFloor[formattedSelectedDate]?.data.length <
                officeFloor[formattedSelectedDate]?.meta.total_results
              }
              wrapper={true}
              children={officeFloor?.[format(prevSelectedDate)]?.data?.map((employee, index) => {
                let special_shift_time = employee.special_shift_time;
                if (Array.isArray(special_shift_time)) {
                  const tzOffset = getMyTimezoneOffset(formattedSelectedDate);
                  special_shift_time = special_shift_time.map(shift => {
                    const [start_time, start_time_offset] = convertHhmmToUserTimezone(shift.special_shift_start_time.time, tzOffset);
                    const [end_time, end_time_offset] = convertHhmmToUserTimezone(shift.special_shift_end_time.time, tzOffset);
                    return {
                      special_shift_start_time: {
                        time: start_time,
                        time_offset: start_time_offset,
                      },
                      special_shift_end_time: {
                        time: end_time,
                        time_offset: end_time_offset,
                      }
                    }
                  })
                }

                const emp = {
                  ...employee,
                  expected_back_at: isNotNone(employee.expected_back_at) ? convertUTCToTimezone(employee.expected_back_at) : employee.expected_back_at,
                  clock_in_time: isNotNone(employee.clock_in_time) ? convertUTCToTimezone(employee.clock_in_time) : employee.clock_in_time,
                  clock_out_time: isNotNone(employee.clock_out_time) ? convertUTCToTimezone(employee.clock_out_time) : employee.clock_out_time,
                  special_shift_time
                }

                return (
                  <TeamMemberCard
                    key={`${index}-${emp.resource_id}`}
                    emp={emp}
                    loggedInUser={loggedInUserRef.current}
                    prevSelectedDate={prevSelectedDate}
                    expandedCard={expandedCard}
                    setExpandedCard={setExpandedCard}
                    showUserDetails={showUserDetails}
                    setShowUserDetails={setShowUserDetails}
                  />
                );
              })}
            /> : <div className='no-data'>
              <span>No Data Available</span>
              <span>Check Filters</span>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

function TeamMemberSkeletonRow({ selectedMemberId, handleMemberSelection }) {
  return (
    <tr>
      <td className={`name-container empty-cell ${selectedMemberId ? 'not-selected' : ''}`}
        onClick={() => selectedMemberId && handleMemberSelection(selectedMemberId)}
      ></td>
      {!selectedMemberId && (
        <>
          <td className="capitalize"></td>
          <td>
            <div className="clock-info-wrapper">
              <div className="bg-gray clock-info"></div>
              <div className="bg-gray clock-info"></div>
            </div>
          </td>
          <td>
            <div className="user-details">
              <div className="user-details break-details"></div>
              <div className="bg-gray away-time"></div>
            </div>
          </td>
          <td></td>
          <td>
            <SkeletonShiftLog />
          </td>
        </>
      )}
    </tr>
  );
}

export default AttendanceTable