import React, { useEffect, useRef, useState } from "react";
import SuperPager from "super-pager";

// components
import * as Constants from "components/Constants";
import ClickOutsideListner from "components/ClickOutsideListner";
import Avatar from "components/Avatar";

// services
import APIService from "services/apiService";
import { alertService } from "services/alertService";
import { debounce, getUser } from "utils/Common";
import LineLoader from "components/LineLoader";

const INITIAL_PAGINATION_META = {
  num_pages: 0,
  page_number: 1,
  page_size: 20, // always the page size is 20
  total_results: 0,
};

export default function AddCandidateModal({
  funnelId,
  funnel,
  toggle,
  onAddNewCandidate,
}) {
  const user = getUser();
  const [searchText, setSearchText] = useState("");
  const [loadingCandidates, setLoadingCandidates] = useState(true);
  const [addCandidateInProgress, setAddCandidateInProgress] = useState(false);

  const abortController = useRef(new AbortController());

  function resetAbortController() {
    abortController.current.abort();
    abortController.current = new AbortController();
  }

  useEffect(() => () => abortController.current.abort(), []);

  const candidatesWrapperDOMRef = useRef();

  // create page size state
  const [pageSize, setPageSize] = useState(INITIAL_PAGINATION_META.page_size);

  // Overall candidates list
  const [candidates, setCandidates] = useState([]);
  const [candidatesPaginationMeta, setCandidatesPaginationMeta] = useState(
    () => INITIAL_PAGINATION_META
  );

  // Candidates which are fetched by the user search input
  const [searchCandidates, setSearchCandidates] = useState([]);
  const [showSearchCandidates, setShowSearchCandidates] = useState(false);
  const [searchCandidatesPaginationMeta, setSearchCandidatesPaginationMeta] =
    useState(() => INITIAL_PAGINATION_META);

  // candidates api function to fetch candidates list
  const getCandidateList = async ({
    isSearch = false,
    flush = false,
    search = searchText,
    page_number = 1,
    page_size = pageSize,
    controller = abortController.current,
  } = {}) => {
    try {
      setLoadingCandidates(true);

      const url = new URL(
        `${Constants.API_BASE_URL}/recruiter/eligible_candidates?id=${funnelId}`
      );

      if (isSearch) {
        url.searchParams.set("search_string", search);
      }

      url.searchParams.set("page_size", page_size);
      url.searchParams.set("page_number", page_number);

      const apiPayLoad = null;
      const response = await APIService.apiRequest(
        url.toString(),
        apiPayLoad,
        false,
        "GET",
        controller
      );

      if (response.status === 1) {
        //  If the user is searching for candidates then we need to update the search candidates list
        // else we need to update the overall candidates list
        if (isSearch) {
          setSearchCandidatesPaginationMeta(() => response.output.meta);
          setShowSearchCandidates(true);
          setSearchCandidates((prev) => {
            if (flush) {
              return response.output.data;
            }
            return [...prev, ...response.output.data];
          });
        } else {
          setCandidatesPaginationMeta(() => response.output.meta);
          setShowSearchCandidates(false);
          setCandidates((prev) => [...prev, ...response.output.data]);
        }
      }

      setLoadingCandidates(false);
    } catch (error) {
      setLoadingCandidates(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const CANDIDATE_BOX_HEIGHT = 60;

    let page_size = INITIAL_PAGINATION_META.page_size;
    const candidatesWrapperEl = candidatesWrapperDOMRef.current;

    if (candidatesWrapperEl) {
      const candidatesWrapperHeight = candidatesWrapperEl.clientHeight;
      const maxCandidates = Math.floor(
        candidatesWrapperHeight / CANDIDATE_BOX_HEIGHT
      );
      // +2 for the infinite scroll
      page_size = maxCandidates + 2;
    }

    setPageSize(page_size);
    getCandidateList({ page_size });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchInputChange = debounce((value) => {
    resetAbortController();
    setSearchText(value);

    if (!value) {
      setSearchCandidatesPaginationMeta(() => ({
        ...INITIAL_PAGINATION_META,
        page_size: pageSize,
      }));
      setShowSearchCandidates(false);
      setSearchCandidates([]);
    } else {
      getCandidateList({ isSearch: true, search: value, flush: true });
    }
  }, 450);

  const handleAddCandidate = async (candidate) => {
    const candidateId = candidate.id;
    // const isAlreadyAddedToAnyFunnel = ('organization_id' in candidate ? `${candidate.organization_id}` === `${user.organization_id}` : true) && candidate.current_funnel;
    const isAlreadyAddedToAnyFunnel = candidate.current_funnel;
    if (isAlreadyAddedToAnyFunnel) {
      alertService.warning(
        `Candidate is already added in '${candidate.current_funnel}' funnel`
      );
      return;
    }

    try {
      setAddCandidateInProgress(true);

      const funnelFirstStage = funnel.stages.find((stage) => stage.order === 1);

      const apiPayLoad = {
        resource_id: candidateId,
        recruitment_funnel_stage_id: funnelFirstStage.id,
      };

      const response = await APIService.apiRequest(
        Constants.API_BASE_URL + "/recruiter/resource_stage",
        apiPayLoad,
        false,
        "PUT"
      );

      if (response.status === 1) {
        const filterCandidatesCallbackFn = (candidates) => {
          return candidates.filter((candidate) => candidate.id !== candidateId);
        };

        if (showSearchCandidates) {
          setSearchCandidates(filterCandidatesCallbackFn);
        } else {
          setCandidates(filterCandidatesCallbackFn);
        }

        alertService.success(`Success: ${response.msg}`);
        onAddNewCandidate(response.output[0]);
      }
    } catch (error) {
      alertService.error("Error: " + error.msg);
      console.log("handleStageChange error", error);
    } finally {
      setAddCandidateInProgress(false);
    }
  };

  function getSearchCandidatesContent() {
    if (searchCandidates.length > 0) {
      return searchCandidates.map((candidate, index) => {
        return (
          <Candidate
            key={candidate.id + "-" + index}
            candidate={candidate}
            handleAddCandidate={handleAddCandidate}
            addCandidateInProgress={addCandidateInProgress}
            user={user}
          />
        );
      });
    }

    if (searchCandidates.length === 0 && !loadingCandidates) {
      return <NotFound message="Nothing matches your search" />
    }

    return null;
  }

  function getCandidatesContent() {
    if (candidates.length > 0) {
      return candidates.map((candidate, index) => {
        return (
          <Candidate
            key={candidate.id + "-" + index}
            candidate={candidate}
            handleAddCandidate={handleAddCandidate}
            addCandidateInProgress={addCandidateInProgress}
            user={user}
          />
        );
      });
    }

    if (candidates.length === 0 && !loadingCandidates) {
      return <NotFound message="No data to display" />
    }

    return null;
  }

  const showTitle = showSearchCandidates
    ? searchCandidates.length > 0
    : candidates.length > 0;

  return (
    <ClickOutsideListner onOutsideClick={toggle}>
      <div className="console-panel-wrapper add-candidate-wrapper">
        <div className="console-header-wrapper">
          <div className="header-title">
            <h4>Add Candidate</h4>
            <button className="btn-close-panel" onClick={toggle}></button>
          </div>
        </div>
        <div className="loader-wrapper">
          <LineLoader show={loadingCandidates} position="absolute" />
        </div>
        <div className="console-content-wrapper" ref={candidatesWrapperDOMRef}>
          <div className="add-candid-search-wrapper">
            <input
              type="search"
              id="search"
              name="candid-search"
              className="search"
              placeholder="Search"
              defaultValue={searchText}
              onChange={(e) => handleSearchInputChange(e.target.value.trim())}
            />
            {/* <button
              type="button"
              className="btn btn-search"
              title="Search for a candidate"
            // onClick={handleCandidateSearch}
            >
              Search
            </button> */}
          </div>
          <div className="recent-candidates">
            {/* Title */}
            {showTitle && (
              <div className="recent-title">
                {showSearchCandidates ? "Search Results" : "Recent Candidates"}
              </div>
            )}

            <div>
              {/* Search Candidates list */}
              {showSearchCandidates && (
                <SuperPager
                  type="infiniteScroll"
                  isTable={false}
                  dataLength={searchCandidates.length}
                  loadMore={() =>
                    getCandidateList({
                      isSearch: true,
                      page_number:
                        searchCandidatesPaginationMeta.page_number + 1,
                      page_size: candidatesPaginationMeta.page_size,
                    })
                  }
                  hasMore={
                    searchCandidatesPaginationMeta.page_number <
                    searchCandidatesPaginationMeta.num_pages
                  }
                  wrapper={true}
                  children={getSearchCandidatesContent()}
                />
              )}

              {/* Candidates list if there is no search candidates */}
              {!showSearchCandidates && (
                <SuperPager
                  type="infiniteScroll"
                  isTable={false}
                  dataLength={candidates.length}
                  loadMore={() =>
                    getCandidateList({
                      page_number: candidatesPaginationMeta.page_number + 1,
                      page_size: candidatesPaginationMeta.page_size,
                    })
                  }
                  hasMore={
                    candidatesPaginationMeta.page_number <
                    candidatesPaginationMeta.num_pages
                  }
                  wrapper={true}
                  children={getCandidatesContent()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </ClickOutsideListner>
  );
}

function Candidate({ candidate, handleAddCandidate, addCandidateInProgress, user }) {
  // const isAlreadyAddedToAnyFunnel = ('organization_id' in candidate ? `${candidate.organization_id}` === `${user.organization_id}` : true) && candidate.current_funnel;
  const isAlreadyAddedToAnyFunnel = candidate.current_funnel;

  return (
    <div
      className={"result-listing" + (isAlreadyAddedToAnyFunnel ? " gray" : "")}
    >
      <div className="thumb">
        <Avatar
          imgSrc={candidate.img_url}
          alt={`${candidate.first_name}'s pic`}
          firstName={candidate.first_name}
          lastName={candidate.last_name}
          height={28}
          width={28}
          fontSize={12}
        />
      </div>
      <div className="profile-details">
        <h4>{`${candidate.first_name} ${candidate.last_name}`}</h4>
        <div className="info">
          <div className="label">Record ID&nbsp;:&nbsp;</div>
          <div className="value">{candidate.id}</div>
          <span>&nbsp; • &nbsp;</span>
          <div className="label">Current Funnel&nbsp;:&nbsp;</div>
          <div className="value nowrap" title={candidate.current_funnel}>
            {isAlreadyAddedToAnyFunnel ? candidate.current_funnel : "Null"}
          </div>
        </div>
        <button
          type="button"
          className="btn btn-add btn-small"
          disabled={addCandidateInProgress}
          onClick={() =>
            handleAddCandidate(candidate)
          }
        >
          ADD
        </button>
      </div>
    </div>
  );
}

function NotFound({ message }) {
  return (
    <small
      style={{
        textAlign: "center",
        display: "block",
        width: "100%",
        marginTop: '25px'
      }}
    >
      {message}
    </small>
  )
}