import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Header from "./components/Header"
import BasicFormComp from "./components/BasicFormComp";
import Questionnaire from "./components/Questionnaire"
import NotFound from "./components/NotFound";
import Oops from "./components/Oops";
import { JobInfoProvider } from './context';
import "./Forms.scss"

function Forms() {
  return (
    <div className="forms-wrapper">
      <Header />
      <main>
        <JobInfoProvider>
          <Router>
            <Switch>
              <Route exact path="/not-found" component={NotFound} />
              <Route exact path="/oops" component={Oops} />
              <Route exact path="/:id" component={BasicFormComp} />
              <Route exact path="/:id/:uniqueId" component={Questionnaire} />
              <Redirect to="/not-found" />
            </Switch>
          </Router>
        </JobInfoProvider>
      </main>
    </div>
  )
}

export default Forms;