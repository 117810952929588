import { useEffect, useState } from 'react';

// services
import APIService from 'services/apiService';
import { alertService } from 'services/alertService';

// components
import Panel from 'components/Panel';
import LineLoader from 'components/LineLoader';

// hooks
import useUserData from 'hooks/useUserData';

// constants
import { AL_BASE_URL } from '../constants';

// styles
import './UserAnalytics.scss';

const UserAnalytics = ({ id, isLoggedUser, toggleAnalytics }) => {
    const { user, isManager } = useUserData();
    const [showLoader, setShowLoader] = useState(true);
    const [leaveDetails, setLeaveDetails] = useState(null);

    useEffect(() => {
        const controller = new AbortController();
        const fetchLeaveDetails = async () => {
            try {
                setShowLoader(true);
                const response = await APIService.apiRequest(
                    AL_BASE_URL + `/resource_analytics/?resource_id=${id}&org_id=${user.organization_id}`,
                    null,
                    false,
                    "GET",
                    controller
                );

                if (response.status === 1) {
                    setLeaveDetails(response);
                } else {
                    if (response?.message?.includes('aborted')) return;
                    alertService.error(response.msg);
                }
                setShowLoader(false);
            } catch (err) {
                setShowLoader(false);
                console.log('fetchLeaveDetails catch', err);
                alertService.error(err.message);
            }
        };

        fetchLeaveDetails();
        return () => controller.abort();
    }, [id]);

    const maxPaidLeaves = leaveDetails?.leaves?.max_paid_leaves
    const totalLeaves = leaveDetails?.leaves?.total_leaves;
    let paidLeaves = 0;
    let unpaidLeaves = 0;
    if (maxPaidLeaves != null) {
        if (totalLeaves > maxPaidLeaves) {
            paidLeaves = maxPaidLeaves;
            unpaidLeaves = totalLeaves - maxPaidLeaves;
        } else {
            paidLeaves = totalLeaves
        }
    }

    return (
        <Panel
            isOpen={!!id}
            onClose={toggleAnalytics}
            width={600}
            id={`user-analytics-${id}`}
            top={`calc(var(--header-height-other-tab) + 2px)`}
        >
            <div className='attendance-modal user-analytics-wrapper'>
                <LineLoader
                    show={showLoader}
                    position="absolute"
                />
                <div className='requests-header'>
                    <div className='title'>Analytics</div>
                </div>
                <div className='requests-body'>
                    {/* {!showLoader && leaveDetails ? ( */}
                    {!showLoader && leaveDetails ? (
                        <div className='leave-card-wrapper'>
                            <div className='leave-card-header'>
                                <div className='leave-card-title'>Total Leaves</div>
                                <div className='leave-card-value'>{totalLeaves}</div>
                            </div>
                            {maxPaidLeaves != null && (isLoggedUser || isManager) ? (
                                <div className='leave-card-compensation'>
                                    <div className='leave-paid'>
                                        <div className='leave-card-title'>Paid</div>
                                        <div className='leave-card-value'>{paidLeaves} / {maxPaidLeaves}</div>
                                    </div>
                                    <div className='leave-unpaid'>
                                        <div className='leave-card-title'>Unpaid</div>
                                        <div className='leave-card-value'>{unpaidLeaves}</div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </Panel>
    )
}

export default UserAnalytics