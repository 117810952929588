import React, { useCallback, useState, useEffect } from 'react';
import { getTimeDiff, formatTime } from '../timerUtils';

function BreakDiff({ timeStamp, timeInMinutes }) {
    const calculateTimeDifference = useCallback(() => {
        return getTimeDiff(new Date(timeStamp), timeInMinutes);
    }, [timeStamp, timeInMinutes]);
    const [timeDifference, setTimeDifference] = useState(calculateTimeDifference());

    useEffect(() => {
        let requestID = null;
        function updateDifference() {
            setTimeDifference(calculateTimeDifference);
            requestID = requestAnimationFrame(updateDifference);
        }
        requestID = requestAnimationFrame(updateDifference);
        return () => {
            cancelAnimationFrame(requestID); // Cancel the animation frame on unmount
        };
    }, [calculateTimeDifference]);

    return (
        <span className={'bold-value' + (timeDifference < 0 ? ' red' : '')}>
            {formatTime(Math.abs(timeDifference))}
        </span>
    );
}

export default BreakDiff;
