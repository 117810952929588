import { useEffect, useRef, useLayoutEffect } from "react"

/**
 * @typedef {keyof MediaQueryListEventMap | keyof WindowEventMap | keyof DocumentEventMap | keyof HTMLElementEventMap} EventName
 */

/**
 * @template {EventName} K
 * @param {K} eventName 
 * @param {(e: MediaQueryListEventMap<K> | WindowEventMap<K> | DocumentEventMap<K> | HTMLElementEventMap<K>) => void} handler 
 * @param {import("react").RefObject<HTMLElement>} element 
 * @param {boolean | AddEventListenerOptions} [options] 
 */
export function useEventListener(eventName, handler, element, options) {
  // Create a ref that stores handler
  const savedHandler = useRef(handler)

  useLayoutEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    // Define the listening target
    const targetElement = element?.current ?? window

    if (!(targetElement && targetElement.addEventListener)) return

    // Create event listener that calls handler function stored in ref
    const listener = event => savedHandler.current(event)

    targetElement.addEventListener(eventName, listener, options)

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, listener, options)
    }
  }, [eventName, element, options])
}
