import { useEffect, useLayoutEffect, useRef, useState, } from 'react';

// components
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Avatar from 'components/Avatar';
import TabStack from './TabStack';
import { MouseTooltip } from 'components/ToolTip';

// contexts
import { useTabContext } from 'components/SuperTabs/TabContext';

// services
import EventEmitter from "services/eventEmitter";

// utils and constants
import { hasPrivilege, isDev, removeForwardSlashAtEnd, reorder, isChildPath, homeTab, getTextWidth, calculateWidth, compareDecimals, reorderSubTabs, getCssUnitDifference, getTitle } from './Utils';
import { transitionDuration, transitionDurationExtended, MarginLeft, fontStyle, } from './Constants';

// styles
import './SuperTabs.scss';

export const emitter = new EventEmitter();

const filterSubTabs = (subTabs) => {
    // const subTabsInList = [];
    const subTabsInNavbar = [];
    subTabs.forEach(subTab => {
        if (subTab.order !== 0) {
            subTabsInNavbar.push(subTab);
            // } else {
            //     subTabsInList.push(subTab);
        }
    });
    return { subTabsInNavbar };
    // return { subTabsInList, subTabsInNavbar };
};

// function calculateTransitionDuration(totalWidth, avgWidth = maxTabWidth) {
//     // return (totalWidth / avgWidth) * 100;
//     // return (totalWidth / transitionDurationExtended) * avgWidth;
//     return (totalWidth / avgWidth) * transitionDurationExtended;
// }

const SuperTabs = ({ Suffix }) => {

    const draggingTimeOutRef = useRef(null);
    const timeOutRef = useRef(null);
    const addButtonCallBackRefs = useRef({});
    const transitionTimeOutRef = useRef(null);

    const {
        lastOpenedUrl,
        SITE_PAGES, SITE_PREFIX,
        tabs, updateTabs,
        closeTab, reorderTabs,
        activeTab, activeTabRef, updateActiveTab,
        activeSubTab, updateActiveSubTab,
        subTabWrapperWidth, setSubTabWrapperWidth,
        expandedTabID, updateExpandedID, expandedTabIDRef,
        updateCurrentTab,
        subTabChange, updateSubTabChange,
        superTabsWrapperElemRef,
        maxWidth, updateMaxWidth, resetMaxWidth,
        user,
    } = useTabContext();

    const history = useHistory();
    const location = useLocation();

    const [isSubTabCollapsing, setIsSubTabCollapsing] = useState(false);

    const [closingTabID, setClosingTabID] = useState(null);
    // const [lastOpenedSubTabs, setLastOpenedSubTabs] = useState({});

    useEffect(() => {
        const unsub = emitter.subscribe('bindCallback', ({ id, callback }) => addButtonCallBackRefs.current[id] = callback);
        return unsub
    }, [activeTab]);

    useLayoutEffect(() => {
        if (subTabChange && !expandedTabID) {
            if (!subTabChange?.oldTab || subTabChange?.newTab?.id === subTabChange?.oldTab?.id) return;
            const openingTab = document.querySelector(`#tab-${subTabChange.parentTabId}-${subTabChange.newTab.id.toString().replace('/', '-')}`);
            const closingTab = document.querySelector(`#tab-${subTabChange.parentTabId}-${subTabChange.oldTab.id.toString().replace('/', '-')}`);
            const subTabGroup = document.querySelector(`.sub-tabs-wrapper.tab-${subTabChange.parentTabId}`);
            const openingTabWidth = openingTab.style.width;
            const closingTabWidth = calculateWidth({ text: getTitle(subTabChange.oldTab), hasAvatar: subTabChange.oldTab.showAvatar, addMargin: true });
            const subTabGroupWidth = `${subTabGroup.scrollWidth}px`;
            const subTabGroupUpdatedWidth = getCssUnitDifference(openingTabWidth, `-42px`);

            // const isNewTabSmaller = getCssUnitDifference(openingTabWidth, `${closingTabWidth}px`).includes('-');
            // setSubTabWrapperWidth(subTabGroupUpdatedWidth)

            if (closingTab) {
                closingTab.style.width = `${closingTabWidth}px`;
                closingTab.style.marginLeft = `${MarginLeft}px`;
                closingTab.classList.add('slide-out');
            }

            subTabGroup.style.width = subTabGroupWidth;
            subTabGroup.style.transition = `width ${transitionDuration}ms`;

            openingTab.style.width = `0px`;
            openingTab.style.marginLeft = `0px`;
            openingTab.style.transition = `opacity 50ms`;
            openingTab.style.opacity = `0`;

            setTimeout(() => {
                if (closingTab) {
                    closingTab.style.opacity = `0`;
                    closingTab.style.width = `0px`;
                    closingTab.style.marginLeft = `0px`;
                }

                // openingTab.classList.add('fade-in');
                openingTab.style.width = openingTabWidth;
                openingTab.style.marginLeft = `${MarginLeft}px`;

                // if (!isNewTabSmaller) subTabGroup.style.width = subTabGroupUpdatedWidth;
                // if (!isNewTabSmaller) setSubTabWrapperWidth(subTabGroupUpdatedWidth);
                setSubTabWrapperWidth(prev => ({ ...prev, [user.organization_id + '_' + activeTabRef.current.id]: subTabGroupUpdatedWidth }));
                subTabGroup.style.width = subTabGroupUpdatedWidth;
                setTimeout(() => {
                    // subTabGroup.style.removeProperty('width');
                    openingTab.style.opacity = `1`;
                    subTabGroup.style.removeProperty('transition');
                    // openingTab.style.removeProperty('transition');
                    // openingTab.style.removeProperty('opacity');
                    setTimeout(() => {
                        if (closingTab) closingTab.classList.remove('slide-out');
                        // if (isNewTabSmaller) subTabGroup.style.width = subTabGroupUpdatedWidth;
                        // if (isNewTabSmaller) setSubTabWrapperWidth(subTabGroupUpdatedWidth);
                        // if (isNewTabSmaller) {
                        //     setSubTabWrapperWidth(prev => ({ ...prev, [activeTabRef.current.id]: subTabGroupUpdatedWidth }));
                        //     subTabGroup.style.width = subTabGroupUpdatedWidth;
                        // }

                        setTimeout(() => {
                            // if (isNewTabSmaller) subTabGroup.style.removeProperty('transition');
                            updateSubTabChange(null);
                        }, transitionDuration);

                        // subTabGroup.style.removeProperty('width');

                        // openingTab.style.removeProperty('width');
                        // openingTab.style.removeProperty('margin-left');
                        openingTab.style.removeProperty('transition');
                        openingTab.style.removeProperty('opacity');
                    }, transitionDuration);
                    // parentSuperTab.style.removeProperty('width');
                    // subTabGroup.style.width = 'unset';
                    // parentSuperTab.style.width = 'unset';
                    // openingTab.classList.remove('fade-in');
                }, transitionDuration);
            }, transitionDuration);
        }

    }, [expandedTabID, subTabChange, user.organization_id]);

    // useEffect(() => {
    //     const openedPage = SITE_PAGES.find(page => page.url === location.pathname);
    //     if (openedPage?.containsSubTabs) {
    //         toggleSubTabs(openedPage);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [SITE_PAGES]);

    useEffect(() => {
        SITE_PAGES.forEach((page) => {
            // const pageUrl = removeAfterLastSlashIfEndsWithHome(page.url);
            // const locationPathname = removeAfterLastSlashIfEndsWithHome(location.pathname);

            // console.log('pageUrl === locationPathname', page.url, location.pathname, pageUrl, locationPathname);

            // if (pageUrl === locationPathname) {
            //     updateActiveTab(tabs.filter(tab => tab.url === page.url)[0]);
            // } else {
            //     console.log('locationPathname else');
            // }

            if (page.url === location.pathname) {
                updateTabs((prev) => {
                    let newTabs = structuredClone(prev);
                    const openedTabIndex = newTabs.findIndex((tab) => tab.url === page.url);
                    if (openedTabIndex !== -1) {
                        // Page is already opened, update the lastOpened flag
                        newTabs = newTabs.map((tab, index) => ({
                            ...tab,
                            lastOpened: index === openedTabIndex,
                        }));

                        return newTabs;
                    }

                    for (let i = 0; i < newTabs.length; i++) {
                        newTabs[i].lastOpened = false;
                    }

                    newTabs.push({ ...page, subTabs: [], lastOpened: true });
                    return newTabs;
                });
            }
        });
        return () => {
            if (draggingTimeOutRef.current) clearTimeout(draggingTimeOutRef.current);
        }
    }, [SITE_PAGES, location.pathname, updateTabs]);

    useEffect(() => {
        return () => {
            clearTimeout(timeOutRef.current);
            clearTimeout(transitionTimeOutRef.current);
        }
    }, [])

    const handleDragStart = () => {
        const superTabsWrapperElem = document.querySelector('.super-tabs-wrapper');
        if (superTabsWrapperElem) superTabsWrapperElem.classList.add('dragging-in-progress');
        const tabsWithChildWrapperElem = document.querySelector('.tab-with-child-wrapper');
        if (tabsWithChildWrapperElem) tabsWithChildWrapperElem.classList.add('dragging-in-progress');
    }
    const handleDragEnd = (res) => {
        const { destination, source } = res;

        draggingTimeOutRef.current = setTimeout(() => {
            const superTabsWrapperElem = document.querySelector('.super-tabs-wrapper');
            if (superTabsWrapperElem) superTabsWrapperElem.classList.remove('dragging-in-progress');
        }, transitionDuration);
        const tabsWithChildWrapperElem = document.querySelector('.tab-with-child-wrapper');
        if (tabsWithChildWrapperElem) tabsWithChildWrapperElem.classList.remove('dragging-in-progress');

        if (!destination) return
        if (destination.index === source.index) return;

        reorderTabs({ tab: tabs[source.index], order: destination.index + 1 })

        updateTabs((prev) => {
            const updatedTabs = reorder(
                prev,
                source.index,
                destination.index
            );
            return updatedTabs;
        });
    }
    const handleSubTabDragStart = () => {
        const subTabsWrapperElem = document.querySelector('.sub-tabs-wrapper.expanded');
        if (subTabsWrapperElem) subTabsWrapperElem.classList.add('dragging-in-progress');
    }
    const handleSubTabDragEnd = (res, parentId) => {
        draggingTimeOutRef.current = setTimeout(() => {
            const subTabsWrapperElem = document.querySelector('.sub-tabs-wrapper.expanded');
            if (subTabsWrapperElem) subTabsWrapperElem.classList.remove('dragging-in-progress');
        }, transitionDuration);
        const { destination, source } = res;

        if (!destination) return
        if (destination.index === source.index) return;

        const parentIndex = tabs.findIndex(tab => tab.id === parentId);
        reorderTabs({ tab: tabs[parentIndex].subTabs.filter(t => t.order !== 0)[source.index], order: destination.index + 1 })

        updateTabs((prev) => {
            const parentIndex = prev.findIndex((tab) => tab.id === activeTab.id);
            const updatedTabs = reorderSubTabs(
                prev[parentIndex].subTabs,
                source.index,
                destination.index
            );
            prev[parentIndex].subTabs = updatedTabs;
            return [...prev];
        });
    }

    const handleTabClose = ({ tab, tabIndex, isSubTab, parentIndex } = {}) => {

        closeTab({ tab, isSubTab });
        updateSubTabChange(null);
        let appId = !isSubTab ? tab.id : tabs[parentIndex].id;
        emitter.emit('superTabClose', { appId, tab, isSubTab });

        // const id = !isSubTab ? tab.id : activeTab.id;
        if (isSubTab ? tabs[parentIndex].subTabs.length <= 2 : tab.id === expandedTabID) {
            updateExpandedID(null);
        }

        // if (lastOpenedSubTabs.hasOwnProperty(appId)) {
        //     setLastOpenedSubTabs((prev) => {
        //         const updated = { ...prev };
        //         if (lastOpenedSubTabs[appId]?.id === tab.id) {
        //             updated[appId] = null;
        //         }
        //         return updated;
        //     });
        // }

        if (!isSubTab) {
            if (activeSubTab.url && isChildPath(tab.url, activeSubTab.url)) {
                updateActiveSubTab({});
            }
        } else {
            setSubTabWrapperWidth((prev) => {
                const updated = { ...prev };
                delete updated[user.organization_id + '_' + appId];
                return updated;
            });
        }
    }

    const toggleSubTabs = (tab, subTabs) => {
        clearTimeout(timeOutRef.current);
        const id = tab.id;
        const isTabExpanded = expandedTabID === id;
        if (isTabExpanded) {
            setIsSubTabCollapsing(true);
            transitionTimeOutRef.current = setTimeout(() => {
                superTabsWrapperElemRef.current.style.setProperty('--transition-duration-extended', `${transitionDuration}ms`);
                setIsSubTabCollapsing(false);
            }, 1000);
            setTimeout(() => {
                resetMaxWidth(null);
            }, 200);
        } else {
            superTabsWrapperElemRef.current.style.setProperty('--transition-duration-extended', `${transitionDurationExtended}ms`);
            // expandedTabIDRef.current = id;
            updateMaxWidth();
            // let parentTabsWidth = HomeTabWidth;
            // document.querySelectorAll('.parent-super-tab').forEach((elem) => {
            //     parentTabsWidth += (elem.offsetWidth + MainTabGap);
            // });
            // console.log('parentTabsWidth', parentTabsWidth);

        }
        if (isTabExpanded) {
            // document.querySelectorAll('.sub-tabs-wrapper.expanded .sub-tab-group .sub-tab:not(.active, .show)').forEach((elem) => {
            document.querySelectorAll(`.tab-${id}.sub-tabs-wrapper .sub-tab-group .sub-tab:not(.active, .show)`).forEach((elem) => {
                elem.classList.add('hide-sub-tab');
            });
            timeOutRef.current = setTimeout(() => {
                // document.querySelectorAll('.sub-tabs-wrapper.expanded .sub-tab-group .sub-tab:not(.active, .show)').forEach((elem) => {
                document.querySelectorAll(`.tab-${id}.sub-tabs-wrapper .sub-tab-group .sub-tab:not(.active, .show)`).forEach((elem) => {
                    elem.classList.remove('hide-sub-tab');
                });
                document.querySelector('.super-tabs-wrapper').classList.remove('nested-tabs-visible');
                updateExpandedID(null);
            }, 200);
        } else {

            // let subTabsWidth = 0;
            // tab?.subTabs?.forEach(subTab => {
            //     // if (openedSubTab?.id !== subTab.id) {
            //     //     const title = (subTab.title || subTab.name || (subTab?.firstName ? `${subTab.firstName} ${subTab.lastName || ''}` : 'Untitled')).trim();
            //     //     subTabsWidth += calculateWidth(title, subTab.showAvatar);
            //     // }
            //     const title = (subTab.title || subTab.name || (subTab?.firstName ? `${subTab.firstName} ${subTab.lastName || ''}` : 'Untitled')).trim();
            //     subTabsWidth += calculateWidth({ text: title, hasAvatar: subTab.showAvatar, addMargin: true });
            // })
            // const transitionDuration = subTabsWidth > 0 ? calculateTransitionDuration(Math.ceil(subTabsWidth)) : transitionDurationExtended;
            // document.querySelector('.super-tabs-wrapper').style.setProperty('--transition-duration-extended', `${transitionDuration}ms`);
            // console.log('transitionDuration', subTabsWidth, Math.ceil(subTabsWidth), transitionDuration);

            document.querySelector('.super-tabs-wrapper').classList.add('nested-tabs-visible');
            expandedTabIDRef.current = id;
            updateExpandedID(id);
            updateMaxWidth();
        }
    }

    const closeSubTabStack = (id = expandedTabID) => {
        clearTimeout(timeOutRef.current);
        setClosingTabID(id);
        document.querySelectorAll(`.tab-${id}.sub-tabs-wrapper .sub-tab-group .sub-tab`)?.forEach((elem) => {
            setTimeout(() => {
                elem.classList.add('hide-sub-tab');
            }, 0);
        });

        document.querySelectorAll(`.tab-${id}.sub-tabs-wrapper .sub-tab-group .sub-tab`)?.forEach((elem) => {
            elem.style.width = '0px';
            elem.style.marginLeft = '0px';
        });
        updateExpandedID(null);

        timeOutRef.current = setTimeout(() => {
            setClosingTabID(null);
            document.querySelector('.super-tabs-wrapper').classList.remove('nested-tabs-visible');
            document.querySelectorAll(`.tab-${id}.sub-tabs-wrapper .sub-tab-group .sub-tab`)?.forEach((elem) => {
                elem.classList.remove('hide-sub-tab');
            });
        }, transitionDurationExtended);
    }

    const handleTabClick = ({ tab, parentId, isSubTab, ignoreActiveTabUpdate = false, sort = false } = {}) => {
        if (location.pathname === tab.url.split('?')[0]) return;
        updateCurrentTab({ tab, ignoreActiveTabUpdate, parentId, isSubTab, sort });
        setTimeout(() => {
            // expandedTabIDRef.current = null;
            updateMaxWidth();
        }, 0);
        if (isSubTab) {
            // lastOpenedUrl.current[parentId] = tab;
            // updateActiveSubTab(tab);
            // setLastOpenedSubTabs((prev) => {
            //     const updated = { ...prev };
            //     updated[parentId] = isSubTab ? tab : null;
            //     return updated;
            // });
            if (subTabWrapperWidth[user.organization_id + '_' + parentId]) {
                setSubTabWrapperWidth((prev) => {
                    const updated = { ...prev };
                    delete updated[user.organization_id + '_' + parentId];
                    return updated;
                });
            }
        } else {
            if (expandedTabID && tab.id !== expandedTabID) {
                closeSubTabStack();
            } else {
                superTabsWrapperElemRef.current.style.setProperty('--transition-duration-extended', `${transitionDuration}ms`);
                // if (activeTab?.id && activeTab.id !== tab.id) closeSubTabStack(activeTab.id);
            }
            const subTabsWrapperElem = document.querySelectorAll('.sub-tabs-wrapper');
            subTabsWrapperElem.forEach((elem) => {
                elem.style.transition = `width ease-out ${expandedTabID ? transitionDurationExtended : transitionDuration}ms`;
            });
            setTimeout(() => {
                subTabsWrapperElem.forEach((elem) => {
                    elem.style.removeProperty('transition');
                });
            }, (expandedTabID ? transitionDurationExtended : transitionDuration) + 50);

            // if (ignoreActiveTabUpdate) return;
            // lastOpenedUrl.current[tab.id] = tab;
            // updateActiveTab(tab);
            // if (activeSubTab.url && isChildPath(tab.url, activeSubTab.url)) {
            //     updateActiveSubTab({});
            // }
            // updateActiveSubTab({});
            // setLastOpenedSubTabs((prev) => {
            //     const updated = { ...prev };
            //     updated[tab.id] = null;
            //     return updated;
            // });
        }
    }

    // useEffect(() => {
    //     if (activeTab) {
    //         setLastOpenedSubTabs((prev) => {
    //             const updated = { ...prev };
    //             if (activeSubTab?.url && activeTab?.url && isChildPath(activeTab?.url, activeSubTab?.url)) {
    //                 updated[activeTab.id] = activeSubTab;
    //             }
    //             return updated;
    //         });
    //     }
    // }, [activeTab, activeSubTab]);

    return (
        <div className={`super-tabs-wrapper`} ref={superTabsWrapperElemRef}>
            <NavLink
                to="/"
                className="home-tab"
                // activeClassName="active"
                // title="Home"
                onClick={() => {
                    // if (expandedTabID || activeTab?.id) {
                    if (expandedTabID) {
                        // updateExpandedID(null);
                        // closeSubTabStack(expandedTabID ?? activeTab.id);
                        closeSubTabStack(expandedTabID);
                    }
                    updateActiveTab({});
                    updateActiveSubTab({});

                    updateCurrentTab({ tab: homeTab, isHomeTab: true });
                }}
                exact
            >
                <div className='brand-wrapper'>
                    <span className={`brand-logo ${isDev() ? 'dev' : ''}`}></span>
                </div>
            </NavLink>
            <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId="HEADER_TABS"
                    direction="horizontal"
                >
                    {(provided) => {
                        return (
                            <div
                                className="main-tabs"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {tabs.map((tab, index) => {
                                    let url = tab.url;

                                    const pathname = window.location.pathname;
                                    const formattedPathname = removeForwardSlashAtEnd(pathname);
                                    const tabUrl = removeForwardSlashAtEnd(tab.url);
                                    if (tabUrl === formattedPathname) {
                                        url = pathname;
                                    }

                                    const hasAccessToTab = hasPrivilege(SITE_PREFIX, tab);
                                    const showNotificationDot = tab.showNotificationDot || false;
                                    // const hasSubTabs = tab?.subTabs?.length > 0;
                                    const containsSubTabs = tab?.containsSubTabs;
                                    const showSubTabs = expandedTabID === tab.id;
                                    const isTabClosing = closingTabID === tab.id;
                                    const isActive = tabUrl === formattedPathname;
                                    // const isActiveWithSubTabsExpanded = isActive && containsSubTabs && showSubTabs;
                                    // const openedSubTab = lastOpenedSubTabs[tab.id];
                                    // const isSubTabOpened = tab?.subTabs?.some(t => t.id === openedSubTab?.id)
                                    // const subTabsLength = (isSubTabOpened ? tab?.subTabs?.length - 1 : tab?.subTabs?.length) || 0;
                                    const hasAddBtnPrivilege = (typeof tab.showAddButton === 'boolean' ? tab.showAddButton : typeof (tab.showAddButton) === 'object' && Array.isArray(tab.showAddButton) ? hasPrivilege(SITE_PREFIX, { privilege: tab.showAddButton }) : false);
                                    const showAddButton = hasAddBtnPrivilege ? tab?.subTabs?.length <= 1 : false;
                                    const showChevron = tab?.subTabs?.length > 1;

                                    const widthLessThan520 = window.innerWidth < 520;
                                    if (widthLessThan520 && !tab.lastOpened && !tab.isFixed) return null;
                                    const shrinkedTab = tab.id === expandedTabID ? false : expandedTabID;
                                    const width = getTextWidth(tab.title, fontStyle) + 42;

                                    const subTabWithOpenOrderOne = tab?.subTabs?.length >= 1 && tab.subTabs.find(t => t.open_order === 1);
                                    const subTabWithOpenOrderOneWidth = subTabWithOpenOrderOne && calculateWidth({ text: getTitle(subTabWithOpenOrderOne), hasAvatar: subTabWithOpenOrderOne.showAvatar })

                                    const getItemStyle = (draggableStyle) => ({
                                        maxWidth: expandedTabID ? `${tab.width || width}px` : `${width}px`,
                                        ...draggableStyle,
                                    });

                                    const { subTabsInNavbar } = filterSubTabs(tab.subTabs);

                                    return (
                                        containsSubTabs ? (
                                            <Draggable
                                                draggableId={String(index)}
                                                index={index}
                                                key={index}
                                            >
                                                {(provided, snapshot) => (

                                                    <div
                                                        // key={index}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        // (snapshot.isDragging ? " dragging" : "") +
                                                        className={'tab-with-child-wrapper parent-super-tab' +
                                                            ` tab-${tab.id}` +
                                                            (isActive ? ' active' : '') +
                                                            (closingTabID === tab.id ? ' stack-closing' : '') +
                                                            (shrinkedTab ? ' shrinked-parent-tab' : '') +
                                                            (snapshot.isDragging ? ' dragging' : '')
                                                        }
                                                    >
                                                        <div
                                                            className={'child-tabs-wrapper tab-' + tab.id +
                                                                (showSubTabs ? ' expanded' : '')
                                                                // (isActive ? ' active' : '')
                                                            }
                                                        >
                                                            <MouseTooltip
                                                                content={tab.title}
                                                                delay={500}
                                                            >
                                                                <NavLink
                                                                    to={tab.url}
                                                                    className={
                                                                        "tab" +
                                                                        " tab-" + tab.id +
                                                                        ((expandedTabID ? tab.width : maxWidth) && compareDecimals(expandedTabID ? tab.width || width : maxWidth, width) === -1 ? ' fade-at-end' : '') +
                                                                        (!hasAccessToTab ? ' disabled-tab' : '') +
                                                                        (containsSubTabs ? ' has-child' : '') +
                                                                        (shrinkedTab ? ' shrinked-tab' : '')
                                                                        // (showSubTabs ? ' expanded' : '')
                                                                    }
                                                                    style={{ maxWidth: expandedTabID ? `${(tab.width || width) - 10}px` : `${width}px` }}
                                                                    // title={tab.title}
                                                                    onClick={(e) => {
                                                                        let ignoreActiveTabUpdate = false;
                                                                        if (containsSubTabs && !isActive) {
                                                                            const lastOpenedTab = lastOpenedUrl.current?.[tab.id];
                                                                            updateActiveTab(tab);
                                                                            ignoreActiveTabUpdate = true;
                                                                            if (lastOpenedTab?.url && lastOpenedTab?.url !== tab.url) {
                                                                                e.preventDefault();
                                                                                if (lastOpenedTab?.url !== tab.url) {
                                                                                    updateActiveSubTab(lastOpenedTab || {});
                                                                                    // setLastOpenedSubTabs((prev) => {
                                                                                    //     const updated = { ...prev };
                                                                                    //     updated[tab.id] = lastOpenedTab;
                                                                                    //     return updated;
                                                                                    // });
                                                                                }
                                                                                history.push(lastOpenedTab.url || tab.url);
                                                                                // } else {
                                                                                // setTimeout(() => {
                                                                                //     toggleSubTabs(tab);
                                                                                // }, 0);
                                                                            }
                                                                        }
                                                                        handleTabClick({ tab, parentId: tab.id, ignoreActiveTabUpdate });
                                                                    }}
                                                                    exact={tab.fixed}
                                                                >
                                                                    <TabContentWrapper
                                                                        tab={tab}
                                                                        index={index}
                                                                        isActive={tab.url === pathname}
                                                                        handleTabClose={handleTabClose}
                                                                        isSubTab={false}
                                                                        containsSubTabs={containsSubTabs}
                                                                        SITE_PREFIX={SITE_PREFIX}
                                                                        Suffix={Suffix[tab?.id] || null}
                                                                    />
                                                                </NavLink>
                                                            </MouseTooltip>
                                                        </div>
                                                        {/* {isActive && <div className={`sub-tabs-wrapper ${showSubTabs && isActive ? 'expanded' : ''}`}> */}
                                                        {containsSubTabs && <div
                                                            className={`sub-tabs-wrapper tab-${tab.id} ${showSubTabs ? 'expanded' : ''}`}
                                                            style={isActive ? { width: (showSubTabs || isSubTabCollapsing || !subTabWithOpenOrderOneWidth) ? '100%' : subTabWrapperWidth[user.organization_id + '_' + tab.id] || `${subTabWithOpenOrderOneWidth + (showChevron || showAddButton ? 42 : 5)}px` } : { width: '0px' }}
                                                        >
                                                            <DragDropContext onDragStart={handleSubTabDragStart} onDragEnd={(res) => handleSubTabDragEnd(res, tab.id)}>
                                                                <Droppable
                                                                    droppableId={`HEADER_SUB_TABS_${tab.id}`}
                                                                    direction="horizontal"
                                                                >
                                                                    {(subprovided) => {
                                                                        return (
                                                                            <div className='sub-tab-group'
                                                                                ref={subprovided.innerRef}
                                                                                {...subprovided.droppableProps}
                                                                            >
                                                                                {
                                                                                    subTabsInNavbar.map((subTab, subIndex) => {
                                                                                        return <SubTab
                                                                                            activeTab={activeTab}
                                                                                            closingTabID={closingTabID}
                                                                                            expandedTabID={expandedTabID}
                                                                                            maxWidth={maxWidth}
                                                                                            showSubTabs={showSubTabs}
                                                                                            subIndex={subIndex}
                                                                                            hasSingleSubTab={tab.subTabs.length === 1}
                                                                                            subTab={subTab}
                                                                                            tab={tab}
                                                                                            tabUrl={tabUrl}
                                                                                            handleTabClick={handleTabClick}
                                                                                            handleTabClose={handleTabClose}
                                                                                            SITE_PREFIX={SITE_PREFIX}
                                                                                            index={index}
                                                                                            key={subTab.id}
                                                                                        />
                                                                                    })
                                                                                }
                                                                                {subprovided.placeholder}
                                                                            </div>
                                                                        );
                                                                    }}
                                                                </Droppable>
                                                            </DragDropContext>
                                                            {(showChevron || showAddButton) && (
                                                                <TabStack
                                                                    handleTabClick={handleTabClick}
                                                                    handleTabClose={handleTabClose}
                                                                    index={index}
                                                                    isActive={isActive}
                                                                    isTabClosing={isTabClosing}
                                                                    showAddButton={showAddButton}
                                                                    showChevron={showChevron}
                                                                    showSubTabs={showSubTabs}
                                                                    subTabsInList={tab.subTabs}
                                                                    tab={tab}
                                                                    toggleSubTabs={toggleSubTabs}
                                                                    addButtonCallBackRefs={addButtonCallBackRefs}
                                                                    SITE_PREFIX={SITE_PREFIX}
                                                                />
                                                            )}
                                                        </div>
                                                        }
                                                    </div>
                                                )}
                                            </Draggable>

                                        ) : (
                                            <Draggable
                                                key={index}
                                                draggableId={String(index)}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <MouseTooltip
                                                        content={tab.title}
                                                        delay={500}
                                                    >
                                                        <NavLink
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                            to={url}
                                                            onClick={() => {
                                                                handleTabClick({ tab })
                                                            }}
                                                            className={
                                                                "tab parent-super-tab" +
                                                                (snapshot.isDragging ? " dragging" : "") +
                                                                (maxWidth && compareDecimals(maxWidth, width) === -1 ? ' fade-at-end' : '') +
                                                                (expandedTabID ? ' shrinked-tab' : '') +
                                                                (!hasAccessToTab ? " disabled-tab" : "")
                                                            }
                                                            style={getItemStyle(provided.draggableProps.style)}
                                                            exact={true}
                                                        // exact={tab.exact}
                                                        // title={tab.title}
                                                        >
                                                            <TabContentWrapper
                                                                tab={tab}
                                                                index={index}
                                                                isActive={tab.url === pathname}
                                                                showNotificationDot={showNotificationDot}
                                                                handleTabClose={handleTabClose}
                                                                isSubTab={false}
                                                                SITE_PREFIX={SITE_PREFIX}
                                                                Suffix={Suffix[tab?.id] || null}
                                                            />
                                                        </NavLink>
                                                    </MouseTooltip>
                                                )}
                                            </Draggable>
                                        )
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </DragDropContext>
        </div>
    )
}

const TabContentWrapper = ({ tab, index, tabTitle, isActive = false, handleTabClose, isSubTab, Suffix = null, parentIndex, containsSubTabs }) => {
    // const isFixed = tab.isFixed || false;
    const showAvatar = tab.showAvatar || false;
    const showNotificationDot = tab.showNotificationDot || false;

    const title = tabTitle ?? getTitle(tab);

    return <>
        <div className={`tab-content-wrapper`}>
            {!isSubTab && <div className={`single-tab ${showNotificationDot ? 'show-notification-dot' : ''}`}></div>}
            <div className="tab-title-wrapper">
                {showAvatar && (
                    <Avatar
                        firstName={tab.firstName}
                        lastName={tab.lastName}
                        imgSrc={tab.imgSrc || tab.img_url}
                        width={20}
                        height={20}
                        fontSize={8}
                        borderRadius={12}
                    />
                )}
                <div className='tab-title'>
                    <div>{title}</div>
                </div>
            </div>
            {isActive && <div className="tab-close-btn-wrapper">
                <button
                    className="tab-close-btn"
                    title="Close tab"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleTabClose({ tab, index, isSubTab, parentIndex });
                    }}
                ></button>
            </div>}
        </div>
        {Suffix && isActive && window.innerWidth < 640 && <Suffix />}
        {!isActive && <div className="tab-close-btn-wrapper">
            <button
                className="tab-close-btn"
                title="Close tab"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleTabClose({ tab, index, isSubTab, parentIndex });
                }}
            ></button>
        </div>}
    </>
}

export const SubTab = ({ activeTab, closingTabID, expandedTabID, maxWidth, showSubTabs, subIndex, subTab, tab, tabUrl, handleTabClick, handleTabClose, SITE_PREFIX, index, hasSingleSubTab }) => {
    // const { closingUnexpandedSubTab, openingUnexpandedSubTab, subTabChange } = useTabContext();
    const subTabUrl = subTab.url ?? `/${tabUrl}/f-${subTab.id}`;
    const subTabUrlWithoutSearchParam = subTabUrl.split('?')[0];
    const pathname = window.location.pathname;
    const isSubTabActive = subTabUrlWithoutSearchParam === pathname;
    const title = getTitle(subTab);
    const width = calculateWidth({ text: title, hasAvatar: subTab.showAvatar, addMargin: false, });
    // const show = (subTab.id === openedSubTab?.id && (activeTab.id === tab.id || closingTabID === tab.id)) || (hasSingleSubTab && activeTab.id === tab.id);
    // const isTabClosing = subTabChange?.oldTab?.id === subTab.id;
    // const isTabOpening = subTabChange?.newTab?.id === subTab.id;
    const show = (subTab.open_order === 1 && (activeTab.id === tab.id || closingTabID === tab.id)) || (hasSingleSubTab && activeTab.id === tab.id);

    const getItemStyle = (isVisible, draggableStyle, width) => {
        // const duration = tab.id === 6 ? calculateTransitionDuration(Math.ceil(width)) : transitionDurationExtended;
        // const transition = `transition: all ${duration}ms, opacity ${transitionDuration}ms ${duration}ms`;

        return ({
            // calculateWidth({ text: title, hasAvatar: subTab.showAvatar, });
            marginLeft: isVisible ? `${MarginLeft}px` : '0px',
            // maxWidth: maxWidth ? `${maxWidth}px` : `${width}px`,
            width: isVisible ? `${expandedTabID === tab.id || closingTabID === tab.id ? subTab.width || width : width}px` : '0px',
            opacity: isVisible ? 1 : 0,
            // transition: transition,

            // styles we need to apply on draggables
            ...draggableStyle
        });
    }

    return <Draggable
        key={subIndex}
        draggableId={String(subIndex)}
        index={subIndex}
    >
        {(subprovided, snapshot) => {
            return (
                <MouseTooltip
                    content={title}
                    delay={500}
                >
                    <NavLink
                        {...subprovided.draggableProps}
                        {...subprovided.dragHandleProps}
                        ref={subprovided.innerRef}
                        to={subTabUrl}
                        id={`tab-${tab.id}-` + subTab.id.toString().replace('/', '-')}
                        className={
                            "tab sub-tab" +
                            (show ? ' show' : '') +
                            // (isTabClosing ? ' slide-out' : '') +
                            // (isTabOpening ? ' fade-in' : '') +
                            (expandedTabID && subTab.width && compareDecimals(subTab.width, width) === -1 ? ' fade-at-end' : '') +
                            // (closingUnexpandedSubTab || openingUnexpandedSubTab ? ' no-transition' : '') +
                            (snapshot.isDragging ? ' dragging' : '') +
                            (subTab.isFixed ? ' fixed' : '')
                        }
                        style={getItemStyle(
                            showSubTabs || show,
                            subprovided.draggableProps.style,
                            width,
                        )}
                        // title={subTab.name}
                        exact={false}
                        onClick={() => handleTabClick({ tab: subTab, parentId: tab.id, isSubTab: true })}
                    // key={subIndex}
                    >
                        <TabContentWrapper
                            tab={subTab}
                            tabTitle={title}
                            isActive={isSubTabActive}
                            index={subIndex}
                            handleTabClose={handleTabClose}
                            isSubTab={true}
                            SITE_PREFIX={SITE_PREFIX}
                            parentIndex={index}
                        />
                    </NavLink>
                </MouseTooltip>
            )
        }}
    </Draggable>
}


export default SuperTabs
