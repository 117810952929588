import { useEffect, useRef, useState } from "react";
import SuperPager from "super-pager";
import { useWikiContext } from "../context";
import Avatar from "components/Avatar";
import ShareArticleModal from "./ShareArticleModal";
import CompanyDocsModal from "./CompanyDocsModal";
import { alertService } from "services/alertService";
import { useOnClickOutside } from "hooks/useClickOutside";
import useScrollRestoration from "hooks/useScrollRestoration";
import { format } from "utils/date";
import { convertUTCToTimezone } from "utils/Common";
import "./ListView.scss";

export default function ListView({ data, loadingData, pageMeta, getArticles }) {
  const [selectedShareArticleId, setSelectedShareArticleId] = useState(null);
  const shareArticleModalRef = useRef(null);
  const modalTimeoutRef = useRef(null);

  useEffect(() => () => clearTimeout(modalTimeoutRef.current), []);

  const openShareArticleModal = (id) => {
    clearTimeout(modalTimeoutRef.current);
    setSelectedShareArticleId(id);
    modalTimeoutRef.current = setTimeout(() => {
      if (shareArticleModalRef.current) {
        const modalEl = shareArticleModalRef.current;
        modalEl?.classList?.add("show");
        clearInterval(modalTimeoutRef.current);
      }
    }, 1);
  };

  const closeShareArticleModal = () => {
    clearTimeout(modalTimeoutRef.current);
    shareArticleModalRef?.current?.classList?.remove("show");
    modalTimeoutRef.current = setTimeout(() => {
      setSelectedShareArticleId(null);
    }, 200);
  };

  useOnClickOutside(shareArticleModalRef, closeShareArticleModal);

  const [showCompanyDocsModal, setShowCompanyDocsModal] = useState(false);
  const companyDocsModalRef = useRef(null);
  const companyDocsModalTimeoutRef = useRef(null);

  useEffect(() => () => clearTimeout(companyDocsModalTimeoutRef.current), []);

  const openCompanyDocsModal = () => {
    clearTimeout(companyDocsModalTimeoutRef.current);
    setShowCompanyDocsModal(true);
    companyDocsModalTimeoutRef.current = setTimeout(() => {
      if (companyDocsModalRef.current) {
        const modalEl = companyDocsModalRef.current;
        modalEl?.classList?.add("show");
        clearInterval(companyDocsModalTimeoutRef.current);
      }
    }, 1);
  };

  const closeCompanyDocsModal = () => {
    clearTimeout(companyDocsModalTimeoutRef.current);
    companyDocsModalRef?.current?.classList?.remove("show");
    companyDocsModalTimeoutRef.current = setTimeout(() => {
      setShowCompanyDocsModal(false);
    }, 200);
  };

  useOnClickOutside(companyDocsModalRef, closeCompanyDocsModal);
  const scrollRef = useScrollRestoration("wiki-home-list-scroll");

  return (
    <div className="data-list" ref={scrollRef}>
      <table>
        <thead>
          <tr>
            <th style={{ width: "540px" }}>Documents</th>
            <th style={{ width: "229px" }}>Tags</th>
            <th style={{ width: "205px" }}>Owner</th>
            <th style={{ width: "170px" }}>Modified</th>
            <th style={{ width: "140px" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          <SuperPager
            type="infiniteScroll"
            isTable={true}
            dataLength={loadingData ? 0 : data?.length || null}
            loadMore={() =>
              getArticles({ page_number: pageMeta.pagination.current_page + 1 })
            }
            hasMore={data.length < pageMeta.pagination.total_count}
            wrapper={true}
            children={
              data?.length === 0 ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    No data to display
                  </td>
                </tr>
              ) : (
                data.map((item) => (
                  <WikiItem
                    key={item.id}
                    item={item}
                    openShareArticleModal={openShareArticleModal}
                  />
                ))
              )
            }
          />
        </tbody>
      </table>
      {selectedShareArticleId !== null && (
        <ShareArticleModal ref={shareArticleModalRef} />
      )}
      {showCompanyDocsModal && <CompanyDocsModal ref={companyDocsModalRef} />}
    </div>
  );
}

function WikiItem({ item, openShareArticleModal }) {
  const {
    openArticle,
    openArticleInEditMode,
    deleteArticle,
    removeArticleInList,
  } = useWikiContext();

  let ownerFirstName = "";
  let ownerLastName = "";

  if (item?.authors?.length > 0) {
    const name = item.authors[0].name.split(" ");
    ownerFirstName = name[0];
    if (name.length > 1) {
      name.shift();
      ownerLastName = name.join(" ");
    }
  }

  const [deletingArticle, setDeletingArticle] = useState(false);
  const handleArticleDelete = async (e) => {
    e.stopPropagation();
    setDeletingArticle(true);
    try {
      const res = await deleteArticle(item);
      if (res.article_id) {
        removeArticleInList(item);
      } else {
        setDeletingArticle(false);
        alertService.error(
          res?.detail?.msg ?? "Something went wrong. Please try again.",
        );
      }
    } catch (error) {
      console.log(error);
      setDeletingArticle(false);
    }
  };

  return (
    <tr onClick={() => openArticle(item)}>
      <td>
        <div className="doc-name">
          <span className="icon-file"></span>
          <span>{item.title}</span>
        </div>
      </td>
      <td>
        {item?.tags?.length > 0 && (
          <div className="tags">
            {item.tags.map((tag, index) => (
              <span className="tag" key={index}>
                {tag.tag}
              </span>
            ))}
          </div>
        )}
      </td>
      <td>
        {item?.authors?.length > 0 && (
          <div className="owner-name">
            <Avatar firstName={ownerFirstName} lastName={ownerLastName} />
            <span>
              {ownerFirstName} {ownerLastName}
            </span>
          </div>
        )}
      </td>
      <td>
        {format(
          convertUTCToTimezone(item?.updated_at ?? item.created_at),
          "MMM, YYYY • hh:mm A",
        )}
      </td>
      <td className="actions-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="actions">
          <button
            className="icon-share"
            onClick={() => openShareArticleModal(item.id)}
            disabled
          ></button>
          <button
            className="icon-edit"
            onClick={(e) => {
              e.stopPropagation();
              openArticleInEditMode(item);
            }}
          ></button>
          {/* <button className="icon-settings" onClick={() => openCompanyDocsModal()}></button> */}
          <button className="icon-archive" disabled></button>
          <button
            className="icon-delete"
            onClick={handleArticleDelete}
            disabled={deletingArticle}
          ></button>
        </div>
      </td>
    </tr>
  );
}
