import React from 'react';
import './Button.scss';

const Button = ({ onClick = null, children = null }) => {
  return (
    <button type='button' className='custom-btn' onClick={onClick}>
        {children}
    </button>
  )
}

export default Button