import {
  formatDecimalNum,
  isNone,
} from "views/Attendance/utils";
import { formatTimeInSeconds } from "utils/date";
import "./ShiftLogPopover.scss";

export default function ShiftLogPopover({ log }) {
  const outside_shift_total_time = log.total_time - log.overlap_seconds;

  return (
    <div className="shift-log-popover">
      <table>
        <thead>
          <tr>
            <th>Within Shift</th>
            <th>Outside Shift</th>
            <th>Total</th>
            <th>Overlap</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {log.overlap_seconds > 0
                ? formatTimeInSeconds(log.overlap_seconds)
                : "-"}
            </td>
            <td>
              {outside_shift_total_time > 0
                ? formatTimeInSeconds(outside_shift_total_time)
                : "-"}
            </td>
            <td>
              {isNone(log.total_time)
                ? "-"
                : formatTimeInSeconds(log.total_time)}
            </td>
            <td>
              {isNone(log.total_time)
                ? "-"
                : `${formatDecimalNum(
                  Math.min(100, (log.overlap_seconds / log.max_seconds) * 100)
                )}%`}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}