export const requestStatusOptions = [
    {
        id: '1',
        name: 'Approved',
        value: 'approved',
        is_approved: 1,
        is_rejected: 0,
    }, {
        id: '2',
        name: 'Pending',
        value: 'pending',
        is_approved: 0,
        is_rejected: 0,
    }, {
        id: '3',
        name: 'Declined',
        value: 'rejected',
        is_approved: 0,
        is_rejected: 1,
    }
]

export const requestOptions = [
    {
        id: '1',
        name: 'My Requests'
    }, {
        id: '2',
        name: 'Team Requests'
    }
]

export const shiftTypeOptions = [
    {
        //     id: '1',
        //     name: 'Adhoc'
        //     value: 'adhoc_shift',
        // }, {
        id: '2',
        name: 'Special',
        value: 'special_shift',
    }, {
        id: '3',
        name: 'Standard',
        value: 'schedule',
    }
]

export const dateOptions = [
    {
        id: '1',
        name: 'Requested Date',
        key: 'requested',
    }, {
        id: '2',
        name: 'Effective Date',
        key: 'effective',
    }, {
        id: '3',
        name: 'Last Updated',
        key: 'updated',
    }
]