import { forwardRef, useRef, useState } from "react";
import SpeedSelect from "react-speedselect"
import Avatar from "components/Avatar";
import "./ShareArticleModal.scss";
import Switch from "components/Switch/Switch";

const roleOptions = [
  { id: 1, name: "Admin" },
  { id: 2, name: "Editor" },
  { id: 3, name: "Viewer" }
]

const securityOptions = [
  { id: 2, name: "Private" },
  { id: 1, name: "Public" }
]

const demoUsers = [
  { name: "Ramesh Suthar", role: 3 },
  { name: "Chandan Kumar", role: 3 },
  { name: "Vineet Kumar", role: 3 },
]

const ShareArticleModal = forwardRef((_, ref) => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const searchInputRef = useRef(null);

  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedSecurity, setSelectedSecurity] = useState(null);

  const [showShareLinkUI, setShowShareLinkUI] = useState(false);

  const [showUserSearchInput, setShowUserSearchInput] = useState(false);
  const [searchUserList, setSearchUserList] = useState([]);

  return (
    <div className="share-article-modal" ref={ref}>
      <div className="modal-header">
        <h6>Share</h6>
        <button className="icon-search" onClick={() => {
          setShowSearchInput(true);
          searchInputRef?.current?.focus();
        }}></button>
        <div className={`search-input-wrapper ${showSearchInput ? 'show' : ''}`}>
          <input ref={searchInputRef} type="text" placeholder="Search" />
          <button className="icon-close" onClick={() => setShowSearchInput(false)}></button>
        </div>
      </div>
      <div className="modal-body">
        <div className="select-options react-speedselect-option">
          <SpeedSelect
            options={roleOptions}
            displayKey="name"
            uniqueKey="id"
            disableSearch={true}
            selectedOption={roleOptions.find(
              (option) => option.id === selectedRole
            )}
            onSelect={(option) => setSelectedRole(option.id)}
            prominentLabel="Role"
          />
          <SpeedSelect
            options={securityOptions}
            displayKey="name"
            uniqueKey="id"
            disableSearch={true}
            selectedOption={securityOptions.find(
              (option) => option.id === selectedSecurity
            )}
            onSelect={(option) => setSelectedSecurity(option.id)}
            prominentLabel="Security"
          />
        </div>
        <div className="user-list-wrapper">
          {!showShareLinkUI ? (
            <>
              <div className="add-user">
                <button
                  className="add-user-btn"
                  onClick={() => setShowUserSearchInput(true)}
                >
                  <span className="icon-plus"></span>
                  <span>User</span>
                </button>
                <button onClick={() => setShowShareLinkUI(true)}>
                  <span className="icon-link"></span>
                </button>
                {showUserSearchInput && (
                  <input
                    type="text"
                    className="user-search-input"
                    placeholder="Type Name or mail id"
                    autoFocus
                    onBlur={(e) => {
                      if (e.target.value.trim() === "") {
                        setShowUserSearchInput(false);
                        setSearchUserList([]);
                      }
                    }}
                    onInput={(e) =>
                      setSearchUserList([{ name: "Vaibhav Singh" }])
                    }
                  />
                )}
              </div>
              {searchUserList.length > 0 ? (
                <div className="search-user-list">
                  {searchUserList.map((user, index) => {
                    const firstName = user.name.split(" ")[0];
                    const lastName = user.name.split(" ")[1];
                    return (
                      <div className="search-user" key={index}>
                        <div className="search-user-info">
                          <Avatar
                            firstName={firstName}
                            lastName={lastName}
                            width="20px"
                            height="20px"
                            borderRadius="2px"
                          />
                          <div className="user-name">{user.name}</div>
                        </div>
                        <button className="user-cta">Add</button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="user-list">
                  {demoUsers.map((user, index) => {
                    const firstName = user.name.split(" ")[0];
                    const lastName = user.name.split(" ")[1];
                    return (
                      <div className="user" key={index}>
                        <div className="user-info">
                          <Avatar
                            firstName={firstName}
                            lastName={lastName}
                            width="20px"
                            height="20px"
                            borderRadius="2px"
                          />
                          <div className="user-name">{user.name}</div>
                        </div>
                        <div className="user-cta">
                          <div className="react-speedselect-option">
                            <SpeedSelect
                              options={roleOptions}
                              displayKey="name"
                              uniqueKey="id"
                              disableSearch={true}
                              selectedOption={roleOptions.find(
                                (option) => option.id === user.role
                              )}
                              onSelect={(option) => { }}
                              prominentLabel=""
                            />
                          </div>
                          <button className="icon-minus"></button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          ) : (
            <ShareLink toggle={() => setShowShareLinkUI(!showShareLinkUI)} />
          )}
        </div>
      </div>
    </div>
  );
})

export default ShareArticleModal;

function ShareLink({ toggle }) {
  const [allowGuestAccess, setAllowGuestAccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSaveCopy = () => {
    // Copy to clipboard
    navigator.clipboard.writeText("TODO: Embed code here");
    // Close modal
    toggle();
  }

  return (
    <div className="share-link">
      <div className="title">
        <span className="icon-link"></span>
        <span>Link</span>
      </div>
      <div className="guest-access">
        <span className="title">Allow Guest Access</span>
        <Switch
          isOn={allowGuestAccess}
          handleToggle={() => setAllowGuestAccess(!allowGuestAccess)}
        />
      </div>
      <div className="password">
        <span className="title">Password</span>
        <div className="password-input-wrapper">
          <input
            type={showPassword ? "text" : "password"}
            placeholder=""
            autoComplete="off"
            id="share-article-search"
            name="share-article-search"
          />
          <button className={`password-toggler ${showPassword ? 'show' : 'hide'}`} onClick={() => setShowPassword(!showPassword)}></button>
        </div>
      </div>
      <div className="set-expiration">
        <span className="title">Set Expiration</span>
        <input type="text" placeholder="DD/MM/YYYY HH:MM:SS" />
      </div>
      <div className="embed-code">
        <div>
          <span className="title">Embed Code</span>
          <a href="#" target="__blank" className="embed-code-link">
            https://www.insights.com/Shared/link/datatrend/d-319
          </a>
        </div>
        <div className="embed-code-cta">
          <button
            onClick={handleSaveCopy}
          >
            Save &amp; Copy
          </button>
          <button
            onClick={toggle}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}