import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
// contexts
import { TimeWorkerContext } from "Main";
import { useGlobalContext } from "contexts/GlobalContext";
// components
import ActivityTrackerSubmitErrorIcon from "./SubComponents/ActivityTrackerSubmitErrorIcon";
import AlertPopup from "components/AlertPopup/AlertPopup";
import BreakDiff from "./SubComponents/BreakDiff";
import EndShiftConfirmation from "./SubComponents/EndShiftConfirmation";
import InactivityPopup from "./SubComponents/InactivityPopup";
// hooks
import { useOnClickOutside } from "hooks/useClickOutside";
import { useOnline } from "hooks/useOnline";
// services
import { connectSocket, socket } from "socket";
import APIService from "services/apiService";
import { alertService } from "services/alertService";
import EventEmitter from "services/eventEmitter";
// utils
import {
  cancelAutoResumeTracker,
  cancelCheckin,
  displayAlert,
  disableTracker,
  endShift,
  endPausedShift,
  extendBreak,
  extendTrackerDisable,
  resumeShift,
  resumeTracker,
  revokeClockOut,
  startNewShiftButtonClick,
  takeBreak,
  useTimerStore,
  recoverLostTime,
  scheduleCheckin,
  scheduleAutoResumeTracker,
  stopTimerOnLastCheckin,

  handleResumeShiftFromServer,
  handleTakeBreakFromServer,
  extendBreakFromServer,
  handleDisableTracker,
  handleExtendTrackerDisableFromServer,
  handleCheckinFromServer,
  handleStartNewShiftFromServer,

  handleEndShiftFromServer,
  handleEndPausedShiftFromServer,
  handleRevokeClockOutFromServer,
  handleRecoverLostTimeFromServer,
  handleStopTimerOnLastCheckinFromServer,
  TIMER_BASE_URL,
  getTimeFromStr,
  getUserShiftData,
  ONE_DAY_IN_MINUTES,
  STANDARD_WORK_TIMINGS,
} from "./store";
import clsx from "utils/clsx";
import { convertUTCToTimezone, getUTCDate, getUTCTime, getUser, isElectronApp } from "utils/Common";
import { format, isWithinInterval, differenceInSeconds, isDate, getWeek, addDays, subMinutes } from "utils/date";
import {
  addShakeEffectToEl,
  convertTimeZone,
  formatTime,
  getTimeDiff,
} from "./timerUtils";
import { SITE_PREFIX } from "../Constants";
import { differenceInMilliseconds, differenceInMinutes } from "utils/date";
// styles
import "./Timer.scss";

export const timerEventEmitter = new EventEmitter();

function moveOtherToEnd(data) {
  const otherIndex = data.findIndex(item => item.name === 'Other');
  if (otherIndex !== -1 && otherIndex !== data.length - 1) {
    const otherItem = data.splice(otherIndex, 1)[0];
    data.push(otherItem);
  }
  return data;
}

export default function Timer({ isMobile = false, hasFullAccess = true }) {
  const state = useTimerStore();
  const {
    checkIsDSTActive,
    isAppInActive,
    organizationsList,
    selectedOrganization,
  } = useGlobalContext();

  const menuRef = useRef(null);
  const getTimerInProgressRef = useRef(null);
  const getTimerAbortControllerRef = useRef(null);
  const getTimerTimeOutRef = useRef(null);
  const getTimerRefreshAttemptsRef = useRef(null);
  const autoClockOutTimeOutRef = useRef(null);
  const selectedOrganizationIdRef = useRef(null);
  const stopOnLastCheckinTimeOutRef = useRef(null);

  const timeWorkerInstance = useContext(TimeWorkerContext);

  useEffect(() => selectedOrganizationIdRef.current = selectedOrganization?.id, [selectedOrganization?.id]);

  const getTimerToken = useCallback(() => {
    const selectedOrgId = selectedOrganizationIdRef.current;
    const timerOrg = organizationsList.find(org => +org.is_timer === 1);
    if (selectedOrgId === timerOrg?.id) return;
    const timerToken = localStorage.getItem(SITE_PREFIX + 'timer_token')
    return `Bearer ${timerToken}`;
  }, [organizationsList]);

  const getTimerSettings = useCallback(() => {
    const userDetails = getUser(getTimerToken() ? 'timer_token' : 'token');
    const meta = userDetails?.meta; // Optional chaining to handle userDetails.meta being undefined
    const max_recovery_time_allowed_mins = meta?.max_recovery_time_allowed_mins ?? 5; // Default value of 5 for max_recovery_time_allowed_mins
    const max_recovery_time_allowed_seconds = (max_recovery_time_allowed_mins + 2) * 60;
    const max_break_allowed_mins = meta?.max_break_allowed_mins ?? 60; // Default value of 60 for max_break_allowed_mins
    const max_disable_activity_tracker_allowed_mins = meta?.max_disable_activity_tracker_allowed_mins ?? 60; // Default value of 60 for max_disable_activity_tracker_allowed_mins
    return { max_recovery_time_allowed_seconds, max_break_allowed_mins, max_disable_activity_tracker_allowed_mins };
  }, [getTimerToken])

  const isOnline = useOnline();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [timerLoadedAt, setTimerLoadedAt] = useState(null);
  useOnClickOutside(menuRef, (e) => {
    if (!e.target.className.includes('timer')) {
      setIsMenuOpen(false);
    }
    if (showEndShiftConfirmation) setShowEndShiftConfirmation(false);
  });

  const [disableTrackerOptions, setDisableTrackerOptions] = useState([]);
  const [showEndShiftConfirmation, setShowEndShiftConfirmation] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  }

  const handleEndShift = (confirmed = false) => {
    const { isTimerStarted, hasRevokedClockOut } = useTimerStore.getState();
    if (hasRevokedClockOut && !confirmed) {
      setShowEndShiftConfirmation(true);
      return;
    }
    if (!isElectronApp) toggleMenu();

    if (isTimerStarted) {
      endShift();
    } else {
      endPausedShift();
    }
  };

  const getTimer = useCallback(async ({ autoClockOutCheck = false, reconnectSocket = false, initiateSocketConnection = false, missedCheckin = false } = {}) => {
    if (getTimerInProgressRef.current || (timerLoadedAt && differenceInSeconds(getUTCDate(), timerLoadedAt) < 2)) return;
    const timerToken = getTimerToken();
    const { isSocketEmitInProgress } = useTimerStore.getState();
    if (isSocketEmitInProgress && !reconnectSocket) {
      if (!getTimerTimeOutRef.current) {
        getTimerTimeOutRef.current = setTimeout(() => {
          getTimerTimeOutRef.current = null;
          getTimer({ autoClockOutCheck })
        }, 5 * 1000);
      }
      return;
    }
    if (getTimerAbortControllerRef.current.signal.aborted) getTimerAbortControllerRef.current = new AbortController();

    getTimerInProgressRef.current = true;
    try {
      const response = await APIService.apiRequest(
        TIMER_BASE_URL + `/timer`,
        null,
        false,
        "GET",
        getTimerAbortControllerRef.current,
        timerToken,
        !!timerToken,
      );

      if (response.status === 1) {
        if (reconnectSocket) {
          timerEventEmitter.emit('reconnectSocket');
          if (isSocketEmitInProgress) return;
        }
        if (initiateSocketConnection) connectSocket();
        if (getTimerRefreshAttemptsRef.current > 0) getTimerRefreshAttemptsRef.current = 0;
        const oldState = useTimerStore.getState();

        const {
          time,
          timer_id: timerId,
          last_clock_in_time: lastClockInTime = 0,
          last_clock_out_timer: lastClockOutTime = 0,
          state,
          output: {
            expected_back_at: expectedBackAt = '',
            break_started_at: breakStartedAt = '',
            afk_time_min: breakTakenFor = 0,
            next_checkin_time: nextCheckinTime = 0,
            activity_tracker_disabled_time_min: trackerDisabledFor = 0,
            timer_start_time: startTime = ''
          } = {},
          is_revoke_clock_out: hasRevokedClockOut = 0
        } = response;
        // const lastClockInTime = "2024-06-11 14:51:50";

        const timerSettings = getTimerSettings();
        const isTimerStarted = state === 'On';
        const hasTimerStartedHere = JSON.parse(sessionStorage.getItem(SITE_PREFIX + 'hasTimerStartedHere')) || false;

        let isShiftStarted = false;

        if (autoClockOutCheck && lastClockOutTime !== oldState.lastClockOutTime) {
          displayAlert({
            msg: "You've been clock out automatically as it's been 24 hours since the start of your previous shift. Please start your new day shift.",
            options: ['Ok', 'Start New Shift'],
          });
        }

        if (lastClockInTime !== 0) {
          if (lastClockOutTime === 0 || new Date(lastClockInTime) > new Date(lastClockOutTime)) {
            isShiftStarted = true;
          }
        }

        const trackerDisabledAt = response?.output?.is_activity_tracked === 0 ? startTime : "";
        const updatedState = {
          time,
          timerId,
          nextCheckinTime,
          isTimerStarted,
          isShiftStarted,
          lastClockInTime,
          lastClockOutTime,
          hasRevokedClockOut: !!+hasRevokedClockOut,
          onBreak: isShiftStarted && !isTimerStarted,
          breakStartedAt,
          breakTakenFor,
          expectedBackAt,
          startTime,
          timerSettings,
          trackerDisabledAt,
          trackerDisabledFor,
        };

        if (expectedBackAt) {
          updatedState.expectedBackAt = expectedBackAt;
        }

        useTimerStore.setState(updatedState);

        const isActivityTracked = response?.output?.is_activity_tracked !== 0;

        if (isActivityTracked) {
          cancelAutoResumeTracker();
        } else {
          if (hasTimerStartedHere) scheduleAutoResumeTracker();
        }

        if (isTimerStarted && isActivityTracked && hasFullAccess && hasTimerStartedHere) {
          console.log(`[CHECKIN] - Timer - ${format(getUTCDate(), "YYYY-MM-DD HH:mm:ss")} -> ${nextCheckinTime}`);
          // If checkin time from backend is short eg. 5 mins from start time, schedule checkin from user_shift
          // This is to check if the tracker is disabled from request or not
          scheduleCheckin({ nextCheckinTime });
        }

        const nowUTC = getUTCDate();
        if (missedCheckin && nextCheckinTime && (new Date(nextCheckinTime).getTime() + timerSettings.max_recovery_time_allowed_seconds * 1000) < nowUTC) {
          // add a random timeout within 10 seconds
          const timeout = Math.floor(Math.random() * 10) * 1000;
          stopOnLastCheckinTimeOutRef.current = setTimeout(() => {
            stopTimerOnLastCheckin();
          }, timeout);
        }

        if (isElectronApp) {
          window.ipcRender.send('message:timerDetails', {
            timer: {
              lastClockOutTime,
              lastClockInTime,
              isTimerStarted,
              isShiftStarted,
              isShiftEnded: false,
              expectedBackAt,
              breakStartedAt,
              breakTakenFor,
              trackerDisabledFor,
              trackerDisabledAt,
              disableUntimedBreakBtn: true,
              is_acitivity_tracked: response?.output?.is_acitivity_tracked,
              hasRevokedClockOut: updatedState.hasRevokedClockOut,
            },
            timeInSeconds: response.time,
            timerSettings,
          });
        }

        setTimerLoadedAt(getUTCDate());
      } else {
        console.log('getTimer else', response);
        if (response?.message?.includes('aborted') || response?.message === "User does not have access to this API") return;
        if (getTimerRefreshAttemptsRef.current < 5) {
          getTimerRefreshAttemptsRef.current += 1;
          getTimerTimeOutRef.current = setTimeout(() => getTimer(), 5000);
        }
        alertService.error(response.msg);
      }
    } catch (err) {
      console.log('getTimer catch', err);
      alertService.error(err.message);
    } finally {
      getTimerInProgressRef.current = false;
    }
  }, [getTimerSettings, getTimerToken, hasFullAccess, timerLoadedAt]);

  const startAutoClockOutCheck = useCallback(() => {
    const { isShiftStarted, isTimerStarted, lastClockInTime } = useTimerStore.getState();

    /* Auto clock out check - if the time is more than (last clock in time + 24 hrs) - 2 mins, then auto ask user to Start new shift
       else if the time is more than (last clock in time + 24 hours), then refresh the timer state
    */
    const currentTime = getUTCDate();
    const lastClockInDateTime = new Date(lastClockInTime);
    const lastClockInDateTimePlus24Hrs = addDays(lastClockInDateTime, 1);
    const clockInMinusBuffer = subMinutes(lastClockInDateTimePlus24Hrs, 2);
    const timeout = clockInMinusBuffer - currentTime;

    const startAutoClockOutCheckTimeOut = (timeoutInSeconds = 5) => {
      autoClockOutTimeOutRef.current = setTimeout(() => {
        autoClockOutTimeOutRef.current = null;
        getTimer({ autoClockOutCheck: true });
      }, timeoutInSeconds * 1000);
    }

    if (isShiftStarted) {
      if (currentTime > clockInMinusBuffer) {
        startAutoClockOutCheckTimeOut();
      } else {
        if (isTimerStarted) {
          autoClockOutTimeOutRef.current = setTimeout(() => {
            autoClockOutTimeOutRef.current = null;
            displayAlert({
              msg: "It's been nearly 24 hours since your last shift. Ready to start a new one?",
              options: ['End Shift', 'Start New Shift'],
            });
            startAutoClockOutCheckTimeOut(120);
          }, Math.max(timeout, 1000));
        } else {
          startAutoClockOutCheckTimeOut(Math.max(timeout / 1000, 1));
        }
      }
    }
  }, [getTimer]);

  useEffect(() => {
    getTimerAbortControllerRef.current = new AbortController();
    const fetchTimer = () => {
      getTimer();
    };

    // socket.on("connect", fetchTimer);
    window.addEventListener("online", fetchTimer);
    if (!getTimerInProgressRef.current) fetchTimer();
    const unsubTimer = timerEventEmitter.subscribe('fetchTimer', getTimer);

    return () => {
      getTimerAbortControllerRef.current.abort();
      clearTimeout(getTimerTimeOutRef.current);
      // socket.off("connect", fetchTimer);
      window.removeEventListener("online", fetchTimer);
      unsubTimer();
    }
  }, [getTimer]);

  useEffect(() => {
    // This effect is to refresh data from store
    getTimer();
  }, [getTimer, state.refreshTimerAPICount]);

  useEffect(() => {
    if (state.isSocketEmitInProgress && getTimerInProgressRef.current) {
      getTimerAbortControllerRef.current.abort();
      getTimerAbortControllerRef.current = new AbortController();
      getTimerTimeOutRef.current = setTimeout(() => {
        getTimerTimeOutRef.current = null;
        getTimer();
      }, 5 * 1000);
    }
  }, [getTimer, state.isSocketEmitInProgress])

  useEffect(() => {
    const controller = new AbortController();
    async function getActivityTrackerDetails() {
      try {
        const timerToken = getTimerToken();
        const response = await APIService.apiRequest(
          TIMER_BASE_URL + "/meta_disable_activity_tracker",
          null,
          false,
          "GET",
          controller,
          timerToken,
          !!timerToken,
        );
        if (response.status === 1) {
          const trackerOptions = moveOtherToEnd(response.output.map((option) => ({
            ...option,
            name: option.display_name,
          })));

          setDisableTrackerOptions(trackerOptions);
          window?.ipcRender?.send(
            "message:disableActivityTrackerOptions",
            trackerOptions
          );
          useTimerStore.setState(() => ({ maxCheckinTime: response.max_checkin_time }));
        } else {
          console.log("getActivityTrackerDetails - else response", response);
        }
      } catch (error) {
        console.log("getActivityTrackerDetails - error", error);
      }
    }

    getActivityTrackerDetails();

    return () => controller.abort();
  }, [getTimerToken]);

  useEffect(() => {
    if (timerLoadedAt) {
      const { time, isTimerStarted } = useTimerStore.getState();
      timeWorkerInstance.postMessage({
        command: 'worker:updateTimerStatus',
        startClock: isTimerStarted,
        time,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerLoadedAt, state.isTimerStarted, timeWorkerInstance, state.trackerResumeCount]);

  useEffect(() => {
    const updateTime = (e) => {
      /*  Call Get Timer API in case checkin is missed for more than 2 mins
          Do not call if socket emit is in progress and tracker is disabled
      */
      const { isSocketEmitInProgress, trackerDisabledFor, isTimerStarted, nextCheckinTime } = useTimerStore.getState();
      const isOnline = window.navigator.onLine;
      const nowUTC = getUTCDate();
      const isCheckinMissed = new Date(new Date(nextCheckinTime).getTime() + 60 * 2 * 1000) < nowUTC; // 2 mins
      if (!getTimerInProgressRef.current && !isSocketEmitInProgress && !trackerDisabledFor && isTimerStarted && isCheckinMissed && isOnline) {
        // if seconds is divisible by 20 then call getTimer API, this is to prevent multiple calls to getTimer API
        // if isActivityTracked is false and checkin time is 24 hrs from clock in time, then don't call getTimer API
        nowUTC.getSeconds() % 20 === 0 && getTimer({ missedCheckin: true });
      }

      if (e.data.command === 'updateTime') {
        useTimerStore.setState((prev) => ({ time: e.data.time }));
        if (isElectronApp) window.ipcRender.send('message:updateTime', e.data.time);
      }
    }
    timeWorkerInstance.addEventListener("message", updateTime, false);

    return () => {
      timeWorkerInstance.postMessage({
        command: 'worker:updateTimeout',
        timeoutTS: null,
        startClock: false,
      });
      timeWorkerInstance.removeEventListener('message', updateTime, false);
    }

  }, [getTimer, timeWorkerInstance])

  useEffect(() => () => {
    clearTimeout(stopOnLastCheckinTimeOutRef.current);
    cancelCheckin();
    cancelAutoResumeTracker();
  }, []);

  useEffect(() => useTimerStore.setState({ isAppInActive }), [isAppInActive]);

  // update electron tray menu with the current timer status
  useEffect(() => {
    if (isElectronApp) {
      const state = useTimerStore.getState();
      const { inactivityPopupContent, alertContent, ...updatedState } = state;
      window?.ipcRender?.send('message:updateOptions', updatedState);
    }
  }, [state.isSocketEmitInProgress, state.isActivityTracked])

  // update electron tracker disabled total minutes
  useEffect(() => {
    if (isElectronApp) {
      window.ipcRender.send('message:activityTrackerDisabledTotalMins', state.trackerDisabledTotalMins);
    }
  }, [state.trackerDisabledTotalMins])

  useEffect(() => {
    const handleTimerEvent = (data) => {
      const { event_session_id, event_name, data_input, data: eventData } = data;
      if (event_session_id === socket.id) return;

      const isCheckin = event_name === "checkin";
      if (!isCheckin) useTimerStore.setState({ isSocketEmitInProgress: true });

      const eventHandlers = {
        start_timer: () => {
          if (data_input.is_clock_in === 0) {
            handleResumeShiftFromServer(data, data_input?.is_resume_activity_tracker === 1);
          } else {
            handleStartNewShiftFromServer(data);
          }
        },
        stop_timer: () => {
          if (data_input.is_clock_out === 0) {
            handleTakeBreakFromServer(data, data_input?.stop_for_disable_tracking === 1);
          } else {
            handleEndShiftFromServer(data);
          }
        },
        extend_timer: () => {
          if (eventData.extend_type === "break") {
            extendBreakFromServer(data);
          } else {
            handleExtendTrackerDisableFromServer(data);
          }
        },
        disable_activity_tracker: () => handleDisableTracker(data),
        checkin: () => handleCheckinFromServer(data),
        clock_out: () => handleEndPausedShiftFromServer(data),
        revoke_clock_out: () => handleRevokeClockOutFromServer(data),
        recover_lost_time: () => handleRecoverLostTimeFromServer(data),
        stop_timer_last_checkin: () => handleStopTimerOnLastCheckinFromServer(data),
      };

      if (eventHandlers[event_name]) {
        eventHandlers[event_name]();
      }
    };

    socket.on("timer", handleTimerEvent);

    return () => socket.off("timer", handleTimerEvent);
  }, []);

  const [schedule, setSchedule] = useState([]);
  const [specialShiftData, setSpecialShiftData] = useState([])
  const [specialShiftBreakTimings, setSpecialShiftBreakTimings] = useState([]);
  const [userTotalShiftTimings, setUserTotalShiftTimings] = useState(STANDARD_WORK_TIMINGS)

  function resetUserShiftData() {
    // scheduled break functions
    setSpecialShiftBreakTimings([]);
    updateShowScheduledBreak(false);
    clearScheduledBreakWorker();
    // Unlimited break time enable function
    setSchedule([]);
    setSpecialShiftData([]);
    setUserTotalShiftTimings(STANDARD_WORK_TIMINGS);
    updateIsUserCompletedShift(false);
  }

  useEffect(() => {
    if (state.lastClockInTime) {
      resetUserShiftData();
      const controller = new AbortController();
      const clockInDate = convertUTCToTimezone(state.lastClockInTime);
      const formattedDate = format(clockInDate, "YYYY-MM-DD");
      getUserShiftData({ clockInTime: clockInDate, controller })
        .then((data) => {
          if (data) {
            const {
              leave,
              schedule,
              dst_schedule,
              date,
              disabled_activity_tracker_seconds,
              special_shift_timings,
              special_shift_break_timings,
              is_activity_tracked,
              is_user_on_special_shift,
              user_total_shift_timings,
            } = data;

            if (leave.length > 0) {
              let isUserOnLeave = leave.some(
                (l) =>
                  l.start_date <= formattedDate && l.end_date >= formattedDate
              );
              if (isUserOnLeave) {
                updateIsUserCompletedShift(true);
                return;
              }
            }

            const isDST = checkIsDSTActive(null, new Date(`${date} 12:00:00`));
            setSchedule(isDST ? dst_schedule : schedule);
            setSpecialShiftData(special_shift_timings);
            setUserTotalShiftTimings(user_total_shift_timings);
            setSpecialShiftBreakTimings(special_shift_break_timings);
            useTimerStore.setState({
              trackerDisabledTotalMins: Math.round(parseInt(disabled_activity_tracker_seconds) / 60),
              isActivityTracked: is_activity_tracked,
              isUserOnSpecialShift: is_user_on_special_shift,
            });
          }
        });

      return () => controller.abort();
    }
  }, [checkIsDSTActive, state.lastClockInTime]);

  useEffect(() => {
    if (!state.isShiftEnded) startAutoClockOutCheck();
    return () => clearTimeout(autoClockOutTimeOutRef.current);
  }, [startAutoClockOutCheck, state.lastClockInTime, state.isShiftEnded]);

  function updateIsUserCompletedShift(value) {
    useTimerStore.setState({ isUserCompletedShift: value });
    if (isElectronApp) {
      window.ipcRender.send("message:shiftCompleted", value);
    }
  }

  // This useEffect will calculate and update the today's user shift timings
  // To allow the user to take unlimited break timings after completing his/her day shift
  useEffect(() => {
    if (isDate(state.lastClockInTime)) {
      if (specialShiftData.length > 0) {
        updateIsUserCompletedShift(false);
      } else {
        const weekDay = getWeek(convertUTCToTimezone(state.lastClockInTime));
        const sch = schedule.find((sch) => Number(sch.weekday) === weekDay);
        // No schedule found which means the user is working on his/her off day
        if (!sch) {
          updateIsUserCompletedShift(true);
        } else {
          updateIsUserCompletedShift(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule, specialShiftData, state.lastClockInTime]);

  useEffect(() => {
    if (!state.isUserCompletedShift) {
      const checkIsUserCompletedShift = (totalShiftTime) => {
        if (totalShiftTime > userTotalShiftTimings) {
          // If user is on special shift timings, check if user crossed the last shift end timings
          if (specialShiftData.length > 0) {
            const now = new Date(convertTimeZone());
            const specialShiftEndTime = specialShiftData[specialShiftData.length - 1].end_time;
            const [startTimeHour, startTimeMin] = getTimeFromStr(specialShiftEndTime.time);

            const shiftEndTime = new Date(convertTimeZone());
            shiftEndTime.setHours(startTimeHour, startTimeMin, 0, 0);

            if (Number.isFinite(specialShiftEndTime.time_offset)) {
              shiftEndTime.setDate(shiftEndTime.getDate() + specialShiftEndTime.time_offset);
            }

            if (now.getTime() > shiftEndTime.getTime()) return true;

            return false;
          }

          return true;
        }

        return false;
      };

      // The listener below will not start until the timer is started.
      // So, check if they're in break and already completed their shift.
      if (state.isShiftStarted && !state.isTimerStarted) {
        if (checkIsUserCompletedShift(useTimerStore.getState().time)) {
          console.log('✅ USER COMPLETED SHIFT (USER IN BREAK)');
          updateIsUserCompletedShift(true);
          return;
        }
      }

      const handleTimeWorkerMessage = (e) => {
        if (e.data.command === "updateTime") {
          if (
            e.data.time > userTotalShiftTimings &&
            !state.isUserCompletedShift &&
            checkIsUserCompletedShift(e.data.time)
          ) {
            console.log('✅ USER COMPLETED SHIFT');
            updateIsUserCompletedShift(true);
          }
        }
      };

      timeWorkerInstance.addEventListener(
        "message",
        handleTimeWorkerMessage,
        false
      );

      return () => {
        timeWorkerInstance.removeEventListener(
          "message",
          handleTimeWorkerMessage
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.isUserCompletedShift,
    state.isShiftStarted,
    state.isTimerStarted,
    userTotalShiftTimings,
    specialShiftData,
  ]);

  const [showScheduledBreakBtn, setShowScheduledBreakBtn] = useState(false);

  const updateShowScheduledBreak = (value) => {
    setShowScheduledBreakBtn(value);
    if (isElectronApp) {
      window.ipcRender.send("message:showScheduledBreak", value);
    }
  }

  const startScheduledBreakWorker = (breakTimings) => {
    const getDateFormat = (date) => {
      return format(date, "DD/MM/YYYY hh:mm A");
    }

    if (Array.isArray(breakTimings) && breakTimings.length > 0) {
      const command = "worker:schedule";
      breakTimings.forEach(shift => {
        const [startTimeHour, startTimeMin] = getTimeFromStr(shift.start_time.time);
        const [endTimeHour, endTimeMin] = getTimeFromStr(shift.end_time.time);
        const now = convertUTCToTimezone(getUTCTime())

        // As per the implementation I'm checking the special shift timings and filtering out the break timings by lastClockInTime. (Please refer getUserSpecialShiftTimings function)
        // And it returns only the break timings for the current shift. But there's an case where a user revoking his clockout today and started working on his previous shift.
        // In that case the break timings is responsible for yesterday shift. So to bypass that we are using lastClockInTime in startTime and endTime.
        // Additionally by using that would also solve the issue where the user working on night shift (10.00 PM to 6.00 AM) which is break timings have today and tomorrow timings.

        const startTime = convertUTCToTimezone(state.lastClockInTime);
        startTime.setHours(startTimeHour, startTimeMin, 0, 0);
        startTime.setDate(startTime.getDate() + shift.start_time.time_offset);

        const endTime = convertUTCToTimezone(state.lastClockInTime);
        endTime.setHours(endTimeHour, endTimeMin, 0, 0);
        endTime.setDate(endTime.getDate() + shift.end_time.time_offset);

        if (endTime.getTime() > now.getTime()) {
          if (isWithinInterval(now, { start: startTime, end: endTime })) {
            updateShowScheduledBreak(true);
            const disableMs = differenceInMilliseconds(endTime, now);
            timeWorkerInstance.postMessage({
              command,
              timeout: disableMs,
              value: false,
            });
            console.log(`[SCHEDULED BREAK]: Disable on ${getDateFormat(endTime)}`);
          } else {
            const enableMs = differenceInMilliseconds(startTime, now);
            timeWorkerInstance.postMessage({
              command,
              timeout: enableMs,
              value: true,
            });
            console.log(`[SCHEDULED BREAK]: Enable on ${getDateFormat(startTime)}`);

            const disableMs = differenceInMilliseconds(endTime, now);
            timeWorkerInstance.postMessage({
              command,
              timeout: disableMs,
              value: false,
            });
            console.log(`[SCHEDULED BREAK]: Disable on ${getDateFormat(endTime)}`);
          }
        }
      })
    }
  }

  const clearScheduledBreakWorker = () => {
    timeWorkerInstance.postMessage({ command: "worker:scheduleClear" });
    updateShowScheduledBreak(false);
  }

  useEffect(() => {
    const cb = (ev) => {
      if (ev.data.command === "scheduleTimeout") {
        updateShowScheduledBreak(ev.data.value);
      }
    }

    timeWorkerInstance.addEventListener("message", cb);
    return () => timeWorkerInstance.removeEventListener("message", cb);
  }, []);

  useEffect(() => {
    if (state.isTimerStarted && specialShiftBreakTimings.length > 0) {
      startScheduledBreakWorker(specialShiftBreakTimings);
      return () => clearScheduledBreakWorker();
    }
  }, [state.isTimerStarted, specialShiftBreakTimings]);

  const handleScheduleBreakClick = () => {
    const now = convertTimeZone();
    for (const shift of specialShiftBreakTimings) {
      const [startTimeHour, startTimeMin] = getTimeFromStr(shift.start_time.time);
      const [endTimeHour, endTimeMin] = getTimeFromStr(shift.end_time.time);

      const startTime = new Date(convertTimeZone())
      startTime.setHours(startTimeHour, startTimeMin, 0, 0);
      const endTime = new Date(convertTimeZone());
      endTime.setHours(endTimeHour, endTimeMin, 0, 0);

      // isWithinInterval is used to get the current break timings.
      // So we can calc correct timings
      if (isWithinInterval(now, { start: startTime, end: endTime })) {
        const mins = differenceInMinutes(endTime, now, { roundingMethod: "ceil" });
        takeBreak({ breakTakenFor: mins });
        break;
      }
    }
  }

  const shouldShowStartShiftButton = () => {
    if (!state.lastClockInTime) return true;

    const lastClockInDate = format(convertTimeZone(new Date(state.lastClockInTime + ' GMT')), "YYYY-MM-DD");
    const lastClockOutDate = state.lastClockOutTime ? format(convertTimeZone(new Date(state.lastClockOutTime + ' GMT')), "YYYY-MM-DD") : null;
    const currentDate = format(convertTimeZone(), "YYYY-MM-DD");
    const isNewDay = lastClockInDate !== currentDate;
    const isTodayClockedOut = lastClockOutDate ? lastClockOutDate === currentDate : false;

    return !state.isTimerStarted && ((!state.isShiftStarted && !isTodayClockedOut) || isNewDay);
  }

  const shouldShowRevokeClockOut = () => {
    if (!state.lastClockInTime) return false;
    let currentDate = new Date(convertTimeZone());
    const lastClockInDate = convertTimeZone(new Date(state.lastClockInTime + ' GMT'));

    let diff = parseInt(Math.abs(new Date(lastClockInDate) - currentDate) / 36e5);
    return (!state.isTimerStarted && !state.isShiftStarted && diff < 24) || state.isShiftEnded;
  }

  useEffect(() => {
    if (isElectronApp) {
      const receivers = [
        {
          channel: "startNewShift",
          listener: () => startNewShiftButtonClick()
        },
        {
          channel: "endShift",
          listener: () => handleEndShift(true)
        },
        {
          channel: "revokeClockOut",
          listener: () => revokeClockOut(),
        },
        {
          channel: "takeBreak",
          listener: ({ afkInMins = 0, toastError }) => takeBreak({ breakTakenFor: afkInMins, toastError })
        },
        {
          channel: "resumeShift",
          listener: () => resumeShift()
        },
        {
          channel: "scheduledBreak",
          listener: () => handleScheduleBreakClick(),
        },
        {
          channel: "disableTracker",
          listener: (data) => disableTracker(data),
        },
        {
          channel: "resumeTracker",
          listener: () => resumeTracker(),
        },
        {
          channel: "extend", // Extend break or tracker disable
          listener: (afkInMins = 0) => {
            if (useTimerStore.getState().onBreak) extendBreak(afkInMins)
            else extendTrackerDisable(afkInMins)
          },
        },
        {
          channel: "recoverCurrentLostTime",
          listener: (data) => recoverLostTime(data),
        },
        {
          channel: "log",
          listener: (log) => console.log("(electron) error log", log),
        },
        {
          channel: "refreshData",
          listener: () => getTimer({ initiateSocketConnection: true }),
        },
        {
          channel: "stopClock",
          listener: () => {
            timeWorkerInstance.postMessage({
              command: "worker:updateTimerStatus",
              time: useTimerStore.getState().time,
              startClock: false,
            });
          },
        },
      ]

      receivers.forEach(receiver => window.ipcRender.receive(receiver.channel, receiver.listener));

      return () => {
        receivers.forEach(receiver => window.ipcRender.removeAllListeners(receiver.channel));
      }
    }
  }, [getTimer, specialShiftBreakTimings, timeWorkerInstance])

  return (
    timerLoadedAt ? <div className={`menu-timer-wrapper ${state.isSocketEmitInProgress ? 'disable' : ''}`}>
      <button
        type="button"
        className="btn-timer"
        title={state.isShiftStarted && state.lastClockInTime ? state.lastClockInTime : ""}
        onClick={toggleMenu}
      >
        <svg
          className="timer-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="14"
          fill="none"
        >
          <path
            fill="#B1B2B3"
            d="M8.001 0H4.003v1.332h3.998V0ZM5.336 8.663h1.332V4.665H5.336v3.998Zm5.351-4.405.947-.947a7.364 7.364 0 0 0-.94-.94l-.946.947a5.997 5.997 0 1 0 .94.94Zm-4.685 8.403a4.665 4.665 0 1 1 0-9.33 4.665 4.665 0 0 1 0 9.33Z"
          />
        </svg>
        <span className="timer-value">{formatTime(state.time)}</span>
      </button>
      {isMenuOpen && (
        <div
          ref={menuRef}
          className={clsx("timer-options-wrapper", { "is-mobile": isMobile })}
        >
          {!isOnline && (
            <div className="no-online-overlay">
              <p>No internet connection.</p>
            </div>
          )}
          {state.isShiftStarted && !state.isShiftEnded ? (
            <div className="big-wrapper">
              {state.onBreak && (
                <div className="break-details-wrapper">
                  <div className="break-details">
                    <div className="timer-label-wrapper">
                      <span className="timer-label">
                        {(state.breakTakenFor !== -1 ? "" : "Untimed ") +
                          "Break"}
                      </span>
                      {state.breakTakenFor !== -1 && (
                        <span className="bold-value">
                          {`${state.breakTakenFor} ${state.breakTakenFor !== 1 ? "mins" : "min"
                            }`}
                        </span>
                      )}
                    </div>
                    <span className="timer-label sep">•</span>
                    <div className="timer-label-wrapper">
                      <span className="timer-label">Started at</span>
                      <span className="bold-value">
                        {state.breakStartedAt
                          ? format(new Date(state.breakStartedAt + ' GMT'), "hh:mm A")
                          : "12:00 AM"}
                      </span>
                    </div>
                    {state.breakStartedAt && state.breakTakenFor !== -1 && (
                      <>
                        <span className="timer-label sep">•</span>
                        <div className="timer-label-wrapper">
                          <span className="timer-label">Away</span>
                          <BreakDiff
                            timeStamp={state.breakStartedAt}
                            timeInMinutes={state.breakTakenFor}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {state.trackerDisabledFor > 0 && (
                <div className="break-details-wrapper tracker-disabled-wrapper">
                  <div className="break-details">
                    <div className="timer-label-wrapper">
                      <span className="timer-label">Tracker Disabled for</span>
                      <span className="bold-value">
                        {state.isActivityTracked ? `${state.trackerDisabledFor} ${state.trackerDisabledFor !== 1 ? "mins" : "min"}` : "Entire Shift"}
                      </span>
                    </div>
                    {state.isActivityTracked && (
                      <>
                        <span className="timer-label sep">•</span>
                        <div className="timer-label-wrapper">
                          <span className="timer-label">Started at</span>
                          <span className="bold-value">
                            {format(state.trackerDisabledAt + ' GMT', "hh:mm A")}
                          </span>
                        </div>
                        <span className="timer-label sep">•</span>
                        <div className="timer-label-wrapper">
                          <span className="timer-label">Enable in</span>
                          <span className={"bold-value"}>
                            {formatTime(
                              Math.abs(
                                getTimeDiff(
                                  state.trackerDisabledAt,
                                  state.trackerDisabledFor
                                )
                              )
                            )}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {state.trackerDisabledAt && state.isActivityTracked && <ExtendTrackerDisabled toggleMenu={toggleMenu} />}
              {state.onBreak && <ExtendBreak toggleMenu={toggleMenu} />}
              {state.isTimerStarted && (
                <Fragment>
                  <TakeBreak
                    showScheduledBreak={showScheduledBreakBtn}
                    handleScheduleBreakClick={handleScheduleBreakClick}
                    toggleMenu={toggleMenu}
                  />
                  {!state.trackerDisabledAt && state.isActivityTracked && hasFullAccess && (
                    <DisableTracker
                      options={disableTrackerOptions}
                      isMobile={isMobile}
                      hasFullAccess={hasFullAccess}
                      toggleMenu={toggleMenu}
                    />
                  )}
                </Fragment>
              )}
              <div className="timer-section">
                <div className="separator"></div>
                <div className="action-btn-wrapper">
                  {state.trackerDisabledAt && state.isActivityTracked && (
                    <button
                      className="timer-options"
                      onClick={() => {
                        toggleMenu();
                        resumeTracker();
                      }}
                      disabled={!hasFullAccess}
                    >
                      Resume Tracker
                    </button>
                  )}
                  {state.onBreak && (
                    <button
                      className="timer-options"
                      onClick={() => {
                        toggleMenu();
                        resumeShift();
                      }}
                      disabled={!hasFullAccess}
                    >
                      Resume Shift
                    </button>
                  )}
                  {state.isShiftStarted && !state.isShiftEnded && (
                    <button
                      className="timer-options"
                      onClick={() => {
                        handleEndShift();
                      }}
                    >
                      End Shift
                    </button>
                  )}
                  {shouldShowStartShiftButton() && <button
                    className="timer-options"
                    onClick={() => {
                      toggleMenu();
                      startNewShiftButtonClick();
                    }}
                    disabled={!hasFullAccess}
                  >
                    Start New Shift
                  </button>}
                  {/* <button className="timer-options" onClick={() => {
                    // if (isElectronApp) {
                    //   window?.ipcRender?.send('message:showInactivityAlertModal', {
                    //     currentLostTime: 313,
                    //     msg: 'Timer paused because the check-in was declined',
                    //     timerSettings: state.timerSettings,
                    //     MAX_RECOVERY_TIME: state.timerSettings.max_recovery_time_allowed_seconds,
                    //   });
                    // } else {
                    //   useTimerStore.setState({
                    //     inactivityPopupContent: { currentLostTime: 303, msg: 'Timer paused because the check-in was declined' },
                    //   });
                    // }
                    checkin();
                    // getTimer();
                    // window.ipcRender.send('message:stopCheckin');
                    // isScreenLockedRef.current = true
                    // disconnectSocket();
                  }}>
                    Testing
                  </button> */}
                </div>
              </div>
              {showEndShiftConfirmation && (
                <EndShiftConfirmation
                  onYes={() => {
                    toggleMenu();
                    handleEndShift(true);
                  }}
                  onNo={() => setShowEndShiftConfirmation(false)}
                  disableBtn={state.isSocketEmitInProgress}
                />
              )}
            </div>
          ) : (
            <div className={`small-wrapper ${isMobile && !hasFullAccess ? 'show-no-access-msg' : ''}`}>
              {isMobile && !hasFullAccess ? (
                <div className="no-access-overlay">
                  <p>You can only extend breaks or end your shift via mobile.</p>
                </div>
              ) : (
                <>
                  {shouldShowStartShiftButton() && (
                    <button
                      className="timer-options"
                      onClick={() => {
                        toggleMenu();
                        startNewShiftButtonClick();
                      }}
                      disabled={!hasFullAccess}
                    >
                      Start New Shift
                    </button>
                  )}
                  {shouldShowRevokeClockOut() && (
                    <>
                      <button
                        className="timer-options"
                        onClick={() => {
                          toggleMenu();
                          revokeClockOut();
                        }}
                        disabled={!hasFullAccess || state.hasRevokedClockOut}
                      >
                        Revoke Clock Out
                      </button>
                      {state.hasRevokedClockOut && (
                        <p className="revoke-clock-out-label">
                          <span className="icon-info"></span>
                          <span>Revoke clock out is allowed once</span>
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
      {state.alertContent && (
        <AlertPopup
          icon="warning"
          title={state.alertContent.title}
          description={state.alertContent.description}
          actionButtons={state.alertContent.actionButtons}
          displayFullScreen={true}
        />
      )}
      {state.inactivityPopupContent && (
        <InactivityPopup
          disableTrackerOptions={disableTrackerOptions}
          inactivityPopupContent={state.inactivityPopupContent}
          closeInactivityPopup={() => {
            useTimerStore.setState({
              inactivityPopupContent: null,
            });
          }}
        />
      )}
    </div> : <div className="timer-placeholder">00:00:00</div>
  );
}

function TakeBreak({ showScheduledBreak = false, handleScheduleBreakClick, toggleMenu }) {
  const [breakInputMins, setBreakInputMins] = useState(0);
  const { max_break_allowed_mins = 60 } = useTimerStore(state => state.timerSettings)
  const isUserCompletedShift = useTimerStore(state => state.isUserCompletedShift);

  return (
    <div className="timer-section">
      <div className="timer-section-inner">
        <p className="timer-label">Take a Break</p>
        <TimerMinOptions
          onClickOk={() => {
            toggleMenu();
            takeBreak({ breakTakenFor: breakInputMins });
          }}
          inputMax={isUserCompletedShift ? ONE_DAY_IN_MINUTES : max_break_allowed_mins}
          inputValue={breakInputMins}
          setInputValue={setBreakInputMins}
          onOptionClick={(breakTakenFor) => {
            toggleMenu();
            takeBreak({ breakTakenFor });
          }}
        />
        {showScheduledBreak && <button className="timer-options" onClick={handleScheduleBreakClick}>Scheduled Break</button>}
      </div>
    </div>
  );
}

function ExtendBreak({ toggleMenu }) {
  const [extendInputMins, setExtendInputMins] = useState(0);
  const { max_break_allowed_mins = 60 } = useTimerStore(state => state.timerSettings);
  const isUserCompletedShift = useTimerStore(state => state.isUserCompletedShift);

  return (
    <div className="timer-section">
      <div className="timer-section-inner">
        <p className="timer-label">Extend</p>
        <TimerMinOptions
          onOptionClick={(mins) => {
            toggleMenu();
            extendBreak(mins);
          }}
          inputMax={isUserCompletedShift ? ONE_DAY_IN_MINUTES : max_break_allowed_mins}
          onClickOk={() => {
            toggleMenu();
            setExtendInputMins(0);
            extendBreak(extendInputMins)
          }}
          inputValue={extendInputMins}
          setInputValue={setExtendInputMins}
        />
      </div>
    </div>
  );
}

function DisableTracker({
  options,
  isMobile = false,
  toggleMenu,
}) {
  const state = useTimerStore();
  const { trackerDisabledTotalMins: totalTrackerDisabledMins, timerSettings } = state;
  const maxTrackerDisableAllowed = timerSettings.max_disable_activity_tracker_allowed_mins || 60;

  const [selectedActivity, setSelectedActivity] = useState(null);
  const [disablingReason, setDisablingReason] = useState("");
  const [selectedDisableTrackerInMins, setSelectedDisableTrackerInMins] =
    useState(0);
  const [disableTrackerInputMins, setDisableTrackerInputMins] = useState(0);

  const [disableActivityTrackerSubmitError, setDisableActivityTrackerSubmitError] = useState("");

  const onSubmit = () => {
    const disableActivityMins = selectedDisableTrackerInMins || disableTrackerInputMins;
    const totalDisableMins = disableActivityMins + totalTrackerDisabledMins;

    if (totalDisableMins > maxTrackerDisableAllowed) {
      const minsLeft = Math.max(maxTrackerDisableAllowed - totalTrackerDisabledMins, 0);
      setDisableActivityTrackerSubmitError(
        `Activity tracker: ${maxTrackerDisableAllowed} min/day limit, ${minsLeft} ${minsLeft < 10 ? "min" : "mins"} left.`
      );
      return;
    }

    toggleMenu();
    disableTracker({ activityId: selectedActivity, disableActivityMins, reason: disablingReason });
  };

  return (
    <div className="timer-section">
      <div className="separator"></div>
      <div className="timer-section-inner">
        <p className="timer-label">Disable Activity Tracker</p>
        <TimerMinOptions
          showOkButton={false}
          selectedOption={selectedDisableTrackerInMins}
          onOptionClick={(mins) => {
            if (disableTrackerInputMins > 0) setDisableTrackerInputMins(0);
            setSelectedDisableTrackerInMins(
              selectedDisableTrackerInMins === mins ? 0 : mins
            );
          }}
          inputMax={maxTrackerDisableAllowed}
          inputValue={disableTrackerInputMins}
          setInputValue={(cb) => {
            if (selectedDisableTrackerInMins)
              setSelectedDisableTrackerInMins(0);
            setDisableTrackerInputMins(cb);
          }}
        />
      </div>
      {(selectedDisableTrackerInMins > 0 || disableTrackerInputMins > 0) && (
        <div className="selected-activity-wrapper">
          <div className="activity-tracker">
            {isMobile ? (
              <select
                className="timer-options timer-options-select"
                value={selectedActivity ?? ""}
                onChange={e => {
                  const value = parseInt(e.target.value)
                  setSelectedActivity(isNaN(value) ? null : value)
                }}
              >
                <option value="">Select Activity</option>
                {options.map((value) => (
                  <option key={value.id} value={value.id}>
                    {value.name}
                  </option>
                ))}
              </select>
            ) : options.map((value) => {
              return (
                <button
                  key={value.id}
                  className={clsx("timer-options capitalize", {
                    active: selectedActivity === value.id,
                  })}
                  onClick={() => setSelectedActivity(selectedActivity === value.id ? null : value.id)}
                >
                  {value.name}
                </button>
              );
            })}
          </div>
          <textarea
            placeholder="Reason"
            className="reason-input"
            value={disablingReason}
            onChange={(e) => setDisablingReason(e.target.value)}
          />
          {disableActivityTrackerSubmitError && (
            <div className="activity-tracker-limit-error-wrapper">
              <ActivityTrackerSubmitErrorIcon />
              <p className="timer-label" style={{ fontSize: "11px" }}>
                {disableActivityTrackerSubmitError}
              </p>
            </div>
          )}
          <button
            className="timer-options btn-submit"
            disabled={!selectedActivity || disablingReason.trim().length === 0}
            onClick={onSubmit}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
}

function ExtendTrackerDisabled({ toggleMenu }) {
  const state = useTimerStore();
  const { trackerDisabledTotalMins: totalTrackerDisabledMins, trackerDisabledFor, timerSettings } = state;
  const maxTrackerDisableAllowed = timerSettings.max_disable_activity_tracker_allowed_mins || 60;

  const [extendInputMins, setExtendInputMins] = useState(0);
  const [submitError, setSubmitError] = useState("");

  const handleExtendTrackerDisable = (mins) => {
    const totalMins = totalTrackerDisabledMins + trackerDisabledFor + mins;
    if (totalMins > maxTrackerDisableAllowed) {
      setSubmitError(
        `Activity tracker: ${maxTrackerDisableAllowed} min/day limit, ${Math.max(maxTrackerDisableAllowed - (totalTrackerDisabledMins + trackerDisabledFor), 0)} min left to extend.`
      );
      return;
    }

    toggleMenu();
    extendTrackerDisable(mins);
  }

  const handleInputOk = () => {
    handleExtendTrackerDisable(extendInputMins);
  }

  const handleOptionClick = (mins) => {
    handleExtendTrackerDisable(mins);
  }

  return (
    <div className="timer-section">
      <div className="timer-section-inner">
        <p className="timer-label">Extend</p>
        <TimerMinOptions
          onOptionClick={handleOptionClick}
          inputMax={maxTrackerDisableAllowed}
          onClickOk={() => {
            setExtendInputMins(0);
            handleInputOk();
          }}
          inputValue={extendInputMins}
          setInputValue={setExtendInputMins}
        />
      </div>
      {submitError && (
        <div className="activity-tracker-limit-error-wrapper">
          <ActivityTrackerSubmitErrorIcon />
          <p className="timer-label" style={{ fontSize: "11px" }}>
            {submitError}
          </p>
        </div>
      )}
      <div className="separator"></div>
    </div>
  );
}

function NumberInput({ max = 60, value, setValue, disabled }) {
  const inputRef = useRef(null);

  const handleInc = () => {
    const newValue = value + 1;
    if (newValue > max) addShakeEffectToEl(inputRef.current);
    else setValue(newValue);
  };

  const handleDec = () => {
    const newValue = value - 1;
    if (newValue < 0) addShakeEffectToEl(inputRef.current);
    else setValue(newValue);
  };

  const handleChange = (e) => {
    setValue((prevValue) => {
      if (prevValue === 0 && isNaN(e) && +e.target.value % 10 === 0) {
        return +e.target.value / 10;
      }
      return isNaN(e) ? +e?.target?.value : +e;
    });
  };

  const handleKeyDown = (e) => {
    let currentValue = isNaN(+e.key) ? "" : +e.key;
    let finalValue = e.target.value + currentValue;
    const isGreaterThanMax = finalValue > max;

    if (
      (e.key === "ArrowUp" && isGreaterThanMax) ||
      (e.key === "ArrowDown" && finalValue <= 1 && finalValue !== "")
    ) {
      addShakeEffectToEl(inputRef.current);
    }

    const isBackspace = e.key === "Backspace";
    const isDelete = e.key === "Delete";
    const isArrow = e.key.includes("Arrow");

    if (
      (isGreaterThanMax && !isBackspace && !isDelete && !isArrow) ||
      ["e", "E", "-", "+", "."].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className="mins-input">
      <button className="btn-arithmetic btn-minus" onClick={handleDec} disabled={disabled}></button>
      <input
        ref={inputRef}
        type="number"
        min="1"
        max={60}
        id="disable-activity-input"
        name="disable-activity-input"
        className="user-input"
        placeholder="0"
        value={value === 0 ? "" : value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
      <button className="btn-arithmetic btn-plus" onClick={handleInc} disabled={disabled}></button>
    </div>
  );
}

function TimerMinOptions({
  showOkButton = true,
  onClickOk,
  selectedOption,
  onOptionClick,
  inputMax,
  inputValue,
  setInputValue,
  disabled,
}) {
  return (
    <div className="multiple-options-wrapper">
      {[5, 10, 15, 30, 45, 60].map((mins, index) => (
        <button
          key={index}
          className={clsx("timer-options", { active: selectedOption === mins })}
          onClick={() => onOptionClick?.(mins)}
          disabled={disabled}
        >
          {mins}
        </button>
      ))}
      <div className="pipe"></div>
      <div className="custom-input-wrapper">
        <NumberInput max={inputMax} value={inputValue} setValue={setInputValue} disabled={disabled} />
        {showOkButton && (
          <button
            className="timer-options"
            onClick={onClickOk}
            disabled={disabled || selectedOption || !inputValue || inputValue <= 0}
          >
            OK
          </button>
        )}
      </div>
    </div>
  );
}
