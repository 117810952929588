import React from "react";
import { format } from "utils/date";
import { formatTime } from "../utils";
import { weekdays } from "../constants";
import "./StandardShiftDetailCard.scss";

export default function StandardShiftDetailCard({ data, dst_data, label = null, trimWeekDay = null, moreSpace = false }) {
  const hasDST = !!dst_data;
  return (
    <div className="standard-shift-wrapper">
      {data[0]?.effective_start_date && <div className="standard-shift">
        <div className="standard-shift-date-wrapper">
          <div className={`standard-shift-date-range ${hasDST ? 'has-label' : ''}`}>
            <span>{format(new Date(data[0]?.effective_start_date), 'ddd, MMM DD')}</span>
          </div>
        </div>
      </div>}
      <div className='standard-shift-data-wrapper'>
        <div className='weekday'>
          {hasDST && <div>&nbsp;</div>}
          {
            data.map((shift) => {
              const day = weekdays[shift.weekday];
              const weekday = trimWeekDay ? day.slice(0, trimWeekDay) : day;
              return <div key={day} className='weekday-name'>{weekday}</div>
            })
          }
        </div>
        <StandardShiftDetails data={data} label={dst_data ? "Standard Time" : label} hasDST={!!dst_data} />
        {dst_data && <StandardShiftDetails data={dst_data} label="Daylight saving Time" isDST={true} hasDST={hasDST} />}
      </div>
    </div>
  );
}

const StandardShiftDetails = ({ data, label, isDST, hasDST }) => {
  return (
    <div className={`time-wrapper ${isDST ? 'daylight-time' : ''}`}>
      {hasDST && <div className="request-card-label">{label}</div>}
      {data?.map((shift, index) => {
        const formattedStartTime = formatTime(shift.start_time.time).replace(':', ' : ').replace('-', '');
        const formattedEndTime = formatTime(shift.end_time.time).replace(':', ' : ').replace('-', '');
        const isNegativeStartTime = shift.start_time.time.startsWith('-');
        const isNegativeEndTime = shift.end_time.time.startsWith('-');

        return (
          <div className={`shift-time ${isDST ? '' : ''}`} key={index}>
            <div className={isNegativeStartTime ? "icon-info-bubble-after-wrapper" : ''}>
              {formattedStartTime}
              {/* {isNegativeStartTime && (
                <MouseTooltip
                  asChild
                  content={
                    <span>
                      Clock-In will occur a day before in your selected timezone
                    </span>
                  }
                  style={{ lineHeight: "17px" }}
                >
                  <span className="icon-info-bubble-absolute" />
                </MouseTooltip>
              )} */}
            </div>
            <span className="hyphen"></span>
            <div className={isNegativeEndTime ? "icon-info-bubble-after-wrapper" : ''}>
              {formattedEndTime}
              {/* {isNegativeEndTime && (
                <MouseTooltip
                  asChild
                  content={
                    <span>
                      Clock-Out will occur a day before in your selected timezone
                    </span>
                  }
                  style={{ lineHeight: "17px" }}
                >
                  <span className="icon-info-bubble-absolute" />
                </MouseTooltip>
              )} */}
            </div>
          </div>
        );
      })}
    </div>
  )
}