import { useState } from "react";
import TabList from "./TabList";
import { hasPrivilege } from "./Utils";


const TabStack = ({ handleTabClick, handleTabClose, index, isActive, isTabClosing, showAddButton, showChevron, showSubTabs, subTabsInList, tab, toggleSubTabs, addButtonCallBackRefs, SITE_PREFIX }) => {
    const [showTabStack, setShowTabStack] = useState(null);
    const [tabListSearch, setTabListSearch] = useState('');
    const hasAddBtnPrivilege = (typeof tab.showAddButton === 'boolean' ? tab.showAddButton : typeof (tab.showAddButton) === 'object' && Array.isArray(tab.showAddButton) ? hasPrivilege(SITE_PREFIX, { privilege: tab.showAddButton }) : false);

    return (
        <div
            className={`stack-wrapper stack-${tab.id} ${showSubTabs || isActive ? 'expanded' : ''} ${isTabClosing ? 'stack-closing' : ''}`}
            onMouseOver={() => !showAddButton && subTabsInList.length > 0 && setShowTabStack(tab.id)}
            onMouseOut={() => !showAddButton && subTabsInList.length > 0 && !tabListSearch.trim() && setShowTabStack(null)}
        >
            <button
                className={`icon-stack`}
                onClick={() => {
                    setShowTabStack('');
                    if (showAddButton) {
                        addButtonCallBackRefs.current?.[tab.id]?.()
                    } else {
                        toggleSubTabs(tab, tab.subTabs);
                    }
                }}
            >
                {showAddButton && <span className='icon-plus'></span>}
                {showChevron && <span className={`icon-chevron  ${showSubTabs ? 'open' : ''}`}></span>}
            </button>
            <TabList
                id={tab.id}
                isTabExpanded={showSubTabs}
                showTabStack={showTabStack}
                showAddButton={hasAddBtnPrivilege}
                handleTabClick={handleTabClick}
                tabs={subTabsInList}
                onClose={handleTabClose}
                parentIndex={index}
                onAddButtonClick={() => addButtonCallBackRefs.current?.[tab.id]?.()}
                closeList={() => setShowTabStack(null)}
                tabListSearch={tabListSearch}
                setTabListSearch={setTabListSearch}
            />
        </div>
    )
}

export default TabStack