import { useCallback, useEffect, useRef } from 'react';

import APIService from 'services/apiService';
import { alertService } from 'services/alertService';
import { API_BASE_URL } from "components/Constants.js";

import './Maintenance.scss';

const refreshWindow = () => window.location.reload();
const TIMEOUT = (5 * 1000) + 100;

const Maintenance = () => {
    const timeoutIdRef = useRef(null);

    const checkMaintenanceStatus = useCallback(async (controller = new AbortController()) => {
        try {
            const response = await APIService.apiRequest(
                `${API_BASE_URL}/user_preference`,
                null,
                false,
                "GET",
                controller
            );
            if (response.status === 1) {
                refreshWindow();
            } else {
                timeoutIdRef.current = setTimeout(() => {
                    checkMaintenanceStatus();
                }, TIMEOUT);
            }
        } catch (err) {
            alertService.warning(err.msg);
        };
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        timeoutIdRef.current = setTimeout(() => {
            checkMaintenanceStatus(abortController);
        }, TIMEOUT);

        return () => {
            abortController.abort();
            clearTimeout(timeoutIdRef.current);
        }
    }, [checkMaintenanceStatus]);

    return (
        <div className="maintenance-wrapper">
            <div className="maintenance-content">
                <h1 className="maintenance-header">Under Maintenance</h1>
                <p className="maintenance-body">We are currently undergoing maintenance. Please check back later.</p>
                <button className="refresh-button" onClick={refreshWindow}>Refresh</button>
            </div>
        </div>
    )
}

export default Maintenance
