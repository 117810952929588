export default class EventEmitter {
  constructor() {
    /** @type {Map<string|symbol, Function[]>} */
    this.events = new Map();
  }

  /**
   * Register an event handler for the given type.
   * @param {string|symbol} type Type of event to listen for, or `'*'` for all events
   * @param {Function} handler Function to call in response to given event
   * @return {Function}  A function that will unregister this event handler when called
   */
  subscribe(type, handler) {
    const handlers = this.events.get(type);
    if (handlers) {
      handlers.push(handler);
    } else {
      this.events.set(type, [handler]);
    }

    return () => {
      this.unsubscribe(type, handler)
    };
  }

  /**
   * Remove an event handler for the given type.
   * If `handler` is omitted, all handlers of the given type are removed.
   * @param {string|symbol} type Type of event to unregister `handler` from (`'*'` to remove a wildcard handler)
   * @param {Function} [handler] Handler function to remove
   */
  unsubscribe(type, handler) {
    const handlers = this.events.get(type);
    if (handlers) {
      if (handler) {
        handlers.splice(handlers.indexOf(handler) >>> 0, 1);
      } else {
        this.events.set(type, []);
      }
    }
  }

  /**
   * Invoke all handlers for the given type.
   * If present, `'*'` handlers are invoked after type-matched handlers.
   *
   * Note: Manually firing '*' handlers is not supported.
   *
   * @param {string|symbol} type The event type to invoke
   * @param {any} [evt] Any value (object is recommended and powerful), passed to each handler
   */
  emit(type, evt) {
    let handlers = this.events.get(type);
    if (handlers) {
      handlers.slice().map((handler) => {
        handler(evt);
        return false;
      });
    }

    handlers = this.events.get("*");
    if (handlers) {
      handlers.slice().map((handler) => {
        handler(type, evt);
        return false;
      });
    }
  }

  /**
   * Register an event handler for once of the given type.
   * @param {string|symbol} type Type of event to listen for an event
   * @param {Function} handler Function to call in response to given event
   */
  once(type, handler) {
    const unsubscribe = this.subscribe(type, (evt) => {
      unsubscribe();
      handler(evt);
    });
  }

  /**
   * Remove all event handlers.
   * @param {string|symbol} [type] Type of event to unregister `handler` from (`'*'` to remove a wildcard handler)
   */
  clear(type) {
    if (type) {
      this.events.delete(type);
    } else {
      this.events.clear();
    }
  }
}