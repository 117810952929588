export default function ActivityTrackerSubmitErrorIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" fill="none">
      <path
        fill="red"
        d="M5.71 7.708h1.43V3.424H5.706l.004 4.284Zm5.734-4.72a7.888 7.888 0 0 0-1.007-1.006 6.425 6.425 0 1 0 1.007 1.006Zm-5.02 9.003a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1-.004 9.995h.005Z"
      />
      <path fill="red" d="M5.711 8.926h1.43v1.43h-1.43z" />
    </svg>
  )
}