import React from "react";
import "./LeaveDetailCard.scss";
import { format } from "utils/date";

export default function LeaveDetailsCard({ data }) {
  return (
    <div className="leave-details">
      <div className="leave-details-body">
        {data?.map((leave, index) => {
          return (
            <div className="leave-day" key={index}>
              <div className="leave-day-range">
                <span className="leave-day-range-start">{format(new Date(leave.start_date), 'ddd, MMM DD')}</span>
                <div className="hyphen"></div>
                <span className="leave-day-range-end">{format(new Date(leave.end_date), 'ddd, MMM DD')}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
