import { useState, useEffect } from "react";

// components
import * as Constants from 'components/Constants';
import Avatar from 'components/Avatar'
import ClickOutsideListner from 'components/ClickOutsideListner'
import CandidateDetails from "./CandidateDetails";
import CandidateComments, { CommentsContextProvider } from "./CandidateComments";
import Questionnaire from "./Questionnaire";
import LineLoader from "components/LineLoader";
import { questionsData } from 'views/Forms/constants';
import { RECRUITER_BASE_URL } from 'views/RecruitmentFunnel/Constants';

// services
import { alertService } from 'services/alertService';
import APIService from "services/apiService";

export default function CandidateProfileModal({ candidate, funnel, toggle, onHired, onRejected, onRemoved, onEmailUpdate }) {
    const [activeTab, setActiveTab] = useState('basic_info');

    const hasCommentPrivilege = funnel.recruitment_funnel[0].privileges.includes('COMMENT')
    const isFunnelArchived = funnel.recruitment_funnel[0].is_archive === 1
    const isCandidateHired = candidate.status === 'Employee'
    const isCandidateRejected = candidate.status === 'Rejected'
    const isCandidateBlacklisted = candidate.status === 'BlackListed'

    // Used to disable all the update buttons when an status update is happening
    const [statusUpdateInProgress, setStatusUpdateInProgress] = useState(false);
    const disableUpdateBtns = isFunnelArchived || isCandidateHired || isCandidateRejected || isCandidateBlacklisted || statusUpdateInProgress

    const handleStatusChange = (statusId) => {
        setStatusUpdateInProgress(true)

        const apiPayLoad = {
            "status_id": statusId
        };

        APIService.apiRequest(
            Constants.API_BASE_URL + `/resource_status_change/${candidate.id}`,
            apiPayLoad,
            false,
            'PUT'
        ).then(response => {
            setStatusUpdateInProgress(false)

            if (response.status === 1) {
                alertService.success('Status has been updated');

                if (statusId === 4) {
                    onHired({ candidate, statusId })
                }

                if (statusId === 10) {
                    onRejected({ candidate, statusId })
                }
            } else {
                alertService.error(response.msg);
                console.log('handleStatusChange else', response);
            }
        }).catch(error => {
            alertService.error('Error: ' + error.msg);
            console.log('handleStatusChange error', error);
            setStatusUpdateInProgress(false)
        })
    }

    const handleCandidateRemove = () => {
        const apiPayLoad = {
            "resource_id": candidate.id,
            "recruitment_funnel_stage_id": candidate.stage.id
        };

        setStatusUpdateInProgress(true);

        APIService.apiRequest(Constants.API_BASE_URL + `/recruiter/remove_resource_stage`, apiPayLoad, false, 'PUT')
            .then(response => {
                if (response.status === 1) {
                    onRemoved(candidate)
                    alertService.success('Candidate has been removed successfully');
                    toggle()
                }


                setStatusUpdateInProgress(false);
            })
            .catch(err => {
                setStatusUpdateInProgress(false);
                alertService.error('Error: ' + err.msg);
                console.log('handleRemoveCandidate error', err);
            });
    }

    const [isQuestionnaireFetched, setIsQuestionnaireFetched] = useState(false);
    const [isQuestionnaireFetching, setIsQuestionnaireFetching] = useState(false);
    const [questions, setQuestions] = useState([]);
    useEffect(() => {
        const controller = new AbortController();
        const fetchQuestionnaire = async () => {
            if (isQuestionnaireFetched) return;
            setIsQuestionnaireFetching(true)
            try {
                const url = new URL(RECRUITER_BASE_URL + `/questionnaire_response`);
                url.search = new URLSearchParams({
                    resource_id: candidate.id,
                    recruitment_funnel_stage_id: candidate.stage.id
                }).toString();

                const res = await APIService.apiRequest(url.toString(), null, false, "GET")
                if (res.status === 1 && res.output.response.length > 0) {
                    setQuestions(questionsData.map(item => {
                        return {
                            title: item.title,
                            qa: item.questions.map(q => ({
                                question: q.question,
                                answer: res.output.response.find(r => String(r.question_id) === String(q.id))?.answer ?? 'Not answered',
                            }))
                        }
                    }))
                    setIsQuestionnaireFetched(true)
                } else if (res.status === 0 && res.msg === 'No Response received') {
                    setIsQuestionnaireFetched(true)
                } else {
                    alertService.error(res.error)
                }
            } catch (error) {
                console.log('fetchQuestionnaire error', error);
                alertService.error('Something went wrong. Please try again later.')
            } finally {
                setIsQuestionnaireFetching(false)
            }
        }

        if (activeTab === 'questionnaire') fetchQuestionnaire();
        return () => controller.abort();
    }, [activeTab])

    const hasQuestionnaireStage = funnel?.stages?.find(stage => stage.is_questionnaire === 1);

    return (
        <ClickOutsideListner onOutsideClick={toggle}>
            <div className={`console-panel-wrapper ${activeTab === 'questionnaire' ? 'expand-width' : ''}`}>
                <div className="console-header-wrapper">
                    <div className="header-title">
                        <div className='candidate-info'>
                            <Avatar
                                imgSrc={candidate.img_url}
                                alt={`${candidate.first_name}'s pic`}
                                firstName={candidate.first_name}
                                lastName={candidate.last_name}
                                height={20}
                                width={20}
                                fontSize={10}
                            />
                            <h4>{`${candidate.first_name} ${candidate.last_name ?? ''}`}</h4>
                        </div>
                        <button
                            type='button'
                            className='btn-close-panel'
                            title='Close side panel'
                            onClick={toggle}
                        >
                        </button>
                    </div>
                </div>
                <div className="console-content-wrapper">
                    <LineLoader show={isQuestionnaireFetching} position="absolute" style={{ top: '0px' }} />
                    <div className='tabbar'>
                        <button
                            className={activeTab === 'basic_info' ? 'selected' : ''}
                            onClick={() => setActiveTab('basic_info')}
                        >
                            Basic Info
                        </button>
                        {hasQuestionnaireStage && (
                            <button
                                className={activeTab === 'questionnaire' ? 'selected' : ''}
                                onClick={() => setActiveTab('questionnaire')}
                            >
                                Questionnaire
                            </button>
                        )}
                        {
                            hasCommentPrivilege &&
                            <button
                                className={activeTab === 'comments' ? 'selected' : ''}
                                onClick={() => setActiveTab('comments')}
                            >
                                Comments
                            </button>
                        }
                    </div>
                    {/* Tabs */}
                    <div className='tabs-out details' style={{ display: activeTab !== 'basic_info' ? 'none' : '' }}>
                        <CandidateDetails candidate={candidate} onEmailUpdate={onEmailUpdate} />
                    </div>
                    <div className='tabs-out details' style={{ display: activeTab !== 'questionnaire' ? 'none' : '' }}>
                        <Questionnaire questions={questions} isQuestionnaireFetching={isQuestionnaireFetching} />
                    </div>
                    <div className="comments-container" style={{ display: activeTab !== 'comments' ? 'none' : '' }}>
                        <CommentsContextProvider>
                            <CandidateComments
                                candidate={candidate}
                                funnel={funnel}
                            />
                        </CommentsContextProvider>
                    </div>
                </div>
                <div className="console-footer-wrapper">
                    <div className='console-action-btn'>
                        <button
                            className={`btn-with-icon btn-approve btn-small ${disableUpdateBtns ? 'btn-disabled' : ''}`}
                            disabled={disableUpdateBtns}
                            onClick={() => handleStatusChange(4)}
                        >
                            <i></i><span>Hire</span>
                        </button>

                        <button
                            className={`btn-with-icon btn-cancel btn-small ${disableUpdateBtns ? 'btn-disabled' : ''}`}
                            disabled={disableUpdateBtns}
                            onClick={() => handleStatusChange(10)}
                        >
                            <i></i><span>Reject</span>
                        </button>

                        <button
                            className={`btn-with-icon btn-remove btn-small ${disableUpdateBtns ? 'btn-disabled' : ''}`}
                            disabled={disableUpdateBtns}
                            onClick={handleCandidateRemove}
                        >
                            <i></i><span>Remove</span>
                        </button>
                    </div>
                </div>
            </div>
        </ClickOutsideListner>
    )
}