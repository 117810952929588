export const transitionDuration = 300;
export const transitionDurationExtended = 800;

export const HomeTabWidth = 36;
export const MainTabGap = 2;
export const SubTabGap = 6;
export const MarginLeft = 5;
export const SubTabPadding = 20;

export const SubTabWrapperPadding = MarginLeft * 2;
export const StackButtonWidth = 32 + MarginLeft;
export const AvatarSize = 20 + SubTabGap;

export const fontStyle = '600 12px "Open Sans", Arial, sans-serif';