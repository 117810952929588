import { useEffect } from "react";
import SuperLink from "components/SuperTabs/SuperLink";
// constants
import { SITE_PAGES } from "components/Navigation.js";
import * as Constants from "components/Constants.js";
// hooks
import useUserData from "hooks/useUserData";
import useWindowDimensions from "hooks/useWindowDimensions";
// services
import APIService from "services/apiService";
import { alertService } from "services/alertService";

// utils
import { checkPrivilege } from "utils/Common";

// styles
import "./NewHome.scss";

export default function NewHome() {
  const { user } = useUserData();
  const { width } = useWindowDimensions();
  const isMobile = width < 640;
  // const userPrivileges = Object.values(user.privileges).flat();

  useEffect(() => {
    const abortController = new AbortController();
    APIService.apiRequest(
      Constants.API_BASE_URL + "/user_preference",
      null,
      false,
      "GET",
      abortController,
    )
      .then((res) => {
        if (res.status !== 1 && !res?.message?.includes("aborted")) {
          alertService.error(res.status + ": " + res.msg);
        }
      })
      .catch((err) => {
        console.log("user_preference - error", err);
      });
    return () => abortController.abort();
  }, []);

  return (
    <div className="home-container">
      {SITE_PAGES.map((page, index) => {
        // if (!userPrivileges.includes(page.privilege)) {
        //   return null;
        // }
        if (
          (isMobile && !page?.showInMobile) ||
          !checkPrivilege(user.privileges, page.privilege)
        ) {
          return null;
        }

        return (
          <div key={index} className="module">
            <SuperLink tab={page} isSubTab={false}>
              {page.title}
            </SuperLink>
          </div>
        );
      })}
    </div>
  );
}
