import { Fragment } from 'react'
import './Questionnaire.scss'

const Questionnaire = ({ questions, isQuestionnaireFetching }) => {
    return (
        <div className="recruiter-questionairre-wrapper">
            {!isQuestionnaireFetching && questions.length > 0 ? (
                questions.map((item, index) => {
                    return (
                        <div className="questions-wrapper" key={index}>
                            <div className="group">
                                <div className="group-title">{item.title}</div>
                                {item.qa?.length > 0
                                    ? item.qa.map((item, index, arr) => {
                                        return (
                                            <Fragment key={index}>
                                                <div className="question-wrapper">
                                                    <label>{item.question}</label>
                                                    <div className="answer">{item.answer}</div>
                                                </div>
                                                {index !== arr.length - 1 ? <hr /> : null}
                                            </Fragment>
                                        );
                                    })
                                    : null}
                            </div>
                        </div>
                    );
                })
            ) : !isQuestionnaireFetching && questions.length === 0 ? (
                <div className="no-response">No response received</div>
            ) : null}
            {/* <Question title="Additional information" placeholder="Add a cover letter or anything else you want to share." /> */}
        </div>
    );
}



export default Questionnaire