import React, { useEffect, useLayoutEffect, useRef, useState, useCallback, useContext } from "react";
import * as Constants from 'components/Constants';
import { BREAK_SUB_OPTIONS, INACTIVITY_SUB_OPTIONS, SHIFT_COMPARE_OPTIONS, STANDARD_WORK_TIMINGS_OPTION_KEY } from '../../constants';
import { AL_BASE_URL } from "views/Attendance/constants";
// components
// import HoverText from 'react-hover-text';
import HeaderSubMenu from './HeaderSubMenu'
import BreakDetails from './BreakDetails';
import InactivityDetails from './InactivityDetails';
import ShiftTotalHeader from './ShiftTotalHeader';
import { MouseTooltip } from "components/ToolTip";
import ClockTimeDetails from './ClockTimeDetails';
import ShiftLog, { SkeletonShiftLog } from "./ShiftLog";
import AttendanceValue from "./AttendanceValue";
// context
import { TimeWorkerContext } from "Main";
import { useTeamMemberFilter } from "../../context/teamMemberFilterContext";
import { useSocket } from "socket";
// hooks
import { useLocalStorage } from 'hooks/useLocalStorage';
import useClickPreventionOnDoubleClick from "hooks/useClickPreventionOnDoubleClick";
// services
import { alertService } from "services/alertService";
import APIService from "services/apiService";
// utils
import { convertHhmmToUserTimezone, convertTimeZone, convertUTCToTimezone, getDateFromHhmm, getMyTimezoneOffset, isElectronApp } from "utils/Common";
import { format, isDate, isWithinInterval, endOfMonth, startOfMonth } from "utils/date";
import { isNone, isNotNone } from '../../utils';

const SORT_BY_STATE_LOCAL_KEY = Constants.SITE_PREFIX + 'attendanceUserSortBy';
const SELECTED_AVERAGE = Constants.SITE_PREFIX + 'userSelectedAverage';
const STANDARD_SHIFT_AVERAGE = Constants.SITE_PREFIX + 'userStandarShiftAverage';

const calculateTotalTime = (val1, val2) => {
  const isVal1NotNone = isNotNone(val1);
  const isVal2NotNone = isNotNone(val2);

  if (!isVal1NotNone && !isVal2NotNone) {
    return 'None';
  } else if (!isVal1NotNone && isVal2NotNone) {
    return val2;
  } else if (isVal1NotNone && !isVal2NotNone) {
    return val1;
  } else {
    return val1 + val2;
  }
};

export default function TeamMemberAttendanceTable({
  selectedDate,
  isLoggedInUser,
  resourceId,
  showLineLoader,
  setShowLineLoader,
  isTimerStarted,
  userShiftDetails,
  setUserShiftDetails,
}) {
  const socket = useSocket();

  useLayoutEffect(() => {
    const tableWidth = document.querySelector('.attendance-table-wrapper').clientWidth - 30;
    const userTableWidth = document.querySelector('.team-member-table').clientWidth;
    const usersTableElem = document.querySelector('.users-table');
    if (usersTableElem) {
      usersTableElem.style.width = `${tableWidth - userTableWidth}px`;
    }
    return () => {
      usersTableElem.style.width = 'unset';
    }
  }, []);
  const [logs, setLogs] = useState([]);
  const [sortBy, setSortBy] = useLocalStorage(SORT_BY_STATE_LOCAL_KEY, { column: 'date', order: 'ascending', update: false });
  const [selectedAverage, setSelectedAverage] = useLocalStorage(SELECTED_AVERAGE, SHIFT_COMPARE_OPTIONS[1].options[3]);
  const selectedAverageRef = useRef(selectedAverage);
  const [standarShiftAverage, setStandarShiftAverage] = useLocalStorage(STANDARD_SHIFT_AVERAGE, false);
  const standarShiftAverageRef = useRef(standarShiftAverage);
  const [showBreaksMenu, setShowBreaksMenu] = useState(false);
  const [showInactivityMenu, setShowInactivityMenu] = useState(false);
  const timeWorkerInstance = useContext(TimeWorkerContext);

  function startTimeWorker() {
    if (!isTimerStarted) {
      timeWorkerInstance.postMessage({
        command: 'worker:updateTotalHoursStatus',
        updateTotalHours: true,
      });
    }
  }

  function stopTimeWorker() {
    if (!isTimerStarted) {
      timeWorkerInstance.postMessage({
        command: 'worker:updateTotalHoursStatus',
        updateTotalHours: false,
      });
    }
  }

  const updateSelectedAverage = (option) => {
    if (option.value === STANDARD_WORK_TIMINGS_OPTION_KEY && !standarShiftAverage) {
      setStandarShiftAverage(true);
      standarShiftAverageRef.current = true;
    } else {
      if (standarShiftAverage) {
        setStandarShiftAverage(false);
        standarShiftAverageRef.current = false;
      }

      if (option.value !== selectedAverage.value) {
        setSelectedAverage(() => option);
        selectedAverageRef.current = option;
      }
    }
  }

  const customSort = useCallback((data, sortBy) => {
    let sortedData = structuredClone(data);
    let sortByColumn = sortBy.column;
    let isAscending = sortBy.order === 'ascending';

    if (sortBy.column === 'shift_total') sortByColumn = 'total_time';
    if (sortBy.column.includes('clock')) sortByColumn += '_time';
    sortByColumn = sortByColumn.replaceAll('-', '_');

    sortedData.sort((a, b) => {
      if (a?.isEmpty && b?.isEmpty) {
        return 0; // No change in order
      }

      // Move 'a' with isEmpty: true down
      if (a?.isEmpty) {
        return 1;
      }

      // Move 'b' with isEmpty: true down
      if (b?.isEmpty) {
        return -1;
      }

      if (sortByColumn === 'total_break_time') {
        a['total_break_time'] = calculateTotalTime(a.punctual_break_time, a.late_break_time);
        b['total_break_time'] = calculateTotalTime(b.punctual_break_time, b.late_break_time);
      }
      if (a[sortByColumn] === b[sortByColumn] || sortByColumn === 'date') {
        if (isAscending) {
          return a['date'] > b['date'] ? 1 : -1;
        } else {
          return a['date'] < b['date'] ? 1 : -1;
        }
      }
      if ((a[sortByColumn] === 'None' && isAscending) || (b[sortByColumn] === 'None' && !isAscending)) return -1;
      if ((a[sortByColumn] === 'None' && !isAscending) || (b[sortByColumn] === 'None' && isAscending)) return 1;

      if (sortByColumn === 'clock_in_time' || sortByColumn === "clock_out_time") {
        const aClockTime = a[sortByColumn].toLocaleTimeString();
        const bClockTime = b[sortByColumn].toLocaleTimeString();
        return isAscending ? aClockTime > bClockTime ? 1 : -1 : aClockTime < bClockTime ? 1 : -1;
      }

      return isAscending ? a[sortByColumn] > b[sortByColumn] ? 1 : -1 : a[sortByColumn] < b[sortByColumn] ? 1 : -1;
    })

    return sortedData;
  }, []);

  const tableHeight = window.innerHeight - 92; // 90 is the height of the header
  const tableEmptyRowsCount = Math.round((tableHeight / 32) - 1); // 32 is the height of each row and -1 is for the header row

  const { startDate, endDate, filterQuery, toggleTeamMemberFilters, clear: clearFilters, handleDateChange, setResourceStartDate } =
    useTeamMemberFilter();

  useEffect(() => {
    return () => {
      clearFilters();
      toggleTeamMemberFilters(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isInitialLoad, setisInitialLoad] = useState(true);

  const controllerRef = useRef(new AbortController());
  function resetController() {
    controllerRef.current.abort();
    controllerRef.current = new AbortController();
  }

  async function getAttendanceLog() {
    const controller = controllerRef.current;
    try {
      setShowLineLoader(true);

      const url = new URL(AL_BASE_URL + `/home_page/`);
      // url.searchParams.append("search_string", resourceId);
      url.searchParams.append("start_date", startDate);
      url.searchParams.append("end_date", endDate);

      let filters = [`resource_id:${resourceId}`];
      const hasFilter = filterQuery.length > 0;
      if (hasFilter) {
        filters.push(`attendance:${filterQuery}`)
      }
      url.searchParams.append('filters', filters.join(';'));

      if (selectedAverageRef.current.value && !standarShiftAverageRef.current) {
        url.searchParams.append('shift_total_compare_with', selectedAverageRef.current.value);
      }

      const response = await APIService.apiRequest(
        url.toString(),
        null,
        false,
        "GET",
        controller
      );
      if (response.status === 1) {
        if (isInitialLoad) {
          setisInitialLoad(false);
        }

        setLogs(() => {
          let shiftTotalCompareTime = 0;

          let result = Object
            .entries(response.output)
            .reduce((acc, cur) => {
              // The user may use filters to filter out the data. In that case, the data will be an empty array.
              if (typeof cur[1]?.data?.[0] === "object") {
                acc.push({ ...cur[1].data[0] });

                if (shiftTotalCompareTime === 0 && cur[1]?.meta?.compare_time?.shift_total_compare_time) {
                  shiftTotalCompareTime = Number(cur[1].meta.compare_time.shift_total_compare_time);
                }
              } else {
                if ((cur[1]?.meta?.resource_start_date === null || cur[1]?.meta?.resource_start_date > cur[0]) && !hasFilter) {
                  acc.push({
                    "attendance": "not_started",
                    "break_count": "None",
                    "clock_in_time": "None",
                    "clock_out_time": "None",
                    "date": cur[0],
                    "day_status": "weekly_off",
                    "department": "",
                    "designation": "",
                    "expected_back_at": "None",
                    "first_name": "",
                    "img_url": "",
                    "inactivity_count": "None",
                    "inactivity_time": "None",
                    "is_pinned": 0,
                    "is_self": 0,
                    "last_name": "",
                    "late_break_count": "None",
                    "late_break_time": "None",
                    "leave_date": "None",
                    "middle_name": "",
                    "punctual_break_time": "None",
                    "resource_id": 0,
                    "special_shift_activity_tracked": "None",
                    "special_shift_time": "None",
                    "shiftTotalCompareTime": null,
                    "status": "Off",
                    "total_time": "None"
                  });
                }
              }

              return acc;
            }, [])
            .map(log => {
              const tzOffset = getMyTimezoneOffset(log.date);
              let special_shift_time = log.special_shift_time;
              if (Array.isArray(special_shift_time)) {
                special_shift_time = special_shift_time.map(shift => {
                  const [start_time, start_time_offset] = convertHhmmToUserTimezone(shift.special_shift_start_time.time, tzOffset);
                  const [end_time, end_time_offset] = convertHhmmToUserTimezone(shift.special_shift_end_time.time, tzOffset);
                  return {
                    special_shift_start_time: {
                      time: start_time,
                      time_offset: start_time_offset,
                    },
                    special_shift_end_time: {
                      time: end_time,
                      time_offset: end_time_offset,
                    }
                  }
                })
              }

              const [approved_start_time, approved_start_time_offset] = convertHhmmToUserTimezone(log.approved_start_time, tzOffset)
              const [approved_end_time, approved_end_time_offset] = convertHhmmToUserTimezone(log.approved_end_time, tzOffset)

              return {
                ...log,
                approved_start_time,
                approved_start_time_offset,
                approved_end_time,
                approved_end_time_offset,
                expected_back_at: isNotNone(log.expected_back_at) ? convertUTCToTimezone(log.expected_back_at) : log.expected_back_at,
                clock_in_time: isNotNone(log.clock_in_time) ? convertUTCToTimezone(log.clock_in_time) : log.clock_in_time,
                clock_out_time: isNotNone(log.clock_out_time) ? convertUTCToTimezone(log.clock_out_time) : log.clock_out_time,
                special_shift_time
              }
            })

          const todayDate = format(convertTimeZone(), "YYYY-MM-DD");
          const todayLog = result.find((log) => log.date === todayDate);
          if (todayLog && todayLog.status !== "clocked_out") startTimeWorker();

          result = customSort(result, sortBy);

          const emptyRowsCount = tableEmptyRowsCount - result.length;
          if (emptyRowsCount > 0) {
            for (let i = 0; i < emptyRowsCount; i++) {
              result.push({ isEmpty: true });
            }
          }

          return result;
        });
        setResourceStartDate(response.output?.[startDate].meta.resource_start_date);
      } else {
        if (!(response instanceof DOMException && response.name === 'AbortError')) {
          alertService.error(
            `Error occured while fetching user's attendance details`
          );
        }
      }
    } catch (error) {
      alertService.error(error);
    } finally {
      if (!controller.signal.aborted) {
        setShowLineLoader(false);
      }
    }
  }

  const getDaysBetweenDates = (start_date, end_date) => {
    const daysArray = [];
    let currentDate = new Date(start_date);
    let _endDate = new Date(end_date)

    while (currentDate <= _endDate) {
      daysArray.push(format(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return daysArray;
  }

  const getSkeleton = () => {
    const skeleton = [];
    const dates = getDaysBetweenDates(startDate, endDate);
    for (let i = 0; i < dates.length; i++) {
      const isToday = format(convertTimeZone()) === dates[i];

      let isWeekEnd = false;
      const day = new Date(dates[i]).getDay();
      if (day === 0 || day === 6) isWeekEnd = true;

      skeleton.push(
        <LogSkeletonRow key={`skeleton-row-${i}`} isWeekEnd={isWeekEnd} isToday={isToday} date={dates[i]} />
      )
    }
    return skeleton;
  }

  const getAttendanceContent = (data) => {
    if (Array.isArray(data) === false) {
      return null;
    }

    return data.map((log, index) => {
      if (log.isEmpty) { return <LogSkeletonRow key={`skeleton-row-${index}`} /> }
      // const lateBreakTime = isNotNone(log.late_break_time)
      //   ? log.late_break_time
      //   : 0;

      const isHoliday = log?.holiday ? isNotNone(log?.holiday) : false;
      const isToday = format(convertTimeZone()) === log.date;
      const isWeeklyOff = log.day_status === "weekly_off"
      // const hasTakenBreak = isNotNone(log.break_count);
      // const isDarkMode = document.body.dataset.theme === "dark";

      return (
        <tr key={log.date} id={log.date}>
          <td
            className={
              " " +
              (isHoliday ? "holiday" : isWeeklyOff ? "off" : "")
            }
          >
            <span className={`${isToday ? "current-day" : ""}`}>
              {format(new Date(log.date), "ddd, MMM DD, YYYY")}
            </span>
          </td>
          <td className="capitalize">
            <AttendanceValue
              data={log}
              userShiftDetails={userShiftDetails}
              setUserShiftDetails={setUserShiftDetails}
              date={log.date}
            />
          </td>
          <td>
            <ClockTimeDetails data={log} date={log.date} />
          </td>
          <td>
            <BreakDetails data={log} />
            {/* <div className={'bg-gray away-time' + (awayTime < 0 ? ' red' : '')}>{awayTime !== null ? formatTimeInSeconds(Math.abs(awayTime)) : ''}</div> */}
          </td>
          <td>
            {isNotNone(log.inactivity_count) && (
              <InactivityDetails data={log} />
            )}
          </td>
          <td>
            <ShiftLog log={log} />
          </td>
        </tr>
      );
    });
  };

  const handleSort = (colName) => {
    if (showLineLoader) return;
    const isSortableColumn = ['shift_total', 'punctual_break_time', 'late_break_time', 'inactivity_time', 'total_break_time'].includes(colName);
    const newSortBy = {
      column: colName,
      order: sortBy.column === colName ? (sortBy.order === 'ascending' ? 'descending' : 'ascending') : 'descending',
      update: isSortableColumn, // This is used for updating values dynamically
    };
    setSortBy(newSortBy);
    setLogs(customSort(logs, newSortBy));
  };

  const clearSort = () => {
    setSortBy({ column: 'date', order: 'ascending', update: false });
  }

  const toggleBreaksMenu = () => {
    setShowBreaksMenu(!showBreaksMenu);
  }

  const toggleInactivityMenu = () => {
    setShowInactivityMenu(!showInactivityMenu);
  }

  const isBreakSortingApplied = () => {
    return BREAK_SUB_OPTIONS.some(item => item.value === sortBy.column);
  }

  const isInactivitySortingApplied = () => {
    return INACTIVITY_SUB_OPTIONS.some(item => item.value === sortBy.column);
  }

  const [handleBreakHeaderClick, handleBreakHeaderDoubleClick] = useClickPreventionOnDoubleClick(toggleBreaksMenu, clearSort);
  const [handleInactivityHeaderClick, handleInactivityHeaderDoubleClick] = useClickPreventionOnDoubleClick(toggleInactivityMenu, clearSort);
  const [handleSortClick, handleSortDoubleClick] = useClickPreventionOnDoubleClick(handleSort, clearSort);

  const getBreaksHeader = () => {
    const isBreakSorting = isBreakSortingApplied();
    return <div className='sub-menu-wrapper header'>
      <MouseTooltip
        asChild
        delay={500}
        show={isBreakSorting && !showBreaksMenu}
        content={'Double click to remove sorting'}
        style={{ lineHeight: "17px" }}
      >
        <span
          className={'breaks-header' +
            (isBreakSorting
              ? " chevron" +
              (sortBy.order === "ascending"
                ? " chevron-up"
                : " chevron-down")
              : "")
          }
          onClick={handleBreakHeaderClick}
          onDoubleClick={() => {
            if (!showBreaksMenu && isBreakSorting) {
              handleBreakHeaderDoubleClick()
            }
          }}
        >
          Breaks
        </span>
      </MouseTooltip>
      {showBreaksMenu &&
        <HeaderSubMenu
          showMenu={showBreaksMenu}
          setShowMenu={setShowBreaksMenu}
          sortBy={sortBy}
          menuOptions={BREAK_SUB_OPTIONS}
          onOptionClick={handleSort}
        />
      }
    </div>
  }

  const getInactivityHeader = () => {
    const isInactivitySorting = isInactivitySortingApplied()
    return <div className='sub-menu-wrapper header'>
      <MouseTooltip
        asChild
        delay={500}
        show={isInactivitySorting && !showInactivityMenu}
        content={'Double click to remove sorting'}
        style={{ lineHeight: "17px" }}
      >
        <span
          className={'inactivity-header' +
            (isInactivitySorting
              ? " chevron" +
              (sortBy.order === "ascending"
                ? " chevron-up"
                : " chevron-down")
              : "")
          }
          onClick={handleInactivityHeaderClick}
          onDoubleClick={() => {
            if (!showInactivityMenu && isInactivitySorting) {
              handleInactivityHeaderDoubleClick()
            }
          }}
        >
          Inactivity
        </span>
      </MouseTooltip>
      {showInactivityMenu &&
        <HeaderSubMenu
          showMenu={showInactivityMenu}
          setShowMenu={setShowInactivityMenu}
          sortBy={sortBy}
          menuOptions={INACTIVITY_SUB_OPTIONS}
          onOptionClick={handleSort}
        />
      }
    </div>
  }

  const getClockInOutHeader = (key, val) => {
    const isSortingApplied = sortBy.column === key;
    return (
      <div className="header">
        <MouseTooltip
          asChild
          delay={500}
          show={isSortingApplied}
          content={'Double click to remove sorting'}
          style={{ lineHeight: "17px" }}
        >
          <span
            className={
              isSortingApplied
                ? ' chevron clock-in-out-header' +
                (sortBy.order === 'ascending' ? ' chevron-up' : ' chevron-down')
                : ''
            }
            onClick={() => handleSortClick(key)}
            onDoubleClick={() => isSortingApplied && handleSortDoubleClick()}
          >
            {val}
          </span>
        </MouseTooltip>
      </div>
    );
  };

  const getShiftTotalHeader = () => {
    const isSortingApplied = sortBy.column === 'shift_total';
    return (
      <div className='header'>
        <MouseTooltip
          asChild
          delay={500}
          show={isSortingApplied}
          content={'Double click to remove sorting'}
          style={{ lineHeight: "17px" }}
        >
          <span
            className={
              `shift-total ${isSortingApplied
                ? ' chevron' + (sortBy.order === 'ascending' ? ' chevron-up' : ' chevron-down')
                : ''}`
            }
            onClick={() => handleSort('shift_total')}
            onDoubleClick={() => isSortingApplied && handleSortDoubleClick()}
          >
            Total Hrs
          </span>
        </MouseTooltip>
        <span className='overlap-title'>Scheduled Hrs</span>
      </div>
    );
  };

  useEffect(() => {
    if (!startDate) {
      const selectedStartDate = format(startOfMonth(selectedDate));
      const selectedEndDate = format(endOfMonth(selectedDate));
      handleDateChange(selectedStartDate, selectedEndDate);
      return;
    }

    resetController();
    const controller = controllerRef.current;

    getAttendanceLog();

    function callback() {
      const today = convertTimeZone();
      if (isWithinInterval(today, { start: startDate, end: endDate })) {
        getAttendanceLog();
      }
    }

    socket.on("connect", callback)

    return () => {
      socket.off("connect", callback);
      setShowLineLoader(false);
      controller.abort();
    }
  }, [startDate, endDate, filterQuery, selectedAverage, resourceId]);

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(format(selectedDate, 'YYYY-MM-DD'));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 0);
  }, []);

  useEffect(() => {
    function updateUserStatus(e) {
      if (e.data.resource_id === resourceId) {
        // check if the e.data.date is in between the start and end date
        const now = convertTimeZone();
        const isDateInRange = isWithinInterval(now, {
          start: convertTimeZone(new Date(startDate)),
          end: convertTimeZone(new Date(endDate)),
        });

        if (isDateInRange) {
          setLogs((prev) => {
            const index = prev.findIndex((log) => log?.date === e.data.date);
            let { attendance, holiday_name } = e.data;
            const clock_in_time = isNotNone(e.data.clock_in_time) ? convertUTCToTimezone(e.data.clock_in_time) : e.data.clock_in_time;
            const clock_out_time = isNotNone(e.data.clock_out_time) ? convertUTCToTimezone(e.data.clock_out_time) : e.data.clock_out_time;
            let special_shift_time = e.data.special_shift_time;
            if (Array.isArray(special_shift_time)) {
              const tzOffset = getMyTimezoneOffset(e.data.date);
              special_shift_time = special_shift_time.map(shift => {
                const [start_time, start_time_offset] = convertHhmmToUserTimezone(shift.special_shift_start_time.time, tzOffset);
                const [end_time, end_time_offset] = convertHhmmToUserTimezone(shift.special_shift_end_time.time, tzOffset);
                return {
                  special_shift_start_time: {
                    time: start_time,
                    time_offset: start_time_offset,
                  },
                  special_shift_end_time: {
                    time: end_time,
                    time_offset: end_time_offset,
                  }
                }
              });
            }

            const tzOffset = getMyTimezoneOffset(e.data.date);
            const [approved_start_time, approved_start_time_offset] = convertHhmmToUserTimezone(e.data.approved_start_time, tzOffset)
            const [approved_end_time, approved_end_time_offset] = convertHhmmToUserTimezone(e.data.approved_end_time, tzOffset)

            let includesOnLeave = filterQuery.includes('on_leave');
            let includesOnSpecialShift = filterQuery.includes('special_shift');
            let includesHoliday = filterQuery.includes('holiday');

            let includesEarly = filterQuery.includes('early');
            let includesLate = filterQuery.includes('late');
            let includesOnTime = filterQuery.includes('on_time');
            let includesPunctuality = includesEarly || includesLate || includesOnTime;

            let isLeaveMatching = includesOnLeave ? attendance.includes('leave') : true;
            let isSpecialShiftMatching = includesOnSpecialShift ? isNotNone(special_shift_time) : true;
            let isHolidayMatching = includesHoliday ? isNotNone(holiday_name) : true;
            let isWorkingStatusMatching = includesPunctuality ? filterQuery.includes(attendance) : true;

            let shiftStatusMatching = false;
            if (includesOnLeave && includesOnSpecialShift && includesHoliday && includesPunctuality) {
              shiftStatusMatching = isLeaveMatching || isSpecialShiftMatching || isHolidayMatching || isWorkingStatusMatching;
            } else if (includesOnLeave && includesOnSpecialShift && includesHoliday) {
              shiftStatusMatching = isLeaveMatching || isSpecialShiftMatching || isHolidayMatching;
            } else if (includesOnLeave && includesPunctuality) {
              shiftStatusMatching = isLeaveMatching || isWorkingStatusMatching;
            } else if (includesOnSpecialShift && includesPunctuality) {
              shiftStatusMatching = isSpecialShiftMatching || isWorkingStatusMatching;
            } else if (includesHoliday && includesPunctuality) {
              shiftStatusMatching = isSpecialShiftMatching || isWorkingStatusMatching;
            } else {
              shiftStatusMatching = isLeaveMatching && isSpecialShiftMatching && isHolidayMatching && isWorkingStatusMatching;
            }

            if (index === -1) {
              if (filterQuery.length > 0 && !shiftStatusMatching) {
                return prev;
              }

              // remove one empty row if present in the logs due to table height calculation
              const emptyIndex = prev.findIndex((log) => log?.isEmpty === true);
              if (emptyIndex !== -1) {
                prev.splice(emptyIndex, 1);
              }

              return customSort([e.data, ...prev], sortBy);
            }

            if (filterQuery.length > 0 && !shiftStatusMatching) {
              prev.splice(index, 1, { isEmpty: true });
              return customSort(prev, sortBy);
            }

            prev[index] = {
              ...prev[index],
              ...e.data,
              clock_in_time,
              clock_out_time,
              special_shift_time,
              approved_start_time,
              approved_start_time_offset,
              approved_end_time,
              approved_end_time_offset,
            };

            return customSort(prev, sortBy);
          });
        }
      }
    }

    socket.on("attendance", updateUserStatus);

    return () => {
      socket.off("attendance", updateUserStatus);
    };
  }, [socket, sortBy, startDate, endDate, resourceId, selectedAverage, filterQuery, customSort]);

  useEffect(() => {
    const isLogsPresent =
      logs.length > 0 && !logs.every((log) => log.isEmpty === true);

    if (isLogsPresent) {
      const todayDate = format(convertTimeZone(), "YYYY-MM-DD");

      function updateTodayTotalTime(e) {
        if (e.data.command === "updateTotalHours") {
          const now = convertTimeZone();
          setLogs((prev) => {
            const logs = prev.map((log) => {
              if (log.date === todayDate) {
                const isUserOnline = log.status === "On";
                const isUserClockedIn = isNotNone(log.clock_in_time);
                const isUserClockedOut = isNotNone(log.clock_out_time);

                if (
                  isUserOnline &&
                  isNotNone(log.total_time) &&
                  isUserClockedIn &&
                  !isUserClockedOut
                ) {
                  const total_time = isElectronApp && isLoggedInUser
                    ? e.data.time || log.total_time
                    : log.total_time + 1;

                  const startTime = getDateFromHhmm(log.approved_start_time, log.date);
                  startTime.setDate(startTime.getDate() + log.approved_start_time_offset);

                  const endTime = getDateFromHhmm(log.approved_end_time, log.date);
                  endTime.setDate(endTime.getDate() + log.approved_end_time_offset);

                  if (
                    isNone(log.holiday_name) &&
                    log.attendance !== "on_leave" &&
                    isNone(log.special_shift_time) &&
                    log.day_status === "working_day" &&
                    isWithinInterval(now, { start: startTime, end: endTime })
                  ) {
                    log.overlap_seconds += total_time - log.total_time; // 2000 + (2111 - 2110) = 2001
                  }

                  return { ...log, total_time };
                }

                if (!isUserOnline && isUserClockedIn && !isUserClockedOut) {
                  const now = convertTimeZone();
                  const isUserInactive = log.expected_back_at === "None";
                  const isUserLate = isDate(log.expected_back_at)
                    ? now.getTime() >
                    // convertTimeZone(new Date(log.expected_back_at)).getTime()
                    new Date(log.expected_back_at).getTime()
                    : false;

                  return {
                    ...log,
                    late_break_time:
                      isUserLate &&
                        !isUserInactive &&
                        isNotNone(log.late_break_time)
                        ? log.late_break_time + 1
                        : log.late_break_time,
                    punctual_break_time:
                      !isUserLate &&
                        !isUserInactive &&
                        isNotNone(log.punctual_break_time)
                        ? log.punctual_break_time + 1
                        : log.punctual_break_time,
                    inactivity_time: isUserInactive
                      ? log.inactivity_time + 1
                      : log.inactivity_time,
                  };
                }
              }

              return log;
            });

            return customSort(logs, sortBy);
          });
        }
      }

      timeWorkerInstance.addEventListener("message", updateTodayTotalTime);

      return () => {
        timeWorkerInstance.removeEventListener("message", updateTodayTotalTime);
      };
    }
  }, [logs, timeWorkerInstance, sortBy]);

  useEffect(() => stopTimeWorker, []);

  // const isDarkMode = document.body.dataset.theme === "dark";

  return (
    <div className="team-member-table">
      <table className="attendance-table">
        <thead className={`${showLineLoader ? 'data-loading' : ''}`}>
          <tr>
            <th style={{ width: '130px' }}>
              <div className="office-floor-header-label header">
                <span
                  className={
                    sortBy.column === "date"
                      ? " chevron" +
                      (sortBy.order === "ascending"
                        ? " chevron-up"
                        : " chevron-down")
                      : ""
                  }
                  onClick={() => handleSort("date")}
                >
                  Date
                </span>
              </div>
            </th>
            <th style={{ width: '100px' }}>Shift</th>
            <th style={{ width: '190px' }}>
              <div className="clock-info-wrapper">
                {getClockInOutHeader('clock_in', 'Clock-In')}
                {getClockInOutHeader('clock_out', 'Clock-Out')}
              </div>
            </th>
            <th style={{ minWidth: '202px' }} className={`${showBreaksMenu || showInactivityMenu ? 'open-menu' : ''}`}>
              <div className="break-header">
                <div className="break-info">
                  {getBreaksHeader()}
                </div>
              </div>
            </th>
            <th style={{ minWidth: '126px' }} className={`${showBreaksMenu || showInactivityMenu ? 'open-menu' : ''}`}>
              {getInactivityHeader()}
            </th>
            <th style={{ width: '206px', minWidth: '125px' }}>
              <ShiftTotalHeader
                standarShiftAverage={standarShiftAverage}
                selectedAverage={selectedAverage}
                updateSelectedAverage={updateSelectedAverage}
              >
                {getShiftTotalHeader()}
              </ShiftTotalHeader>
            </th>
          </tr>
        </thead>
        <tbody>
          {isInitialLoad ? getSkeleton() : getAttendanceContent(logs)}
          {/* {getSkeleton()} */}
        </tbody>
      </table>
    </div>
  );
}

function LogSkeletonRow({ isWeekEnd = false, isToday = false, date = '' }) {
  return (
    <tr>
      <td
        className={(isWeekEnd ? 'off' : '')}
        id={date}
      >
        <span
          className={isToday ? "current-day" : ""}
        >
          {isDate(date) ? format(new Date(date), "ddd, MMM DD, YYYY") : ''}
        </span>
      </td>
      <td></td>
      <td>
        <div className='clock-info-wrapper'>
          <div className='bg-gray clock-info'></div>
          <div className='bg-gray clock-info'></div>
        </div>
      </td>
      <td>
        <div className='user-details break-details'></div>
      </td>
      <td></td>
      <td>
        <SkeletonShiftLog />
      </td>
    </tr>
  )
}
