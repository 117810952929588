import React, { Suspense, lazy, useEffect, useState } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";

import { GlobalProvider } from 'contexts/GlobalContext.js';

import Loading from "./views/Loading";
import Maintenance from "views/Maintenance";
import Toast from "components/Toast";

import { apiEventEmitter } from "services/apiService";
import { getToken } from "./utils/Common";
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";

import { SITE_VERSION } from "./components/Constants";

import "./styles/Global.scss";

window.SITE_VERSION = SITE_VERSION;

const Login = lazy(() => import("./views/Login"));
const Main = lazy(() => import("./Main"));

const App = () => {
  const [authLoading, setAuthLoading] = useState(true);
  const [inMaintenance, setInMaintenance] = useState(false);

  useEffect(() => {
    if (!getToken()) return;
    setAuthLoading(false);
  }, []);

  useEffect(() => {
    const handleMaintenance = ({ status }) => setInMaintenance(status);
    const unsubMaintenance = apiEventEmitter.subscribe('maintenance', handleMaintenance);

    return () => unsubMaintenance();
  }, []);

  return authLoading && getToken() ? (
    <div className="init-authentication">Checking Authentication...</div>
  ) : (
    <div>
      <Toast />
      {inMaintenance ? (
        <Maintenance />
      ) : (
        <Router>
          <Suspense fallback={<Loading />}>
            <Switch>
              <PublicRoute path="/login" component={Login} />
              <GlobalProvider>
                <PrivateRoute path="/" component={Main} />
              </GlobalProvider>
            </Switch>
          </Suspense>
        </Router>
      )}
    </div>
  );
}

export default App;
