import { create } from "libs/store";
import { socketEmitService } from 'socket';
import { formatTime, getTimeDiff } from "./timerUtils";
import { addDays, addMinutes, differenceInMinutes, differenceInSeconds, endOfDay, format, isWithinInterval, startOfDay } from "utils/date";
import { convertHhmmToUserTimezone, convertUTCToTimezone, getDateFromHhmm, getMyTimezoneOffset2, getUTCDate, getUTCTime, isElectronApp } from "utils/Common";
import { API_BASE_URL, SITE_PREFIX } from "../Constants";
import APIService from "services/apiService";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const TRACKER_AUTO_RESUME_BUFFER_MINS = 2;

export const STANDARD_WORK_TIMINGS = 28800; // in seconds
export const ONE_DAY_IN_MINUTES = 1440; // 24 hours in minutes
export const TIMER_BASE_URL = API_BASE_URL + "/attendance";

const updateHasTimerStartedHere = (value) => {
  sessionStorage.setItem(SITE_PREFIX + 'hasTimerStartedHere', value);
}

export const useTimerStore = create(() => ({
  isAppInActive: false,
  isSocketEmitInProgress: false,
  timerSettings: {},
  maxCheckinTime: null,
  inactivityPopupContent: null,
  alertContent: null,

  time: 0,
  timerId: null,
  startTime: '',

  isActivityTracked: true,
  nextCheckinTime: 0,
  checkinTimeoutID: null,
  autoResumeTimeoutID: null,

  isTimerStarted: false,
  isShiftStarted: false,
  isShiftEnded: false,
  // User unlimited break timings after completing his/her day shift
  isUserCompletedShift: false,
  isUserOnSpecialShift: false,

  lastClockInTime: 0,
  lastClockOutTime: 0,

  hasRevokedClockOut: false,

  onBreak: false,
  breakStartedAt: "",
  breakTakenFor: 0,
  expectedBackAt: "",

  trackerDisabledFor: 0,
  trackerDisabledAt: "",
  trackerDisabledTotalMins: 0,
  trackerResumeCount: 0,

  refreshTimerAPICount: 0,
  organizationsList: [],
  selectedOrganization: null,
}));

export const getTimeFromStr = (timeStr) => {
  const hh = parseInt(timeStr.slice(0, -2))
  const mm = parseInt(timeStr.slice(-2))
  return [hh, mm];
}

const getTimerToken = () => {
  const { organizationsList, selectedOrganization } = useTimerStore.getState();
  const timerOrg = organizationsList.find(org => +org.is_timer === 1);
  if (selectedOrganization.id === timerOrg?.id) return;
  const timerToken = localStorage.getItem(SITE_PREFIX + 'timer_token')
  return `Bearer ${timerToken}`;
}

export const getUserShiftData = async ({ clockInTime, controller = null } = {}) => {
  try {
    const timerToken = getTimerToken();
    let clockInDate = getUTCDate(clockInTime);
    const formattedDate = format(clockInDate, "YYYY-MM-DD")
    let url = new URL(TIMER_BASE_URL + `/user_shift`);
    url.searchParams.append(
      "date",
      formattedDate
    );
    url.searchParams.append("status", "approved");

    const response = await APIService.apiRequest(
      url.toString(),
      null,
      false,
      "GET",
      controller,
      timerToken,
      !!timerToken,
    );
    if (response.status === 1) {
      const { leave, schedule, special_shift, dst_schedule } = response.output;
      const disabled_activity_tracker_seconds = parseInt(response.meta.disabled_activity_tracker_seconds || 0)
      let isUserOnSpecialShift = false;
      let isActivityTracked = true;
      let special_shift_timings = [];
      let special_shift_break_timings = [];
      let user_total_shift_timings = STANDARD_WORK_TIMINGS;

      if (Array.isArray(special_shift) && special_shift.length > 0) {
        const curShiftDate = clockInDate;
        isUserOnSpecialShift = special_shift.find(ss => ss.start_date >= formattedDate && ss.end_date <= formattedDate);

        if (isUserOnSpecialShift && isUserOnSpecialShift.is_activity_tracked === 0) {
          isActivityTracked = false;
        }

        // Get today's special shift timings
        let specialShiftTimings = special_shift.reduce((acc, cur) => {
          const startDate = convertUTCToTimezone(startOfDay(cur.start_date))
          const endDate = convertUTCToTimezone(endOfDay(cur.end_date))
          const isShiftWithinInterval = isWithinInterval(curShiftDate, {
            start: startDate,
            end: endDate,
          });

          if (isShiftWithinInterval) {
            const startTzOffset = getMyTimezoneOffset2(getDateFromHhmm(cur.start_time.time, startDate));
            // const endTzOffset = getMyTimezoneOffset2(getDateFromHhmm(cur.end_time.time, endDate));
            const [start_time, start_time_offset] = convertHhmmToUserTimezone(
              cur.start_time.time,
              startTzOffset,
              cur.start_time.time_offset
            );
            const [end_time, end_time_offset] = convertHhmmToUserTimezone(
              cur.end_time.time,
              startTzOffset,
              cur.end_time.time_offset
            );

            acc.push({
              start_time: { time: start_time, time_offset: start_time_offset },
              end_time: { time: end_time, time_offset: end_time_offset },
            });
          }

          // * Since the timings already sorted from frontend when the special shift is requested.
          // * No need to worry about the order.

          return acc;
        }, []);

        if (specialShiftTimings.length > 0) {
          // Calc and update user's total shift timings
          const totalShiftTimings = specialShiftTimings.reduce((acc, cur) => {
            const [startTimeHour, startTimeMin] = getTimeFromStr(cur.start_time.time);
            const [endTimeHour, endTimeMin] = getTimeFromStr(cur.end_time.time);

            const startTime = convertUTCToTimezone(getUTCTime());
            startTime.setHours(startTimeHour, startTimeMin, 0, 0);
            startTime.setDate(startTime.getDate() + cur.start_time.time_offset);

            const endTime = convertUTCToTimezone(getUTCTime());
            endTime.setHours(endTimeHour, endTimeMin, 0, 0);

            acc += differenceInSeconds(endTime, startTime, { roundingMethod: "ceil" });
            return acc;
          }, 0);

          special_shift_timings = specialShiftTimings;
          user_total_shift_timings = totalShiftTimings;

          // Get break timings present inbetween today's special shift timings
          const breakTimings = specialShiftTimings.reduce(
            (acc, shift, index, arr) => {
              // ? No need to check for last index since we're not going to show the button after the last shift timing
              if (index === arr.length - 1) return acc;
              acc.push({ start_time: shift.end_time, end_time: arr[index + 1].start_time, });
              return acc;
            },
            []
          );

          special_shift_break_timings = breakTimings;
        }
      }

      const today = clockInDate.getDay();
      const currentSchedule = schedule.find(item => item.weekday === today);

      if (!isUserOnSpecialShift && currentSchedule && currentSchedule.is_activity_tracked === 0) {
        isActivityTracked = false;
      }

      return {
        is_activity_tracked: isActivityTracked,
        is_user_on_special_shift: isUserOnSpecialShift,
        special_shift_timings,
        special_shift_break_timings,
        user_total_shift_timings,
        disabled_activity_tracker_seconds,
        leave,
        schedule,
        dst_schedule,
        date: response.date
      }
    } else {
      console.log("getUserShiftData - else response", response);
      return null;
    }
  } catch (error) {
    console.log("getUserShiftData - error", error);
  }
}

export const startNewShiftButtonClick = () => {
  const { lastClockInTime, lastClockOutTime } = useTimerStore.getState();
  const clockInTime = new Date(lastClockInTime);
  const lastClockInTimePlus5Second = new Date(clockInTime.getTime() + 5 * 1000);
  let now = getUTCTime();
  let diffHours = parseInt(Math.abs(new Date(now) - lastClockInTimePlus5Second) / (1000 * 60 * 60));
  let isPrevShiftEnded = new Date(lastClockInTime) <= new Date(lastClockOutTime);
  let isNewDay = format(clockInTime, 'YYYY-MM-DD') !== now.split(' ')[0];

  /*  call start timer if lastclockin time has crossed more than 24 hours (+ 5 seconds as buffer time)
      else if last shift is not ended and it is a new day call updateClockOut
      else call startTimer
  */

  if (diffHours >= 24) {
    startNewShift();
  } else if (!isPrevShiftEnded && isNewDay) {
    endPausedShift({ startShift: true, clockInTime: now });
  } else {
    startNewShift();
  }
}

export function startNewShift() {
  const stateCopy = useTimerStore.getState();
  const lastClockInTime = getUTCTime();
  const payload = {
    start_time: lastClockInTime,
    is_clock_in: 1,
    is_resume_activity_tracker: 0,
  };

  useTimerStore.setState({
    isShiftStarted: true,
    isShiftEnded: false,
    isSocketEmitInProgress: true,
    isTimerStarted: true,
    inactivityPopupContent: null,
    lastClockInTime,
    onBreak: false,
  });

  const successCallback = (res) => {
    updateHasTimerStartedHere(true);
    const { next_checkin_time: nextCheckinTime, timer_id: timerId } = res;
    console.log(
      `[CHECKIN] - Start Timer - ${getUTCTime()} -> ${nextCheckinTime}`
    );
    useTimerStore.setState({
      hasRevokedClockOut: false,
      isSocketEmitInProgress: false,
      nextCheckinTime,
      startTime: lastClockInTime,
      timerId,
    });
    scheduleCheckin({ nextCheckinTime });
  };

  const errorCallback = (err) => {
    const {
      isShiftStarted,
      isShiftEnded,
      isTimerStarted,
      inactivityPopupContent,
      lastClockInTime,
      onBreak,
    } = stateCopy;
    useTimerStore.setState({
      isShiftStarted,
      isShiftEnded,
      isTimerStarted,
      isSocketEmitInProgress: false,
      inactivityPopupContent,
      lastClockInTime,
      onBreak,
    });
    displayAlert({
      msg: `Failed to start timer. Message: ${err?.message}`,
    });
  };

  getUserShiftData().then((data) => {
    if (data) {
      useTimerStore.setState({
        isActivityTracked: data.is_activity_tracked,
        isUserOnSpecialShift: data.is_user_on_special_shift,
      });

      if (data.is_activity_tracked) {
        socketEmitService({ eventName: "start_timer", payload })
          .then(successCallback)
          .catch(errorCallback);
      } else {
        const disabledMins = 24 * 60;
        useTimerStore.setState({
          trackerDisabledAt: payload.start_time,
          trackerDisabledFor: disabledMins,
        })

        scheduleAutoResumeTracker();
        socketEmitService({
          eventName: "disable_activity_tracker",
          payload: {
            start_time: payload.start_time,
            is_clock_in: 1,
            disable_activity_tracker_reason: data.is_user_on_special_shift
              ? "Special shift"
              : "Schedule",
            activity_tracker_disabled_time_min: disabledMins,
            disable_activity_tracker_id: 6,
          },
        })
          .then(successCallback)
          .catch(errorCallback);
      }
    } else {
      errorCallback(new Error('Failed to fetch user shift data'));
    }
  })
}

export function resumeShift({ resumeTracker = false, clockInTime } = {}) {
  const stateCopy = useTimerStore.getState();
  const startTime = clockInTime ?? getUTCTime();
  const payload = {
    start_time: startTime,
    is_clock_in: 0,
    is_resume_activity_tracker: resumeTracker ? 1 : 0,
  };

  useTimerStore.setState({
    breakStartedAt: "",
    breakTakenFor: 0,
    expectedBackAt: "",
    inactivityPopupContent: null,
    isSocketEmitInProgress: true,
    isTimerStarted: true,
    onBreak: false,
  });

  const successCallback = (res) => {
    updateHasTimerStartedHere(true);
    const { next_checkin_time: nextCheckinTime, timer_id: timerId } = res;
    console.log(
      `[CHECKIN] - Start Timer - ${getUTCTime()} -> ${nextCheckinTime}`
    );
    useTimerStore.setState({
      isSocketEmitInProgress: false,
      nextCheckinTime,
      startTime,
      timerId,
    });
    scheduleCheckin({ nextCheckinTime });
  };

  const errorCallback = (err) => {
    const {
      breakStartedAt,
      breakTakenFor,
      expectedBackAt,
      inactivityPopupContent,
      isTimerStarted,
      onBreak,
    } = stateCopy;
    useTimerStore.setState({
      breakStartedAt,
      breakTakenFor,
      expectedBackAt,
      inactivityPopupContent,
      isSocketEmitInProgress: false,
      isTimerStarted,
      onBreak,
    });
    displayAlert({ msg: `Failed to resume timer. Message: ${err?.message}` });
  };

  getUserShiftData({ clockInTime: stateCopy.lastClockInTime }).then((data) => {
    if (data) {
      useTimerStore.setState({
        isActivityTracked: data.is_activity_tracked,
        isUserOnSpecialShift: data.is_user_on_special_shift,
      });

      if (data.is_activity_tracked) {
        socketEmitService({ eventName: "start_timer", payload })
          .then(successCallback)
          .catch(errorCallback);
      } else {
        const clockOutTime = addDays(new Date(stateCopy.lastClockInTime), 1);
        const now = getUTCDate();
        const diffInMins = differenceInMinutes(clockOutTime, now);
        const disabledAt = getUTCTime();

        useTimerStore.setState({
          trackerDisabledAt: disabledAt,
          trackerDisabledFor: diffInMins,
        })

        scheduleAutoResumeTracker();
        socketEmitService({
          eventName: "disable_activity_tracker",
          payload: {
            start_time: disabledAt,
            is_clock_in: 0,
            disable_activity_tracker_reason: stateCopy.isUserOnSpecialShift
              ? "Special shift"
              : "Schedule",
            activity_tracker_disabled_time_min: diffInMins,
            disable_activity_tracker_id: 6,
          },
        })
          .then(successCallback)
          .catch(errorCallback);
      }
    } else {
      errorCallback(new Error('Failed to fetch user shift data'));
    }
  });
}

export function endShift() {
  cancelCheckin();
  cancelAutoResumeTracker();
  const stateCopy = useTimerStore.getState();
  const lastClockOutTime = getUTCTime();
  // const date = new Date(lastClockOutTime);
  // const clockInTime = getUTCTime(new Date(stateCopy.lastClockInTime));
  // const diff = parseInt(Math.abs(new Date(clockInTime) - date) / 36e5);
  // const isShiftEnded =
  //   !stateCopy.isTimerStarted && !stateCopy.isShiftStarted && diff < 24;

  const isActivityTrackerDisabled = stateCopy.trackerDisabledFor > 0;
  if (isActivityTrackerDisabled) updateDisabledTrackerTotalMins();

  const payload = {
    end_time: lastClockOutTime,
    is_clock_out: 1,
    timer_id: stateCopy.timerId,
  };

  useTimerStore.setState({
    time: 0,
    isTimerStarted: false,
    isShiftEnded: true,
    lastClockOutTime,
    breakStartedAt: "",
    breakTakenFor: 0,
    expectedBackAt: "",
    trackerDisabledFor: 0,
    trackerDisabledAt: "",
    isSocketEmitInProgress: true,
  });
  if (isElectronApp) window.ipcRender.send('message:updateTime', 0);

  socketEmitService({ eventName: "stop_timer", payload })
    .then((res) => {
      updateHasTimerStartedHere(false);
      useTimerStore.setState({
        isSocketEmitInProgress: false,
      });
    })
    .catch((err) => {
      if (err?.message?.includes('This timer has already been ended')) {
        console.log('endShift - ', err.message);
        useTimerStore.setState({ isSocketEmitInProgress: false, refreshTimerAPICount: stateCopy.refreshTimerAPICount + 1 });
        return;
      }
      let checkinTimeoutID = null;
      if (isActivityTrackerDisabled) {
        scheduleAutoResumeTracker();
      } else {
        const checkInCallback = () => {
          scheduleCheckin({ nextCheckinTime: stateCopy.nextCheckinTime });
        }
        checkinTimeoutID = setTimeout(checkInCallback, 5 * 1000);
      }
      const {
        time,
        isTimerStarted,
        isShiftEnded,
        lastClockOutTime,
        breakStartedAt,
        breakTakenFor,
        expectedBackAt,
        trackerDisabledFor,
        trackerDisabledAt,
      } = stateCopy;
      useTimerStore.setState({
        time,
        checkinTimeoutID,
        isTimerStarted,
        isShiftEnded,
        lastClockOutTime,
        breakStartedAt,
        breakTakenFor,
        expectedBackAt,
        trackerDisabledFor,
        trackerDisabledAt,
        isSocketEmitInProgress: false,
      });
      if (isElectronApp) window.ipcRender.send('message:updateTime', time);
      displayAlert({ msg: `Failed to stop timer. Message: ${err?.message}` });
    });
}

export function endPausedShift({ startShift = false, clockInTime } = {}) {
  useTimerStore.setState({
    isSocketEmitInProgress: true,
  });

  socketEmitService({ eventName: "clock_out" })
    .then((res) => {
      updateHasTimerStartedHere(false);
      const { clock_out_time: lastClockOutTime } = res;
      useTimerStore.setState({
        lastClockOutTime,
        isShiftEnded: true,
        expectedBackAt: "",
        time: 0,
        isSocketEmitInProgress: false,
      });
      if (isElectronApp) window.ipcRender.send('message:updateTime', 0);

      if (startShift) startNewShift();
    })
    .catch((err) => {
      useTimerStore.setState({
        isSocketEmitInProgress: false,
      });
      displayAlert({ msg: `Failed to stop previous shift. Message: ${err?.message}` });
    });
}

export function revokeClockOut() {
  const stateCopy = useTimerStore.getState();
  useTimerStore.setState({
    isShiftEnded: false,
    isShiftStarted: true,
    isSocketEmitInProgress: true,
  });

  socketEmitService({ eventName: "revoke_clock_out" })
    .then((res) => {
      updateHasTimerStartedHere(false);
      useTimerStore.setState({
        lastClockOutTime: res.last_clock_out_time ?? 0,
        time: +res.total_time_sec,
        hasRevokedClockOut: true,
      });

      resumeShift();
    })
    .catch((err) => {
      const { isShiftEnded, isShiftStarted } = stateCopy;
      useTimerStore.setState({
        isShiftEnded,
        isShiftStarted,
        isSocketEmitInProgress: false,
      });
      displayAlert({ msg: `Failed to revoke clock out. Message: ${err?.message}` });
    });
}

export function disableTracker({ reason, activityId, disableActivityMins } = {}) {
  const stateCopy = useTimerStore.getState();
  const disabledAt = getUTCTime();
  useTimerStore.setState({
    trackerDisabledAt: disabledAt,
    trackerDisabledFor: disableActivityMins,
  });

  scheduleAutoResumeTracker();

  takeBreak({
    stopForDisableTracking: true,
    clockOutTime: disabledAt,
  }, () => {
    const payload = {
      start_time: disabledAt,
      is_clock_in: 0,
      disable_activity_tracker_reason: reason,
      activity_tracker_disabled_time_min: +disableActivityMins,
      disable_activity_tracker_id: activityId,
    };

    socketEmitService({ eventName: "disable_activity_tracker", payload })
      .then((res) => {
        updateHasTimerStartedHere(true);
        const { next_checkin_time: nextCheckinTime, timer_id: timerId } = res;
        console.log(`[CHECKIN] - Disable Tracker - ${getUTCTime()} -> ${nextCheckinTime}`);
        useTimerStore.setState({
          isSocketEmitInProgress: false,
          nextCheckinTime,
          timerId,
          startTime: disabledAt,
        });
        // scheduleCheckin({ nextCheckinTime });
      })
      .catch((err) => {
        cancelAutoResumeTracker();
        const { trackerDisabledAt, trackerDisabledFor } = stateCopy;
        useTimerStore.setState({
          breakStartedAt: disabledAt,
          breakTakenFor: 0,
          isTimerStarted: false,
          isSocketEmitInProgress: false,
          onBreak: true,
          expectedBackAt: disabledAt,
          trackerDisabledAt,
          trackerDisabledFor,
        });
        displayAlert({ msg: `Failed to disable tracker. Message: ${err?.message}` });
      });
  });
}

export function extendTrackerDisable(extendFor) {
  const stateCopy = useTimerStore.getState();
  const { timerId, trackerDisabledAt, trackerDisabledFor } = stateCopy;

  const startTime = getUTCTime();
  const isDisableTrackerExtending = getTimeDiff(trackerDisabledAt, trackerDisabledFor) >= 0;
  const updatedDisableTrackerStartedAtTS = !isDisableTrackerExtending ? startTime : trackerDisabledAt;
  const updatedDisableTrackerFor = isDisableTrackerExtending ? +trackerDisabledFor + +extendFor : +extendFor;

  const payload = {
    extend_mins: +extendFor,
    start_time: startTime,
    timer_id: timerId,
  };

  useTimerStore.setState({
    trackerDisabledAt: updatedDisableTrackerStartedAtTS,
    trackerDisabledFor: updatedDisableTrackerFor,
    isSocketEmitInProgress: true,
  });

  scheduleAutoResumeTracker();

  socketEmitService({ eventName: "extend_timer", payload })
    .then((res) => {
      updateHasTimerStartedHere(true);
      useTimerStore.setState({ isSocketEmitInProgress: false });
    })
    .catch((err) => {
      useTimerStore.setState({
        trackerDisabledAt,
        trackerDisabledFor,
        isSocketEmitInProgress: false,
      });
      // we won't cancel auto resume tracker here as we are extending the tracker upon failure we need to update the auto resume tracker schedule
      scheduleAutoResumeTracker();
      displayAlert({ msg: `Failed to extend tracker disable. Message: ${err?.message}` });
    });
}

export function resumeTracker(autoResume = false) {
  if (!autoResume) {
    cancelAutoResumeTracker();
  }

  const stateCopy = useTimerStore.getState();

  let startTime = null, stopTime = null;
  const { isAppInActive, trackerDisabledFor, trackerDisabledAt } = stateCopy;

  if (autoResume) {
    stopTime = new Date(trackerDisabledAt);
    stopTime.setMinutes(stopTime.getMinutes() + +trackerDisabledFor);
    stopTime = format(stopTime, "YYYY-MM-DD HH:mm:ss");

    const isWithinBufferMins = getTimeDiff(trackerDisabledAt, trackerDisabledFor + TRACKER_AUTO_RESUME_BUFFER_MINS);
    if (isWithinBufferMins > 0) {
      startTime = stopTime;
    }
  } else {
    const currentDate = getUTCTime();
    stopTime = currentDate;
    startTime = currentDate;
  }

  updateDisabledTrackerTotalMins();

  useTimerStore.setState({
    trackerDisabledAt: "",
    trackerDisabledFor: 0,
  });

  takeBreak({
    stopForDisableTracking: !isAppInActive,
    clockOutTime: stopTime,
    autoResume,
  }, () => {
    // TODO: check the following properties
    // clockInDateTime: startTime ?? currentDate, updateTime: false, autoResume
    const currentDate = getUTCTime();
    if (isAppInActive) {
      displayAlert({
        msg: `Your screen is locked, so the timer hasn't started`,
        options: ['Ok', 'Resume Shift'],
      });
      return;
    } else {
      const clockInTime = startTime ?? currentDate;
      const resumeTracker = true;
      const stateCopy = useTimerStore.getState();

      const payload = {
        start_time: clockInTime,
        is_clock_in: 0,
        is_resume_activity_tracker: resumeTracker ? 1 : 0,
      };

      useTimerStore.setState({
        breakStartedAt: "",
        breakTakenFor: 0,
        expectedBackAt: "",
        inactivityPopupContent: null,
        isSocketEmitInProgress: true,
        isTimerStarted: true,
        onBreak: false,
      });

      socketEmitService({ eventName: "start_timer", payload })
        .then((res) => {
          updateHasTimerStartedHere(true);
          const { next_checkin_time: nextCheckinTime, timer_id: timerId } = res;
          console.log(
            `[CHECKIN] - Start Timer - ${getUTCTime()} -> ${nextCheckinTime}`
          );
          useTimerStore.setState({
            isSocketEmitInProgress: false,
            nextCheckinTime,
            startTime,
            timerId,
          });
          scheduleCheckin({ nextCheckinTime });
        })
        .catch((err) => {
          const {
            breakStartedAt,
            breakTakenFor,
            expectedBackAt,
            inactivityPopupContent,
            isTimerStarted,
            onBreak,
          } = stateCopy;
          useTimerStore.setState({
            breakStartedAt,
            breakTakenFor,
            expectedBackAt,
            inactivityPopupContent,
            isSocketEmitInProgress: false,
            isTimerStarted,
            onBreak,
          });
          displayAlert({ msg: `Failed to resume timer. Message: ${err?.message}` });
        });
    }
  });
}

export function takeBreak({ breakTakenFor = 0, clockOutTime, stopForDisableTracking = false, toastError = null } = {}, callback) {
  cancelCheckin();
  if (!stopForDisableTracking) cancelAutoResumeTracker();
  const stateCopy = useTimerStore.getState();
  const { startTime, time, timerId, timerSettings, trackerDisabledFor, trackerResumeCount } = stateCopy;
  const breakStartedAt = clockOutTime ? format(clockOutTime, DATE_FORMAT) : getUTCTime();
  const expectedBackAt = format(
    addMinutes(breakStartedAt, breakTakenFor),
    DATE_FORMAT
  );
  const isActivityTrackerDisabled = trackerDisabledFor > 0;

  if (isActivityTrackerDisabled) updateDisabledTrackerTotalMins();

  const payload = {
    afk_time_min: breakTakenFor,
    end_time: breakStartedAt,
    is_clock_out: 0,
    timer_id: timerId,
  };

  // This is to handle the case where the end_time is less than the start_time
  // This happens when the user disables the tracker for some time and when it gets auto enabled and still the user is inactive.
  if (new Date(startTime) > new Date(payload.end_time)) {
    payload.end_time = startTime;
  }

  if (stopForDisableTracking) {
    payload.stop_for_disable_tracking = 1;
  }

  const newState = stopForDisableTracking ? {} : {
    isTimerStarted: false,
    onBreak: true,
    breakStartedAt,
    breakTakenFor,
    expectedBackAt,
    trackerDisabledAt: "",
    trackerDisabledFor: 0,
  }
  newState.isSocketEmitInProgress = true;

  useTimerStore.setState(newState);

  socketEmitService({ eventName: "stop_timer", payload })
    .then((res) => {
      updateHasTimerStartedHere(false);
      const newState = stopForDisableTracking ? {} : { isSocketEmitInProgress: false }
      newState.time = res.total_time;
      if (stopForDisableTracking) newState.trackerResumeCount = trackerResumeCount + 1;
      if (isElectronApp) window.ipcRender.send('message:updateTime', res.total_time);

      if (res.total_time !== 'None' && (res.total_time - time) >= 300) {
        const msg = `There was a huge time diff of ${res.total_time - time} seconds at ${format(new Date(), DATE_FORMAT)}. 
                     Please report this issue on isupport@teamlink.com`;
        if (toastError) {
          toastError.msg += `\nAlso ${msg}`;
        } else {
          displayAlert({ msg });
        }
      }

      if (toastError?.msg?.includes('Your timer has been paused for being idle') || toastError?.msg?.includes('Checkin was not accepted')) {
        if (isElectronApp) {
          window?.ipcRender?.send('message:showInactivityAlertModal',
            { ...toastError.data, timerSettings, MAX_RECOVERY_TIME: timerSettings.max_recovery_time_allowed_seconds }
          )
        } else {
          newState.inactivityPopupContent = toastError?.data ? {
            msg: toastError.data.msg || '',
            currentLostTime: toastError.data.currentLostTime
          } : null;
        }
        playSound();
      } else {
        if (toastError) displayAlert(toastError);
      }
      useTimerStore.setState(newState);
      if (typeof callback === 'function') {
        callback();
      }
    })
    .catch((err) => {
      if (err?.message?.includes('This timer has already been ended')) {
        console.log('takeBreak - ', err.message);
        useTimerStore.setState({ isSocketEmitInProgress: false, refreshTimerAPICount: stateCopy.refreshTimerAPICount + 1 });
        return;
      }
      let checkinTimeoutID = null;
      if (isActivityTrackerDisabled && !stopForDisableTracking) {
        scheduleAutoResumeTracker();
      } else {
        const checkInCallback = () => {
          scheduleCheckin({ nextCheckinTime: stateCopy.nextCheckinTime });
        }
        checkinTimeoutID = setTimeout(checkInCallback, 5 * 1000);
      }
      const {
        isTimerStarted,
        onBreak,
        breakStartedAt,
        breakTakenFor,
        expectedBackAt,
        trackerDisabledAt,
        trackerDisabledFor,
      } = stateCopy;

      const newState = stopForDisableTracking ? {
        trackerDisabledAt: "",
        trackerDisabledFor: 0
      } : {
        checkinTimeoutID,
        isTimerStarted,
        onBreak,
        breakStartedAt,
        breakTakenFor,
        expectedBackAt,
        trackerDisabledAt,
        trackerDisabledFor,
      }
      newState.isSocketEmitInProgress = false;

      useTimerStore.setState(newState);
      displayAlert({ msg: `Failed to pause timer. Message: ${err?.message}` });
    });
}

export function stopTimerOnLastCheckin({ breakTakenFor = 0, clockOutTime } = {}, callback) {
  cancelCheckin();
  cancelAutoResumeTracker();
  const stateCopy = useTimerStore.getState();
  const { timerSettings, trackerDisabledFor } = stateCopy;
  const breakStartedAt = clockOutTime ? format(clockOutTime, DATE_FORMAT) : getUTCTime();
  const expectedBackAt = format(
    addMinutes(breakStartedAt, breakTakenFor),
    DATE_FORMAT
  );
  const isActivityTrackerDisabled = trackerDisabledFor > 0;

  if (isActivityTrackerDisabled) updateDisabledTrackerTotalMins();

  const newState = {
    isTimerStarted: false,
    onBreak: true,
    breakStartedAt,
    breakTakenFor,
    expectedBackAt,
    trackerDisabledAt: "",
    trackerDisabledFor: 0,
    isSocketEmitInProgress: true,
  }

  useTimerStore.setState(newState);

  socketEmitService({ eventName: "stop_timer_last_checkin" })
    .then((res) => {
      updateHasTimerStartedHere(false);
      const newState = {
        isSocketEmitInProgress: false,
        time: res.total_time
      }
      if (isElectronApp) window.ipcRender.send('message:updateTime', res.total_time);

      const popupInfo = {
        currentLostTime: timerSettings.max_recovery_time_allowed_seconds,
        msg: 'Your session with active timer has failed to check-in at its desired time. If you wish to change your active timer session to this one, please click below.', timerSettings,
        MAX_RECOVERY_TIME: timerSettings.max_recovery_time_allowed_seconds,
      }
      if (isElectronApp) {
        window?.ipcRender?.send('message:showInactivityAlertModal', popupInfo)
      } else {
        newState.inactivityPopupContent = popupInfo;
      }
      useTimerStore.setState(newState);

      if (typeof callback === 'function') {
        callback();
      }
    })
    .catch((err) => {
      let checkinTimeoutID = setTimeout(checkin, 5 * 1000);
      const {
        isTimerStarted,
        onBreak,
        breakStartedAt,
        breakTakenFor,
        expectedBackAt,
        trackerDisabledAt,
        trackerDisabledFor,
      } = stateCopy;

      const newState = {
        checkinTimeoutID,
        isTimerStarted,
        onBreak,
        breakStartedAt,
        breakTakenFor,
        expectedBackAt,
        trackerDisabledAt,
        trackerDisabledFor,
        isSocketEmitInProgress: false,
      }

      useTimerStore.setState(newState);
      displayAlert({ msg: `Failed to pause timer. Message: ${err?.message}` });
    });
}

export function extendBreak(extendFor) {
  const { breakTakenFor, breakStartedAt, timerId } = useTimerStore.getState();
  const startTime = getUTCTime();
  const isBreakExtending = getTimeDiff(breakStartedAt, breakTakenFor) >= 0;
  const newBreakStartedAt = !isBreakExtending ? startTime : breakStartedAt;
  const newBreakTakenFor = !isBreakExtending ? +extendFor : breakTakenFor + +extendFor;
  const expectedBackAt = format(
    addMinutes(newBreakStartedAt, newBreakTakenFor),
    DATE_FORMAT
  );

  const payload = {
    extend_mins: +extendFor,
    start_time: startTime,
    timer_id: timerId,
  };

  useTimerStore.setState({
    breakStartedAt: newBreakStartedAt,
    breakTakenFor: newBreakTakenFor,
    expectedBackAt,
    isSocketEmitInProgress: true,
  });

  socketEmitService({ eventName: "extend_timer", payload })
    .then((res) => {
      updateHasTimerStartedHere(false);
      const newState = { isSocketEmitInProgress: false };
      if (res?.data?.new_timer_id) {
        newState.timerId = res.data.new_timer_id;
      }
      useTimerStore.setState(newState);
    })
    .catch((err) => {
      useTimerStore.setState({
        breakTakenFor,
        expectedBackAt,
        isSocketEmitInProgress: false,
      });
      displayAlert({ msg: `Failed to extend break. Message: ${err?.message}` });
    });
}

export function recoverLostTime({
  activityId,
  reason,
  disableTracker,
  afkInMins,
} = {}) {
  const stateCopy = useTimerStore.getState();
  const currentDate = getUTCTime();
  const shouldDisableTracker = disableTracker && +afkInMins > 0;
  const updatedState = {
    isSocketEmitInProgress: true,
    inactivityPopupContent: null,
  };
  const payload = {
    disable_activity_tracker_reason: reason,
    disable_activity_tracker_id: activityId,
  };

  if (shouldDisableTracker) {
    payload.extend_mins = +afkInMins;
    updatedState.trackerDisabledAt = currentDate;
    updatedState.trackerDisabledFor = +afkInMins;
  }

  useTimerStore.setState(updatedState);

  if (shouldDisableTracker) scheduleAutoResumeTracker();

  socketEmitService({ eventName: "recover_lost_time", payload })
    .then((res) => {
      const updatedState = {
        isSocketEmitInProgress: false,
      };
      if (res.total_time !== "None") {
        updatedState.time = res.total_time;
        if (isElectronApp) window.ipcRender.send('message:updateTime', res.total_time);
      }

      if (shouldDisableTracker) {
        const { timer_id, next_checkin_time: nextCheckinTime } = res;
        console.log(`[CHECKIN] - Recover Lost Time - ${getUTCTime()} -> ${nextCheckinTime}`);
        updatedState.timerId = timer_id;
        updatedState.nextCheckinTime = nextCheckinTime;
        updatedState.isTimerStarted = true;
        updatedState.onBreak = false;
        if (res?.start_time && res.start_time !== 'None') {
          updatedState.startTime = res.start_time;
        }
        updateHasTimerStartedHere(true);
        scheduleCheckin({ nextCheckinTime });
      } else {
        resumeShift({ clockInTime: currentDate });
      }

      useTimerStore.setState(updatedState);
    })
    .catch((err) => {
      const { trackerDisabledAt, trackerDisabledFor } = stateCopy;
      if (shouldDisableTracker) cancelAutoResumeTracker();
      useTimerStore.setState({
        trackerDisabledAt,
        trackerDisabledFor,
        isSocketEmitInProgress: false,
      });
      displayAlert({ msg: `Failed to recover lost time. Message: ${err?.message}` });
    });
}

export function scheduleCheckin({ nextCheckinTime } = {}) {
  const { checkinTimeoutID: oldTimeoutID } = useTimerStore.getState();
  clearTimeout(oldTimeoutID);
  const currentTime = getUTCDate();
  const timeDiff = (new Date(nextCheckinTime) - currentTime) / 1000;

  const checkinTimeoutID = setTimeout(checkin, Math.max(timeDiff, 0) * 1000);
  useTimerStore.setState({ checkinTimeoutID });
}

export async function checkin() {
  const { isShiftEnded, maxCheckinTime, timerId, trackerDisabledFor, startTime } = useTimerStore.getState();
  if (isShiftEnded || trackerDisabledFor > 0) return;

  let idleTime = 0;
  if (isElectronApp) {
    idleTime = await window.ipcRender.invoke('getIdleTime');

    // sometimes the user is idle for a long time and the timer is resumed after disabled time
    // in that case, we need to discard the idle time (e.g Tracker disabled for 5 mins and user was idle for 10 mins)
    if (startTime) {
      const start_time = new Date(startTime);
      const now = getUTCDate();
      const diff = differenceInSeconds(now, start_time, { roundingMethod: "round" });
      if (idleTime > diff) {
        idleTime = diff;
      }
    }

    if (maxCheckinTime && idleTime > maxCheckinTime) {
      const toastError = {
        msg: `Your timer has been paused for being idle. ${formatTime(idleTime)} has been discarded`,
        options: ['Ok', 'Resume Shift'],
        data: {
          currentLostTime: idleTime,
          msg: `Your timer has been paused for being idle. ${formatTime(idleTime)} has been discarded`
        }
      };
      takeBreak({ clockOutTime: new Date(getUTCDate().getTime() - (idleTime * 1000)), toastError });
      return;
    }
  }

  const payload = {
    idle_time: idleTime,
    timer_id: timerId,
  };

  console.log(`[CHECKIN] - Started - ${getUTCTime()}`);

  socketEmitService({ eventName: "checkin", payload })
    .then(({ next_checkin_time: nextCheckinTime }) => {
      console.log(`[CHECKIN] - Success - ${getUTCTime()} -> ${nextCheckinTime}`);
      scheduleCheckin({ nextCheckinTime })
      useTimerStore.setState({ nextCheckinTime });
    })
    .catch((err) => {
      const isCheckinNotAccepted = err?.message?.includes('Checkin not accepted');
      const isIdleTimeExceeded = err?.message?.toLowerCase()?.includes('idle time cannot be more than');

      if (isCheckinNotAccepted || isIdleTimeExceeded) {
        let errorMsg, currentLostTime;
        const clockOutTime = getUTCDate().getTime();
        if (isCheckinNotAccepted) {
          currentLostTime = Math.floor(Math.abs(err.diff) - 60);
          errorMsg = `Checkin was not accepted therefore your timer has been paused. ${formatTime(Math.abs(parseInt(currentLostTime)))} has been discarded`;
        } else if (err.message.toLowerCase().includes('idle time cannot be more than')) {
          currentLostTime = idleTime;
          errorMsg = `Your timer has been paused for being idle. ${formatTime(currentLostTime)} has been discarded`;
        }

        if (errorMsg) {
          const toastError = {
            msg: errorMsg,
            options: ['Ok', 'Resume Shift'],
            data: {
              currentLostTime,
              msg: isCheckinNotAccepted ? 'Timer paused because the check-in was declined' : 'Timer paused for being idle'
            }
          };
          takeBreak({ clockOutTime: new Date(clockOutTime - (currentLostTime * 1000)), toastError });
          return;
        }
      }
      displayAlert({ msg: `Failed to checkin. Message: ${err?.message}` });
    });
}

export function cancelCheckin() {
  const { checkinTimeoutID } = useTimerStore.getState();
  clearTimeout(checkinTimeoutID);
  useTimerStore.setState({ checkinTimeoutID: null })
}

export function cancelAutoResumeTracker() {
  const { autoResumeTimeoutID } = useTimerStore.getState();
  clearTimeout(autoResumeTimeoutID);
  useTimerStore.setState({ autoResumeTimeoutID: null })
}

export function scheduleAutoResumeTracker() {
  const { autoResumeTimeoutID: oldTimeoutID, trackerDisabledAt, trackerDisabledFor } = useTimerStore.getState();

  clearTimeout(oldTimeoutID);
  const timeout = getTimeDiff(trackerDisabledAt, trackerDisabledFor);
  const autoResumeTimeoutID = setTimeout(() => {
    resumeTracker({ autoResume: true, disabledAt: trackerDisabledAt, disabledFor: trackerDisabledFor });
  }, timeout * 1000);

  useTimerStore.setState({ autoResumeTimeoutID });
}

function updateDisabledTrackerTotalMins() {
  const { trackerDisabledAt, trackerDisabledFor, trackerDisabledTotalMins } = useTimerStore.getState();
  const disabledTimestamp = new Date(trackerDisabledAt);
  const now = getUTCDate();
  const diff = differenceInMinutes(now, disabledTimestamp, { roundingMethod: "round" });
  const total =
    diff > trackerDisabledFor
      ? trackerDisabledTotalMins + trackerDisabledFor
      : trackerDisabledTotalMins + diff;

  useTimerStore.setState({ trackerDisabledTotalMins: total });
}

const playSound = () => {
  let audio = new Audio('/audio/error-notification-tone.mp3');
  audio.volume = 0.5;
  audio.play();
}

function handleCallBack(handlerType) {
  useTimerStore.setState({
    alertContent: null,
  });
  switch (handlerType) {
    case 'resume shift':
      resumeShift();
      break;
    case 'start new shift':
      startNewShiftButtonClick();
      break;
    case 'end shift':
      endShift();
      break;
    case 'ok':
      // do nothing
      break;

    default:
      console.log('handlerType is missing in handleCallBack');
      break;
  }
}

export function displayAlert(obj) {
  if (isElectronApp) {
    window.ipcRender.send('message:showError', obj);
  } else {
    useTimerStore.setState({
      alertContent: {
        title: 'Timer Alert',
        description: obj.msg,
        actionButtons: obj?.options?.length
          ? obj.options.map((btn) => {
            return {
              name: btn,
              isActive: btn.toLocaleLowerCase() !== 'ok',
              handler: () => handleCallBack(btn.toLocaleLowerCase()),
            };
          })
          : [{ name: 'Ok', handler: () => handleCallBack('ok') }],
      },
    });
  }
  playSound();
}

export function handleResumeShiftFromServer(data, resumeTracker = false) {
  const { next_checkin_time: nextCheckinTime, timer_id: timerId } = data;

  const updatedState = {
    breakStartedAt: "",
    breakTakenFor: 0,
    expectedBackAt: "",
    inactivityPopupContent: null,
    isTimerStarted: true,
    onBreak: false,
    isSocketEmitInProgress: false,
    nextCheckinTime,
    startTime: data.data_input.start_time,
    timerId,
  }
  if (resumeTracker) {
    updatedState.trackerDisabledAt = "";
    updatedState.trackerDisabledFor = 0;
    updateDisabledTrackerTotalMins();
  }
  useTimerStore.setState(updatedState);
  updateHasTimerStartedHere(false);

  // console.log(`[CHECKIN] - Start Timer (s) - ${getUTCTime()} -> ${nextCheckinTime}`);
  // scheduleCheckin({ nextCheckinTime });
}

export function handleTakeBreakFromServer(data, disableTracker = false) {
  cancelCheckin();
  if (!disableTracker) cancelAutoResumeTracker();

  const { end_time: breakStartedAt, afk_time_min: breakTakenFor } = data.data_input;
  const expectedBackAt = format(
    addMinutes(breakStartedAt, breakTakenFor),
    DATE_FORMAT
  );

  if (isElectronApp) window.ipcRender.send('message:updateTime', data.total_time);
  const updatedState = disableTracker ? {} : {
    isTimerStarted: false,
    onBreak: true,
    breakStartedAt,
    breakTakenFor,
    expectedBackAt,
    trackerDisabledAt: "",
    trackerDisabledFor: 0,
  }
  updatedState.isSocketEmitInProgress = false;
  updatedState.time = data.total_time;

  useTimerStore.setState(updatedState);
  updateHasTimerStartedHere(false);
}

export function handleStopTimerOnLastCheckinFromServer(data) {
  cancelCheckin();
  cancelAutoResumeTracker();
  const { timerSettings, trackerDisabledFor } = useTimerStore.getState();
  const breakTakenFor = 0;
  const breakStartedAt = getUTCTime()
  // const { end_time: breakStartedAt } = data?.data_input;

  const expectedBackAt = format(
    addMinutes(breakStartedAt, breakTakenFor),
    DATE_FORMAT
  );

  const isActivityTrackerDisabled = trackerDisabledFor > 0;
  if (isActivityTrackerDisabled) updateDisabledTrackerTotalMins();

  const newState = {
    isTimerStarted: false,
    onBreak: true,
    breakStartedAt,
    breakTakenFor,
    expectedBackAt,
    trackerDisabledAt: "",
    trackerDisabledFor: 0,
    isSocketEmitInProgress: false,
    time: data.total_time
  }

  const popupInfo = {
    currentLostTime: timerSettings.max_recovery_time_allowed_seconds,
    msg: 'Your session with active timer has failed to check-in at its desired time. If you wish to change your active timer session to this one, please click below.', timerSettings,
    MAX_RECOVERY_TIME: timerSettings.max_recovery_time_allowed_seconds,
  }
  if (isElectronApp) {
    window?.ipcRender?.send('message:showInactivityAlertModal', popupInfo);
    playSound();
  } else {
    newState.inactivityPopupContent = popupInfo;
  }

  if (isElectronApp) window.ipcRender.send('message:updateTime', data.total_time);

  useTimerStore.setState(newState);
  updateHasTimerStartedHere(false);
}

export function extendBreakFromServer(data) {
  const { breakTakenFor, breakStartedAt } = useTimerStore.getState();
  const { start_time: startTime, extend_mins: extendFor } = data.data_input;
  const isBreakExtending = getTimeDiff(breakStartedAt, breakTakenFor) >= 0;
  const newBreakStartedAt = !isBreakExtending ? startTime : breakStartedAt;
  const newBreakTakenFor = !isBreakExtending ? +extendFor : breakTakenFor + +extendFor;
  const expectedBackAt = format(
    addMinutes(newBreakStartedAt, newBreakTakenFor),
    DATE_FORMAT
  );

  const newState = {
    breakStartedAt: newBreakStartedAt,
    breakTakenFor: newBreakTakenFor,
    expectedBackAt,
    isSocketEmitInProgress: false,
  };
  if (data?.data?.new_timer_id) {
    newState.timerId = data.data.new_timer_id;
  }
  useTimerStore.setState(newState);
  updateHasTimerStartedHere(false);
}

export function handleBreakForDisableTrackerFromServer(data) {
  cancelCheckin();
  if (isElectronApp) window.ipcRender.send('message:updateTime', data.total_time);

  useTimerStore.setState({
    isSocketEmitInProgress: false,
    time: data.total_time,
  });
  updateHasTimerStartedHere(false);
}

export function handleDisableTracker(data) {
  const { next_checkin_time: nextCheckinTime, timer_id: timerId } = data;
  const { start_time: trackerDisabledAt, activity_tracker_disabled_time_min: trackerDisabledFor } = data.data_input;

  // console.log(`[CHECKIN] - Disable Tracker (s) - ${getUTCTime()} -> ${nextCheckinTime}`);
  useTimerStore.setState({
    isSocketEmitInProgress: false,
    trackerDisabledAt,
    trackerDisabledFor,
    nextCheckinTime,
    timerId,
    startTime: trackerDisabledAt,
  });

  // scheduleAutoResumeTracker();
  updateHasTimerStartedHere(false);
}

export function handleExtendTrackerDisableFromServer(data) {
  const { trackerDisabledAt, trackerDisabledFor } = useTimerStore.getState();
  const { start_time: startTime, extend_mins: extendFor } = data.data_input;
  const isDisableTrackerExtending = getTimeDiff(trackerDisabledAt, trackerDisabledFor) >= 0;
  const updatedDisableTrackerStartedAtTS = !isDisableTrackerExtending ? startTime : trackerDisabledAt;
  const updatedDisableTrackerFor = isDisableTrackerExtending ? +trackerDisabledFor + +extendFor : +extendFor;

  useTimerStore.setState({
    trackerDisabledAt: updatedDisableTrackerStartedAtTS,
    trackerDisabledFor: updatedDisableTrackerFor,
    isSocketEmitInProgress: false,
  });
  updateHasTimerStartedHere(false);
}

export function handleEndShiftFromServer(data) {
  cancelCheckin();
  cancelAutoResumeTracker();
  const stateCopy = useTimerStore.getState();

  const { end_time: lastClockOutTime } = data.data_input;

  const isActivityTrackerDisabled = stateCopy.trackerDisabledFor > 0;
  if (isActivityTrackerDisabled) updateDisabledTrackerTotalMins();

  useTimerStore.setState({
    time: 0,
    isTimerStarted: false,
    isShiftEnded: true,
    lastClockOutTime,
    breakStartedAt: "",
    breakTakenFor: 0,
    expectedBackAt: "",
    trackerDisabledFor: 0,
    trackerDisabledAt: "",
    isSocketEmitInProgress: false,
  });
  if (isElectronApp) window.ipcRender.send('message:updateTime', 0);
  updateHasTimerStartedHere(false);
}

export function handleCheckinFromServer(data) {
  const { next_checkin_time: nextCheckinTime } = data;
  // console.log(`[CHECKIN] - Success (s) - ${getUTCTime()} -> ${nextCheckinTime}`);
  // scheduleCheckin({ nextCheckinTime });
  useTimerStore.setState({ nextCheckinTime });
}

export function handleEndPausedShiftFromServer(data) {
  const { clock_out_time: lastClockOutTime } = data;
  useTimerStore.setState({
    lastClockOutTime,
    isShiftEnded: true,
    expectedBackAt: "",
    time: 0,
    isSocketEmitInProgress: false,
  });
  if (isElectronApp) window.ipcRender.send('message:updateTime', 0);
  updateHasTimerStartedHere(false);
}

export function handleStartNewShiftFromServer(data) {
  const { next_checkin_time: nextCheckinTime, timer_id: timerId } = data;
  const { start_time: lastClockInTime } = data.data_input;

  useTimerStore.setState({
    isShiftStarted: true,
    isShiftEnded: false,
    isTimerStarted: true,
    inactivityPopupContent: null,
    lastClockInTime,
    onBreak: false,

    hasRevokedClockOut: false,
    isSocketEmitInProgress: false,
    nextCheckinTime,
    startTime: data.data_input.start_time,
    timerId,
  });
  // console.log(`[CHECKIN] - Start Timer (s) - ${getUTCTime()} -> ${nextCheckinTime}`);
  // scheduleCheckin({ nextCheckinTime });
  updateHasTimerStartedHere(false);
}

export function handleRevokeClockOutFromServer(data) {
  const { last_clock_out_time: lastClockOutTime, total_time_sec } = data;
  useTimerStore.setState({
    isShiftEnded: false,
    isShiftStarted: true,
    isSocketEmitInProgress: false,

    lastClockOutTime,
    time: +total_time_sec,
    hasRevokedClockOut: true,
  });
  if (isElectronApp) window.ipcRender.send('message:updateTime', +total_time_sec);
  updateHasTimerStartedHere(false);
}

export function handleRecoverLostTimeFromServer(data) {
  const { extend_mins: trackerDisabledFor = 0 } = data.data_input;
  const { start_time: startTime = 0, timer_id: timerId = null, next_checkin_time: nextCheckinTime = 0 } = data;

  const updatedState = {
    isSocketEmitInProgress: false,
    inactivityPopupContent: null,
  };

  if (data.total_time !== 'None') {
    updatedState.time = data.total_time;
    if (isElectronApp) window.ipcRender.send('message:updateTime', data.total_time);
  }

  const shouldDisableTracker = +trackerDisabledFor > 0;

  if (shouldDisableTracker) {
    // TODO: check if trackerDisabledAt is correct
    updatedState.trackerDisabledAt = startTime;
    updatedState.trackerDisabledFor = +trackerDisabledFor;
    updatedState.timerId = timerId;
    updatedState.nextCheckinTime = nextCheckinTime;
    updatedState.isTimerStarted = true;
    updatedState.onBreak = false;
    if (data?.start_time && data.start_time !== 'None') {
      updatedState.startTime = startTime;
    }
    // console.log(`[CHECKIN] - Recover Lost Time (s) - ${getUTCTime()} -> ${nextCheckinTime}`);
    // scheduleCheckin({ nextCheckinTime });
  }

  useTimerStore.setState(updatedState);
  // if (shouldDisableTracker) scheduleAutoResumeTracker();
  updateHasTimerStartedHere(false);
}
