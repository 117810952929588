import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { getUser } from '../utils/Common';

import '../styles/Global.scss';
// import Header from '../components/header/Header';
// import Sidebar from '../components/sidebar/Sidebar';

class PageNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: getUser() // Get User Details
    };
  }
  
  componentDidMount(){
    //Do Nothing
  }

  render(){
    return (
      <div className="app-wrapper">
        {/* <Header />
        <Sidebar /> */}
        
        <div className="container">
          <div className="pagenotfound">
            <h2>Ahhhhhhh! This page doesn't exist.</h2>
            Go back to <Link to="/">homepage</Link>.
          </div>
        </div>
      </div>
    );
  }
  
}
 
export default PageNotFound;