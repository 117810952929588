import React from 'react';
import './styles.scss'

const AlertPopup = ({ icon, title, description, yesHandler, cancelHandler, displayFullScreen = false, actionButtons = '' }) => {
    return (
        <div id='alert-popup-overlay' className={displayFullScreen ? 'display-full-screen' : 'not-full-screen'}>
            <div className={'alert-popup-wrapper ' + (icon ? 'hasIcon' : '')}>
                <div className={'alert-content-wrapper ' + (icon ? `alert-icon-wrapper ${icon}` : '')}>
                    <div className='alert-title'>
                        {title ? title : 'Discard Changes'}
                    </div>
                    <div className='alert-description'>
                        {description ? description : 'You have unsaved changes, Do you want to discard?'}
                    </div>
                    <div className='alert-action-btn-wrapper'>
                        {actionButtons && actionButtons?.map((btn, index) => {
                            return <button className={`alert-btn ${btn.isActive ? 'btn-active' : ''}`} onClick={btn.handler} key={index}>
                                {btn.name}
                            </button>
                        })}
                        {yesHandler && <button className='alert-btn btn-active' onClick={yesHandler}>
                            Yes
                        </button>}
                        {cancelHandler && <button className='alert-btn' onClick={cancelHandler}>
                            Cancel
                        </button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertPopup