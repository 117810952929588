import "./LineLoader.scss";

export default function LineLoader({ show = true, absolute = false, style }) {
  return (
    <div
      className={`form-progress-line-loader ${show ? 'show' : ''} ${absolute ? "absolute" : "sticky"}`}
      style={style}
    >
      <div className="loading-progress"></div>
    </div>
  );
}
