import React from "react";
import "./LineLoader.scss";

const validClass = {
  sticky: true,
  absolute: true,
  fixed: true,
};

export default function LineLoader({ show = true, position = "sticky", style }) {
  if (!show) return null;

  return (
    <div
      id="progress-line-loader"
      className={validClass[position] ? position : ""}
      style={style}
    >
      <div className="loading-progress"></div>
    </div>
  );
}
