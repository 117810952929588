import React from "react";
import { MouseTooltip } from "components/ToolTip";
import { formatSecondsHhmm, formatTimeInSeconds } from "utils/date";
import { isNotNone } from "../../utils";

const InactivityDetails = ({ data }) => {
    const isUserOnline = data.status === "On";
    const isUserClockedIn = isNotNone(data.clock_in_time);
    const isUserClockedOut = isNotNone(data.clock_out_time);
    const isUserInactive =
        !isUserOnline &&
        isUserClockedIn &&
        !isUserClockedOut &&
        data.expected_back_at === "None";
    return (
        <div className="user-details">
            <MouseTooltip
                content={
                    <div className="break-tooltip">
                        <span>Inactivity Count</span>
                    </div>
                }
                delay={500}
            >
                <span className="inactivity-count instance-count">
                    {data.inactivity_count}
                </span>
            </MouseTooltip>
            <MouseTooltip
                content={
                    <div className="break-tooltip">
                        <span>Inactivity Time</span>
                    </div>
                }
                delay={500}
            >
                <span className="inactivity-time-count">
                    {isUserInactive
                        ? formatTimeInSeconds(
                            isNotNone(data.inactivity_time) ? data.inactivity_time : 0
                        )
                        : formatSecondsHhmm(data.inactivity_time)}
                </span>
            </MouseTooltip>
        </div>
    );
};

export default InactivityDetails;
