import React, { useReducer, useEffect, useRef } from 'react';
import './styles.scss';
import ReactDOMServer from 'react-dom/server';
import { copyToClipBoard } from 'utils/Utils';
import subjectObj from '../../subjects/Subject1';
import { withKeepAlive } from 'components/KeepAlive';
import { PAGE_IDS } from 'components/Navigation';

function GenerateSignature() {
  useEffect(() => {
    subjectObj.notify({ page_title: 'Generate Signature' }); //update page title in header
  }, []);

  console.log(
    'HTML ',
    ReactDOMServer.renderToString(
      <div className='here' style={{ fontWeight: 'bold' }}>
        Hello there
      </div>
    )
  );

  const signReducer = (state, event) => {
    return {
      ...state,
      [event.name]: [event.value],
    };
  };

  const [signState, setSignState] = useReducer(signReducer, {
    name: 'John Doe',
    designation: 'Employee',
    email: 'jane.doe@gmail.com',
    number: '+1 (XXX) XXX-XXXX',
  });

  const handleSignChange = (e) =>
    setSignState({ name: e.target.name, value: e.target.value });

  // const [signStateBack, setSignStateBack] = useState({
  //   name: 'John Doe',
  //   designation: 'Employee',
  //   email: 'jane.doe@gmail.com',
  //   number: '+1 (XXX) XXX-XXXX',
  // });

  // const signChangeHandler = (event) =>
  //   setSignState({
  //     ...signState,
  //     [event.target.name]: event.target.value,
  //   });

  let sigRef = useRef();
  const SigAsHTML = `<!DOCTYPE html>
<html>
  <head>
    <meta https-equiv="Content-Type" content="text/html; charset=UTF-8" />

    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <meta charset="UTF-8" />

    <title>Ascendeum Co.</title>
    <style type="text/css" media="all">
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }
      .a {
        color: #000000;
        border-bottom: none;
        text-decoration: none;
      }
    </style>
  </head>

  <body
    style="word-wrap: break-word; line-break: after-white-space; border: none"
  >
    <table
      cellpadding="0"
      cellspacing="0"
      border="0"
      style="
        max-width: 100%;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: normal;
        color: #000000;
        border-spacing: 0px;
        border: none;
      "
    >
      <tr>
        <td width="500" border="0" style="border: none; padding: 12px 0 10px 0">
          <table
            cellpadding="0"
            cellspacing="0"
            border="0"
            style="
              font-family: Arial, Helvetica, sans-serif;
              font-size: 15px;
              line-height: 22px;
              border-collapse: collapse;
              border-spacing: 0px;
              margin: 0px;
              padding: 0px;
              text-align: left;
            "
          >
            <tr valign="top">
              <td width="500" border="0" style="padding: 0 10px">
                <p
                  style="
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 700;
                    color: #000000;
                    margin: 0;
                    padding: 0;
                  "
                >
                 ${signState.name}
                </p>
                <p
                  style="
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 400;
                    color: #000000;
                    margin: 0 0 10px 0;
                    padding: 0;
                  "
                >
                  ${signState.designation}
                </p>
                <p
                  style="
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    color: #000000;
                    margin: 0 0 16px 0;
                    padding: 0;
                  "
                >
                  Email:
                  <a
                    href="mailto:  ${signState.name}"
                    style="color: #000000; text-decoration: none"
                    > ${signState.name}
                    </a><br />
                  
                  Toll-free:
                  <a
                    href="tel:  ${signState.number}"
                    style="color: #000000; text-decoration: none"
                    > ${signState.number}</a>
                </p>
                <p
                  style="
                    margin: 0;
                    padding: 0;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 20px;
                  "
                >
                  <a
                    href="https://ascendeum.com"
                    style="
                      color: #000000;
                      text-decoration: none;
                      border: none;
                      width: 180px;
                      height: 27px;
                    "
                    title="Ascendeum"
                    ><img
                      src="https://ascendeum-media.s3.us-east-2.amazonaws.com/email-signature/ascendeum-logo.png"
                      alt="Ascendeum"
                      width="180"
                      height="27"
                      style="border: none; max-width: 100%"
                  /></a>
                </p>
              </td>
            </tr>
          </table>
        </td>
        <!-- Root TD-->
      </tr>
    </table>
    <!-- Root Table -->
  </body>
</html>
`;
  const Signature = () => (
    <div
      style={{
        wordWrap: 'break-word',
        lineBreak: 'after-white-space',
        border: 'none',
      }}
      ref={sigRef}
    >
      <table
        style={{
          maxWidth: '100%',
          textAlign: 'center',
          fontFamily: 'Arial, Helvetica, sans-serif',
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 'normal',
          color: '#000000',
          borderSpacing: '0px',
          border: 'none',
        }}
        cellSpacing={0}
        cellPadding={0}
        border={0}
      >
        <tbody>
          <tr>
            <td
              border={0}
              style={{ border: 'none', padding: '12px 0 10px 0' }}
              width={500}
            >
              <table
                style={{
                  fontFamily: 'Arial, Helvetica, sans-serif',
                  fontSize: '15px',
                  lineHeight: '22px',
                  borderCollapse: 'collapse',
                  borderSpacing: '0px',
                  margin: '0px',
                  padding: '0px',
                  textAlign: 'left',
                }}
                cellSpacing={0}
                cellPadding={0}
                border={0}
              >
                <tbody>
                  <tr valign='top'>
                    <td border={0} style={{ padding: '0 10px' }} width={500}>
                      <p
                        style={{
                          fontSize: '15px',
                          lineHeight: '20px',
                          fontWeight: 700,
                          color: '#000000',
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {signState.name}
                      </p>
                      <p
                        style={{
                          fontSize: '15px',
                          lineHeight: '20px',
                          fontWeight: 400,
                          color: '#000000',
                          margin: '0 0 10px 0',
                          padding: 0,
                        }}
                      >
                        {signState.designation}
                      </p>
                      <p
                        style={{
                          fontSize: '14px',
                          lineHeight: '20px',
                          fontWeight: 400,
                          color: '#000000',
                          margin: '0 0 16px 0',
                          padding: 0,
                        }}
                      >
                        Email:&nbsp;&nbsp;
                        <a
                          href={`mailto: ${signState.email}`}
                          style={{
                            color: '#000000',
                            textDecoration: 'none',
                          }}
                        >
                          {signState.email}
                        </a>
                        <br />
                        {/* Direct Line:&nbsp;&nbsp;
                            <a
                              href='tel: +1 (609) 920-6545'
                              style={{
                                color: '#000000',
                                textDecoration: 'none',
                              }}
                            >
                              +1 (609) 920-6545
                            </a> */}
                        {/* <br /> */}
                        Toll-free:&nbsp;&nbsp;
                        <a
                          href={`tel: ${signState.number}`}
                          style={{
                            color: '#000000',
                            textDecoration: 'none',
                          }}
                        >
                          {signState.number}
                        </a>
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: '20px',
                          fontWeight: 700,
                          lineHeight: '20px',
                        }}
                      >
                        <a
                          href='https://ascendeum.com'
                          target='_blank'
                          rel="noreferrer"
                          style={{
                            color: '#000000',
                            textDecoration: 'none',
                            border: 'none',
                            width: '180px',
                            height: '27px',
                          }}
                          title='Ascendeum'
                        >
                          <img
                            src='https://ascendeum-media.s3.us-east-2.amazonaws.com/email-signature/ascendeum-logo.png'
                            alt='Ascendeum'
                            style={{ border: 'none', maxWidth: '100%' }}
                            width={180}
                            height={27}
                          />
                        </a>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            {/* Root TD*/}
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <div id='sig-gen'>
      {/* <div id='app-sub-header'>
        <h2 class='page-title'>Generate Signature</h2>
        <div className='action-button-wrapper'>
          <div className='btn-wrapper'>
            <button
              className='btn btn-setting'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Settings
            </button>
          </div>
        </div>
      </div> */}

      <div className='sign-content-wrapper'>
        <div className='sign-content'>
          <div className='form-wrapper'>
            <div className='title'>Edit</div>
            <div className='form'>
              <form
                action=''
                onChange={(e) => {
                  console.log('Form change ', e);
                }}
                className='form-fields'
              >
                <div className='row'>
                  <label htmlFor='name'>Name</label>
                  <input
                    type='text'
                    placeholder='Name'
                    name='name'
                    value={signState.name}
                    onChange={handleSignChange}
                  />
                </div>
                <div className='row'>
                  <label htmlFor='designation'>Designation</label>

                  <input
                    type='text'
                    placeholder='Designation'
                    name='designation'
                    value={signState.designation}
                    onChange={handleSignChange}
                  />
                </div>
                <div className='row'>
                  <label htmlFor='email'>Email</label>

                  <input
                    type='text'
                    placeholder='Email'
                    name='email'
                    value={signState.email}
                    onChange={handleSignChange}
                  />
                </div>
                <div className='row'>
                  <label htmlFor='number'>Number</label>

                  <input
                    type='text'
                    placeholder='Number'
                    name='number'
                    value={signState.number}
                    onChange={handleSignChange}
                  />
                </div>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    console.log('Sig ref ', sigRef);
                    // sigRef.current.innerHTML.select();
                    copyToClipBoard(SigAsHTML);
                    // copy(sigRef.current.outerHTML);
                    // document.execCommand('copy');
                  }}
                >
                  COPY HTML
                </button>
              </form>
            </div>
          </div>

          <div className='preview-wrapper'>
            <div className='title'>Preview</div>
            <div className='preview-outer'>
              <div className='preview'>
                <Signature />
              </div>
              <span>Select and copy content from the preview for rich text.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withKeepAlive(GenerateSignature, PAGE_IDS.BRANDING_KIT);