import { useEffect, useRef, useState } from "react";
import SearchInput from "components/SearchInput/SearchInput";
import ListView from "./components/ListView";
import GridView from "./components/GridView";
import ManageFolderModal from "./components/ManageFolderModal";
import LineLoader from "components/LineLoader";
import { NavTabs } from "components/Tabs/Tabs";
import { useOnClickOutside } from "hooks/useClickOutside";
import { useWikiContext } from "./context";
import { debounce } from "utils/Common";

const tabs = [
  {
    id: 1,
    label: "My Files",
  },
  {
    id: 2,
    label: "Shared with me",
  },
];

export default function Wiki() {
  const {
    VIEW,
    loadingArticles,
    selectedView,
    setSelectedView,
    activeTabId,
    setActiveTabId,
    articles,
    isInitialArticlesFetch,
    pageSize,
    pageMeta,
    fetchArticles,
    search,
    setSearch,
  } = useWikiContext();

  useEffect(() => {
    if (isInitialArticlesFetch) {
      fetchArticles({ search_string: search });
    }
  }, []);

  const [showSettingsMenu, setShowSettingsMenu] = useState(false);

  const [showManageFolderModal, setShowManageFolderModal] = useState(false);
  const manageFolderModalRef = useRef(null);
  const manageFolderModalTimeoutRef = useRef(null);

  useEffect(() => () => clearTimeout(manageFolderModalTimeoutRef.current), []);

  const openManageFolderModal = () => {
    clearTimeout(manageFolderModalTimeoutRef.current);
    setShowManageFolderModal(true);
    manageFolderModalTimeoutRef.current = setTimeout(() => {
      if (manageFolderModalRef.current) {
        const modalEl = manageFolderModalRef.current;
        modalEl?.classList?.add("show");
        clearInterval(manageFolderModalTimeoutRef.current);
      }
    }, 1);
  };

  const closeManageFolderModal = () => {
    clearTimeout(manageFolderModalTimeoutRef.current);
    manageFolderModalRef?.current?.classList?.remove("show");
    manageFolderModalTimeoutRef.current = setTimeout(() => {
      setShowManageFolderModal(false);
    }, 200);
  };

  useOnClickOutside(manageFolderModalRef, closeManageFolderModal);

  const searchInputRef = useRef(null);
  const [expandSearch, setExpandSearch] = useState(!!search);
  const handleExpandSearch = (value) => {
    if (value) {
      setExpandSearch(true);
    } else {
      if (search) {
        searchInputRef.current.value = "";
        setSearch("");
        fetchArticles();
      }

      setExpandSearch(false);
    }
  };

  const handleSearchInputChange = debounce((e) => {
    const searchText = e.target.value.trim();
    setSearch(searchText);
    fetchArticles({ search_string: searchText });
  }, 450);

  return (
    <>
      <LineLoader show={loadingArticles} position="absolute" />
      {!isInitialArticlesFetch ? (
        <div className="wiki-content">
          <div className="home-filters">
            <div className="filters">
              <button
                className="view-select-btn"
                onClick={() =>
                  setSelectedView(
                    selectedView === VIEW.LIST ? VIEW.GRID : VIEW.LIST,
                  )
                }
              >
                {selectedView === VIEW.LIST ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                  >
                    <path
                      fill="#B1B2B3"
                      d="M14 .876H5.25v1.75H14V.876ZM14 6.126H5.25v1.75H14v-1.75ZM14 11.376H5.25v1.75H14v-1.75ZM2.625 0H.875C.35 0 0 .35 0 .877v1.75c0 .525.35.875.875.875h1.75c.525 0 .875-.35.875-.875V.876C3.5.35 3.15 0 2.625 0ZM2.625 5.25H.875C.35 5.25 0 5.6 0 6.127v1.75c0 .525.35.875.875.875h1.75c.525 0 .875-.35.875-.875v-1.75c0-.525-.35-.875-.875-.875ZM2.625 10.5H.875c-.525 0-.875.35-.875.876v1.75c0 .524.35.875.875.875h1.75c.525 0 .875-.35.875-.875v-1.75c0-.525-.35-.875-.875-.875Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                  >
                    <path
                      fill="#B1B2B3"
                      d="M5.25 0H.875C.35 0 0 .35 0 .877V5.25c0 .525.35.875.875.875H5.25c.525 0 .875-.35.875-.875V.876C6.125.35 5.775 0 5.25 0ZM13.125 0H8.75c-.525 0-.875.35-.875.876V5.25c0 .525.35.875.875.875h4.375c.525 0 .875-.35.875-.875V.876C14 .35 13.65 0 13.125 0ZM5.25 7.876H.875c-.525 0-.875.35-.875.875v4.375c0 .524.35.875.875.875H5.25c.525 0 .875-.35.875-.875V8.75c0-.525-.35-.875-.875-.875ZM13.125 7.876H8.75c-.525 0-.875.35-.875.875v4.375c0 .524.35.875.875.875h4.375c.525 0 .875-.35.875-.875V8.75c0-.525-.35-.875-.875-.875Z"
                    />
                  </svg>
                )}
              </button>
              <NavTabs
                tabs={tabs}
                activeTabId={activeTabId}
                onTabClick={(id) =>
                  setActiveTabId(id === activeTabId ? null : id)
                }
                disabled
              ></NavTabs>
              <button className="bin-btn" disabled>
                <span className="icon-recycle"></span>
                <span>Bin</span>
              </button>
              <SearchInput
                ref={searchInputRef}
                expand={expandSearch}
                setExpand={handleExpandSearch}
                value={search}
                onChange={handleSearchInputChange}
                blurDelay={200}
                showCloseBtn={true}
              />
            </div>
            <div className="settings-wrapper">
              <button
                className="settings-btn"
                onClick={() => setShowSettingsMenu(!showSettingsMenu)}
                disabled
              >
                <span className="icon-settings"></span>
              </button>
              {showSettingsMenu && (
                <div className="settings-menu">
                  <button
                    onClick={() => {
                      openManageFolderModal();
                      setShowSettingsMenu(false);
                    }}
                  >
                    Manage Folder
                  </button>
                </div>
              )}
            </div>
          </div>
          {selectedView === VIEW.LIST ? (
            <ListView
              data={articles}
              pageSize={pageSize}
              loadingData={loadingArticles}
              pageMeta={pageMeta}
              getArticles={fetchArticles}
            />
          ) : (
            <GridView
              data={articles}
              pageSize={pageSize}
              loadingData={loadingArticles}
              pageMeta={pageMeta}
              getArticles={fetchArticles}
            />
          )}
        </div>
      ) : null}
      {showManageFolderModal && (
        <ManageFolderModal ref={manageFolderModalRef} />
      )}
    </>
  );
}
