import { forwardRef, useRef, useState } from "react";
import SpeedSelect from "react-speedselect";
import "./ManageFolderModal.scss";
import Avatar from "components/Avatar";

const roleOptions = [
  { id: 1, name: "Admin" },
  { id: 2, name: "Editor" },
  { id: 3, name: "Viewer" }
]

const ManageFolderModal = forwardRef((_, ref) => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const searchInputRef = useRef(null);

  const [showFolderAddInput, setShowFolderAddInput] = useState(false);
  const [folders, setFolders] = useState(["Company Docs", "Onboarding", "Engineering", "Design", "Product"]);

  const deleteFolder = (index) => {
    const newFolders = [...folders];
    newFolders.splice(index, 1);
    setFolders(newFolders);
  }

  const [selectedRole, setSelectedRole] = useState(null);
  const [showUserSearchInput, setShowUserSearchInput] = useState(false);
  const [searchUserList, setSearchUserList] = useState([]);

  const [users, setUsers] = useState([
    { name: "Ramesh Suthar", role: 3 },
    { name: "Chandan Kumar", role: 3 },
    { name: "Vineet Kumar", role: 3 },
  ])

  const removeUser = (index) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    setUsers(newUsers);
  }

  const addUser = (user) => {
    setUsers([...users, user]);
    setShowUserSearchInput(false);
  }

  return (
    <div className="manage-folder-modal" ref={ref}>
      <div className="modal-header">
        <h6>Manage Folder</h6>
        <button className="icon-search" onClick={() => {
          setShowSearchInput(true);
          searchInputRef?.current?.focus();
        }}></button>
        <div className={`search-input-wrapper ${showSearchInput ? 'show' : ''}`}>
          <input ref={searchInputRef} type="text" placeholder="Search" />
          <button className="icon-close" onClick={() => setShowSearchInput(false)}></button>
        </div>
      </div>
      <div className="modal-body">
        <div className="folders-wrapper">
          <div className="add-folder-wrapper">
            <button className="add-folder-btn" onClick={() => setShowFolderAddInput(true)}>
              <span className="icon-plus"></span>
              <span>Folder</span>
            </button>
            {showFolderAddInput && (
              <input
                type="text"
                className="add-folder-input"
                placeholder="Type here"
                autoFocus
                onBlur={(e) => {
                  if (e.target.value.trim() === "") {
                    setShowFolderAddInput(false);
                  }
                }}
                onKeyDown={e => {
                  if (e.key === "Enter" && e.target.value.trim() !== "") {
                    setFolders([...folders, e.target.value.trim()]);
                    setShowFolderAddInput(false);
                  }
                }}
              />
            )}
          </div>
          <div className="folders-list">
            {folders.map((name, index) => {
              return (
                // TODO: use folder id as key
                <div className="folder" key={index}>
                  <div className="name">
                    <span className="icon-folder"></span>
                    <span>{name}</span>
                  </div>
                  <button className="icon-delete" onClick={() => deleteFolder(index)}></button>
                </div>
              )
            })}
          </div>
        </div>
        <div className="users-wrapper">
          <div className="role-select-options react-speedselect-option">
            <SpeedSelect
              className="role-select"
              options={roleOptions}
              displayKey="name"
              uniqueKey="id"
              disableSearch={true}
              selectedOption={roleOptions.find(
                (option) => option.id === selectedRole
              )}
              onSelect={(option) => setSelectedRole(option.id)}
              prominentLabel="Role"
            />
          </div>
          <div className="user-list-wrapper">
            <div className="add-user">
              <button
                className="add-user-btn"
                onClick={() => setShowUserSearchInput(true)}
              >
                <span className="icon-plus"></span>
                <span>User</span>
              </button>
              {showUserSearchInput && (
                <input
                  type="text"
                  className="user-search-input"
                  placeholder="Type Name or mail id"
                  autoFocus
                  onBlur={(e) => {
                    if (e.target.value.trim() === "") {
                      setShowUserSearchInput(false);
                      setSearchUserList([]);
                    }
                  }}
                  onInput={(e) =>
                    setSearchUserList([{ name: "Vaibhav Singh" }])
                  }
                />
              )}
            </div>
            {showUserSearchInput && searchUserList.length > 0 ? (
              <div className="search-user-list">
                {searchUserList.map((user, index) => {
                  const firstName = user.name.split(" ")[0];
                  const lastName = user.name.split(" ")[1];
                  return (
                    <div className="search-user" key={index}>
                      <div className="search-user-info">
                        <Avatar
                          firstName={firstName}
                          lastName={lastName}
                          width="20px"
                          height="20px"
                          borderRadius="2px"
                        />
                        <div className="user-name">{user.name}</div>
                      </div>
                      <button className="user-cta" onClick={() => addUser(user)}>Add</button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="user-list">
                {users.map((user, index) => {
                  const firstName = user.name.split(" ")[0];
                  const lastName = user.name.split(" ")[1];
                  return (
                    <div className="user" key={index}>
                      <div className="user-info">
                        <Avatar
                          firstName={firstName}
                          lastName={lastName}
                          width="20px"
                          height="20px"
                          borderRadius="2px"
                        />
                        <div className="user-name">{user.name}</div>
                      </div>
                      <div className="user-cta">
                        <div className="react-speedselect-option">
                          <SpeedSelect
                            options={roleOptions}
                            displayKey="name"
                            uniqueKey="id"
                            disableSearch={true}
                            selectedOption={roleOptions.find(
                              (option) => option.id === user.role
                            )}
                            onSelect={(option) => { }}
                            prominentLabel=""
                          />
                        </div>
                        <button className="icon-minus" onClick={() => removeUser(index)}></button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})

export default ManageFolderModal
