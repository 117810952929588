import React, { useRef } from "react";
import { CLOCK_IN_COLOR_MAP, REQUEST_TYPES } from "views/Attendance/constants";
// components
import HoverText from "react-hover-text";
import Avatar from "components/Avatar";
import SpecialShiftInfoIcon from './SpecialShiftPopUp';
import UserProfileDetailsCard from "./UserProfileDetailsCard";
import BreakDetails from "./BreakDetails";
import { Popover, PopoverContent, PopoverTrigger } from "components/Popover";
// utils
import { getFormattedTimeAndDiff, isNotNone, getAwayTime, isNone } from "views/Attendance/utils";
import { formatTimeInSeconds, format, formatSecondsHhmm } from "utils/date";
// styles
import "./TeamMemberCard.scss";

export default function TeamMemberCard({
  emp,
  loggedInUser,
  prevSelectedDate,
  expandedCard,
  setExpandedCard,
  showUserDetails,
  setShowUserDetails,
}) {

  const isClockedIn = emp.clock_in_time !== "None";
  const isClockedOut = emp.clock_out_time !== "None";
  const isOnLeave = emp.attendance.includes(REQUEST_TYPES.LEAVE);
  const isOnline = emp.status === "On";
  const onSpecialShift = isNotNone(emp.special_shift_time);
  const isInactive =
    !isOnline &&
    isClockedIn &&
    !isClockedOut &&
    emp.expected_back_at === "None";
  const inActiveTime = isNotNone(emp.inactivity_time) ? emp.inactivity_time : 0;
  let status = ""
  if (isOnline) {
    // if (emp?.special_shift_activity_tracked === 0) {
    //   status = "online-working-afk";
    // } else {
    // }
    status = "online";
  } else {
    if (isClockedOut) {
      status = "offline";
    } else {
      // we add leave class for both leave and not clocked in 
      if (isOnLeave) {
        status = isClockedIn ? "away" : 'leave';
      } else {
        status = isClockedIn ? "away" : "offline";
      }
    }
  }

  // const lateBreakTime = isNotNone(emp.late_break_time) ? emp.late_break_time : 0;
  const awayTime = !isOnline && isClockedIn && isNotNone(emp.expected_back_at) ? getAwayTime(emp.expected_back_at) : null;
  const clockInColor = CLOCK_IN_COLOR_MAP[emp.attendance];

  const getStatus = () => {
    let status = '';
    if (isOnLeave) {
      status = 'On Leave';
    } else if (isClockedOut) {
      status = 'Clocked-Out';
    } else if (isOnline) {
      // if (emp?.special_shift_activity_tracked === 0) {
      //   status = "Working AFK";
      // } else {
      // }
      status = 'Working';
    } else if (isClockedIn) {
      status = awayTime !== null && !isClockedOut ? <span><span className={`away-time ${awayTime < 0 ? 'red' : ''}`}>{formatTimeInSeconds(Math.abs(awayTime))}</span> Away</span> : "Away";
    } else {
      status = 'Not Started';
    }

    return (
      <div
        className={`employee-status ${isNotNone(emp.special_shift_time) ? "in-special-shift" : ""
          }`}
        onClick={(e) => e.stopPropagation()}
      >
        {status}
        {isNotNone(emp.special_shift_time) &&
          emp.special_shift_time?.length > 0 && (
            <>
              <span className="p0">-</span>
              {onSpecialShift && (
                <SpecialShiftInfoIcon
                  data={emp.special_shift_time}
                  isActivityTracked={emp.special_shift_activity_tracked !== 0}
                />
              )}
            </>
          )}
      </div>
    );
  }

  const toggleShowDetails = (e) => {
    e.stopPropagation();
    setShowUserDetails(prev => emp.resource_id === prev ? '' : emp.resource_id);
  }

  const toggleExpandedCard = () => {
    if (showUserDetails) setShowUserDetails('');
    if (isClockedIn) setExpandedCard(prev => emp.resource_id === prev ? '' : emp.resource_id);
  }

  let clock_in_time = !isClockedIn
    ? ""
    : getFormattedTimeAndDiff(emp.clock_in_time, prevSelectedDate);
  let clock_out_time = !isClockedOut
    ? ""
    : getFormattedTimeAndDiff(emp.clock_out_time, prevSelectedDate);

  const isDarkMode = document.body.dataset.theme === "dark";
  const popoverTriggerRef = useRef(null);

  return (
    <div
      className="team-member-card"
      id={`team-member-card-${emp.resource_id}`}
    >
      <div className="header" onClick={toggleExpandedCard}>
        <div className="employee">
          <div className={`employee-avatar ${status}`}>
            <div className="avatar-wrapper" onClick={toggleShowDetails}>
              <Popover>
                <PopoverTrigger ref={popoverTriggerRef} asChild>
                  <div>
                    <Avatar
                      imgSrc={emp.img_url}
                      firstName={emp.first_name}
                      lastName={emp.last_name}
                      fontSize={14}
                      height={36}
                      width={36}
                      borderRadius={2}
                    />
                  </div>
                </PopoverTrigger>
                <PopoverContent
                  className="fade-in"
                  root={popoverTriggerRef}
                  style={{ zIndex: 10, left: "10px" }}
                >
                  <UserProfileDetailsCard user={emp} />
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <div className="name-status-wrapper">
            <span
              className={
                loggedInUser?.resource_id === emp.resource_id ? "font-bold" : ""
              }
            >
              {emp.first_name} {emp.last_name}
            </span>
            {getStatus()}
          </div>
        </div>
        {isClockedIn && (
          <div
            className={`icon-chevron-right ${expandedCard === emp.resource_id ? "opened" : ""
              }`}
          ></div>
        )}
      </div>
      <div
        className={`body ${expandedCard === emp.resource_id ? "collapse" : ""}`}
      >
        <div>
          <div className="data">
            <span className="label">Clock In</span>
            <span className={`value ${clockInColor}`}>
              {clock_in_time ? clock_in_time.time : "-"}
            </span>
          </div>
          <div className="data">
            <span className="label">Clock-Out</span>
            <span className="value clock-out">
              <span>{clock_out_time ? clock_out_time.time : "-"}</span>
              {clock_out_time.diff && (
                <span
                  className="icon-info-bubble-wrapper"
                  onClick={(e) => e.stopPropagation()}
                >
                  <HoverText
                    placement="bottom"
                    content={`Clock-Out rolled over to next date : ${format(
                      emp.clock_out_time,
                      "ddd, MMM DD, YYYY"
                    )}`}
                    stylingOptions={{
                      bgColor: isDarkMode ? "#1F2124" : "#E2E2E2",
                      color: isDarkMode ? "#B1B2B3" : "#000",
                      width: "210px",
                      borderRadius: "2px",
                    }}
                  >
                    <span
                      className={`icon icon-info-bubble user-${emp.resource_id}`}
                    ></span>
                  </HoverText>
                </span>
              )}
            </span>
          </div>
          <div className="data">
            <span className="label">Total Hrs</span>
            <span className="value">
              {emp.total_time === "None"
                ? "-"
                : formatTimeInSeconds(+emp.total_time)}
            </span>
          </div>
          <div className="data fill-line">
            <div>
              <span className="label">Breaks</span>
              <span className="value">
                <BreakDetails data={emp} />
              </span>
            </div>
            {/* {awayTime && (
              <div>
                <span className="label">Away</span>
                <span className={`value bg-gray value-block ${awayTime < 0 ? 'red' : ''}`}>
                  {formatTimeInSeconds(Math.abs(awayTime))}
                </span>
              </div>
            )} */}
          </div>
          <div className="data">
            <span className="label">Inactivity Count</span>
            {isNone(emp.inactivity_count) || !isClockedIn ? (
              <span className="value">-</span>
            ) : (
              <span className="value bg-gray inactivity-count">
                {emp.inactivity_count}
              </span>
            )}
          </div>
          <div className="data">
            <span className="label">Inactive Time</span>
            {isNone(emp.inactivity_time) || !isClockedIn ? (
              <span className="value">-</span>
            ) : (
              <span className="value bg-gray inactivity-time">
                {isInactive
                  ? formatTimeInSeconds(inActiveTime)
                  : formatSecondsHhmm(inActiveTime)}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
