import { useEffect, useMemo, useRef, useState } from "react"
import ClickOutsideListner from "components/ClickOutsideListner";
import DatePicker from "components/ReactCalendar/DatePicker";
import { format } from "utils/date";

const dateFormat = 'MM / DD / YYYY'

const DOBPicker = ({ value, handleInputChange, hasError }) => {
    const [showCalendar, setShowCalendar] = useState(false)
    const toggleCalendar = (e) => {
        if (e && e.target.id === 'dob-value') return;
        setShowCalendar((prev) => !prev)
    }
    return (
        <div className='date-picker-wrapper'>
            <button
                className={'date' + (!value ? ' placeholder' : '') + (hasError ? ' has-error' : '')}
                onClick={() => toggleCalendar()}
                id="dob-value"
            >
                {value ? format(value, dateFormat) : dateFormat}
            </button>

            {showCalendar && (
                <Picker
                    value={value}
                    handleInputChange={handleInputChange}
                    toggleCalendar={toggleCalendar}
                />
            )}
        </div>
    )
}

const Picker = ({ value, handleInputChange, toggleCalendar }) => {
    const ref = useRef(null)
    useEffect(() => {
        if (ref.current) {
            ref.current.classList.add('show')
        }
    }, [])

    const endDate = useMemo(() => {
        const today = new Date();
        const firstDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        const date = new Date(firstDayOfNextMonth - 1);
        date.setFullYear(today.getFullYear() - 18);
        date.setHours(23, 59, 59, 999);
        return date;
    }, [])

    const defaultValue = useMemo(() => {
        const today = new Date();
        const date = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
        return format(date, "YYYY-MM-DD");
    }, [])

    return (
        <div className={`date-picker-container`} ref={ref}>
            <ClickOutsideListner onOutsideClick={toggleCalendar}>
                <div className={`date-picker ${value ? '' : 'default-value'}`}>
                    <DatePicker
                        date={value ? value : defaultValue}
                        onChange={(date) => {
                            handleInputChange(date, 'dob')
                            toggleCalendar()
                        }}
                        picker="year"
                        showInline={true}
                        disableFn={(date) => date > endDate}
                    />
                </div>
            </ClickOutsideListner>
        </div>
    )
}

export default DOBPicker