import React, { useState } from 'react';
import APIService from "services/apiService";
import { alertService } from 'services/alertService';
import * as Constants from 'components/Constants';
import './styles.scss';
import Avatar from 'components/Avatar';

const SearchRecipient = ({ addRecipient, recipientsList, loggedInUserResId }) => {
    const [searchString, setSearchString] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [loadingResults, setLoadingResults] = useState(false);

    const handleSearch = () => {
        setLoadingResults(true);
        const url = new URL(Constants.API_BASE_URL + `/employee_details`);
        if (searchString.includes('@')) {
            url.searchParams.append('email', searchString);
        } else {
            url.searchParams.append('search_string', searchString);
        }

        APIService.apiRequest(url.toString(), null, false, 'GET')
            .then(response => {
                if (response.status === 1) {
                    setLoadingResults(false);
                    // Remove self from the list
                    setSearchResults(response.output.filter(result => result.resource_id !== loggedInUserResId));
                    // console.log('handleSearch - if response', response);
                } else {
                    setLoadingResults(false);
                    // console.log('handleSearch - else response', response.msg);
                }
            }).catch(err => {
                setLoadingResults(false);
                alertService.error(`Error: ${err.msg}`);
                console.log('handleSearch - error', err);
            });
    }

    const searchResultsContent = searchResults && searchResults.length && searchResults.map(c => {
        return <div
            className='candidate-wrapper'
            key={c.resource_id}
            title='Click to add candidate'
            onClick={() => addRecipient(c.resource_id, c.first_name, c.last_name, c.img_url)}
            style={{ opacity: recipientsList && recipientsList.length && recipientsList.some(recipeint => recipeint.id === c.resource_id) ? '0.3' : '' }}
        >
            <Avatar
                imgSrc={c.img_url}
                firstName={c.first_name}
                lastName={c.last_name}
                alt={c.first_name + "'s pic"}
                height='30px'
                width='30px'
                borderRadius='50%'
                fontSize='11px'
            />
            <div>
                <span className='name'>{`${c.first_name} ${c.last_name}`} </span>
                <div className='candidate-details'>
                    <p><span className='pl-label'>ID : </span>{c.resource_id}</p>
                    <p><span className='pl-label'>Dept : </span>{c.department}</p>
                </div>
            </div>
        </div>
    })
    return (
        <div className='search-recipient-form'>
            <p className='pl-label'>Search for a recipient</p>
            <div className="gl-search">
                <input
                    type="search"
                    placeholder="Search by Name, ID or Email"
                    autoFocus
                    title="Hit 'Enter' key to search"
                    onChange={(e) => setSearchString(e.target.value)}
                    onKeyDown={(e) => {
                        e.key === 'Enter' && searchString && handleSearch();
                    }}
                />
            </div>
            {loadingResults ? <div className="loader-container"><span className='loader'></span></div> :
                (searchResults && searchResults.length > 0 && <div className='search-result-wrapper'>
                    <p>Search Results</p>
                    {searchResultsContent}
                </div>)}
        </div>
    )
}

export default SearchRecipient