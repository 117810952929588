import { useState } from "react";
import { ATTENDANCE_STATUS_FILTER_OPTIONS } from "../constants";
// components
import FilterButton from "components/FilterModal/FilterButton";
import FilterModal from "components/FilterModal/FilterModal";
// context
import { useTeamMemberFilter } from "../context/teamMemberFilterContext";
// styles
import "./AttendanceFilterBy.scss";

const userAttendanceStatusFilterOptions = [
  ...ATTENDANCE_STATUS_FILTER_OPTIONS,
  {
    name: "Holiday",
    id: "holiday",
  },
];

export default function TeamMemberAttendanceFilterBy() {
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  function toggleShowFilterModal() {
    setShowFilterModal(!showFilterModal);
  }

  const { filterQuery, setFilterQuery } = useTeamMemberFilter();

  function handleClear() {
    setFilterQuery([]);
    setIsFilterApplied(false);
  }

  function handleAttendanceChange(opt, isChecked) {
    const newFilterQuery = isChecked
      ? [...filterQuery, opt.id]
      : filterQuery.filter((item) => item !== opt.id);

    setFilterQuery(() => newFilterQuery);
    setIsFilterApplied(newFilterQuery.length > 0 ? true : false);
  }

  return (
    <div className="filter-by">
      <FilterButton
        isFilterApplied={filterQuery.length > 0}
        onClick={() => toggleShowFilterModal()}
      />

      {isFilterApplied && (
        <button className={`clear-button`} onClick={handleClear}>
          Clear
        </button>
      )}

      <FilterModal
        show={showFilterModal}
        toggle={toggleShowFilterModal}
        options={[
          {
            label: "Attendance",
            options: userAttendanceStatusFilterOptions.map((option) => ({
              id: option.id,
              name: option.name,
              checked: filterQuery.includes(option.id),
            })),
            onChange: handleAttendanceChange,
          },
        ]}
      />
    </div>
  );
}