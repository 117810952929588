import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import LineLoader from './LineLoader';
import SuccessPage from './SuccessPage';
import APIService from 'services/apiService';
import { API_BASE_URL } from 'components/Constants';
import { questionsData } from '../constants'
import { useJobInfo } from '../context';
import './Questionnaire.scss'

const minLength = 50;
const validateText = (text) => {
    if (!text.trim()) {
        return 'This field is required';
    } else if (text.trim().length < minLength) {
        return `Minimum ${minLength} characters required`;
    }
    return null;
}

function Questionnaire() {
    const params = useParams();
    const [showLineLoader, setShowLineLoader] = useState(false);
    const { jobInfo, fetchJobInfo } = useJobInfo();

    useEffect(() => {
        const id = params.id;
        setShowLineLoader(true)
        const controller = new AbortController()
        fetchJobInfo(id.split("e")[0], controller).finally(() => setShowLineLoader(false));
        return () => controller.abort();
    }, [])

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [errors, setErrors] = useState({});
    const [responses, setResponses] = useState(() => {
        const initialResponses = {};
        questionsData.forEach(item => {
            item.questions.forEach(question => {
                initialResponses[question.id] = '';
            })
        });
        return initialResponses;
    });

    const handleResponseChange = (questionId, answer) => {
        setResponses(prevResponses => {
            const response = { ...prevResponses }
            response[questionId] = answer
            return response
        })
    }

    const submitForm = async () => {
        try {
            if (JSON.stringify(errors) !== '{}') {
                return;
            }
            const newErrors = { ...errors };
            let hasErrors = null;

            for (let key in responses) {
                if (responses.hasOwnProperty(key)) {
                    const error = validateText(responses[key]);

                    if (error) {
                        newErrors[key] = error;
                        hasErrors = true;
                    } else {
                        delete newErrors[key];
                    }
                }
            }

            if (hasErrors) {
                setErrors(newErrors);
                return;
            }

            const answers = Object.keys(responses).map(key => {
                return {
                    question_id: key,
                    answer: responses[key]
                }
            });

            setIsSubmitting(true);
            const payload = {
                recruitment_funnel_stage_id: +params.id.split('e')[1],
                unique_id: params.uniqueId,
                answer: answers,
            };

            const response = await APIService.apiRequest(API_BASE_URL + `/submit_questionnaire`, payload, false, "POST")
            if (response.status === 1) {
                setIsSubmitting(false);
                setIsSubmitted(true);
            } else {
                console.error("Error submitting form", response);
                setIsSubmitting(false);
                alert(response.error);
            }
        } catch (error) {
            console.error("Error submitting questionnaire form", error);
            setIsSubmitting(false);
            alert("Error submitting questionnaire form. Please try again later!");
        }
    };

    return (
        <Fragment>
            <LineLoader show={showLineLoader || isSubmitting} />
            <div className='forms-container'>
                {isSubmitted ? (
                    <SuccessPage />
                ) : jobInfo.title ? (
                    <Fragment>
                        <div className="job-info show-form">
                            <div className="job-title">
                                <h1>{`${jobInfo.title} - Questionnaire`}</h1>
                            </div>
                        </div>
                        <div className='questionnaire-wrapper'>
                            {questionsData.map((item) => {
                                return (
                                    <Question
                                        key={item.id}
                                        title={item.title}
                                        questions={item.questions}
                                        onResponseChange={handleResponseChange}
                                        responses={responses}
                                        errors={errors}
                                        setErrors={setErrors}
                                    />
                                )
                            })}
                        </div>
                        <button
                            className="submit-btn"
                            onClick={submitForm}
                            disabled={isSubmitting}
                        >
                            SUBMIT RESPONSE
                        </button>
                    </Fragment>
                ) : null}
            </div>
        </Fragment>
    )
}

export const Question = ({ questions, title, onResponseChange, responses, errors, setErrors }) => {
    const handleInputChange = (questionId, event) => {
        const answer = event.target.value;
        onResponseChange(questionId, answer);
    };

    const handleBlur = ({ value, id }) => {
        let error = validateText(value);

        setErrors(prevErrors => {
            const updatedErrors = { ...prevErrors };
            if (error) {
                updatedErrors[id] = error;
            } else {
                delete updatedErrors[id];
            }
            return updatedErrors;
        });
    };

    return (
        <div className='questions-wrapper'>
            <div className='group'>
                <div className='group-title'>{title}</div>
                {questions.map((item, index) => {
                    const isRequired = item.isRequired;
                    return (
                        <div className='question-wrapper' key={index}>
                            {item.question && <label className={isRequired ? 'required' : ''}>{item.question}</label>}
                            <textarea
                                className={errors[item.id] ? 'has-error' : ''}
                                id={item.id}
                                onChange={(event) => handleInputChange(item.id, event)}
                                onBlur={(e) => handleBlur(e.target)}
                                placeholder={item.placeholder}
                                value={responses[item.id] || ''}
                                required={isRequired}
                            />
                            {errors[item.id] && <div className='error'>{errors[item.id]}</div>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


export default Questionnaire