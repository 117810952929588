import React, { useCallback } from 'react';
import Button from './Button';
import { isDev } from 'utils/Common';
import './DownloadContent.scss';

const pluginToolTip = () => {
    return <div className="control-panel-tooltip">
        <span className='icon-info'></span>
        <span className="tooltiptext plugin-tooltip">
            <div className='installation-guide'>
                <span className="tooltiptext-header">How to install the plugin file in Chrome</span>
                <ol>
                    <li>Download the plugin and then unzip the file.</li>
                    <li>Goto Chrome Settings using three dots on the top right corner.</li>
                    <li>Click on extension.</li>
                    <li>Now, Enable developer mode.</li>
                    <li>Click on Load Unpacked and select your Unzip folder.<br />
                        Note: You need to select the folder in which the manifest file exists.</li>
                    <li>The extension will be installed now.</li>
                    <li>Then pin the extension by clicking on pin button under chrome extension button.<br /></li>
                </ol>
            </div>
            <div className='installation-guide'>
                <span className="tooltiptext-header">How to reinstall the plugin file in Chrome</span>
                <ol>
                    <li>Goto Chrome Settings using three dots on the top right corner.</li>
                    <li>Click on extension.</li>
                    <li>Now, Enable developer mode.</li>
                    <li>Click on remove button in order to remove the old TeamLink plugin.</li>
                    <li>Then follow the installation steps as mentioned above.</li>
                </ol>
            </div>
        </span>
    </div>
}

const appToolTip = () => {
    return <div className="control-panel-tooltip">
        <span className='icon-info'></span>
        <span className="tooltiptext">
            <ol>
                <li>Download the file suitable for your processor/chip.</li>
                <li>To find your processor/chip:</li>
                <ol type="a">
                    <li>Click the Apple logo (top left).</li>
                    <li>Select "About This Mac"</li>
                    <li>In the Overview tab, locate the chip/processor label.</li>
                </ol>
                <li>After downloading, double-click the file and move the app to Applications.</li>
                <li>Launch the app from Launchpad.</li>
            </ol>
        </span>
    </div>
}

const DownloadContent = ({ content, hasThinkTeamPluginPrivilege, hasThinkTeamAppPrivilege, hasIAssistAppPrivilege }) => {
    const isProd = !isDev();

    const getS3Url = useCallback((appName, arch, version) => {
        return `https://${appName.toLowerCase()}-assets.s3.us-east-2.amazonaws.com/${appName !== 'TeamLink' ? 'application/' : ''}${isProd ? '' : 'Dev-'}${appName}-${version}-${arch}.dmg.zip`;
    }, [isProd]);

    return (
        <div className='download-content-wrapper'>
            {content.map(fileType => {
                if (fileType.name === 'Plugins' && !hasThinkTeamPluginPrivilege) return null;
                if (fileType.name === 'Application' && !hasThinkTeamAppPrivilege) return null;
                if (fileType.name === 'Support' && !hasIAssistAppPrivilege) return null;
                return (<div className='file-type-wrapper' key={fileType.name}>
                    <div className='file-type-header'>
                        <div className='file-type'>{fileType.name}</div>
                        {/* <span className='icon-info'></span> */}
                        {fileType.name === 'Plugins' ? pluginToolTip() : appToolTip()}
                    </div>
                    {fileType.files.map(file => {
                        return (<div className='file-contents-wrapper' key={file.id}>
                            <div className='file-content'>
                                <div className='file-info'>
                                    <div className='file-info-header'>
                                        <span className='file-name'>{`${file.name} ${file.version}`}</span>
                                        <span className='file-release-date'>{file.releaseDate}</span>
                                    </div>
                                    <div className='file-info-body'>
                                        {file.description}
                                    </div>
                                </div>
                                <div className='download-btn-wrapper'>
                                    {file.buttons.map((button, index) => {
                                        return (
                                            <a
                                                key={file.id + '_' + index}
                                                href={button.downloadLink ? button.downloadLink : getS3Url(file.name, button.arch, file.version)}
                                            >

                                                <Button>
                                                    {button.name}
                                                </Button>
                                            </a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>)
                    })}
                </div>)
            })}
        </div>)
}

export default DownloadContent