import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import ClickOutsideListner from 'components/ClickOutsideListner';
import './NewTimePicker.scss'

function formatTime(inputTime) {
    const [hours, minutes] = inputTime.split(':');
    let formattedHours = parseInt(hours, 10) % 12;
    formattedHours = formattedHours === 0 ? 12 : formattedHours;
    const meridiem = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';
    const formattedTime = `${formattedHours.toString().padStart(2, '0')}:${minutes} ${meridiem}`;
    return formattedTime;
}

const TimePicker = ({ id, value, onChange, disabled, readOnly = false, placeholder = 'Select Time' }) => {
    const inputFocusedRef = useRef(null);
    const isAM = +value?.split(":")[0] < 12;

    const [hrs, setHrs] = useState(() => {
        let hr = +value.split(':')[0];
        return hr > 12 ? hr - 12 : hr
    })
    // let hr = +value.split(':')[0];
    // const hrs = hr > 12 ? hr - 12 : hr
    const [mins, setMins] = useState(+value.split(":")[1]);
    // const mins = +value.split(":")[1]
    const [inputFocused, setInputFocused] = useState(null);
    inputFocusedRef.current = inputFocused;
    const [showNumberDropDown, setShowNumberDropDown] = useState(false);

    useLayoutEffect(() => {
        if (inputFocused) {
            document.getElementById(`${id}_h${+hrs}`)?.scrollIntoView({ block: 'center' });
            document.getElementById(`${id}_m${+mins}`)?.scrollIntoView({ block: 'center' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputFocused])

    useEffect(() => {
        setHrs(() => {
            let hr = +value.split(':')[0];
            return hr > 12 ? hr - 12 : hr === 0 ? 12 : hr
        })
        setMins(+value.split(":")[1])
    }, [value]);

    useEffect(() => {
        if (showNumberDropDown && readOnly) {
            setShowNumberDropDown(false);
        }
    }, [readOnly, showNumberDropDown])

    const convertTime = (timeStr) => {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':');

        hours = parseInt(hours, 10);
        minutes = parseInt(minutes, 10);

        if (hours === 12) {
            hours = 0;
        }
        if (modifier === 'PM') {
            hours += 12;
        }

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        onChange({
            value: `${formattedHours}:${formattedMinutes}`,
        });
    };

    const handleClick = (type, currValue) => { // handles, when hour or min value is clicked from dropdown 
        if (inputFocusedRef.current !== type) {
            setInputFocused(type);
            document.getElementById(`${id}${type}-input`).focus();
        }
        if (type === 'hrs') {
            setHrs(currValue)
            convertTime(`${currValue}:${mins} ${+value.split(":")[0] >= 12 ? 'PM' : 'AM'}`)
        } else {
            setMins(currValue)
            convertTime(`${hrs}:${currValue} ${+value.split(":")[0] >= 12 ? 'PM' : 'AM'}`)
        }
    };

    const getTimeList = (type) => {
        const timeList = [];

        // Generate hour list
        if (type === 'hrs') {
            for (let j = 1; j < 13; j++) {
                const isActive = +hrs === 0 ? +j === +hrs + 1 : +j === +hrs;
                timeList.push(
                    <span
                        key={'h' + j}
                        id={id + '_h' + j}
                        className={`list-option ${isActive ? 'active' : ''}`}
                        onClick={() => handleClick(type, j)}
                    >
                        {j < 10 ? '0' + j : j}
                    </span>
                );
            }
        }

        // Generate minute list
        if (type === 'mins') {
            for (let j = 0; j < 60; j++) {
                const isActive = +j === +mins;
                timeList.push(
                    <span
                        key={'m' + j}
                        id={id + '_m' + j}
                        className={`list-option ${isActive ? 'active' : ''}`}
                        onClick={() => handleClick(type, j)}
                    >
                        {j < 10 ? '0' + j : j}
                    </span>
                );
            }
        }

        return timeList;
    };

    const getAMPMList = () => {
        const ampmList = [];
        const ampm = ['AM', 'PM'];
        for (let j = 0; j < 2; j++) {
            const isActive = ampm[j] === (isAM ? 'AM' : 'PM');
            ampmList.push(
                <span
                    key={'ampm' + j}
                    id={id + '_ampm' + j}
                    className={`list-option ${isActive ? 'active' : ''}`}
                    onClick={() => handleAmPMClick(ampm[j])}
                >
                    {ampm[j]}
                </span>
            );
        }
        return ampmList;
    };

    const updateState = (inputType, val) => {
        inputType === 'hrs' ? setHrs(val) : setMins(val);
        // if (inputType === 'hrs') setHrs(val)
        convertTime(`${inputType === 'hrs' ? val : hrs}:${inputType === 'mins' ? val : mins} ${+value.split(":")[0] >= 12 ? 'PM' : 'AM'}`)
        document.getElementById(`${id}_${inputType === 'hrs' ? 'h' : 'm'}${val}`)?.scrollIntoView({ block: 'center' });
    }

    const onKeyDown = (e, inputType) => {

        if (e.key === "Tab") {
            setShowNumberDropDown(false);
        }

        if (e.key === "Backspace") {
            let val = null;
            if (inputType === 'hrs') {
                val = e.target.selectionStart === 1 ? hrs % 10 : e.target.selectionStart === 2 ? parseInt(hrs / 10) : 0
                if (val) {
                    updateState('hrs', val);
                }
            } else {
                val = e.target.selectionStart === 1 ? mins % 10 : e.target.selectionStart === 2 ? parseInt(mins / 10) : null
                if (val !== null) {
                    updateState('mins', val);
                }
            }
        }

        let key = +e.key;

        if (!isNaN(+key) && hrs > 0 && hrs <= 12) {
            if (inputType === 'hrs') {
                let val = 0;
                let newVal = hrs * 10 + key;
                if (newVal <= 12 && newVal > 0) {
                    val = newVal;
                } else {
                    val = key || hrs;
                }
                updateState('hrs', val);
            } else {
                let val = 0;
                let newVal = mins * 10 + key;
                if (newVal < 60 && newVal >= 0) {
                    val = newVal;
                } else {
                    val = key;
                }
                updateState('mins', val);
            }
        }

        if (e.key === "ArrowUp") {
            if (inputType === 'hrs' && +e.target.value + 1 <= 12) {
                updateState('hrs', +e.target.value + 1);
            } else if (inputType === 'mins' && +e.target.value + 1 <= 59) {
                if (+e.target.value < 59) {
                    updateState('mins', +e.target.value + 1);
                }
            }
        }

        if (e.key === "ArrowDown") {
            if (inputType === 'hrs' && hrs > 0) {
                if (+e.target.value === 1 && +hrs !== 1) {
                    setHrs(1);
                } else if (hrs > 0 && hrs - 1 > 0) {
                    updateState('hrs', +e.target.value - 1);
                }
            } else if (inputType === 'mins') {
                if (+e.target.value >= 0 && +e.target.value - 1 >= 0) {
                    updateState('mins', +e.target.value - 1);
                }
            }
        }

        if (inputType === 'hrs' && e.key === 'Enter') {
            if (e.target.value > 0 && e.target.value <= 12) {
                setHrs(+e.target.value)
                onChange({
                    value:
                        `${+e.target.value < 10 ? '0' + +e.target.value : +e.target.value}:${+mins < 10 ? '0' + +mins : +mins}`,
                })
            }
            setShowNumberDropDown(false)
        }

        if (inputType === 'mins' && e.key === 'Enter') {
            // const selectedElement = document.getElementById(`${id}_m${+e.target.value}`);
            // selectedElement.scrollIntoView({ block: 'center' });

            if (e.target.value >= 0 && e.target.value <= 59) {
                setMins(+e.target.value);
                onChange({
                    value:
                        `${+hrs < 10 ? '0' + +hrs : +hrs}:${+e.target.value < 10 ? '0' + +e.target.value : +e.target.value}`,
                })
                setShowNumberDropDown(false)
            }
        }
    };

    const handleAmPMClick = (meridian) => {
        if (inputFocusedRef.current !== 'am-pm') {
            setInputFocused('am-pm');
            document.getElementById(`${id}am-pm-input`).focus();
        }
        convertTime(`${hrs}:${mins} ${meridian}`)
    }

    const handleAmPMKeydown = (e, meridian) => {
        if (e.key === "Tab") {
            setShowNumberDropDown(false);
        }
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            handleAmPMClick(meridian === 'AM' ? 'PM' : 'AM');
        }
        if (e.key === "a") {
            handleAmPMClick('AM');
        }
        if (e.key === "p") {
            handleAmPMClick('PM');
        }
    }

    const handleTimeClick = (e, inputType) => {
        e.stopPropagation();
        if (readOnly) return;
        setInputFocused(inputType);
        inputType && setShowNumberDropDown(true)
    }

    const handleOutsideClick = () => {
        setInputFocused(null);
        setShowNumberDropDown(false);
    }

    const getFormattedValue = () => {
        return value ? <span>{formatTime(value)}</span> : <span className='time-picker-placeholder'>{placeholder}</span>;
    }

    return (
        // readOnly ? <span>{formatTime(value)}</span> :
        (<div className={`new-timepicker-wrapper ${value && !readOnly ? ' focused' : ''}`} onClick={() => {
            if (!showNumberDropDown) {
                setShowNumberDropDown(true);
                setInputFocused('hrs');
                document.getElementById(`${id}hrs-input`)?.focus();
            }
        }}>
            {value && !readOnly ? (
                <>
                    <div className='time-block'>
                        <input // Intentionally removing the type attribute to handle backspace
                            id={id + 'hrs-input'}
                            className={'hrs-input' + (inputFocused === 'hrs' ? ' focused' : '')}
                            min='1'
                            max='12'
                            value={String(hrs).padStart(2, 0)}
                            onChange={(e) => { }}
                            onFocus={(e) => handleTimeClick(e, 'hrs')}
                            onBlur={(e) => handleTimeClick(e, null)}
                            onKeyDown={(e) => onKeyDown(e, 'hrs')}
                            onClick={(e) => handleTimeClick(e, 'hrs')}
                            disabled={disabled}
                        />
                        <span className='separator'>:</span>
                        <input // Intentionally removing the type attribute to handle backspace
                            id={id + 'mins-input'}
                            className={'mins-input' + (inputFocused === 'mins' ? ' focused' : '')}
                            min='0'
                            max='59'
                            value={String(mins).padStart(2, 0)}
                            onChange={(e) => { }}
                            onFocus={(e) => handleTimeClick(e, 'mins')}
                            onBlur={(e) => handleTimeClick(e, null)}
                            onKeyDown={(e) => onKeyDown(e, 'mins')}
                            onClick={(e) => handleTimeClick(e, 'mins')}
                            disabled={disabled}
                        />
                        {showNumberDropDown && (
                            <TimePickerPanel
                                getTimeList={getTimeList}
                                getAMPMList={getAMPMList}
                                handleOutsideClick={handleOutsideClick}
                            />
                        )}
                    </div>
                    <span className="am-pm-wrapper">
                        <input // Intentionally removing the type attribute to handle backspace
                            id={id + 'am-pm-input'}
                            className={'am-pm-input' + (inputFocused === 'am-pm' ? ' focused' : '')}
                            value={isAM ? 'AM' : 'PM'}
                            onChange={(e) => { }}
                            onFocus={(e) => handleTimeClick(e, 'am-pm')}
                            onBlur={(e) => handleTimeClick(e, null)}
                            onKeyDown={(e) => handleAmPMKeydown(e, isAM ? 'AM' : 'PM')}
                            onClick={(e) => handleTimeClick(e, 'am-pm')}
                            disabled={disabled}
                        />
                    </span>
                </>
            ) : (
                getFormattedValue()
            )}
        </div>)
    )
}

const TimePickerPanel = ({ handleOutsideClick, getTimeList, getAMPMList }) => {
    // useEffect(() => {
    //     const picker = document.querySelector('.number-list-wrapper');
    //     if (picker) {
    //         picker.classList.add('show-number-list-wrapper');
    //     }
    // }, []);

    return <ClickOutsideListner onOutsideClick={handleOutsideClick}>
        <div className='number-list-wrapper'>
            <div className='number-list-header'>
                <span className='header'>HH</span>
                <span className='header'>MM</span>
            </div>
            <div className='number-list-container'>
                <div className='hrs-list'>{getTimeList('hrs')}</div>
                <div className='mins-list'>{getTimeList('mins')}</div>
                <div className='am-pm-list'>{getAMPMList()}</div>
            </div>
        </div>
    </ClickOutsideListner>
}

export default TimePicker