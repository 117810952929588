// Constants
export const daysInWeek = 7;
export const daysInYear = 365.2425;
export const maxTime = Math.pow(10, 8) * 24 * 60 * 60 * 1000;
export const millisecondsInWeek = 604800000;
export const millisecondsInDay = 86400000;
export const millisecondsInMinute = 60000;
export const millisecondsInHour = 3600000;
export const millisecondsInSecond = 1000;
export const minTime = -maxTime;
export const minutesInYear = 525600;
export const minutesInMonth = 43200;
export const minutesInDay = 1440;
export const minutesInHour = 60;
export const monthsInQuarter = 3;
export const monthsInYear = 12;
export const quartersInYear = 4;
export const secondsInHour = 3600;
export const secondsInMinute = 60;
export const secondsInDay = secondsInHour * 24;
export const secondsInWeek = secondsInDay * 7;
export const secondsInYear = secondsInDay * daysInYear;
export const secondsInMonth = secondsInYear / 12;
export const secondsInQuarter = secondsInMonth * 3;

/**
 * @typedef {Object} Interval
 * @property {Date | number} start
 * @property {Date | number} end
 */

/**
 * @typedef {Object} StepOptions
 * @property {number} step
 */

/**
 * @typedef {'floor' | 'ceil' | 'round' | 'trunc'} RoundingOptions
 */

/**
 * @param {RoundingOptions | undefined} method
 * @returns {(x: number) => number}
 */
function getRoundingMethod(method) {
  return method ? Math[method] : Math.trunc;
}

export function isDate(date) {
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
}

export function isEqual(date, dateToCompare) {
  return new Date(date).getTime() === new Date(dateToCompare).getTime();
}

export function isToday(dirtyDate) {
  const date = new Date(dirtyDate);
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function isPast(date, now = Date.now()) {
  return new Date(date).getTime() < now;
}

export function isBefore(date, dateToCompare) {
  return new Date(date) < new Date(dateToCompare);
}

export function isAfter(date, dateToCompare) {
  return new Date(date) > new Date(dateToCompare);
}

export function isValid(date) {
  return isDate(date);
}

export function addHours(date, hours) {
  const _date = new Date(date);
  _date.setHours(_date.getHours() + hours);
  return _date;
}

export function subHours(date, hours) {
  const _date = new Date(date);
  _date.setHours(_date.getHours() - hours);
  return _date;
}

export function addDays(date, days) {
  const _date = new Date(date);
  _date.setDate(_date.getDate() + days);
  return _date;
}

export function subDays(date, days) {
  const _date = new Date(date);
  _date.setDate(_date.getDate() - days);
  return _date;
}

export function addMonths(date, months) {
  const _date = new Date(date);

  const originalDayOfMonth = _date.getDate(); // Store the original day of the month
  const isLastDayOfMonth = originalDayOfMonth === getDaysInMonth(_date);

  _date.setMonth(_date.getMonth() + months); // Add the months

  // Check if the day of the month needs to be adjusted
  const daysInNewMonth = getDaysInMonth(_date);
  if (isLastDayOfMonth && _date.getDate() !== daysInNewMonth) {
    if (originalDayOfMonth < daysInNewMonth) {
      _date.setDate(daysInNewMonth);
    } else {
      _date.setDate(0);
    }
  }

  return _date;
}

export function subMonths(date, months) {
  const _date = new Date(date);

  const originalDayOfMonth = _date.getDate(); // Store the original day of the month
  const isLastDayOfMonth = originalDayOfMonth === getDaysInMonth(_date);

  _date.setMonth(_date.getMonth() - months); // Subtract the months

  // Check if the day of the month needs to be adjusted
  const daysInNewMonth = getDaysInMonth(_date);
  if (isLastDayOfMonth && _date.getDate() !== daysInNewMonth) {
    if (originalDayOfMonth < daysInNewMonth) {
      _date.setDate(daysInNewMonth);
    } else {
      _date.setDate(0);
    }
  }

  return _date;
}

export function addYears(date, years) {
  const _date = new Date(date);
  _date.setFullYear(_date.getFullYear() + years);
  return _date;
}

export function subYears(date, years) {
  const _date = new Date(date);
  _date.setFullYear(_date.getFullYear() - years);
  return _date;
}

export function addMinutes(dirtyDate, minutes) {
  const date = new Date(dirtyDate);
  date.setMinutes(date.getMinutes() + minutes);
  return date;
}

export function subMinutes(dirtyDate, minutes) {
  const date = new Date(dirtyDate);
  date.setMinutes(date.getMinutes() - minutes);
  return date;
}

export function differenceInDays(laterDate, earlierDate) {
  const _laterDate = new Date(laterDate);
  const _earlierDate = new Date(earlierDate);

  const differenceInMilliseconds =
    _laterDate.getTime() - _earlierDate.getTime();
  const differenceInDays = differenceInMilliseconds / millisecondsInDay;
  return Math.round(differenceInDays);
}

export function differenceInMilliseconds(laterDate, earlierDate) {
  return new Date(laterDate).getTime() - new Date(earlierDate).getTime();
}

/**
 * 
 * @param {Date} laterDate 
 * @param {Date} earlierDate 
 * @param {{roundingMethod: RoundingOptions}} options 
 * @returns {number}
 */
export function differenceInMinutes(laterDate, earlierDate, options = { roundingMethod: 'floor' }) {
  const diff = differenceInMilliseconds(laterDate, earlierDate) / millisecondsInMinute
  if (options.roundingMethod) {
    return Math[options.roundingMethod](diff)
  } else {
    return Math.trunc(diff);
  }
}

/**
 * @param {Interval} intervalLeft
 * @param {Interval} intervalRight
 * @param {boolean} validateParams
 * @returns {boolean}
 */
export function areIntervalsOverlapping(intervalLeft, intervalRight, validateParams = true) {
  const leftStartTime = new Date(intervalLeft.start).getTime();
  const leftEndTime = new Date(intervalLeft.end).getTime();
  const rightStartTime = new Date(intervalRight.start).getTime();
  const rightEndTime = new Date(intervalRight.end).getTime();

  // Throw an exception if start date is after end date or if any date is `Invalid Date`
  if (validateParams && (!(leftStartTime <= leftEndTime && rightStartTime <= rightEndTime))) {
    throw new RangeError("Invalid interval");
  }

  return leftStartTime <= rightEndTime && rightStartTime <= leftEndTime;
}

/**
 * 
 * @param {Date | number} date 
 * @param {Interval} interval 
 * @returns {boolean}
 */
export function isWithinInterval(
  date,
  interval
) {
  const time = new Date(date)
  const startTime = new Date(interval.start)
  const endTime = new Date(interval.end)

  // Throw an exception if start date is after end date or if any date is `Invalid Date`
  if (!(startTime <= endTime)) {
    throw new RangeError('Invalid interval')
  }

  return time >= startTime && time <= endTime
}

/**
 * Returns the human readable relative time string 
 * @param {Date} from 
 * @param {Date | number} now 
 * @returns {string}
 */
export function formatTimeAgo(from, now = Date.now()) {
  const units = [
    { max: 60000, value: 1000, name: "second" },
    { max: 2760000, value: 60000, name: "minute" },
    { max: 72000000, value: 3600000, name: "hour" },
    { max: 518400000, value: 86400000, name: "day" },
    { max: 2419200000, value: 604800000, name: "week" },
    { max: 28512000000, value: 2592000000, name: "month" },
    { max: Infinity, value: 31536000000, name: "year" },
  ];

  const messages = {
    justNow: "just now",
    past: (n) => (n.match(/\d/) ? `${n} ago` : n),
    future: (n) => (n.match(/\d/) ? `in ${n}` : n),
    month: (n, past) =>
      n === 1
        ? past
          ? "last month"
          : "next month"
        : `${n} month${n > 1 ? "s" : ""}`,
    year: (n, past) =>
      n === 1
        ? past
          ? "last year"
          : "next year"
        : `${n} year${n > 1 ? "s" : ""}`,
    day: (n, past) =>
      n === 1
        ? past
          ? "yesterday"
          : "tomorrow"
        : `${n} day${n > 1 ? "s" : ""}`,
    week: (n, past) =>
      n === 1
        ? past
          ? "last week"
          : "next week"
        : `${n} week${n > 1 ? "s" : ""}`,
    hour: (n) => `${n} hour${n > 1 ? "s" : ""}`,
    minute: (n) => `${n} min${n > 1 ? "s" : ""}`,
    second: (n) => `${n} second${n > 1 ? "s" : ""}`,
    invalid: "",
  };

  const diff = +now - +from;
  const absDiff = Math.abs(diff);

  function getValue(diff, unit) {
    return Math.round(Math.abs(diff) / unit.value);
  }

  function format(diff, unit) {
    const val = getValue(diff, unit);
    const past = diff > 0;

    const str = applyFormat(unit.name, val, past);
    return applyFormat(past ? "past" : "future", str, past);
  }

  function applyFormat(name, val, isPast) {
    const formatter = messages[name];
    if (typeof formatter === "function") {
      return formatter(val, isPast);
    }
    return formatter.replace("{0}", val.toString());
  }

  // less than a minute
  if (absDiff < 60000) {
    return messages.justNow;
  }

  for (const [idx, unit] of units.entries()) {
    const val = getValue(diff, unit);
    if (val <= 0 && units[idx - 1]) {
      return format(diff, units[idx - 1]);
    }
    if (absDiff < unit.max) {
      return format(diff, unit);
    }
  }

  return messages.invalid;
}

export function formatTimeInSeconds(seconds) {
  const isNegative = seconds < 0;
  const absoluteSeconds = Math.abs(seconds);

  const hours = String(Math.floor(absoluteSeconds / 3600)).padStart(2, "0");
  const minutes = String(Math.floor((absoluteSeconds % 3600) / 60)).padStart(2, "0");
  const formattedSeconds = String(Math.floor(absoluteSeconds % 60)).padStart(2, "0");

  const formattedTime = `${hours}:${minutes}:${formattedSeconds}`;

  return isNegative ? `-${formattedTime}` : formattedTime;
}

/**
 * @param {Date | number} date
 * @param {string} formatStr
 * @param {{locales?: Intl.LocalesArgument}} options
 * @returns {string}
 */
export function format(
  date,
  formatStr = "YYYY-MM-DD",
  options = { locales: "en-US" }
) {
  const _date = new Date(date);

  const years = _date.getFullYear();
  const month = _date.getMonth();
  const days = _date.getDate();
  const hours = _date.getHours();
  const minutes = _date.getMinutes();
  const seconds = _date.getSeconds();
  const milliseconds = _date.getMilliseconds();
  const day = _date.getDay();
  // const meridiem = options.customMeridiem ?? defaultMeridiem
  const matches = {
    // Two-digit year (e.g. 23)
    YY: () => String(years).slice(-2),
    // Four-digit year (e.g. 2023)
    YYYY: () => years,
    // The month, beginning at 1 (e.g. 1-12)
    M: () => month + 1,
    // The month, 2-digits (e.g. 01-12)
    MM: () => `${month + 1}`.padStart(2, "0"),
    // The abbreviated month name (e.g. Jan-Dec)
    MMM: () => _date.toLocaleDateString(options.locales, { month: "short" }),
    // The full month name (e.g January-December)
    MMMM: () => _date.toLocaleDateString(options.locales, { month: "long" }),
    // The day of the month (e.g. 1-31)
    D: () => String(days),
    // The day of the month, 2-digits (e.g. 01-31)
    DD: () => `${days}`.padStart(2, "0"),
    // The hour (e.g. 0-23)
    H: () => String(hours),
    // The hour, 2-digits (e.g. 00-23)
    HH: () => `${hours}`.padStart(2, "0"),
    // The 12-hour clock (e.g. 1-12)
    h: () => `${hours % 12 || 12}`.padStart(1, "0"),
    // The 12-hour clock, 2-digits (e.g. 01-12)
    hh: () => `${hours % 12 || 12}`.padStart(2, "0"),
    // The minute (e.g. 0-59)
    m: () => String(minutes),
    // The minute, 2-digits (e.g. 00-59)
    mm: () => `${minutes}`.padStart(2, "0"),
    // The second (e.g. 0-59)
    s: () => String(seconds),
    // The second, 2-digits (e.g. 00-59)
    ss: () => `${seconds}`.padStart(2, "0"),
    // The millisecond (e.g. 000-999)
    SSS: () => `${milliseconds}`.padStart(3, "0"),
    // The day of the week, with Sunday as 0 (e.g. 0-6)
    d: () => day,
    // The min name of the day of the week (e.g. S-S)
    dd: () => _date.toLocaleDateString(options.locales, { weekday: "narrow" }),
    // The short name of the day of the week (e.g. Sun-Sat)
    ddd: () => _date.toLocaleDateString(options.locales, { weekday: "short" }),
    // The name of the day of the week (e.g. Sunday-Saturday)
    dddd: () => _date.toLocaleDateString(options.locales, { weekday: "long" }),
    // The meridiem (e.g AM - PM)
    A: () => meridiem(hours, minutes),
    // The meridiem, periods (e.g. A.M. - P.M.)
    AA: () => meridiem(hours, minutes, false, true),
    // The meridiem, lowercase (e.g. am - pm)
    a: () => meridiem(hours, minutes, true),
    // The meridiem, lowercase and periods (e.g. a.m. - p.m.)
    aa: () => meridiem(hours, minutes, true, true),
  };

  const FORMAT_DATE_REGEX =
    /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a{1,2}|A{1,2}|m{1,2}|s{1,2}|Z{1,2}|SSS/g;

  return formatStr.replace(
    FORMAT_DATE_REGEX,
    (match, $1) => $1 || matches[match]?.() || match
  );
}

/**
 * @param {number} hours
 * @param {number} minutes
 * @param {boolean?} isLowercase
 * @param {boolean?} hasPeriod
 * @returns {string}
 */
function meridiem(hours, minutes, isLowercase, hasPeriod) {
  let m = hours < 12 ? "AM" : "PM";
  if (hasPeriod) m = m.split("").reduce((acc, curr) => (acc += `${curr}.`), "");
  return isLowercase ? m.toLowerCase() : m;
}

export function setHours(date, hours) {
  const _date = new Date(date);
  _date.setHours(hours);
  return _date;
}

export function setMinutes(date, minutes) {
  const _date = new Date(date);
  _date.setMinutes(minutes);
  return _date;
}

export function setSeconds(date, seconds) {
  const _date = new Date(date);
  _date.setSeconds(seconds);
  return _date;
}

/**
 * @param {Interval} interval
 * @param {StepOptions} options
 * @returns {Array<Date>}
 */
export function eachDayOfInterval(interval, options) {
  const startDate = new Date(interval.start);
  const endDate = new Date(interval.end);

  const endTime = endDate.getTime();

  // Throw an exception if start date is after end date or if any date is `Invalid Date`
  if (!(startDate.getTime() <= endTime)) {
    throw new RangeError("Invalid interval");
  }

  const dates = [];

  const currentDate = startDate;
  currentDate.setHours(0, 0, 0, 0);

  const step = options?.step ?? 1;
  if (step < 1 || isNaN(step)) {
    throw new RangeError("`options.step` must be a number greater than 1");
  }

  while (currentDate.getTime() <= endTime) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + step);
    currentDate.setHours(0, 0, 0, 0);
  }

  return dates;
}

export function startOfDay(date) {
  const _date = new Date(date);
  _date.setHours(0, 0, 0, 0);
  return _date;
}

export function startOfMonth(dirtyDate) {
  const date = new Date(dirtyDate)
  date.setDate(1)
  date.setHours(0, 0, 0, 0)
  return date
}

export function endOfMonth(dirtyDate) {
  const date = new Date(dirtyDate);
  const month = date.getMonth();
  date.setFullYear(date.getFullYear(), month + 1, 0);
  date.setHours(23, 59, 59, 999);
  return date;
}

export function setMonth(dirtyDate, month) {
  const date = new Date(dirtyDate);
  date.setMonth(month);
  return date;
}


export function monthsToQuarters(months) {
  const quarters = (months / monthsInQuarter) + 1;
  return Math.floor(quarters);
}

export function addQuarters(dirtyDate, amount) {
  const months = amount * monthsInQuarter;
  return addMonths(dirtyDate, months);
}

export function subQuarters(dirtyDate, amount) {
  const months = amount * monthsInQuarter;
  return subMonths(dirtyDate, months);
}

export function getDaysInMonth(dirtyDate) {
  const date = new Date(dirtyDate);
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  return new Date(year, monthIndex + 1, 0).getDate();
}

export function getFirstAndLastDateOfMonth(date) {
  // Extract the year and month from the given date
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Month is 0-based, so add 1 to get the correct month.

  // Calculate the first day of the specified month and year
  const firstDateOfMonth = new Date(year, month - 1, 1);

  // Calculate the last day of the next month and then subtract one day to get the last day of the specified month and year
  const lastDateOfMonth = new Date(year, month, 0);

  return {
    firstDate: firstDateOfMonth,
    lastDate: lastDateOfMonth,
  };
}

export function getWeek(dirtyDate) {
  const day = format(dirtyDate, "dddd")
  const weekDays = {
    'Sunday': 0,
    'Monday': 1,
    'Tuesday': 2,
    'Wednesday': 3,
    'Thursday': 4,
    'Friday': 5,
    'Saturday': 6,
  }

  return weekDays[day];
}

/**
 * 
 * @param {Date} laterDate 
 * @param {Date} earlierDate 
 * @param {{roundingMethod: RoundingOptions}} options 
 * @returns {number}
 */
export function differenceInSeconds(laterDate, earlierDate, options = { roundingMethod: 'floor' }) {
  const diff = differenceInMilliseconds(laterDate, earlierDate) / 1000
  return Math[options.roundingMethod](diff);
}

export function endOfDay(date) {
  const _date = new Date(date);
  _date.setHours(23, 59, 59, 999);
  return _date;
}

function padNumString(num) {
  return num.toString().padStart(2, "0");
}
export function formatSecondsHhmm(seconds) {
  const hours = Math.trunc(seconds / secondsInHour);
  const minutes = Math.trunc((seconds % secondsInHour) / secondsInMinute);
  const secs = Math.trunc(seconds % 60);

  if (hours > 0) {
    if (minutes > 0) {
      return `${padNumString(hours)}h ${padNumString(minutes)}m`;
    }

    return `${padNumString(hours)}h`;
  }

  if (minutes > 0) {
    return `${padNumString(minutes)}m`;
  }

  return `${padNumString(secs)}s`;
}