import { forwardRef, useCallback, useRef, useState, isValidElement, cloneElement } from "react";
import { useToggle } from "utils/hooks";
import "./styles.scss";

// export default function ToolTip({ top, left, isVisible, text }) {
//   return (
//     <AnimatePresence>
//       {isVisible && (
//         <motion.div
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           exit={{ opacity: 0 }}
//           className="toast"
//           style={{ top: top, left: left }}
//         >
//           {text}
//         </motion.div>
//       )}
//     </AnimatePresence>
//   );
// }

const TootlTip = ({ children, content, ...rest }) => {
  const [isVisible, toggleIsVisible] = useToggle();
  return (
    <div
      onMouseEnter={toggleIsVisible}
      onMouseLeave={toggleIsVisible}
      className="profile-name"
    >
      {children}
      {isVisible && <div className="tooltip">{content}</div>}
    </div>
  );
};

/**
 * Tooltip that follows the cursor when the target is hovered
 * @param {object} props
 * @param {boolean=} props.show
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode} props.content
 * @param {string=} props.className
 * @param {React.CSSProperties=} props.style
 * @param {number=} props.delay
 * @param {boolean=} props.asChild
 */
export const MouseTooltip = ({
  show = true,
  children,
  content,
  className = "",
  style = {},
  delay = 0,
  asChild = false,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef(null);
  const timeoutRef = useRef(null);

  const getMousePosition = useCallback((e) => {
    const { clientX, clientY } = e;
    const { width, height } = tooltipRef.current.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;

    let x =
      clientX + width > innerWidth
        ? Math.round(innerWidth - width - 10)
        : clientX;
    let y = clientY + height + 20 > innerHeight ? clientY - height - 10 : clientY + 20;

    return { x, y };
  }, [])

  const isMouseMovingRef = useRef(false);
  const handleMouseEnter = (e) => {
    timeoutRef.current = setTimeout(() => {
      setIsTooltipVisible(true);
      if (!isMouseMovingRef.current) setPosition(getMousePosition(e));
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    setIsTooltipVisible(false);
    isMouseMovingRef.current = false;
  };

  const handleMouseMove = (e) => {
    isMouseMovingRef.current = true;
    setPosition(getMousePosition(e));
  };

  const showTooltip = show && isTooltipVisible;

  if (asChild && isValidElement(children)) {
    return (
      <>
        {cloneElement(children, {
          onMouseEnter: handleMouseEnter,
          onMouseMove: handleMouseMove,
          onMouseLeave: handleMouseLeave,
          ...children.props
        })}
        <MouseTooltipContent
          ref={tooltipRef}
          className={className}
          style={style}
          content={content}
          showTooltip={showTooltip}
          position={position}
        />
      </>
    )
  }

  return (
    <>
      <span
        className="cursor-tooltip-target"
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </span>
      <MouseTooltipContent
        ref={tooltipRef}
        className={className}
        style={style}
        content={content}
        showTooltip={showTooltip}
        position={position}
      />
    </>
  );
};

const MouseTooltipContent = forwardRef(({ content, showTooltip, position, className, style }, ref) => {
  return (
    <div
      ref={ref}
      className={`cursor-tooltip ${showTooltip ? 'fade-in' : ''} ${className}`}
      style={{
        visibility: showTooltip ? "visible" : "hidden",
        pointerEvents: showTooltip ? "all" : "none",
        position: "fixed",
        top: position.y + "px",
        left: position.x + "px",
        ...style,
      }}
    >
      {content}
    </div>
  )
})

export default TootlTip;
