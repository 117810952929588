import React from 'react'
import { MouseTooltip } from 'components/ToolTip';

import { format } from 'utils/date';
import { getFormattedTimeAndDiff, isNotNone } from '../../utils';
import { CLOCK_IN_COLOR_MAP } from 'views/Attendance/constants';

// import { STANDARD_WORK_TIMINGS, BUFFER_TIMER } from '../../constants';

const clockInTooltipMap = {
  early: 'Early',
  on_time: 'On Time',
  late: 'Late',
};

const ClockTimeDetails = ({ data, date }) => {
  const isOnLeave = data.attendance.includes("leave");
  const isWeekOff = data.day_status === "weekly_off";
  const isOnSpecialShift = isNotNone(data.special_shift_time);
  const isHoliday = isNotNone(data.holiday_name) && !isOnSpecialShift;
  const isClockedIn = isNotNone(data.clock_in_time);
  const isClockedOut = isNotNone(data.clock_out_time);

  let clock_in_time = !isClockedIn
    ? ""
    : getFormattedTimeAndDiff(data.clock_in_time, date);
  let clock_out_time = !isClockedOut
    ? ""
    : getFormattedTimeAndDiff(data.clock_out_time, date);

  const isClockOutOnPreviousDate = clock_out_time.diff < 0;
  const isClockedOutOnNextDate = clock_out_time.diff > 0;

  const clockInColor = isHoliday ? '' : CLOCK_IN_COLOR_MAP[data.attendance] ?? '';
  const clockInTooltip = isHoliday ? '' : clockInTooltipMap[data.attendance] ?? '';

  // if (isClockedIn) {
  //   if (isOnLeave) {
  //     if (data.total_time > BUFFER_TIMER) {
  //       clockOutColor = 'green';
  //     }
  //   } else if (isOnSpecialShift) {
  //     const SPECIAL_SHIFT_DURATION = calculateDurationInSeconds(data.special_shift_time);

  //     if (data.total_time > (SPECIAL_SHIFT_DURATION + BUFFER_TIMER)) {
  //       clockOutColor = 'green';
  //     }
  //     if (isClockedOut && data.total_time < (SPECIAL_SHIFT_DURATION - BUFFER_TIMER)) {
  //       clockOutColor = 'red';
  //     }
  //   } else {
  //     if (data.total_time > (STANDARD_WORK_TIMINGS + BUFFER_TIMER)) {
  //       clockOutColor = 'green';
  //     }
  //     if (isClockedOut && data.total_time < (STANDARD_WORK_TIMINGS - BUFFER_TIMER)) {
  //       clockOutColor = 'red';
  //     }
  //   }
  // }

  return <div className="clock-info-wrapper">
    <div className={`bg-gray clock-info ${clockInColor}`}>
      <MouseTooltip
        show={clockInTooltip !== '' && !isOnLeave && (!isWeekOff || isOnSpecialShift)}
        asChild
        content={clockInTooltip}
        style={{ lineHeight: "14px" }}
      >
        <span>{clock_in_time.time}</span>
      </MouseTooltip>
      {clock_in_time.diff < 0 && (
        <span className="icon-info-bubble-wrapper">
          <MouseTooltip
            asChild
            content={
              <span>
                Clock-In occurred on the preceding date :
                <br />
                {format(data.clock_in_time, "ddd, MMM DD, YYYY")}
              </span>
            }
            style={{ lineHeight: "17px" }}
          >
            <span className="icon icon-info-bubble"></span>
          </MouseTooltip>
        </span>
      )}
    </div>
    <div className={`bg-gray clock-info`}>
      <span>{clock_out_time.time}</span>
      {/* {(clock_out_time.diff ? <span title={data.clock_out_time }>({clock_out_time.diff})</span> : '')} */}
      {(isClockedOutOnNextDate || isClockOutOnPreviousDate) && (
        <span className="icon-info-bubble-wrapper">
          <MouseTooltip
            asChild
            content={
              <span>
                {isClockedOutOnNextDate ? 'Clock-Out rolled over to next date :' : 'Clock-Out occurred on the preceding date :'}
                <br />
                {format(data.clock_out_time, "ddd, MMM DD, YYYY")}
              </span>
            }
            style={{ lineHeight: "17px" }}
          >
            <span className="icon icon-info-bubble"></span>
          </MouseTooltip>
        </span>
      )}
    </div>
  </div>
}

export default ClockTimeDetails