import React, { useEffect, useRef, useState } from "react";
import SuperPager from "super-pager";

// components
import * as Constants from "components/Constants";
import ClickOutsideListner from "components/ClickOutsideListner";

// services
import { alertService } from "services/alertService";
import APIService from "services/apiService";

// utils
import { debounce } from "utils/Common";

// styles
import "./SkillSelect.scss";

export default function SkillSelect({ value, onChange }) {
    const [inputArray, setInputArray] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [options, setOptions] = useState([]);
    const [pagination, setPagination] = useState({
        num_pages: 0,
        page_number: 0,
        page_size: 0,
        total_results: 0,
    });

    const inputElRef = useRef();
    let controller = new AbortController();

    // state that manages the active suggestion index
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);

    const skillContainerDOMRef = useRef()
    const suggestionListDOMRef = useRef()

    // This useEffect is responsible to fix the auto complete suggestion list is overflowing the skill container
    // Like positioning element dynamically if they are overflowing its parent element
    useEffect(() => {
        if (showOptions) {
            const containerEl = skillContainerDOMRef.current
            const suggestionListEl = suggestionListDOMRef.current

            if (suggestionListEl) {
                const containerElRect = containerEl.getBoundingClientRect()
                const suggestionListElRect = suggestionListEl.getBoundingClientRect()

                if (suggestionListElRect.right > containerElRect.right) {
                    // The below style will automatically removed when the suggestionListEl is unmounted
                    suggestionListEl.style.right = '0px'
                    suggestionListEl.style.left = ''
                } else {
                    suggestionListEl.style.right = ''
                    suggestionListEl.style.left = '0px'
                }
            }
        }
    }, [showOptions, value])

    useEffect(() => {
        setInputArray(value);
    }, [value]);

    async function fetchSkills({
        searchString,
        page_size = 100,
        page_number = 1,
    }) {
        const url = new URL(Constants.API_BASE_URL + "/skill");
        url.searchParams.append("search_string", searchString);
        url.searchParams.set("page_size", page_size);
        url.searchParams.set("page_number", page_number);

        const response = await APIService.apiRequest(
            url.toString(),
            null,
            false,
            "GET",
            controller
        );

        return response;
    }

    // Debounce is used to avoid multiple API calls if user abuse the search input field by clicking enter multiple times
    const createNewSkill = debounce((skill) => {
        setShowOptions(false);
        alertService.info("Adding new skill");
        APIService.apiRequest(
            Constants.API_BASE_URL + `/skill`,
            { name: skill },
            false,
            "POST"
        )
            .then((response) => {
                if (response.status === 1) {
                    alertService.success("New skill has been added");
                    const newOption = { name: skill, id: response.id };
                    const newInputArray = [...inputArray, newOption];
                    onChange(newInputArray);
                    setInputArray(newInputArray);
                    inputElRef.current.value = "";
                }
            })
            .catch((error) => {
                console.log("New skill errr", error);
                let startIdx = error.indexOf(":") + 2;
                let endIdx = error.indexOf("[");
                let errorMesg = error.slice(startIdx, endIdx);
                console.log("Error mesg ", errorMesg);
                if (error.includes("1062")) {
                    alertService.error("This skill already added");
                    return;
                }
                alertService.error(errorMesg);
            });
    }, 600);

    function removeItem(array, item) {
        const index = array.indexOf(item);
        if (index > -1) {
            array.splice(index, 1);
        }
        return [...array];
    }

    function handleRemoveTag(index) {
        const newInputArray = removeItem(inputArray, inputArray[index]);
        onChange(newInputArray);
        setInputArray(newInputArray);
    }

    const handleInputChange = debounce(async (e) => {
        const searchString = e.target.value.trim()
        setShowOptions(false);

        if (searchString === '') {
            return
        }

        try {
            setLoadingOptions(true);
            const response = await fetchSkills({ searchString });
            if (response.status === 1) {
                setActiveSuggestionIndex(0);
                setPagination(() => response.output.meta);
                setOptions(() => response.output.data);
                setShowOptions(true);
                setLoadingOptions(false);
                scrollToActiveSuggestion(0);
            }
        } catch (error) {
            setOptions([]);
            setShowOptions(true);
            setLoadingOptions(false);
            alertService.error("Something went wrong while fetching skills");
            console.log(error);
        }
    }, 600)

    async function loadMoreSkills() {
        try {
            setLoadingOptions(true);
            const response = await fetchSkills({
                searchString: inputElRef.current.value,
                page_size: pagination.page_size,
                page_number: pagination.page_number + 1,
            });
            setPagination(() => response.output.meta);
            setOptions((prevOptions) => [...prevOptions, ...response.output.data]);
        } catch (error) {
            alertService.error("Something went wrong while fetching skills");
            console.log(error);
        } finally {
            setLoadingOptions(false);
        }
    }

    function handleSkillSelect(option) {
        const isSkillAlreadyAdded = inputArray.some(
            (skill) => skill.id === option.id
        );

        if (isSkillAlreadyAdded) {
            alertService.error("Skill already added");
            return;
        }

        const newInputArray = [...inputArray, option];
        onChange(newInputArray);
        setInputArray(newInputArray);
        inputElRef.current.focus()
    }

    function scrollToActiveSuggestion(index) {
        document
            ?.getElementById(`suggestion-${index}`)
            ?.scrollIntoView({ block: "nearest" });
    }

    function handleInputKeyDown(e) {
        if (e.key === "Enter" && e.target.value !== "") {
            if (options.length > 0) {
                handleSkillSelect(options[activeSuggestionIndex]);
            } else {
                createNewSkill(e.target.value);
            }
        }

        if (e.key === "ArrowDown") {
            if (options.length - 1 > activeSuggestionIndex) {
                let index = activeSuggestionIndex + 1;
                setActiveSuggestionIndex(index);
                scrollToActiveSuggestion(index);
            }
        }

        if (e.key === "ArrowUp") {
            let index = activeSuggestionIndex - 1;
            if (index >= 0) {
                setActiveSuggestionIndex(index);
                scrollToActiveSuggestion(index);
            }
        }

        if (e.key === "Escape") {
            setShowOptions(false);
            inputElRef.current.value = "";
        }

        if (
            e.key === "Backspace" &&
            e.target.value === "" &&
            inputArray.length > 0
        ) {
            handleRemoveTag(inputArray.length - 1);
        }
    }

    return (
        <div id="skill-select-container" ref={skillContainerDOMRef}>
            {inputArray?.map((skill, index) => {
                return (
                    <div key={index} className="skill-tag">
                        {skill.name}
                        <span
                            className="skill-tag-close-btn"
                            onClick={() => handleRemoveTag(index)}
                        ></span>
                    </div>
                );
            })}
            <div className="skill-input-container">
                {/* Both onBlur and onFocus are not required to handle click outside. Coz we are using ClickOutsideListner component which handles click outside */}
                <input
                    ref={inputElRef}
                    className="skill-input-box"
                    type="text"
                    placeholder="Enter Skill"
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                />
                {loadingOptions ? <div className="loader"></div> : null}
                {showOptions && (
                    <ClickOutsideListner onOutsideClick={() => setShowOptions(false)}>
                        <div
                            className={`skill-suggestion-list ${showOptions && options.length > 0
                                ? "show-suggestion-list"
                                : "no-suggestion"
                                }`}
                            ref={suggestionListDOMRef}
                        >
                            {options.length > 0 ? (
                                <ul>
                                    <SuperPager
                                        type="infiniteScroll"
                                        isTable={false}
                                        dataLength={options.length}
                                        loadMore={loadMoreSkills}
                                        hasMore={pagination.page_number < pagination.num_pages}
                                        wrapper={true}
                                        children={options?.map((option, index) => {
                                            const isActive = activeSuggestionIndex === index;
                                            const isSelected = inputArray.some(
                                                (skill) => skill.id === option.id
                                            );

                                            return (
                                                <li
                                                    key={index}
                                                    className={`${isActive ? "active" : ""} ${isSelected ? "selected" : ""
                                                        }`}
                                                    id={`suggestion-${index}`}
                                                    onClick={() => handleSkillSelect(option)}
                                                >
                                                    {option.name}
                                                </li>
                                            );
                                        })}
                                    />
                                </ul>
                            ) : (
                                <em>
                                    No suggestions, you can add your own skill, just press enter
                                    after typing!
                                </em>
                            )}
                        </div>
                    </ClickOutsideListner>
                )}
            </div>
        </div>
    );
}
