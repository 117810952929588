import { API_BASE_URL } from "components/Constants";

export const PAGE_SIZES = [
    { id: 1, name: '25' },
    // { id: 5, name: '10' },
    // { id: 6, name: '2' },
    // { id: 7, name: '4' },
    { id: 2, name: '50' },
    { id: 3, name: '100' },
    { id: 4, name: '200' },
];

export const RECRUITER_BASE_URL = API_BASE_URL + '/recruiter';