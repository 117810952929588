// useScrollRestoration.js
import { useLayoutEffect, useRef } from 'react';

const debounce = (func, wait) => {
    let timeout;
    const debounced = (...args) => {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
    debounced.cancel = () => {
        clearTimeout(timeout);
    };
    return debounced;
};

const scrollPositions = new Map();

export const saveScrollPosition = (key, position, isScrollEvent = false) => {
    if (position === 0 && !isScrollEvent) return;
    scrollPositions.set(key, position);
};

export const getScrollPosition = (key) => {
    return scrollPositions.get(key) || 0;
};

export const deleteScrollPosition = (key) => {
    scrollPositions.delete(key);
};

export const clearScrollPositions = (string) => {
    if (string) {
        const keys = [...scrollPositions.keys()].filter((key) => key.startsWith(string));
        keys.forEach((key) => scrollPositions.delete(key));
    } else {
        scrollPositions.clear();
    }
}

export const useScrollRestorationOptimised = (key, delay = 100) => {
    // This hook is optimised for performance
    // unfortunately, it doesn't work with KeepAlive component as it doesn't unmount the component
    const containerRef = useRef(null);

    useLayoutEffect(() => {
        const container = containerRef.current;

        // Restore scroll position
        if (container) {
            container.scrollTop = getScrollPosition(key);
        }

        return () => {
            saveScrollPosition(key, containerRef.current?.scrollTop || 0);
        };
    }, [key, delay]);

    return containerRef;
};

const useScrollRestoration = (key, delay = 100) => {
    const containerRef = useRef(null);

    useLayoutEffect(() => {
        const handleScroll = debounce(() => {
            saveScrollPosition(key, containerRef.current.scrollTop, 'user', true);
        }, delay);

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            // Restore scroll position
            container.scrollTop = getScrollPosition(key);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
            handleScroll.cancel();
        };
    }, [key, delay]);

    return containerRef;
};

export default useScrollRestoration;
