import { createPortal } from "react-dom";

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {HTMLElement | string} props.to
 * @param {boolean} props.disabled
 */
function Portal({ children, to = document.body, disabled }) {
  if (disabled) {
    return <>{children}</>;
  }

  // Use createPortal to render children into the portal container
  return createPortal(
    children,
    typeof to === "string" ? document.querySelector(to) ?? document.body : to
  );
}

export default Portal;
