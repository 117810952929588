import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGlobalContext } from 'contexts/GlobalContext';

function prettySize(bytes, separator = ' ', postFix = '') {
    if (!bytes) return 'n/a';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10), sizes.length - 1);
    const size = (bytes / (1024 ** i)).toFixed(i ? 1 : 0);
    return `${size}${separator}${sizes[i]}${postFix}`;
}

const MemoryUsage = () => {
    const [memoryUsage, setMemoryUsage] = useState(null);
    const { isAppInActive } = useGlobalContext();

    const getUsage = useCallback(async () => {
        const usage = await window.ipcRender.invoke('getMemoryUsage');
        setMemoryUsage(usage);
    }, []);

    useEffect(() => {
        if (isAppInActive) return;

        getUsage();
        const intervalID = setInterval(getUsage, 1000);

        return () => clearInterval(intervalID);
    }, [isAppInActive, getUsage]);

    const formattedMemoryUsage = useMemo(() => {
        return prettySize(memoryUsage?.rss);
    }, [memoryUsage]);

    return (
        <div className='memory-usage-wrapper'>
            <span className='control-panel-label'>Memory consumed</span>
            <span>{formattedMemoryUsage}</span>
        </div>
    )
}

export default MemoryUsage