import AuthHeader from '../utils/AuthHeader';
import * as Constants from '../components/Constants';
import { alertService } from 'services/alertService';
import { getUser, getMyTimeZoneFromLocal, getTimeZonesFromLocal, getLocalStorageVal, getUserDetailsFromToken, isElectronApp, removeUserSession } from "../utils/Common";
import EventEmitter from './eventEmitter';
import { compareVersions } from 'utils/Utils';

export const apiEventEmitter = new EventEmitter();

const errorMessages = {
    '400': 'Bad Request',
    '401': 'Unauthorized',
    '402': 'Payment Required',
    '403': 'Forbidden',
    '404': 'Not Found',
    '405': 'Method Not Allowed',
    '406': 'Not Acceptable',
    '407': 'Proxy Authentication Required',
    '408': 'Request Timeout',
    '409': 'Conflict',
    '410': 'Gone',
    '411': 'Length Required',
    '412': 'Precondition Failed',
    '413': 'Payload Too Large',
    '414': 'URI Too Long',
    '415': 'Unsupported Media Type',
    '416': 'Range Not Satisfiable',
    '417': 'Expectation Failed',
    '418': 'I am a teapot',
    '421': 'Misdirected Request',
    '422': 'Unprocessable Entity',
    '423': 'Locked',
    '424': 'Failed Dependency',
    '425': 'Too Early',
    '426': 'Upgrade Required',
    '428': 'Precondition Required',
    '429': 'Too Many Requests',
    '431': 'Request Header Fields Too Large',
    '451': 'Unavailable For Legal Reasons',
    '500': 'Internal Server Error',
    '501': 'Not Implemented',
    '502': 'Bad Gateway',
    '503': 'Service Unavailable',
    '504': 'Gateway Timeout',
    '505': 'HTTP Version Not Supported',
    '506': 'Variant Also Negotiates',
    '507': 'Insufficient Storage',
    '508': 'Loop Detected',
    '510': 'Not Extended',
    '511': 'Network Authentication Required'
};

function handleMaintenance(response) {
    const isFrontendUpdated = compareVersions(window.SITE_VERSION, response?.version_db) === 'greater';
    if (!isFrontendUpdated) {
        setTimeout(() => {
            window.location.reload();
        }, 5 * 1000);
    }
    apiEventEmitter.emit('maintenance', { status: true });
}

function getResetTokenProcessStatus() {
    let updatedResetTokenInProcess = localStorage.getItem(Constants.SITE_PREFIX + 'reset_token_inprocess');
    updatedResetTokenInProcess = updatedResetTokenInProcess ? parseInt(updatedResetTokenInProcess) : updatedResetTokenInProcess;
    return updatedResetTokenInProcess;
}

function forceLogout(msg) {
    console.log('forceLogout msg', msg);
    alertService.error(msg);
    removeUserSession();
    if (isElectronApp) {
        window.ipcRender.send('message:logoutSuccessful');
        window.ipcRender.send('message:showError', { msg: msg });
    }
    setTimeout(() => { window.location.reload() }, 1000);
    return;
}

async function refreshToken({ response = null, API_URL, data, showProgress, req_method, controller, authHeader, apiRequest, timerToken, retry = false, version } = {}) {
    // if no response we are calling this for switching organization
    const isRTForSwitchOrg = response === null;
    let resetTokenInProcess = getResetTokenProcessStatus();
    const rtController = isRTForSwitchOrg ? controller : new AbortController();
    let timeoutId = null;
    if (!isRTForSwitchOrg) {
        timeoutId = setTimeout(() => rtController.abort(), 45000); // abort after 45 seconds
    }
    // console.log('resetTokenInProcess', resetTokenInProcess);
    if (resetTokenInProcess === null || resetTokenInProcess === 0 || retry) {
        localStorage.setItem(Constants.SITE_PREFIX + 'reset_token_inprocess', 1);
        let settings = localStorage.getItem(Constants.SITE_PREFIX + 'settings');
        if (settings) settings = JSON.parse(settings);
        const token_details = getUserDetailsFromToken(authHeader)?.identity;
        let orgId = data?.organization_id || token_details?.organization_id || settings?.selectedOrganization?.id;
        if (!orgId) {
            let userDetails = getUser();
            orgId = userDetails?.organization_id;
        }
        // let userInfo = getUser();
        // if (userInfo.rt !== undefined) {
        let rt = getLocalStorageVal('rt')
        if (rt) {
            // call the api to get refreshed auth token based on refresh token and update it on localstorage
            let apiOptions = {
                method: 'PUT',
                mode: 'cors', // no-cors, *cors, same-origin
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${rt}`,
                    'App-Version': window.SITE_VERSION,
                    // 'App-Version': localStorage.getItem('app_version') || window.SITE_VERSION,
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                signal: rtController.signal
            }
            apiOptions['body'] = JSON.stringify({ 'organization_id': orgId });

            return fetch(Constants.API_BASE_URL + '/auth/rt', apiOptions).then((response) => {
                if (timeoutId) clearTimeout(timeoutId);
                if (response.status === 401 || response.status === 403) {
                    localStorage.setItem(Constants.SITE_PREFIX + 'reset_token_inprocess', 0); // reset to 0 so that it can process further (other apis)
                    forceLogout(`Error ${response.status} - Invalid Credentials`);
                    return;
                }

                if (response.status === 500 || response.status === 502 || response.status === 504) {
                    let pingApiOptions = {
                        method: 'GET',
                        mode: 'cors', // no-cors, *cors, same-origin
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                    }
                    return fetch(Constants.API_BASE_URL + '/ping/', pingApiOptions).then((response) => {
                        if (response.status === 200) {
                            forceLogout('Problem with Authorization, Please login again');
                        } else {
                            return { status: response?.status || 503, msg: 'Service is interrupted, please try after some time' }
                        }
                    }).catch(e => {
                        console.log('Ping API catch error', e.message);
                        let msg = `There was a problem, please check your internet connection, refresh your screen (CMD + R), and try again. \n\nMessage: ${e.message}. \nAPI: Ping`
                        alertService.error(msg);
                        if (isElectronApp) window.ipcRender.send('message:showError', { msg });
                    });
                }

                if (response.status === 207) {
                    // Special check - 207 indicates that a New version of app is available 
                    // so in this case, force reload the browser so that user gets the latest version of app
                    // alertService.alert(`Good News, New update is available. Please wait it might take a couple of reloads to install the update.`)
                    if (!retry) response.json().then(data => {
                        handleMaintenance(data)
                    });
                    // localStorage.setItem('app_version', window.SITE_VERSION);
                    setTimeout(() => {
                        refreshToken({ response, API_URL, data, showProgress, req_method, controller, authHeader, apiRequest, timerToken, retry: true });
                    }, 5 * 1000);
                    return { status: 207, msg: "Your request could not be processed because there is an update to TeamLink. Please try again" };
                }

                return response.json();
            }).then((parsedResponse) => {
                if (!parsedResponse) return { status: response?.status || 501, msg: response?.status === 500 ? 'Internal Server Error' : 'Invalid Credentials' };
                if (parsedResponse?.msg?.includes('Service is interrupted')) {
                    localStorage.setItem(Constants.SITE_PREFIX + 'reset_token_inprocess', 0);
                    return parsedResponse;
                }
                // Redirect to home page after succcessful login
                let user_details = getUserDetailsFromToken(parsedResponse.access_token);

                // set default home url under local storage for future redirection
                // user_info.default_home_url = '/';

                if (user_details) {
                    // Set token and user details in session
                    // let user_info = user_details.identity;
                    // localStorage.setItem(Constants.SITE_PREFIX + 'user', JSON.stringify({ ...user_info, rt: parsedResponse.hasOwnProperty('refresh_token') ? parsedResponse.refresh_token : userInfo.rt }));
                    if (parsedResponse.hasOwnProperty('refresh_token')) {
                        localStorage.setItem(Constants.SITE_PREFIX + 'rt', parsedResponse.refresh_token);
                    }
                    let { timezone_id } = user_details.identity;
                    let currentTimezone = getMyTimeZoneFromLocal();
                    localStorage.setItem(Constants.SITE_PREFIX + (timerToken ? 'timer_token' : 'token'), parsedResponse.access_token);
                    localStorage.setItem(Constants.SITE_PREFIX + 'reset_token_inprocess', 0); // reset to 0 so that it can process further (other apis)
                    if (timezone_id && currentTimezone && timezone_id !== currentTimezone?.id) {
                        let msg = 'Your time zone has been updated. Please reload the page (CMD + R) and try again'
                        let timeZones = getTimeZonesFromLocal();
                        let myTimeZone = timeZones.filter(t => t.id === timezone_id)[0];
                        localStorage.setItem(Constants.SITE_PREFIX + 'my_timezone', JSON.stringify(myTimeZone));
                        window.location.reload();
                        return { status: 207, msg };
                    }
                    return isRTForSwitchOrg ? parsedResponse : apiRequest(API_URL, data, showProgress, req_method, controller, timerToken ? `Bearer ${parsedResponse.access_token}` : authHeader);
                } else {
                    if (parsedResponse.status !== 207) {
                        console.log('RT API skipped, some issue occured', parsedResponse);
                        alertService.error('RT API skipped, some issue occured');
                    }
                    return parsedResponse;
                }

            }).catch(e => {
                // alertService.warning('User password/session expired, Please login again.', e.message);
                localStorage.setItem(Constants.SITE_PREFIX + 'reset_token_inprocess', 0);
                console.log('RT API catch Error', e.message);
                if (e.name === 'AbortError') {
                    console.log('RT API Request timed out', e);
                    const res = { status: 408 }
                    if (isRTForSwitchOrg) {
                        res.message = e.message
                    } else {
                        res.msg = 'RT API Request timed out, Please try again';
                    }
                    return res;
                }

                if (!navigator.onLine || e.message.includes('Failed to fetch')) {
                    let msg = `There was a problem, please check your internet connection, refresh your screen (CMD + R), and try again. \n\nMessage: ${e.message}. \nAPI: RT`
                    console.log('RT API catch Error', msg);
                    // alertService.error(msg);
                    // if (isElectronApp) window.ipcRender.send('message:showError', { msg });
                    return;
                }

                if (!isRTForSwitchOrg) {
                    removeUserSession();
                    if (isElectronApp) {
                        let msg = `Something went wrong, please login again. \nMessage: ${e.message}. \nAPI: RT`;
                        window.ipcRender.send('message:showError', { msg });
                        window.ipcRender.send('message:logoutSuccessful');
                    }
                    alertService.error('Catch: Some issue occured in RT API');
                }
                // setTimeout(() => { window.location.reload() }, 1000);
            });

        } else {
            forceLogout('Error: Invalid Credentials');
        }
    } else {
        // wait for the flag to be 0
        // then wait for api callback response
        // return the response to apiservice caller
        resetTokenInProcess = getResetTokenProcessStatus();
        return new Promise((resolve) => {
            let interval = setInterval(() => {
                resetTokenInProcess = getResetTokenProcessStatus();
                if (resetTokenInProcess === 0) {
                    clearInterval(interval);
                    resolve(1);
                }
            }, 50);
        }).then(async () => {
            if (!isRTForSwitchOrg) {
                return await apiRequest(API_URL, data, showProgress, req_method, controller, authHeader);
            }
        });
    }
}

function getAPIRequestOptions(req_method, authHeader, data, controller) {
    let headerAuthHeader = (authHeader) ? authHeader : AuthHeader();
    let options = {
        method: req_method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'App-Version': window.SITE_VERSION
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer' // no-referrer, *client
    }

    if (req_method === 'POST' || req_method === 'PUT' || req_method === 'DELETE') {
        if (data instanceof FormData) {
            options['body'] = data;
        } else {
            options['body'] = JSON.stringify(data);
            options['headers']['Content-Type'] = 'application/json';
        }
    }
    if (headerAuthHeader) {
        options.headers['Authorization'] = headerAuthHeader;
    }
    if (controller) {
        options['signal'] = controller.signal;
    }
    return options;
}

const APIService = {
    async apiRequest(API_URL, data, showProgress = false, req_method = 'POST', controller = null, authHeader = null, timerToken = false) {
        let options = getAPIRequestOptions(req_method, authHeader, data, controller);

        if (API_URL.includes('auth/rt')) {
            return await refreshToken({ API_URL, data, showProgress, req_method, controller, authHeader, apiRequest: APIService.apiRequest, timerToken });
        }

        // Default options are marked with *
        return fetch(API_URL, options)
            .then(async (response) => {
                // console.log('versionInfo', versionInfo);
                // const cookies = response.headers['set-cookie'];
                // const settingsCookie = cookies.find((cookie) => cookie.startsWith('AWSALB='))
                // const parsedCookie = cookie.parse(settingsCookie)
                // console.log(response.headers);

                // document.cookie = "AWSALB="+response.headers.get('set-cookie');
                // document.cookie = "AWSALB_L1="+response.headers['set-cookie'];
                // console.log('-------------------------');
                // console.log(response.headers.get('set-cookie')); // undefined
                // console.log(document.cookie); // nope
                // console.log('-------------------------');

                // Force log out black listed users
                if (response.status === 301) {
                    forceLogout(`Error ${response.status} - Invalid Credentials`);
                }

                if (isElectronApp && !API_URL.includes('checkin')) {
                    window.ipcRender.send('message:checkForUpdates');
                }

                if (!API_URL.includes('login') && response.status === 403) {
                    return await refreshToken({ response, API_URL, data, showProgress, req_method, controller, authHeader, apiRequest: APIService.apiRequest, timerToken });
                }

                // Don't show alert message on user_preference 500/501 error
                if (API_URL.includes('user_preference') && (response.status === 500 || response.status === 501)) {
                    return {};
                }

                // handle other errors
                if (!API_URL.includes('login') && !API_URL.includes('employee_details') && (response.status > 299 && (response.status !== 401 || response.status !== 403))) {
                    let errorMsg = response.statusText;
                    if (errorMsg === '') {
                        errorMsg = (errorMessages[response.status] !== undefined ? errorMessages[response.status] : '');
                    }
                    if (response.status) alertService.error(response.status + ': ' + errorMsg);
                    // return {};
                    return response.json()
                        .then((parsedResponse) => {
                            return parsedResponse;
                        });
                }

                // handle when it is ok
                if (showProgress) {
                    if (!response.ok) { throw Error(response.status + ' ' + response.statusText) }

                    // ensure ReadableStream is supported
                    if (!response.body) { throw Error('ReadableStream not yet supported in this browser.') }

                    const contentLength = +response.headers.get('Content-Length'); // Step 2: get total length
                    // const AWSALB_Cookie = response.headers.get('Set-Cookie'); 

                    const reader = response.body.getReader(); // Step 3: read the data
                    return { total_len: contentLength, reader: reader };

                } else {
                    if (response.status === 207) {
                        // Special check - 207 indicates that a New version of app is available 
                        // so in this case, force reload the browser so that user gets the latest version of app
                        // alertService.alert(`Good News, New update is available. Please wait it might take a couple of reloads to install the update.`)
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 5 * 1000);

                        // localStorage.setItem('app_version', window.SITE_VERSION);
                        // return {};
                        if (response.status === 408) {
                            return { status: 408, msg: 'RT API Request timed out, Please try again' };
                        }

                        response.json().then(data => handleMaintenance(data));
                        return { status: 207, msg: "Your request could not be processed because there is an update to TeamLink. Please try again" };
                    }

                    return response.json()
                        .then((parsedResponse) => {
                            // if (parsedResponse.status === 0) {
                            //     alertService.error(parsedResponse.message || parsedResponse.msg || 'Some error occured');
                            // }

                            return parsedResponse;
                        });
                }
            })
            .catch((e) => {
                console.log('api request cancel', e.message);
                if (!((e.message instanceof DOMException && e.message.name === 'AbortError') || e.message.includes('aborted'))) {
                    alertService.error(e.message || 'Some error occured while fetching API data');
                }
                return e;
            });
    },
    abortAPIRequests(controller) {
        controller.abort();
    }
};

export default APIService;