import { useEffect, useRef, useState } from 'react';
import SpeedSelect from 'react-speedselect';
import APIService from 'services/apiService';
import { API_BASE_URL } from 'components/Constants';
import { alertService } from 'services/alertService';
import { Steno } from 'react-steno';

const Employment = [
    { id: 1, name: 'Full-time' },
    { id: 2, name: 'Part-time' },
    { id: 3, name: 'Contract' },
    { id: 4, name: 'Internship' },
    { id: 5, name: 'Freelance' },
]

const Location = [
    { id: 1, name: 'Remote' },
    { id: 2, name: 'On-site' },
    { id: 3, name: 'Hybrid' },
]

const STENO_EDITOR_TOOLBAR_PROPS = {
    disabled: false,
    enableResize: false,
    toolbarPosition: 'bottom',
    toolbarChildVisibility: {
        fontSize: false,
        textTransform: false,
        fontColor: false,
        backgroundColor: false,
        removeTextFormat: true,
        addFiles: false,
        borderType: false,
        borderColor: false,
        borderWidth: false,
    }
}

const Advanced = ({ props, jobInfo, setJobInfo }) => {
    const hasQuestionnaire = props.funnelInfo.stages.some(stage => !!stage.isQuestionnaire);
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [isFormFocused, setIsFormFocused] = useState(false)

    const editorRef = useRef(null);
    const fnRef = useRef(null);

    useEffect(() => {
        setIsDataLoading(true)
        const controller = new AbortController()
        const fetchJobInfo = async () => {
            try {
                const URL = API_BASE_URL + `/new_job_application/${props.funnelInfo.funnelId}`
                const response = await APIService.apiRequest(
                    URL,
                    null,
                    false,
                    "GET",
                    controller
                );
                if (response.status === 1) {
                    const { job_title, job_description, employment, location } = response.output;
                    setJobInfo({
                        title: job_title || '',
                        description: job_description || '',
                        employment: employment ? Employment.find(e => e.name === employment) : {},
                        location: location ? Location.find(l => l.name === location) : {},
                    })
                } else {
                    console.error("Error fetching job info", response)
                }
            } catch (err) {
                console.error("CATCH: Error fetching job info", err)
            } finally {
                setIsDataLoading(false)
            }
        }
        fetchJobInfo()
    }, [props.funnelInfo.id])

    const saveJobInformation = async (jobInfo) => {
        try {
            const payload = {}
            if (jobInfo?.title?.trim()) payload.job_title = jobInfo.title.trim()
            if (jobInfo?.description?.trim()) payload.job_description = jobInfo.description.trim()
            if (jobInfo.employment?.id) payload.employment = jobInfo.employment.name
            if (jobInfo.location?.id) payload.location = jobInfo.location.name

            if (JSON.stringify(payload) === '{}') return

            const url = API_BASE_URL + `/recruiter/recruitment_funnel/${props.funnelInfo.funnelId}`
            const response = await APIService.apiRequest(url, payload, false, "PUT")
            if (response.status === 1) {
                // props.handleModifyFunnel('advanced')
            } else {
                console.error("Error saving job info", response)
            }
        } catch (err) {
            console.error("CATCH: Error saving job info", err)
        }
    }


    return (
        <div className='content-setting' style={{ display: props.funnelInfo.step === 4 ? 'block' : 'none' }}>
            <div className="name field-with-label">
                <label htmlFor="job-title">{`Job Title${hasQuestionnaire ? '*' : ''}`}</label>
                <input
                    type="text"
                    className={'field-control' + (props.funnelInfo.is_archive ? ' input-field-disabled' : '')}
                    name="job-title"
                    id="job-title"
                    value={jobInfo.title}
                    disabled={props.funnelInfo.is_archive === 1 || isDataLoading}
                    onClick={(e) => e.target.focus()}
                    onChange={(e) => setJobInfo({ ...jobInfo, title: e.target.value })}
                    onBlur={(e) => {
                        saveJobInformation({ title: e.target.value })
                    }}
                    // maxLength="45"
                    required
                />
                {/* <span className='textLength'>{`${jobInfo.title.length}/45`}</span> */}
            </div>
            <div className="description field-with-label">
                <label htmlFor="jobDesc">{`Job Description${hasQuestionnaire ? '*' : ''}`}</label>
                {/* <textarea
                        className={'field-control textarea' + (props.funnelInfo.is_archive ? ' input-field-disabled' : '')}
                        name="funnelDescription"
                        id='funnelDesc'
                        value={jobInfo.description}
                        disabled={props.funnelInfo.is_archive || isDataLoading}
                        onChange={(e) => setJobInfo({ ...jobInfo, description: e.target.value })}
                        onBlur={(e) => {
                            saveJobInformation({ jobInfo: { ...jobInfo, description: e.target.value } })
                        }}
                        rows="3"
                    // maxLength="150"
                    ></textarea> */}
                {/* <span className='textLength'>{`${jobInfo.description.length}/150`}</span> */}

                <div className={`editor ${isFormFocused ? 'steno-editor-focused' : ''}`}>
                    <Steno
                        html={jobInfo.description}
                        isToolBarVisible={isFormFocused}
                        innerRef={editorRef}
                        ref={fnRef}
                        disabled={props.funnelInfo.is_archive === 1 || isDataLoading}
                        onChange={value => setJobInfo({ ...jobInfo, description: value })}
                        onFocus={() => setIsFormFocused(true)}
                        onBlur={() => {
                            editorRef.current.blur()
                            setIsFormFocused(false)
                            saveJobInformation({ description: editorRef.current.innerHTML })
                        }}
                        sendMsgOnEnter={false}
                        {...STENO_EDITOR_TOOLBAR_PROPS}
                    />
                </div>
            </div>

            <div className='dropdown-container'>
                <div>
                    <SpeedSelect
                        options={Employment}
                        selectedOption={jobInfo.employment}
                        onSelect={(employmentVal) => {
                            const updatedJobInfo = { ...jobInfo, employment: employmentVal }
                            setJobInfo(updatedJobInfo)
                            saveJobInformation({ employment: employmentVal })
                        }}
                        displayKey='name'
                        uniqueKey='id'
                        maxHeight={120}
                        hideOkCancelBtns
                        selectLabel='Select'
                        prominentLabel={`Employment${hasQuestionnaire ? '*' : ''}`}
                        disable={props.funnelInfo.is_archive === 1 || isDataLoading}
                    />
                </div>
                <div>
                    <SpeedSelect
                        options={Location}
                        selectedOption={jobInfo.location}
                        onSelect={(locationVal) => {
                            const updatedJobInfo = { ...jobInfo, location: locationVal }

                            setJobInfo(updatedJobInfo)
                            saveJobInformation({ location: locationVal })
                        }}
                        displayKey='name'
                        uniqueKey='id'
                        maxHeight={120}
                        hideOkCancelBtns
                        selectLabel='Select'
                        prominentLabel={`Location${hasQuestionnaire ? '*' : ''}`}
                        disable={props.funnelInfo.is_archive === 1 || isDataLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default Advanced