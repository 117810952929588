import React from 'react';
import '../styles/Global.scss';

const Unauthorized = () => {
    return (
        <div className='unauthorized-wrapper'>
            <h2>Unauthorized access</h2>
            <p>Report accidental issues by emailing <a href='mailto:isupport@teamlink.com'>isupport@teamlink.com</a> for swift resolution.</p>
        </div>
    )
}

export default Unauthorized