import React, { useLayoutEffect, useRef, useState } from "react";
import { formatTime } from "../../utils";
import "./SpecialShiftPopUp.scss";

const SpecialShiftPopUp = ({ data, isActivityTracked }) => {
    const [isPopupOutOfBounds, setIsPopupOutOfBounds] = useState(false);
    const popupRef = useRef(null);

    useLayoutEffect(() => {
        if (popupRef.current) {
            const popupRect = popupRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            if (popupRect.bottom > windowHeight) {
                setIsPopupOutOfBounds(true);
            } else {
                setIsPopupOutOfBounds(false);
            }
        }
    }, []);

    return (
        <div
            className={`special-shift-popup-wrapper ${isPopupOutOfBounds ? "out-of-bounds" : ""
                }`}
            ref={popupRef}
        >
            <table className="special-shift-body">
                <tbody>
                    {data.map((ss) => {
                        const formattedStartTime = formatTime(ss.special_shift_start_time.time).replace('-', '').replace(':', ' : ');
                        const formattedEndTime = formatTime(ss.special_shift_end_time.time).replace('-', '').replace(':', ' : ');
                        const isNegativeStartTime = ss.special_shift_start_time.time.includes('-');
                        const isNegativeEndTime = ss.special_shift_end_time.time.includes('-');
                        return (
                            <tr
                                className="special-shift-info"
                                key={ss.special_shift_start_time.time}
                            >
                                <td>
                                    <div className={isNegativeStartTime ? "icon-info-bubble-after-wrapper" : ''}>
                                        {formattedStartTime}
                                        {/* {isNegativeStartTime && (
                                            <MouseTooltip
                                                asChild
                                                content={
                                                    <span>
                                                        Clock-In will occur a day before in your selected timezone
                                                    </span>
                                                }
                                                style={{ lineHeight: "17px" }}
                                            >
                                                <span className="icon-info-bubble-absolute" />
                                            </MouseTooltip>
                                        )} */}
                                    </div>
                                </td>
                                <td>
                                    <div className="hyphen-wrapper">
                                        <span className="hyphen"></span>
                                    </div>
                                </td>
                                <td>
                                    <div className={isNegativeEndTime ? "icon-info-bubble-after-wrapper" : ''}>
                                        {formattedEndTime}
                                        {/* {isNegativeEndTime && (
                                            <MouseTooltip
                                                asChild
                                                content={
                                                    <span>
                                                        Clock-Out will occur a day before in your selected timezone
                                                    </span>
                                                }
                                                style={{ lineHeight: "17px" }}
                                            >
                                                <span className="icon-info-bubble-absolute" />
                                            </MouseTooltip>
                                        )} */}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {/* <div className="activity-tracker-info">
                {isActivityTracked ? (
                    <>
                        <span className="label">Activity Tracker</span>
                        <span>ON</span>
                    </>
                ) : (
                    <span>Working AFK</span>
                )}
            </div> */}
        </div>
    );
};

const SpecialShiftInfoIcon = ({ data, isActivityTracked }) => {
    const [showDetails, setShowDetails] = useState(false);
    const toggle = () => {
        setShowDetails(!showDetails);
    };

    return (
        <div
            className="special-shift-wrapper"
            onMouseEnter={toggle}
            onMouseLeave={toggle}
        >
            {'Special Shift'}
            {showDetails && (
                <SpecialShiftPopUp
                    data={data}
                // isActivityTracked={isActivityTracked}
                />
            )}
        </div>
    );
};

export default SpecialShiftInfoIcon;
