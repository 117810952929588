// hooks
import useUserData from "hooks/useUserData";
// context
import { useAttendanceFilters } from "views/Attendance/context";

const RequestButtons = ({
    showRequestsModal,
    toggleRequestsModal,
    loggedInUser,
}) => {
    const { unReadRequestData, } = useAttendanceFilters();
    const { isManager } = useUserData();

    return (
        <div className="attendance__filters__right">
            {(loggedInUser || isManager) && (
                <button
                    onClick={() => !showRequestsModal && toggleRequestsModal()}
                >
                    { unReadRequestData?.unread_count > 0 && (
                        <span className="notification-count font-heavy-bold">
                            {unReadRequestData.unread_count}
                        </span>
                    )}
                    <span>Requests</span>
                </button>
            )}
        </div>
    );
};

export default RequestButtons;
