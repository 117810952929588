/* eslint-disable no-restricted-globals */
// @args: You can pass your worker parameters on initialisation
export default function MyWorker(args) {
    let intervalID = null, time = null, startClock = false, updateTotalHours = false;
    let timeoutTS = null;
    let scheduleBreakTimeouts = new Set();

    self.onmessage = e => { // eslint-disable-line no-unused-vars
        let start;
        if (!intervalID) {
            intervalID = accurateInterval(() => {
                if (startClock) {
                    time += 1;
                    if (!start) start = new Date().getTime();
                    postMessage({
                        command: 'updateTime',
                        time: time,
                    });
                }

                if (updateTotalHours) {
                    postMessage({
                        command: 'updateTotalHours',
                        time: time
                    });
                }

                if (timeoutTS && timeoutTS < new Date().getTime()) {
                    postMessage({
                        command: 'timeout',
                    });
                    timeoutTS = null;
                }
            }, 1000);
        }

        switch (e.data.command) {
            case 'worker:updateTimerStatus': {
                time = e.data.time
                startClock = e.data.startClock
                break;
            }
            case 'worker:updateTotalHoursStatus': {
                updateTotalHours = e.data.updateTotalHours;
                break;
            }
            case 'worker:updateTimeout': {
                timeoutTS = e.data.timeoutTS;
                break;
            }
            case "worker:schedule": {
                const { timeout, value } = e.data;
                const id = setTimeout(() => {
                    postMessage({ command: "scheduleTimeout", value });
                    scheduleBreakTimeouts.delete(id);
                }, timeout);

                scheduleBreakTimeouts.add(id);
                break;
            }
            case "worker:scheduleClear": {
                if (scheduleBreakTimeouts.size > 0) {
                    console.log("[SCHEDULED BREAK WORKER]: Clearing timeouts");
                    scheduleBreakTimeouts.forEach((id) => clearTimeout(id));
                    scheduleBreakTimeouts.clear();
                }
                break;
            }
            default: {
                console.log('WebWorker - Command is missing');
                break;
            }
        }

        if (!startClock && !updateTotalHours) {
            intervalID.cancel();
            intervalID = null;
        }
    };

    self.onerror = e => { // eslint-disable-line no-unused-vars
        console.log('MyWorker error', e);
    };

    function accurateInterval(fn, time) {
        var cancel, nextAt, timeout, wrapper;
        nextAt = new Date().getTime() + time;
        timeout = null;
        wrapper = function () {
            nextAt += time;
            timeout = setTimeout(wrapper, nextAt - new Date().getTime());
            return fn();
        };
        cancel = function () {
            return clearTimeout(timeout);
        };
        timeout = setTimeout(wrapper, nextAt - new Date().getTime());
        return {
            cancel: cancel
        };
    };
}