import { useEffect, useState } from "react";
// services
import EventEmitter from "services/eventEmitter";
// utils
// styles
import "./AttendanceTabOptions.scss";

export const eventsEnum = {
    TOGGLE_REQUESTS: "toggleRequests",
    ON_REQUEST_MODAL_OPEN: "onRequestModalOpen",
    ON_UPDATE_UNREAD_COUNT: "onUpdateUnreadCount",
};

export const emitter = new EventEmitter();

const AttendanceTabOptions = () => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [unReadRequestCount, setUnReadRequestCount] = useState(0);

    // let teamRequestUnReadCount = 0;
    // let myRequestUnReadCount = 0;

    // if (unReadRequestData?.teamRequest) {
    //     Object.keys(unReadRequestData.teamRequest).forEach(status => {
    //         teamRequestUnReadCount += unReadRequestData.teamRequest[status].count;
    //     });
    // }

    // if (unReadRequestData?.myRequest) {
    //     Object.keys(unReadRequestData.myRequest).forEach(status => {
    //         myRequestUnReadCount += unReadRequestData.myRequest[status].count;
    //     });
    // }

    useEffect(() => {
        const unsubs = [
            emitter.subscribe(
                eventsEnum.ON_REQUEST_MODAL_OPEN,
                (e = true) => setIsModalOpened(e)
            ),
            emitter.subscribe(
                eventsEnum.ON_UPDATE_UNREAD_COUNT,
                (data) => setUnReadRequestCount(data)
            )
        ];

        return () => unsubs.forEach(unsub => unsub());
    }, [isModalOpened]);

    const handleRequestsClick = () => {
        emitter.emit(eventsEnum.TOGGLE_REQUESTS);
    };

    return (
        <div className="attendance-tab-options-wrapper">
            <div className="request-btn" onClick={handleRequestsClick}>
                {!isModalOpened && unReadRequestCount > 0 && <span className="notification-number">{unReadRequestCount}</span>}
                <span className={isModalOpened ? "chevron" : ""}>Requests</span>
            </div>
        </div>
    );
};

export default AttendanceTabOptions;
