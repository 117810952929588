import { API_BASE_URL } from "components/Constants";

export const AL_BASE_URL = API_BASE_URL + "/attendance";

export const filterOptions = [
  {
    label: "Shift Status",
    id: 1,
    options: [],
  },
  {
    label: "Reporting Time",
    id: 2,
    options: [],
  },
  {
    label: "Request Status",
    id: 3,
    options: [],
  },
];

export const displayOptions = [
  { id: 1, name: "Working" },
  { id: 2, name: "Standard Shift", parent_id: 1 },
  { id: 3, name: "Early Start", parent_id: 2 },
  { id: 4, name: "Late Start", parent_id: 2 },
  { id: 5, name: "On Time Start", parent_id: 2 },
  { id: 6, name: "Special Shift", parent_id: 1 },
  { id: 7, name: "On Leave" },
  { id: 8, name: "Presence" },
  { id: 9, name: "Online", parent_id: 8 },
  { id: 10, name: "Away", parent_id: 8 },
];

export const COMMENT_TIMEOUT = 5; // Must be Minutes
export const STANDARD_WORK_TIMINGS = 28800; // 8hrs in seconds
export const BUFFER_TIMER = 3600; // 1hrs in seconds

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const REQUEST_TYPES = Object.freeze({
  STANDARD: "schedule",
  SPECIAL: "special_shift",
  LEAVE: "leave",
});

export const SHIFT_TYPE_OPTIONS = [
  { name: "Special", id: REQUEST_TYPES.SPECIAL },
  { name: "Standard", id: REQUEST_TYPES.STANDARD },
];

export const ATTENDANCE_STATUS_FILTER_OPTIONS = [
  {
    name: "Early",
    id: "early",
  },
  {
    name: "Late",
    id: "late",
  },
  {
    name: "On Time",
    id: "on_time",
  },
  {
    name: "Special Shift",
    id: "special_shift",
  },
  {
    name: "On Leave",
    id: "on_leave",
  },
];

export const ATTENDANCE_PRESENCE_FILTER_OPTIONS = [
  // {
  //   name: "Not Started",
  //   id: "not_started",
  // },
  {
    name: "Online",
    id: "online",
  },
  {
    name: "On Break",
    id: "on_break",
  },
  {
    name: "Clocked Out",
    id: "clocked_out",
  },
];

export const INITIAL_PAGINATION_META = {
  num_pages: 0,
  page_number: 1,
  page_size: 10, // always the page size is 10
  total_results: 0,
};

export const STENO_EDITOR_TOOLBAR_PROPS = {
  disabled: false,
  enableResize: false,
  toolbarPosition: "bottom",
  toolbarChildVisibility: {
    fontSize: false,
    textTransform: false,
    fontColor: false,
    backgroundColor: false,
    removeTextFormat: true,
    addFiles: false,
    borderType: false,
    borderColor: false,
    borderWidth: false,
  },
};

export const BREAK_SUB_OPTIONS = [
  { label: 'Break Count', value: 'break_count' },
  { label: 'Puntual Time', value: 'punctual_break_time' },
  { label: 'Late Time', value: 'late_break_time' },
  { label: 'Total Break Time', value: 'total_break_time' },
];

export const INACTIVITY_SUB_OPTIONS = [
  { label: 'Inactivity Count', value: 'inactivity_count' },
  { label: 'Inactivity Time', value: 'inactivity_time' },
];

export const STANDARD_WORK_TIMINGS_OPTION_KEY = "standard_work_timings"
export const SHIFT_COMPARE_OPTIONS = [
  {
    options: [
      {
        label: "Standard Work Timings",
        value: STANDARD_WORK_TIMINGS_OPTION_KEY,
        displayKey: "Standard Work Timings"
      },
    ],
  },
  {
    label: "Organization Average",
    options: [
      {
        label: "Weekly",
        value: "o_w",
        displayKey: "Organization avg. Weekly"
      },
      {
        label: "Monthly",
        value: "o_m",
        displayKey: "Organization avg. Monthly"
      },
      {
        label: "Quarterly",
        value: "o_q",
        displayKey: "Organization avg. Quarterly"
      },
      {
        label: "Yearly",
        value: "o_y",
        displayKey: "Organization avg. Yearly"
      },
    ],
  },
  {
    label: "Self Average",
    options: [
      {
        label: "Weekly",
        value: "s_w",
        displayKey: 'Self avg. Weekly'
      },
      {
        label: "Monthly",
        value: "s_m",
        displayKey: 'Self avg. Monthly'
      },
      {
        label: "Quarterly",
        value: "s_q",
        displayKey: 'Self avg. Quarterly'
      },
      {
        label: "Yearly",
        value: "s_y",
        displayKey: 'Self avg. Yearly'
      },
    ],
  },
];

export const TOOLTIP_STYLING_OPTIONS_LIGHT = {
  bgColor: "#E2E2E2",
  color: "#000",
  borderRadius: "2px",
}

export const TOOLTIP_STYLING_OPTIONS_DARK = {
  bgColor: "#1F2124",
  color: "#B1B2B3",
  borderRadius: "2px",
}

export const CLOCK_IN_COLOR_MAP = {
  early: 'green',
  on_time: '',
  late: 'red',
}