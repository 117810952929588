import { useEffect, useRef, useState } from 'react'

import SuperPager from 'super-pager';
import LineLoader from 'components/LineLoader';
import ManageMetrics from '../ManageMetrics';
import ProfileCard from '../ProfileCard';

import { usePerformanceContext } from '../context';

import { alertService } from 'services/alertService';
import APIService from 'services/apiService';

import { debounce, getUser } from 'utils/Common';
import { API_BASE_URL } from 'components/Constants';
import useScrollRestoration from 'hooks/useScrollRestoration';

const Home = () => {
    const {
        departmentsList,
        leaderBoard, updateLeaderBoard,
        isSearchResult, updateIsSearchResult,
        metrics, updateMetrics,
        savedTabStates, updateSavedTabStates,
        loggedInUserResId,
        metaDataRef,
    } = usePerformanceContext();

    const performanceHomeScrollRef = useScrollRestoration('performance-home');

    const wrapperHeight = window.innerHeight - 125;
    let PAGE_SIZE = parseInt((wrapperHeight / 90) * 1.2) * 4;

    const userInfo = getUser(); // fetch user infor from local storage
    const abortController = useRef(new AbortController());

    const leaderBoardRef = useRef(null);
    const searchInputRef = useRef(null);

    const [loadingLeaderBoard, setLoadingLeaderBoard] = useState(false);
    const [loadingMetrics, setLoadingMetrics] = useState(false);
    const [showManageMetrics, setShowManageMetrics] = useState(false);
    const [searchString, setSearchString] = useState('');

    const resetAbortController = () => {
        abortController.current.abort();
        abortController.current = new AbortController();
    };

    useEffect(() => {
        getLeaderBoard({ showLoading: !leaderBoard });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => () => abortController.current.abort(), []);

    const getMetrics = () => {
        setLoadingMetrics(true);
        APIService.apiRequest(API_BASE_URL + '/performance/metric', null, false, 'GET')
            .then(response => {
                if (response.status === 1) {
                    setLoadingMetrics(false);
                    updateMetrics(response.data);
                    // console.log('getMetrics - if response', response);
                } else {
                    setLoadingMetrics(false);
                    if (response instanceof DOMException && response.name === 'AbortError') return;
                    alertService.error(`Error occured while fetching metrics`);
                    console.log('getMetrics - else response', response.msg);
                }
            }).catch(err => {
                setLoadingMetrics(false);
                alertService.error(`Error: ${err.msg}`);
                console.log('getMetrics - error', err);
            });
    }

    const handleClearBtn = () => {
        resetAbortController();
        if (searchString) {
            setSearchString('');
            getLeaderBoard({ page: 1, loadMore: false, search: '' });
        }
        searchInputRef.current.value = ''
    }

    const handleProfileClick = (profileInfo) => {
        if (!savedTabStates[String(profileInfo.resource_id)]) {
            updateSavedTabStates(profileInfo.resource_id, 'newTab');
        }
    }

    const getHomePageContent = (data) => {
        return data.map(profile => {
            return <ProfileCard
                key={profile.resource_id}
                profileInfo={profile}
                isLoggedInUser={loggedInUserResId === profile.resource_id}
                handleProfileClick={handleProfileClick} />
        })
    }

    const handleMetricsData = (updatedMetrics) => {
        updateMetrics(updatedMetrics);
    }

    const handleSearchChange = debounce((value) => {
        resetAbortController();
        setSearchString(value);
        getLeaderBoard({ search: value })
    }, 450)

    const getLeaderBoard = ({ page = 1, loadMore = false, search = searchString, showLoading = true } = {}) => {
        if (showLoading) setLoadingLeaderBoard(true);

        const url = new URL(API_BASE_URL + '/performance/leaderboard');
        url.searchParams.append('page_size', PAGE_SIZE);
        url.searchParams.append('page_number', page);
        if (search) url.searchParams.append('search_string', search);

        APIService.apiRequest(url.toString(), null, false, 'GET', abortController.current)
            .then(response => {
                if (response.status === 1) {
                    metaDataRef.current = response.meta;

                    if (loadMore) {
                        updateLeaderBoard([...leaderBoard, ...response.output]);
                        leaderBoardRef.current = [...leaderBoard, ...response.output];
                    } else {
                        updateLeaderBoard(response.output);
                        leaderBoardRef.current = response.output;
                    }
                    updateIsSearchResult(!!search);
                } else {
                    if (response instanceof DOMException && response.name === 'AbortError') return;
                    alertService.error(`Error occured while fetching leaderboard data`);
                    console.log('getLeaderBoard - else response', response);
                }

                if (showLoading) setLoadingLeaderBoard(false);
            }).catch(err => {
                if (showLoading) setLoadingLeaderBoard(false);

                alertService.error(`Error: ${err.msg}`);
                console.log('getLeaderBoard - error', err);
            });
    }

    const leaderBoardContent = leaderBoard && getHomePageContent(leaderBoard);

    return (
        <div className="home-content-wrapper">
            <LineLoader position="absolute" show={loadingLeaderBoard} />
            {showManageMetrics && (
                <ManageMetrics
                    setShowManageMetrics={setShowManageMetrics}
                    departmentsList={departmentsList}
                    metrics={metrics}
                    loadingMetrics={loadingMetrics}
                    handleMetricsData={handleMetricsData}
                />
            )}
            <div className="home-header">
                <div className="home-search-container">
                    <div className="home-search-wrapper">
                        <input
                            ref={searchInputRef}
                            type="search"
                            id="candid-search"
                            name="candid-search"
                            className="search"
                            placeholder="Search"
                            defaultValue={searchString}
                            onChange={(e) => handleSearchChange(e.target.value.trim())}
                        />
                        <button
                            type="button"
                            className="btn btn-search"
                            // Disabled button since we have dynamic search as user types, did not remove button as per management instructions
                            // onClick={() =>
                            //     searchString &&
                            //     !loadingLeaderBoard &&
                            //     getLeaderBoard()
                            // }
                            title="Search for a funnel"
                        >
                            Search
                        </button>
                    </div>
                    <button
                        type="button"
                        className="clear-button"
                        onClick={handleClearBtn}
                        title="Clear all filters"
                    >
                        Clear
                    </button>
                </div>
                {userInfo.privileges.employee.includes("MANAGER") && (
                    <button
                        className={"btn-with-icon btn-settings btn-small"}
                        onClick={() => {
                            setShowManageMetrics(true);
                            getMetrics();
                        }}
                    >
                        <i></i>
                        <span>Manage Metrics</span>
                    </button>
                )}
            </div>
            {/* <div className='home-body' id='leaderboard-wrapper' onScroll={handleScroll}> */}
            <div className="home-body" id="leaderboard-wrapper" ref={performanceHomeScrollRef}>
                {leaderBoardContent && (
                    <SuperPager
                        type="infiniteScroll"
                        dataLength={leaderBoard?.length || null}
                        loadMore={() =>
                            getLeaderBoard({
                                page: metaDataRef.current.page_number + 1,
                                loadMore: true,
                            })
                        }
                        hasMore={
                            metaDataRef.current.page_number <
                            metaDataRef.current.num_pages
                        }
                        wrapper={true}
                        children={
                            <>
                                <div className='pl-label-wrapper'>
                                    <span className="pl-label">
                                        {isSearchResult
                                            ? `Showing Results ${leaderBoard.length}`
                                            : "Leader Board"}
                                    </span>
                                </div>
                                <div className="profiles-container">
                                    {leaderBoard &&
                                        leaderBoard.length > 0 ? (
                                        leaderBoardContent
                                    ) : (
                                        <p className="general-info">
                                            Nothing to display
                                        </p>
                                    )}
                                </div>
                            </>
                        }
                    />
                )}
            </div>
        </div>
    )
}

export default Home