import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ClickOutsideListner from "components/ClickOutsideListner";
import DatePicker from "components/ReactCalendar/DatePicker";
import "./RequestDatePicker.scss";
import { format, isDate } from "utils/date";
import { getUniqueId } from "utils/Utils";

const RequestDatePicker = forwardRef(
  (
    {
      date,
      onChange,
      disabled,
      placeholder = "Select Date",
      isFocused = true,
      id = getUniqueId(),
    },
    ref
  ) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const isValidDate = isDate(date);

    function handleDateChange(date) {
      onChange(date);
      setShowDatePicker(false);
    }

    useImperativeHandle(ref, () => ({
      open: () => setShowDatePicker(true),
      close: () => setShowDatePicker(false),
    }))

    return (
      <div className="request-date-picker-wrapper">
        {/* <input
        className="request-date-picker-input"
        type="text"
        value={isDate(date) ? format(new Date(date), 'ddd, MMM DD, YYYY') : placeholder}
        onClick={() => setShowDatePicker(true)}
        onChange={() => { }}
        disabled={disabled}
      /> */}
        <button
          type="button"
          className={
            "request-date-picker-btn" +
            (isFocused ? " focused" : "") +
            (!isValidDate ? " placeholder" : "")
          }
          id={id}
          onClick={(e) => {
            setShowDatePicker(!showDatePicker);
          }}
          disabled={disabled}
        >
          {isValidDate
            ? format(new Date(date), "ddd, MMM DD, YYYY")
            : placeholder}
        </button>
        {showDatePicker && (
          <PickerPanel
            date={date}
            id={id}
            handleDateChange={handleDateChange}
            setShowDatePicker={setShowDatePicker}
          />
        )}
      </div>
    );
  }
);

const PickerPanel = ({ date, handleDateChange, id, setShowDatePicker }) => {
  useEffect(() => {
    const picker = document.querySelector(".request-date-picker");
    if (picker) {
      picker.classList.add("show-date-picker");
    }
  }, []);

  return (
    <ClickOutsideListner
      onOutsideClick={(e) => {
        if (e.target.id.includes(id)) return;
        setShowDatePicker(false);
      }}
    >
      <div className={"request-date-picker"}>
        <DatePicker
          showInline
          date={date}
          onChange={(date) => handleDateChange(date)}
        />
      </div>
    </ClickOutsideListner>
  );
};

export default RequestDatePicker;
