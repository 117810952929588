import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "hooks/useClickOutside";
import "./FilterModal.scss";

const SLIDE_DELAY_TIMEOUT = 200;

export default function FilterModal({ show, toggle, options }) {
  const hasMultipleOptions = options?.length > 1
  const [openIndex, setOpenIndex] = useState(() => {
    return hasMultipleOptions ? null : 0;
  });
  const modalRef = useRef(null);
  let timeoutRef = useRef(null);

  useEffect(() => {
    if (show && modalRef.current) {
      modalRef.current.classList.remove("close");
      modalRef.current.classList.add("open");
    }
  }, [show]);

  useEffect(() => {
    return () => {
      modalRef.current?.classList?.remove("close", "open");
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      toggle();
    };
  }, []);

  const handleToggle = () => {
    modalRef.current.classList.remove("open");
    modalRef.current.classList.add("close");

    timeoutRef.current = setTimeout(() => {
      toggle();
      setOpenIndex(prev => hasMultipleOptions ? null : prev);
    }, SLIDE_DELAY_TIMEOUT);
  };

  useOnClickOutside(modalRef, handleToggle);

  const openAccordion = (index) => {
    if (!hasMultipleOptions) return;
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  if (!show) return null;

  return (
    <div
      className="filter-modal"
      ref={modalRef}
      style={{ "--slide-delay-timeout": `${SLIDE_DELAY_TIMEOUT}ms` }}
    >
      {/* <div className="filter-modal-header">Filter</div> */}
      <div className="filter-modal-body">
        {options.map((item, index) => {
          const selectedOptions = item.options.filter(
            (option) => option.checked
          );

          return (
            <div
              className={`accordion ${openIndex === index || !hasMultipleOptions ? "expanded" : ""} ${hasMultipleOptions ? "" : "single-option"}`}
              key={index}
            >
              <button
                type="button"
                className="accordion-btn"
                onClick={() => openAccordion(index)}
              >
                <span className="accordion-title">{item.label}</span>
                {selectedOptions.length > 0 && (
                  <span className="total-filter-applied">
                    {selectedOptions.length}
                  </span>
                )}
              </button>

              <div
                className={`accordion-content ${openIndex === index ? "" : "hide"
                  }`}
              >
                <div className="options-wrapper">
                  {item.options.map((option, optionIndex) => {
                    return (
                      <CheckboxInput
                        key={optionIndex}
                        option={option}
                        onChange={(e) =>
                          item.onChange(option, e.target.checked)
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function CheckboxInput({ option, onChange }) {
  return (
    <div className="checkbox-wrapper">
      <div className="checkbox">
        <input
          type="checkbox"
          id={`${option.id} ${option.name}`}
          onChange={onChange}
          checked={option.checked}
        />
        <label htmlFor={`${option.id} ${option.name}`}>{option.name}</label>
      </div>
    </div>
  );
}
