/**
 * Represents a single class value that can be a string, number, bigint, null, boolean, or undefined.
 * @typedef {string | number | bigint | null | boolean | undefined} ClassValue
 */

/**
 * Represents a dictionary object where keys are class names and values are either true or false.
 * @typedef {Object.<string, boolean>} ClassDictionary
 */

/**
 * Represents an array of class values.
 * @typedef {Array<ClassValue>} ClassArray
 */

/**
 * Represents the input types accepted by the `clsx` function.
 * @typedef {ClassArray | ClassDictionary | string | null | boolean | undefined} ClassValue
 */

/**
 * Combines input class names into a single string.
 * @param {...ClassValue} inputs - Class names or objects representing class names.
 * @returns {string} A single string representing combined class names.
 */
export function clsx() {
  var i = 0, tmp, x, str = '', len = arguments.length;
  for (; i < len; i++) {
    if (tmp = arguments[i]) {
      if (x = toVal(tmp)) {
        str && (str += ' ');
        str += x
      }
    }
  }
  return str;
}

function toVal(mix) {
  var k, y, str = '';

  if (typeof mix === 'string' || typeof mix === 'number') {
    str += mix;
  } else if (typeof mix === 'object') {
    if (Array.isArray(mix)) {
      var len = mix.length;
      for (k = 0; k < len; k++) {
        if (mix[k]) {
          if (y = toVal(mix[k])) {
            str && (str += ' ');
            str += y;
          }
        }
      }
    } else {
      for (y in mix) {
        if (mix[y]) {
          str && (str += ' ');
          str += y;
        }
      }
    }
  }

  return str;
}

export default clsx;