import { useRef, useState } from "react";
import LineLoader from "components/LineLoader";
import { useOnClickOutside } from "hooks/useClickOutside";
import { alertService } from "services/alertService";
import { isSafari } from "utils/Common";
import "./PreviewModal.scss";

export default function PreviewModal({ isOpen, toggle, url = "", title }) {
  const ref = useRef(null);
  const isPDF = url.endsWith(".pdf");
  const showDownloadBtn = (isPDF && isSafari()) || !isPDF;
  const [isLoading, setIsLoading] = useState(true);
  const toggleLoading = () => setIsLoading(false);
  const handleDownload = () => {
    fetch(url, {
      method: "GET",
      // cache: "reload",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const filename = url.split("_T_L_").pop();
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = filename;
        a.style.display = "none";

        if (ref.current) {
          ref.current.appendChild(a);
          a.click();
          ref.current.removeChild(a);
        } else {
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }

        URL.revokeObjectURL(a.href);
      })
      .catch((error) => {
        alertService("Something went wrong while downloading the file");
      });
  };

  useOnClickOutside(ref, () => toggle());
  if (!isOpen) return null;

  return (
    <div ref={ref} className="preview-modal">
      <LineLoader show={isLoading} position="absolute" />
      <div className="preview-header">
        <button className="preview-title" onClick={(e) => toggle(e)}>
          <span className="icon-left"></span>
          <span>{title}</span>
        </button>
        <div className="preview-cta">
          {showDownloadBtn && (
            <button
              className="download-btn"
              onClick={() => handleDownload()}
            ></button>
          )}
        </div>
      </div>
      <div className="preview-body" onClick={(e) => toggle(e)}>
        {isPDF ? (
          <object
            className="pdf-viewer"
            aria-label={title}
            type="application/pdf"
            data={url}
            onLoad={() => toggleLoading()}
            onClick={(e) => e.stopPropagation()}
            onError={() => {
              toggleLoading();
              alertService.error("Failed to load the PDF");
            }}
          ></object>
        ) : (
          <img
            src={url}
            alt={title}
            onLoad={() => toggleLoading()}
            onClick={(e) => e.stopPropagation()}
            onError={() => {
              toggleLoading();
              alertService.error("Failed to load the image");
            }}
          />
        )}
      </div>
    </div>
  );
}
