import React, { useEffect, useRef, useState } from 'react';
import ClickOutsideListner from 'components/ClickOutsideListner';
import './Panel.scss';

const headerHeight = 'var(--header-height-other-tab, 36px)';
const Panel = ({
  isOpen,
  onClose = () => { },
  width = null,
  transitionDuration = 200,
  transitionType = 'slide-in-from-right',
  button = null,
  children,
  top,
  id = null,
}) => {
  const timeOutRef = useRef(null);
  const panelRef = useRef(null);
  const isCustomWidthTransition = transitionType.includes('slide') && width;
  const style = transitionType === 'slide-in-from-right' ? 'right' : 'left';

  const [showPanel, setShowPanel] = useState(false);
  const styles = isCustomWidthTransition ? { [style]: showPanel ? `0px` : `-${width}px` } : {};

  useEffect(() => {
    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
    };
  }, [id]);

  const closePanel = (e) => {
    if (id && e.target.id && e.target.id !== id) return;
    if (isCustomWidthTransition) {
      setShowPanel(false);
    } else {
      panelRef.current.classList.remove('open');
    }
    timeOutRef.current = setTimeout(() => {
      onClose(e);
    }, transitionDuration);
  }

  useEffect(() => {
    if (panelRef.current) {
      if (isOpen) {
        if (isCustomWidthTransition) {
          setShowPanel(true);
        }
        setTimeout(() => {
          if (!isCustomWidthTransition) {
            panelRef.current.classList.add("open");
          }
        }, 0)
      } else {
        if (isCustomWidthTransition) {
          setShowPanel(false);
        } else {
          panelRef.current.classList.remove("open");
        }
      }
    }
    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
    };
  }, [isCustomWidthTransition, isOpen, transitionType, width]);

  return (
    <div className='animating-panel-wrapper'>
      {button}
      {isOpen ? <ClickOutsideListner onOutsideClick={closePanel}>
        <div
          className={`animating-panel ${transitionType} ${width ? 'fixed-width' : ''}`}
          style={{
            "--transition-duration": `${transitionDuration}ms`,
            top: top ?? (transitionType.includes('slide') ? headerHeight : '20px'),
            ...styles
          }}
          ref={panelRef}
        >
          {children}
        </div>
      </ClickOutsideListner> : null}
    </div>
  );
};

export default Panel;