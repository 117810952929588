import { getMyTimezoneOffset, getUTCDate } from "utils/Common";

export const convertTimeToSeconds = (time) => {
    let [hours, mins, seconds] = time.split(':');
    hours = hours * 3600;
    mins = mins * 60;
    return +hours + +mins + +seconds;
}

export const convertTimeZone = (date = new Date()) => {
    let offset = getMyTimezoneOffset(date);
    let newOffset = ((offset.split(':')[0] * 60) + +offset.split(':')[1]) * -1;
    date = new Date(date.getTime() - (newOffset * 60 * 1000));
    return date.toISOString().replace('T', ' ').split('.')[0];
}

export const formatTime = (timeInSeconds, includeSeconds = true) => {
    const hours = Math.floor(timeInSeconds / 3600) % 24;
    const mins = Math.floor(timeInSeconds / 60) % 60;
    const seconds = Math.floor(timeInSeconds) % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}${includeSeconds ? `:${seconds.toString().padStart(2, '0')}` : ''}`;
}

export const formatDate = (d) => {
    let inputDate = new Date(d);
    let month = (+inputDate.getMonth() + 1 < 10 ? ('0' + (+inputDate.getMonth() + 1)) : (+inputDate.getMonth() + 1));
    let date = (+inputDate.getDate() < 10 ? ('0' + (inputDate.getDate())) : (inputDate.getDate()));
    return date + '-' + month + '-' + inputDate.getFullYear();
}

export const formatClockDate = (d) => {
    const date = new Date(d);
    let time = date.toLocaleTimeString('en-IN', { hour12: false }).split(',');
    let newTime = (+time[0].slice(0, 2) === 24 ? '00' : time[0].slice(0, 2)) + time[0].slice(2)
    let newDate = ((date.toLocaleDateString('en-In', { weekday: 'short', year: 'numeric', month: 'short', day: '2-digit' })) + ' ' + newTime).split(',');
    newDate = `${newDate[0]},${newDate[1]}${newDate[2]} GMT`;
    return newDate;
}

export const addShakeEffectToEl = (el) => {
    if (el instanceof HTMLElement) {
        if (el.classList.contains("shake")) {
            el.classList.remove("shake");
        }

        requestAnimationFrame(() => el.classList.add("shake"));
    }
}

export const removeShakeEffectFromEl = (el) => {
    if (el instanceof HTMLElement) {
        el.classList.remove("shake");
    }
}

// create a function which takes date as input and return only the time in 12 hr format
export const getTimeIn12HourFormat = (date) => {
    const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };

    return new Date(date).toLocaleTimeString('en-US', options);
}

export const getTimeDiff = (time, diffMins) => {
    const currentDate = getUTCDate();
    const startedAtDate = new Date(time);
    const untilDate = new Date(startedAtDate.getTime() + (diffMins * 60000));
    return (untilDate - currentDate) / 1000;
}
