import Switch from 'components/Switch/Switch';
import close from 'images/icon-close2.svg';
import { copyToClipBoard } from 'utils/Utils';
import { isDev } from 'utils/Common';
import { alertService } from 'services/alertService';
import './styles.scss';

export default function CreateStage(props) {
    const { name, description, isQuestionnaire, is_archive, mode, countResources } = props;
    const isJobInfoFilled = !!(props.jobInfo.title && props.jobInfo.description && props.jobInfo.location?.id && props.jobInfo.employment?.id);

    const isEditMode = mode === 'edit';
    const isArchived = is_archive === 1;
    const isFistStage = props.index === 0;
    const showCopyStageLink = props?.funnelId && !isArchived && isFistStage;
    const order = Math.max(...props.arr.map(o => o.order ? o.order : 0));
    return (
        <div id='stage-wrapper' ref={props.myRef}>
            <div className='stage-header'>
                <h4>{props.title}</h4>
                {(!isArchived && props.arr.length > 1 && !countResources) && <button
                    type='button'
                    className='btn-close'
                    onClick={(e) => props.onDelete(props.id, 'deleteStage', null, props.index)}
                >
                    <img src={close} alt='close-icon' />
                </button>}
            </div>
            <div className='stage-body'>
                {isEditMode && <span>No. of Resources: {countResources ? countResources : 0}</span>}
                <div className="name field-with-label">
                    <label htmlFor="stageName">Name*</label>
                    <input
                        type='text'
                        className={'field-control' + (isArchived ? ' input-field-disabled' : '')}
                        name='stageName'
                        value={name}
                        disabled={isArchived}
                        maxLength="50"
                        onChange={(e) => props.onChange(props.id, 'stageName', e.target.value, props.index)}
                        // onBlur={(e) => isEditMode && props.onBlur('updateStageDetails', props.stageId, { name: e.target.value, description, order: props.index })}
                        onBlur={(e) => isEditMode && props.onBlur('updateStageDetails', props.stageId, { name: e.target.value, description, order, isQuestionnaire })}
                    />
                    <span className='textLength'>{`${name.length}/50`}</span>
                </div>

                <div className="description field-with-label">
                    <label htmlFor="stageDescription">Description</label><br />
                    <textarea
                        className={"field-control textarea" + (isArchived ? ' input-field-disabled' : '')}
                        id='stageDescription'
                        name="stageDescription"
                        value={description}
                        disabled={isArchived}
                        rows="3"
                        maxLength="150"
                        onChange={(e) => props.onChange(props.id, 'stageDesc', e.target.value, props.index)}
                        // onBlur={(e) => isEditMode && props.onBlur('updateStageDetails', props.stageId, { name, description: e.target.value, order: props.index })}
                        onBlur={(e) => isEditMode && props.onBlur('updateStageDetails', props.stageId, { name, description: e.target.value, order, isQuestionnaire })}
                    ></textarea>
                    <span className='textLength'>{`${description.length}/150`}</span>
                </div>

                <div className='questionnaire-toggle-wrapper'>
                    <div className='toggle-wrapper'>
                        {!isFistStage &&
                            <Switch
                                key={props.index}
                                id={props.index}
                                disabled={isArchived}
                                isOn={isQuestionnaire}
                                handleToggle={() => {
                                    if (!isJobInfoFilled) {
                                        alertService.error('Fill out all job details in the Advanced section first.');
                                        return;
                                    }
                                    props.onChange(props.id, 'isQuestionnaire', !isQuestionnaire, props.index)
                                    isEditMode && props.onBlur('updateStageDetails', props.stageId, { name, description, order, isQuestionnaire: !isQuestionnaire })
                                }}
                            />}
                        <div className='toggle-label'>{isFistStage ? 'Basic' : 'Questionnaire'} Form</div>
                    </div>
                    {showCopyStageLink && <div className='copy-icon-wrapper'>
                        <button className='icon-copy' onClick={() => {
                            const link = `https://${isDev() ? 'dev-' : ''}forms.teamlink.com/${props.funnelId}`;
                            copyToClipBoard(link)
                        }}></button>
                        <div className='copy-tooltip'>Copy Link</div>
                    </div>}
                </div>
            </div>
        </div>
    )
}