import { alertService } from 'services/alertService';

export const toTitleCase = (str) =>
  str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

export const copyToClipBoard = (info) => {
  navigator.clipboard.writeText(info);
  alertService.success('Copied to clipboard ');
};

//Returns the input date if its valid, otherwise returns current date
export const isValidDate = (date) =>
  new Date(date) instanceof Date && !isNaN(new Date(date).valueOf());

export const getAuthorName = ({
  first_name = '',
  middle_name = '',
  last_name = '',
} = {}) => `${first_name || ''} ${middle_name || ''} ${last_name || ''}`;

export const isMacOs = () => {
  return navigator.userAgent.toLowerCase().indexOf('mac') !== -1;
}

export const getUniqueId = () => {
  return Math.random().toString(36).substring(2, 9);
}

export function compareVersions(version1, version2) {
  const parseVersion = (version) => version.split('.').map(Number);

  const v1 = parseVersion(version1);
  const v2 = parseVersion(version2);

  const length = Math.max(v1.length, v2.length);

  for (let i = 0; i < length; i++) {
      const num1 = v1[i] || 0;
      const num2 = v2[i] || 0;

      if (num1 > num2) {
          return "greater";
      } else if (num1 < num2) {
          return "lesser";
      }
  }

  return "equal";
}