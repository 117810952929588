import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { StenoRenderer } from "react-steno";

import { AL_BASE_URL, COMMENT_TIMEOUT } from "../constants";

// components
import LineLoader from "components/LineLoader";
import Avatar from "components/Avatar";
import ClickOutsideListner from "components/ClickOutsideListner";
import { MouseTooltip } from "components/ToolTip";

// services
import APIService from "services/apiService";
import { alertService } from "services/alertService";

// utils
import { getUser, isBlankHTML } from "utils/Common";
import { addMinutes, format, formatTimeAgo, isPast } from "utils/date";

//hooks
import useWindowDimensions from "hooks/useWindowDimensions";
import { useToggle } from "utils/hooks";

// styles
import "./Comments.scss";
import { convertTimeZone } from "../utils";

/*
  Comment types
  1. reason
  2. decline_reason
  3. pending_reason
  4. comment
*/

const statusMap = {
  decline_reason: "rejected",
  approval: "approved",
}

export default function Comments({
  resource_request_id,
  updateCommentCount,
  markRequestAsRead,
  addCommentRef,
  allowEditing = true,
}) {
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState(null);
  const user = getUser();
  const { width } = useWindowDimensions();
  const isDesktopVersion = width > 640;

  useEffect(() => {
    const controller = new AbortController();
    async function getCommentsApi() {
      try {
        const response = await APIService.apiRequest(
          AL_BASE_URL + `/notes/${resource_request_id}`,
          null,
          false,
          "GET",
          controller
        );

        if (response.status === 1) {
          markRequestAsRead(resource_request_id);
          setComments(response.output.filter(c => c.type !== 'reason'));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    getCommentsApi();
    return () => controller.abort();
  }, [resource_request_id]);

  const [comment, setComment] = useState("");
  const [savingComment, setSavingComment] = useState(false);

  const addComment = useCallback((newComment) => {
    setComments((comments) => {
      const isCommentExist = comments.some(
        (comment) => comment.id === newComment.id
      );
      return isCommentExist ? comments : [...comments, newComment];
    });
  }, []);

  useEffect(() => {
    addCommentRef.current = addComment;
  }, [addComment, addCommentRef]);

  async function handleCommentSave() {
    if (savingComment) return;

    if (isBlankHTML(comment)) {
      alertService.info("Comment cannot be blank");
      return;
    }

    try {
      setSavingComment(true);
      const response = await APIService.apiRequest(
        AL_BASE_URL + `/notes/${resource_request_id}`,
        { note: comment },
        false,
        "POST"
      );

      if (response.status === 1) {
        updateCommentCount(comments.length + 1);
        addComment(response.output[0]);

        handleFormClose();
      } else {
        alertService.error(response.msg || response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSavingComment(false);
    }
  }

  function handleFormClose() {
    setComment("");
  }

  function onCommentDelete(commentId) {
    updateCommentCount(comments.length - 1);
    setComments(comments.filter((comment) => comment.id !== commentId));
  }

  function onCommentEdit(commentId, commentNote) {
    setComments(
      comments.map((comment) => {
        if (comment.id === commentId) {
          return {
            ...comment,
            note: commentNote,
          };
        }

        return comment;
      })
    );
  }

  const commentsWrapperRef = useRef(null);
  const commentFormRef = useRef(null);

  useEffect(() => {
    const commentsWrapper = commentsWrapperRef.current;
    const scrollHeight = commentsWrapper?.scrollHeight;

    if (commentsWrapper && scrollHeight > 500) {
      commentsWrapper.classList.add("scroll-y");
      // this will scroll to the bottom of the comments coz the last comment is the latest one.
      commentsWrapper.scrollTop = scrollHeight;
    }

    commentFormRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [comments]);

  return (
    <div className="request-comments">
      {comments?.length > 0 && <div className="comments" ref={commentsWrapperRef}>
        {comments.map((comment) => (
          <Comment
            key={comment.id}
            user={user}
            comment={comment}
            onCommentDelete={onCommentDelete}
            onCommentEdit={onCommentEdit}
            allowEditing={allowEditing}
          />
        ))}
      </div>}
      <div>
        <LineLoader show={loading} position="absolute" />
        {!loading && <div className="editor">
          <Avatar
            imgSrc={user.resource.img_url}
            firstName={user.resource.first_name}
            lastName={user.resource.last_name}
            height={isDesktopVersion ? 30 : 36}
            width={isDesktopVersion ? 30 : 36}
            fontSize={isDesktopVersion ? "12px" : "14px"}
          />
          <CommentTextArea
            ref={commentFormRef}
            value={comment}
            onChange={(value) => setComment(value)}
            onEnter={(value) => {
              setComment(value);
              handleCommentSave();
            }}
            onSave={() => handleCommentSave()}
            disabled={savingComment}
          />
        </div>}
      </div>
    </div>
  );
}

function Comment({
  comment,
  user,
  onCommentDelete,
  onCommentEdit,
  allowEditing = true,
}) {
  // const isDarkMode = document.body.dataset.theme === "dark";
  const { width } = useWindowDimensions();
  const isDesktopVersion = width > 640;
  const author = comment.created_by_name;
  const authorName = `${author.first_name}${author.middle_name ? ` ${author.middle_name}` : ""
    } ${author.last_name}`;

  const createdAt = new Date(convertTimeZone(new Date(comment.created_at)));
  const editOptExpTimestamp = addMinutes(createdAt, COMMENT_TIMEOUT);
  const showOptionsButton = user.id === comment.created_by;

  const status = statusMap[comment.type] || null;

  const [isOptionsPopoverOpen, toggleOptionsPopover] = useToggle(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editComment, setEditComment] = useState(comment?.note);
  const [savingComment, setSavingComment] = useState(false);

  function openEditForm() {
    if (comment.type !== "comment" && comment.type !== "reason") {
      alertService.info(
        `This is the 
        ${comment.type.replace("_", " ")} 
        for the request, thus it cannot be modified`
      );
      return;
    }

    if (isPast(editOptExpTimestamp, new Date(convertTimeZone())) && comment.type !== "reason") {
      alertService.info(
        `A comment cannot be edited after ${COMMENT_TIMEOUT} minutes`
      );
      return;
    }

    if (!allowEditing && comment.type === "reason") {
      alertService.info(
        `You are not allowed to edit this ${comment.type.replace("_", " ")}`
      );
      return;
    }

    setShowEditForm(true);
  }

  function closeEditForm() {
    setShowEditForm(false);
  }

  async function onSave() {
    if (isBlankHTML(editComment)) {
      alertService.info("Comment cannot be blank");
      return;
    }

    try {
      setSavingComment(true);
      let payload = {
        note: editComment,
      };

      const response = await APIService.apiRequest(
        AL_BASE_URL + `/notes/${comment.id}`,
        payload,
        false,
        "PUT"
      );

      if (response.status === 1) {
        onCommentEdit(comment.id, editComment);
        closeEditForm();
      } else {
        alertService.error(response.msg || response.message);
      }
    } catch (error) {
      console.log("Error occured in editing comment: " + error.message);
    } finally {
      setSavingComment(false);
    }
  }

  async function onDelete() {
    if (comment.type !== "comment") {
      alertService.info(
        `This is the ${comment.type.replace(
          "_",
          " "
        )} for the request, thus it cannot be removed`
      );
      return;
    }
    if (isPast(editOptExpTimestamp)) {
      alertService.info(
        `A comment cannot be deleted after ${COMMENT_TIMEOUT} minutes`
      );
      return;
    }

    try {
      setSavingComment(true);
      const response = await APIService.apiRequest(
        AL_BASE_URL + `/notes/${comment.id}`,
        null,
        false,
        "DELETE"
      );

      if (response.status === 1) {
        onCommentDelete(comment.id);
      } else {
        alertService.error(response.msg || response.message);
      }
    } catch (error) {
      console.log("Error occured in deleting comment: " + error.message);
    } finally {
      setSavingComment(false);
    }
  }

  return (
    <div className="comment">
      <div className="comment-avatar">
        <Avatar
          imgSrc={author.img_url}
          firstName={author.first_name}
          lastName={author.last_name}
          height={isDesktopVersion ? 30 : 36}
          width={isDesktopVersion ? 30 : 36}
          fontSize={isDesktopVersion ? "12px" : "14px"}
        />
      </div>
      <div className="comment-author">
        <span className="font-bold">{authorName}</span>
      </div>
      <div className="comment-timestamp">
        <MouseTooltip
          content={format(
            new Date(createdAt),
            "ddd, MMM DD, YYYY, hh:mm A"
          )}
          style={{ lineHeight: "17px" }}
        >
          <span className="comment-label">
            {formatTimeAgo(new Date(createdAt), new Date(convertTimeZone()))}
            {!isDesktopVersion ? format(new Date(createdAt), ' • MMM DD') : ''}
          </span>
        </MouseTooltip>
      </div>
      {showOptionsButton && (
        <div className="note-action-btn">
          <div
            className="pop-over-btn"
            onClick={() => toggleOptionsPopover(true)}
          >
            {isOptionsPopoverOpen && (
              <ClickOutsideListner
                onOutsideClick={() => toggleOptionsPopover(false)}
              >
                <div className="pop-over-options-wrapper">
                  <ul className="pop-over-options">
                    <li
                      className={`${savingComment ? "disabled" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleOptionsPopover();
                        openEditForm();
                      }}
                    >
                      Edit
                    </li>
                    <li
                      className={`${savingComment ? "disabled" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleOptionsPopover();
                        onDelete();
                      }}
                    >
                      Delete
                    </li>
                  </ul>
                </div>
              </ClickOutsideListner>
            )}
          </div>
        </div>
      )}
      <div className="comment-body">
        {status && <div className='request-card-label capitalize'>
          <span className={`icon-${status}`}>{status}</span>
        </div>}
        {!showEditForm ? (
          comment.type !== 'approval' ? <div className="comment-text">
            <StenoRenderer html={comment.note} />
          </div> : null
        ) : (
          <div className="editor note-edit-form">
            <CommentTextArea
              value={editComment}
              onChange={(value) => setEditComment(value)}
              onEnter={(value) => {
                setEditComment(value);
                onSave();
              }}
              onSave={() => onSave()}
              disabled={savingComment}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const CommentTextArea = forwardRef(
  ({ value, onChange, onEnter, onSave, disabled, isEditMode }, ref) => {

    return (
      <div className="comment-text-area" ref={ref}>
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              onEnter(e.target.value);
            }
          }}
          disabled={disabled}
          placeholder="Message"
        >

        </textarea>
        <button
          className="send-button"
          onClick={onSave}
          disabled={!value || disabled}
        >
          <div className="icon icon-send"></div>
        </button>
      </div>
    );
  }
);
