import Avatar from 'components/Avatar';
import './UserProfileDetailsCard.scss';

const UserProfileDetailsCard = ({ user }) => {
    const { department, designation, first_name, last_name, img_url } = user;

    return (
        <div
            className="user-profile-card"
            onClick={e => e.stopPropagation()}
        >
            <Avatar
                imgSrc={img_url}
                firstName={first_name}
                lastName={last_name}
                borderRadius={2}
                fontSize={20}
                height={50}
                width={50}
            />
            <div className='user-details-wrapper'>
                <div className='user-name'>{first_name} {last_name}</div>
                <div className='user-designation'>{designation}</div>
                <div className='user-department'>{department}</div>
            </div>
        </div>
    )
}

export default UserProfileDetailsCard